import React from 'react';

import StubQuery from "../StubQuery";
import StubMutation from "../StubMutation";
import gql from "graphql-tag";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {AdminOnly} from '../../AdminCheck';

const tableMetadataQuery = gql(`query tableSettings($bot_id:ID!, $table:ID!) {
	warehouse(bot_id: $bot_id) {
		table(name: $table) {
			id
			name

			owner_bot_id
			object_type
			frakture_table_type
		}

		 channel_bots {
			 _id
			 label
		 }
	}
}`);
const tableMetadataUpdate = gql(`mutation updateTable($bot_id:ID!, $table:ID!, $update:WarehouseTableMetadataUpdate!) {
	update_warehouse_table_metadata(bot_id:$bot_id, table_name:$table, update:$update) {
		id
		name

		owner_bot_id
		object_type
		frakture_table_type
	}
}`);

class TableSettingsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			owner_bot_id: '',
			object_type:'',
			frakture_table_type:''
		};
	}
	render() {
		const{bot_id}=this.props;
		return <StubQuery query={tableMetadataQuery} variables={this.props}>
			{({data,error,loading}) => {
				if(loading) return 'loading';
				if(error) return 'error:'+error;
				const {table,channel_bots}=data.warehouse;
				const{name,owner_bot_id,object_type,frakture_table_type}=table;
				return <StubMutation mutation={tableMetadataUpdate}>
					{mutation => {
						const updateTable = update => mutation({variables:{bot_id,table:name,update}});
						return <form>
							<FormControl fullWidth>
								<InputLabel>Owner Bot</InputLabel>
								<Select value={owner_bot_id} onChange={e => updateTable({owner_bot_id: e.target.value})}>
									{channel_bots.map(({_id,label},key) => (<MenuItem key={key} value={_id}>{label}</MenuItem>))}
								</Select>
							</FormControl>
							<TextField label='Object Type' fullWidth
								value={this.state.object_type || object_type}
								onBlur={()=>updateTable({object_type:this.state.object_type})}
								onChange={e=>this.setState({object_type:e.target.value})} />
							<TextField label='Table Type' fullWidth
								value={this.state.frakture_table_type || frakture_table_type}
								onBlur={()=>updateTable({frakture_table_type:this.state.frakture_table_type})}
								onChange={e=>this.setState({frakture_table_type:e.target.value})} />
						</form>;
					}}
				</StubMutation>;
			}}
		</StubQuery>;
	}
};

export default class TableSettingsDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			open:false
		};
	}

	open() { this.setState({open:true}); }
	close() { this.setState({open:false}); }

	render() {
		const{table,bot_id}=this.props;

		return <AdminOnly>
			{() => <React.Fragment>
				<i className="zmdi zmdi-settings" onClick={()=>this.open()}/>
				<Dialog open={this.state.open} onClose={()=>this.close()}>
					<DialogTitle>{table} Settings</DialogTitle>
					<DialogContent>
						<TableSettingsForm table={table} bot_id={bot_id}/>
					</DialogContent>
				</Dialog>
			</React.Fragment>}
		</AdminOnly>;
	}
};
