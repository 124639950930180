import React from 'react';
/*import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReportCard from '../ReportCard';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FraktureScorecard from './FraktureScorecard.js';
import FraktureVerticalBarChart from './FraktureVerticalBarChart.js';
import FraktureBarChart from './FraktureBarChart.js';
//import FraktureStackedBarChart from './FraktureStackedBarChart.js';
import {TabContent,TabContainer} from '../../../FraktureTabs';
*/


export default function CompetitiveSpend(props){
	let {data}=props;
	return JSON.stringify(data);
};
