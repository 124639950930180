// eslint-disable-next-line no-unused-vars
import React,{useState} from 'react';
import {Pie,PieChart,Legend,Cell,Tooltip} from "recharts";
import {ResponsiveContainer} from 'recharts';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {formatValue} from '../../../formatters';
import {useColorFunc} from './colors';

export default function FrakturePieChart(props){
	let {metric,label="",data,display_max}=props;
	display_max=display_max||10;
	const get_color=useColorFunc();

	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;
	if (data[0].name==="undefined"){
		throw new Error("Invalid data, no name");
	}

	let key="col1";
	let d=data;

	//If there's too much data, sort it high to low, then group the other records
	if (data.length>display_max){
		data.sort((a,b)=>{
			if (!a.col0) return 1;//blanks go to the end, to maybe be included in 'Other'
			if (!b.col0) return -1;//blanks go to the end, to maybe be included in 'Other'
			return b[key]<a[key]?-1:1;});
		d=data.slice(0,display_max); //only show the first display_max records
		let remainder=data.slice(display_max).reduce((a,b)=>{a[key]+=b[key];return a;},{col0:"(Other)",[key]:0});
		if (remainder[key]>0) d.push(remainder);
		d.forEach(a=>a.col0=!a.col0?"(Blank)":a.col0);
	}

	return <React.Fragment>
		<Grid container className="h-100 report-bar-chart">
			{label && <Typography variant="h6">{label}</Typography>}
			<Grid item md={12} sm={12}>
				<ResponsiveContainer>
					<PieChart>
						<Pie
							dataKey={key}
							nameKey="col0"
							data={d}
							startAngle={450} endAngle={90}
						>
							{
								d.map((entry, index) => <Cell key={`cell-${index}`} fill={get_color(index,entry.col0)} />)
							}
						</Pie>
						<Tooltip formatter={
							(value)=>{
								return formatValue(value,metric.format);
							}
						}/>
						<Legend layout='vertical' verticalAlign="middle" align="right"/>
					</PieChart>
				</ResponsiveContainer>
			</Grid>
		</Grid>
	</React.Fragment>;
};
