import React from 'react';

export default class TableIcon extends React.Component {
	render() {
		let {object_type}=this.props;
		if(this.props.table) {
			object_type=this.props.table.object_type;
			//frakture_table_type=this.props.table.frakture_table_type;
		}

		const{size='large'}=this.props;
		const widths={
			large: '60px',
			medium: '20px',
			small: '20px',
			width: '10px',
		};
		const sizes={
			large: 'zmdi-hc-5x',
			medium: 'zmdi-hc-3x',
			small: 'zmdi-hc-2x',
			tiny: 'zmdi-hc-1x'
		};

		const style={textAlign:'center', width: this.props.width || widths[size]};
		let iconSymbol = 'zmdi-grid';
		switch((object_type||'').toLowerCase()) {
		case 'people':
		case 'person': iconSymbol = 'zmdi-account'; break;
		case 'transactions':
		case 'transaction': iconSymbol='zmdi-money'; break;
		case 'segment': iconSymbol='accounts-alt'; break;
		case 'email':
		case 'emails':
		case 'messages':
		case 'message':
		default: iconSymbol='zmdi-email'; break;

		}

		const className = `zmdi ${sizes[size]} ${iconSymbol}`;
		return <i className={className} style={style}/>;
	}
};
