import React,{useState} from 'react';

import './save-on-blur.css';

export default function SaveOnBlur(props){
	const onChange=props.onChange || ((v)=>{
		console.log("Doing nothing, no onChange specified for value "+v);
	});
	const [input, setInput] = useState({
		value: props.value,
		touched: false,
	});

	const changeHandler = event => {
		//console.log("value changed: ", event.target.value);
		setInput(currentValue => ({
			...currentValue,
			value: event.target.value
		}));
	};

	const blurHandler = () => {
		console.log("input blurred");
		setInput(currentValue => ({
			...currentValue,
			touched: true,
		}));
		onChange(input.value);
	};

	return <input className={'frakture-save-on-blur'}
		placeholder={props.placeholder}
		value={input.value}
		onChange={changeHandler}
		onBlur={blurHandler}
		onFocus={props.onFocus || (()=>{})}
		type={props.type || 'text'}
	/>;
};
