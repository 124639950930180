// eslint-disable-next-line no-unused-vars
import React,{useState} from 'react';
import {
	Bar,
	BarChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,Cell
} from "recharts";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {formatValue} from '../../../formatters';
import {useColorFunc} from './colors';



export default function FraktureVerticalBarChart(props){
	let {metric,data,label="",display_max}=props;
	display_max=display_max||12;
	const get_color=useColorFunc();
	if (!Array.isArray(data)) return "Data is not an array";

	function xFormat(val){return formatValue(val,metric.format);}
	data=data.filter(Boolean);
	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;
	data.forEach(a=>a.col0=!a.col0?"(Blank)":a.col0);

	//If there's too much data, sort it high to low, then group the other records
	let key=metric.alias || metric.label;
	if (data.length>display_max){
		data.sort((a,b)=>{return b[key]<a[key]?-1:1;});
	}

	let d=data.slice(0,display_max); //only show the first display_max records
	let remainder=data.slice(display_max).reduce((a,b)=>{a[key]+=b[key];return a;},{col0:"(Other)",[key]:0});
	if (remainder[key]>0) d.push(remainder);
	let maxLabel=Math.max(...d.map(i=>(i.col0||"").length));

	return <>
		{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}
		<Grid container className="w-100 report-vertical-bar-chart">
			<Grid item md={12} sm={12}>
				<ResponsiveContainer>
					<BarChart
						data={d}
						layout="vertical"
						margin={{top: 5, right: 10, bottom: 5, left: maxLabel*4}}
					>
						<XAxis type="number" dataKey={key} tickFormatter={xFormat} tickCount={20}/>;
						<YAxis interval={0} type="category" dataKey="col0"/>
						<Tooltip label={metric.label} formatter={
							(value)=>{
								return [formatValue(value,metric.format),metric.label || metric.alias];
							}
						}/>
						<Bar dataKey={key}>
							{
								d.map((entry, index) => <Cell key={`cell-${index}`} name={entry.label } dataKey={key} fill={get_color(index,entry.col0)} />)
							}</Bar>
					</BarChart>
				</ResponsiveContainer>
			</Grid>
		</Grid>
	</>;
};
