/* eslint-disable no-restricted-globals */
import React from 'react';
import {useApolloClient} from '@apollo/client';
import gql from 'graphql-tag';
import {useReportId} from './ReportProvider';
import {useUserContext} from '../../../AdminCheck';
import useNotifiers from '../../Notifiers';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const REPORT_DELETE_MUTATION=gql`
mutation REPORT_DELETE_MUTATION($report_id:ID!) {
	report_delete(_id:$report_id) {
		parent {
			_id
			children {
				_id
			}
		}
		account {
			reports {
				_id
			}
		}
	}
}`;

export function useReportDelete(props) {
	const{is_frakture_admin} = useUserContext();

	const report_id = useReportId(props);
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();
	if(!is_frakture_admin) return null;

	return () => {
		if(!confirm('Are you sure you want to delete this report?')) return;
		client.mutate({
			mutation: REPORT_DELETE_MUTATION,
			variables:{report_id}
		}).then(notify,notifyErr);
	};
}

export function DeleteReportIconButton(props) {
	const deleteReport = useReportDelete(props);

	return (
		<IconButton onClick={deleteReport} size="large">
			<DeleteIcon />
		</IconButton>
	);
}
