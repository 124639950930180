import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Tooltip from '@mui/material/Tooltip';

export default function ReadOnlyJob(props) {
	const{job}=props;
	return <ListItem button className="p-1">
		<div>
			<Tooltip title={job.bot.label}>
				<img alt="icon" key="icon" src={job.bot.definition.metadata.logo} className="rounded" width="80"/>
			</Tooltip>
			<br/>
		</div>
		<div className="p-2">{job.submodule}</div>
		<ListItemText className="p-1 font-weight-bold text-right" primary={job.label} />
	</ListItem>;
};
