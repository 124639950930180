import React,{useState} from 'react';
import queryString from 'query-string';
import {useLocation,useNavigate} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import ReactSelect from 'react-select';
import Switch from '@mui/material/Switch';
import {relativeDate} from "../formatters.js";
import DatePicker from "react-datepicker";
import {fiscalYearStart} from '../../util/fiscal';

import "react-datepicker/dist/react-datepicker.css";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
//import ObjectTypeConfig from 'app/bots/BotObjectType.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");


function TextFieldInput(props){
	return (<FormControl component="fieldset">
		<TextField
			variant="standard"
			name={props.name}
			inputRef={props.register}
			label={props.label}
			style={props.style}
			placeholder={props.placeholder}
			defaultValue={props.defaultValue}
			value={props.value}
			onChange={props.onChange}
		/>
	</FormControl>);
}

let thisYear=parseInt(new Date().toISOString().slice(0,4));
const dateRanges=[
	{start:"-2d",end:"now",label:"Last 2 days"},
	{start:"-3d",end:"now",label:"Last 3 days"},
	{start:"-7d",end:"now",label:"Last 7 days"},
	{start:"-14d",end:"now",label:"Last 14 days"},
	{start:"-30d",end:"now",label:"Last 30 days"},
	{start:"-45d",end:"now",label:"Last 45 days"},
	{start:"-60d",end:"now",label:"Last 60 days"},
	{start:"-3M",end:"now",label:"Last 3 months"},
	{start:"-6M",end:"now",label:"Last 6 months"},
	{start:"-1y",end:"now",label:"Last 12 months"},
	{start:"-2y",end:"now",label:"Last 24 months"},
	{start:"-3y",end:"now",label:"Last 3 years"},
	{start:"-4y",end:"now",label:"Last 4 years"},
	{start:"-5y",end:"now",label:"Last 5 years"},
	{start:"-10y",end:"now",label:"Last 10 years"},
	{start:"-15y",end:"now",label:"Last 15 years"},
	{start:"-100y",end:"now",label:"All time"},
	{start:"-0y.start.y",end:"now",label:"Year-To-Date"},
	{start:"-0M.start.month",end:"-0M.end.month",label:function(){return dayjs().format("MMMM, YYYY");}},
	{start:"-1M.start.month",end:"-1M.end.month",label:function(){return dayjs().subtract(1,'month').format("MMMM, YYYY");}},
	{start:"-2M.start.month",end:"-2M.end.month",label:function(){return dayjs().subtract(2,'month').format("MMMM, YYYY");}},
	{start:"-3M.start.month",end:"-3M.end.month",label:function(){return dayjs().subtract(3,'month').format("MMMM, YYYY");}},
	{start:"-1y.start.y",end:"-1y.end.y",label:function(){return new Date().getFullYear()-1;}},
	{start:"-2y.start.y",end:"-1y.end.y",label:function(){let x=new Date().getFullYear(); return (x-2)+"-"+(x-1);}},
	{start:"-3y.start.y",end:"-1y.end.y",label:function(){let x=new Date().getFullYear(); return (x-3)+"-"+(x-1);}},
	{start:"-5y.start.y",end:"-1y.end.y",label:function(){let x=new Date().getFullYear(); return (x-5)+"-"+(x-1);}},
	{start:"-10y.start.y",end:"-1y.end.y",label:function(){let x=new Date().getFullYear(); return (x-10)+"-"+(x-1);}},
	{start:"-20y.start.y",end:"-1y.end.y",label:function(){let x=new Date().getFullYear(); return (x-20)+"-"+(x-1);}},
].concat([
	thisYear+1,thisYear,thisYear-1,thisYear-2,
	thisYear-3,thisYear-4,thisYear-5,thisYear-6,
	thisYear-7,thisYear-8,thisYear-9,thisYear-10].map(y=>{
	return {start:"FY"+y,end:"FY"+(y+1),label:"FY"+y};
}
));

export function QuickDateRange2(props){
	const navigate=useNavigate();
	const location=useLocation();
	let qs=props.query_string || queryString.parse(location.search || ""); //Query string COULD be coming in from the server
	const initStart=relativeDate(qs.start || props.start || '-30d');
	const initEnd=relativeDate(qs.end || props.end || 'now');
	let fiscal_year=props.fiscal_year || "01-01";

	const [startDisplayDate, setStartDisplayDate] = useState(initStart);
	const [endDisplayDate, setEndDisplayDate] = useState(initEnd);
	const [startDate, setStartDate] = useState(initStart);
	const [endDate, setEndDate] = useState(initEnd);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpen = (event) => {
		setStartDate(initStart);
		setEndDate(initEnd);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);


	const onChange = (start,end) => {
		if (start){
			if (typeof start=='string'){
				let [_start,_end]=start.split("~");
				if (_start.indexOf('FY')===0){
					start=fiscalYearStart(fiscal_year,parseInt(_start.slice(2)));
				}else{
					start=relativeDate(_start);
				}
				if (_end.indexOf('FY')===0){
					end=fiscalYearStart(fiscal_year,parseInt(_end.slice(2)));
				}else{
					end=relativeDate(_end);
				}
			}
			if (!isNaN(start)) setStartDate(start);
		}
		if (!isNaN(end)){
			setEndDate(end);
		}
	};
	const onApply=()=>{
		qs.start=dayjs(startDate).toISOString().slice(0,10);
		qs.end=dayjs(endDate).toISOString().slice(0,10);
		setStartDisplayDate(startDate);
		setEndDisplayDate(endDate);
		navigate(Object.assign({},location,{search:queryString.stringify(qs)}));
		handleClose();
	};
	/* This is ... weird -- react-datepicker is weird about dates */
	function getCorrectDatePickerDisplayDate(d){
		return dayjs(d).utcOffset(-2*(new Date().getTimezoneOffset()),true).toDate();
	}

	return	<div className="date-range-picker">
		<Button className="text-nowrap" onClick={handleOpen}>
			<i className={`zmdi zmdi-calendar`}/>
			{dayjs(startDisplayDate).utc().format("MMM DD, YYYY")+" - "+dayjs(endDisplayDate).utc().format("MMM DD, YYYY")}
		</Button>
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<div id="date-range-picker-wrapper">
				<Card>
					<CardContent>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<h4>Select a date range</h4>
							</Grid>
							<Grid item xs={12} md={8}>
								<div className="date-range-quick-date"><ReactSelect placeholder={"Quick Date ..."} value={''} options={dateRanges.map(d=>({label:typeof d.label=='function'?d.label():d.label,value:d.start+"~"+d.end}))} onChange={x => onChange(x.value)} /></div>
							</Grid>
						</Grid>
						<hr/>
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<DatePicker
									selected={getCorrectDatePickerDisplayDate(startDate)}
									onChange={d=>onChange(d)}
									inline
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<DatePicker
									selected={getCorrectDatePickerDisplayDate(endDate)}
									onChange={d=>onChange(null,d)}
									inline
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Button style={{marginLeft: 'auto'}}variant="contained" color="primary" onClick={onApply}>Apply</Button>
					</CardActions>
				</Card>
			</div>
		</Popover></div>;
}


export function SegmentPicker(props){
	const navigate=useNavigate();
	const location=useLocation();
	let qs=queryString.parse(location.search || "");
	let options=props.data;

	function handleChange(event){
		let value=event.target.value;
		qs.segment_id=value;
		navigate(Object.assign({},location,{search:queryString.stringify(qs)}));
	}

	let value=qs.segment_id;

	return <div>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<FormControl>
				<InputLabel htmlFor="quick-date-range">Segment</InputLabel>
				<Select
					native
					value={value}
					onChange={e=>handleChange(e)}
				>
					{options.map((d,i)=><option key={i} value={d.segment_id}>{d.label}</option>)}
				</Select>
			</FormControl>
		</LocalizationProvider>
	</div>;
}

export function useChangeURL(){
	const navigate=useNavigate();
	const location=useLocation();
	let query=queryString.parse(location.search || "");

	function setURLParameters(o) {
		for (let key in o){
			query[key]=o[key];
		}
		navigate({location:location.pathname,search:queryString.stringify(query)});
	}

	return {setURLParameters,query};
}


function QueryStringForm(props){
	const navigate=useNavigate();
	const location=useLocation();
	let query=queryString.parse(location.search || "");
	const [values,setValues]=React.useState(query);

	function handleChange(event) {
		let o=JSON.parse(JSON.stringify(values ||{}));
		if (event.start!==undefined){//an object
			Object.assign(o,event);
		}else if (event && event.target && event.target.name){
			if (!event.target.value){
				delete o[event.target.name];
			}else{
				o[event.target.name]=[event.target.value];
			}
		}
		setValues(o);
	}

	function handleSubmit(e) {
		console.log("Calling handle submit");
		navigate({location:location.pathname,search:queryString.stringify(values)});
		if (props.onSubmit)props.onSubmit();
		e.preventDefault();
	}

	return (
		<form onSubmit={handleSubmit}>
			<button type="submit" style={{display:"none"}}/>{/* Required to submit it?  Weird*/}
			{props.children({onChange:handleChange,values,submit:handleSubmit})}
		</form>
	);
}


function ToggleSwitch(props){

	const checked=(props.value===true||props.value==='true' || props.value?.[0]==='true'|| props.value?.[0]===true);

	return (
		<FormControlLabel control={<Switch
			name={props.name}
			checked={checked}
			onChange={function(e){
				if (checked){
					props.onChange({target:{name:props.name,value:false}});
				}else{
					props.onChange({target:{name:props.name,value:true}});
				}
			}}
			value="true"
		/>} label={props.label} />
	);
};

function QuerySwitch(props){
	const {useHash=false}=props;
	const location=useLocation();
	const navigate=useNavigate();
	let s=(useHash?location.hash:location.search)||"";

	let query=queryString.parse(s);

	const checked=query[props.name]!==undefined;
	function onChange(e){
		if (e.target.checked){
			query[props.name]=true;
		}else{
			delete query[props.name];
		}

		//Set the hash directly so it doesn't trigger a refresh
		if (useHash){
			const newLocation = Object.assign({},location, {hash:queryString.stringify(query)});
			navigate(newLocation);
		}else{
			const newLocation = Object.assign({},location, {search:queryString.stringify(query)});
			navigate(newLocation);
		}
	}

	return (
		<FormControlLabel control={<Switch
			name={props.name}
			checked={checked}
			onChange={onChange}
			value="true"
		/>} label={props.label} />
	);
};


function QuickDateRange(props){
	const location=useLocation();
	const query=queryString.parse(location.search) ||{};
	let {start:queryStart,end:queryEnd } = query;
	let {start,end}=props;
	if (!queryStart) queryStart=start || "";
	if (!queryEnd) queryEnd=end||"";
	// let {start,end}=this.props;
	let initValue=(queryStart||"")+"~"+(queryEnd||"");

	const [value,setValue]=React.useState(initValue);

	function dateChange(event){
		if (!props.onChange){
			throw new Error("handleChange(event) is a required property");
		}
		setValue(event.target.value);
		let [startVal,endVal]=(event.target.value||"").split("~");
		props.onChange({start:startVal,end:endVal});
	};

	return (
		<FormControl>
			{/*<InputLabel htmlFor="quick-date-range">Date Range</InputLabel>*/}
			<Select
				native
				variant="standard"
				value={value}
				onChange={dateChange}
				inputProps={{
					id: 'quick-date-range',
				}}
			>
				<option value="" />
				<optgroup label="Through Today">
					<option value={"-1h~now"}>Last hour</option>
					<option value={"-3h~now"}>Last 3 hours</option>
					<option value={"-6h~now"}>Last 6 hours</option>
					<option value={"-12hnow~"}>Last 12 hours</option>
					<option value={"-1d~now"}>Last 24 hours</option>
					<option value={"-2d~now"}>Last 2 days</option>
					<option value={"-3d~now"}>Last 3 days</option>
					<option value={"-7d~now"}>Last 7 days</option>
					<option value={"-14d~now"}>Last 14 days</option>
					<option value={"-30d~now"}>Last 30 days</option>
					<option value={"-3M~now"}>Last 3 months</option>
					<option value={"-6M~now"}>Last 6 months</option>
					<option value={"-1y~now"}>Last 12 months</option>
					<option value={"-2y~now"}>Last 24 months</option>
					<option value={"-0y.start.y~now"}>YTD</option>
					<option value={"-100y~"}>All time</option>
				</optgroup>
				<optgroup label="Historical">
					<option value={"-1M.start.month~-1M.end.month"}>Last Month</option>
					<option value={"-1y.start.y~-1y.end.y"}>Last Year</option>
					<option value={"-1y.start.y~-0y.end.y"}>This Year</option>
					<option value={"~-1h"}>1+ Hour Ago</option>
					<option value={"~-3h"}>3+ Hours Ago</option>
					<option value={"~-6h"}>6+ Hours Ago</option>
					<option value={"~-12h"}>12+ Hours Ago</option>
					<option value={"~-1d"}>1+ Days Ago</option>
					<option value={"~-2d"}>2+ Days Ago</option>
					<option value={"~-3d"}>3+ Days Ago</option>
					<option value={"~-7d"}>7+ Days Ago</option>
				</optgroup>
			</Select>
		</FormControl>
	);
};

function useSetHash(){
	const location=useLocation();
	const navigate = useNavigate();
	return function(name,value){
		let hash=queryString.parse(location.hash||"");
		if (!value) delete hash[name];
		else hash[name]=value;
		location.hash=queryString.stringify(hash);

		navigate(JSON.parse(JSON.stringify(location)));
	};
};

export {QuickDateRange,QuerySwitch,ToggleSwitch, TextFieldInput, QueryStringForm,useSetHash};
