import React from 'react';
import gql from 'graphql-tag';

import {Link,useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

export function useDataflowId(props) {
	const params = useParams();
	if(props.dataflow_id) return props.dataflow_id;
	if(!params.dataflow_id) throw new Error('no dataflow_id found in parameters');
	return params.dataflow_id;
};

const DATAFLOW_LINK_QUERY=gql`
query dataflow($dataflow_id: ID!) {
	dataflow(_id:$dataflow_id) {
		_id
		account_id
		label
	}
}`;

export function DataflowLink(props) {
	const{dataflow_id}=props;
	const {data,loading,error} = useQuery(DATAFLOW_LINK_QUERY, {
		variables: {dataflow_id}
	});
	if(loading) return '';
	if(error) return 'error';
	const {_id,account_id,label}=data.dataflow || {};
	return <Link to={`/app/${account_id}/dataflow/${_id}`}>
		{props.label || label || _id}
	</Link>;
}
