import React from 'react';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {DICTIONARY_BOT_QUERY} from '../warehouse/account-warehouse-info.js';
import FraktureDataTable from '../warehouse/FraktureDataTable';



export default function Cleanup() {
	let {account_id}=useParams();
	const {data}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (!data) return "";
	const {account:{default_warehouse_bot:{_id:warehouse_bot_id,global_table_prefix}}}=data;

	let table=global_table_prefix+"source_code_label";
	let fields=[
		{field:"id",title:"Id",width:60},
		{field:"element",title:"Element",width:200},
		{field:"value",title:"Value",width:200},
		{field:"label",title:"Label",width:400},
	];

	return <div className="d-flex flex-column h-100 cleanup-wrapper"><Grid container className="h-100">
		<Grid item><FraktureDataTable
			view="grid"
			table={table}
			warehouse_bot_id={warehouse_bot_id}
			limit={10000}
			fields={fields}
			includeInsert={true}
			insertDefaultFunction={()=>({source_code_element:"",label:"",value:""})}
		/></Grid>
	</Grid></div>;
};
