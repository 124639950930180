import React from 'react';

import useNotifiers from '../../Notifiers';
import gql from "graphql-tag";

import TextField from '@mui/material/TextField';
import {useUserContext} from '../../../AdminCheck';
import {useApolloClient} from '@apollo/client';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SET_WAREHOUSE_MUTATION=gql`mutation setWarehouse($_id:ID!,$warehouse_bot_id:ID!){
	bot_set_warehouse(_id:$_id,warehouse_bot_id:$warehouse_bot_id) {
		_id
		warehouse_bot_id
		warehouse_bot_id_raw
		warehouse_table_prefix
	}
}`;
const SET_WAREHOUSE_PREFIX_MUTATION=gql`mutation setWarehousePrefix($_id:ID!,$warehouse_table_prefix:String!){
	bot_set_warehouse_prefix(_id:$_id,warehouse_table_prefix:$warehouse_table_prefix) {
		_id
		warehouse_bot_id
		warehouse_bot_id_raw
		warehouse_table_prefix
	}
}`;

export default function WarehouseConfiguration(props){
	const{is_frakture_admin}=useUserContext();

	const client = useApolloClient();
	const{bot:{_id,warehouse_bot_id_raw,warehouse_table_prefix,account}}=props;
	const {notify,notifyErr} = useNotifiers();

	const mutateWarehouse = (value) => client.mutate({
		mutation:SET_WAREHOUSE_MUTATION,
		variables:{_id,warehouse_bot_id:value==='default'?'':value}
	}).then(notify,notifyErr);
	const mutatePrefix = (value) => client.mutate({
		mutation:SET_WAREHOUSE_PREFIX_MUTATION,
		variables:{_id,warehouse_table_prefix:value}
	}).then(notify,notifyErr);
	let w=warehouse_bot_id_raw || "default";

	return <React.Fragment>
		<FormControl fullWidth>
			<InputLabel htmlFor="warehouse_bot_id">Warehouse Bot</InputLabel>
			<Select disabled={!is_frakture_admin} value={w} onChange={e => mutateWarehouse(e.target.value)}>
				<MenuItem key='default' value="default">(Account Default)</MenuItem>
				{account.bots.filter(d=>d.definition.metadata.isWarehouse).map(({_id:i,label}) => {
					return <MenuItem key={i} value={i}>{i}{label}</MenuItem>;
				})}
			</Select>
		</FormControl>
		<TextField
			margin="dense"
			label="Warehouse Table Prefix"
			defaultValue={warehouse_table_prefix}
			fullWidth
			onKeyPress={(e) => {
				if (e.key === 'Enter') {
					mutatePrefix(e.target.value);
					e.preventDefault();
				}
			}}
			inputProps={{onBlur:e=>{
				if ((warehouse_table_prefix||"")===e.target.value){
					return;
				}
				mutatePrefix(e.target.value);
				return true;
			}}}
			helperText="Prefix used for tables -- default <bot_id>_"
			disabled={!is_frakture_admin}
		/>

	</React.Fragment>;
};
