import React from 'react';

import gql from 'graphql-tag';

import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useAccountId} from '../AccountInfo';

import {useQuery} from '@apollo/client';
import {useUserContext} from '../../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import useNotifiers from '../../Notifiers';


import CardContent from '@mui/material/CardContent';

const ACCOUNT_UPDATE=gql`
mutation ACCOUNT_UPDATE($_id:ID!,$update:JSON!){
	account_update(_id:$_id,update:$update) {
		_id
		default_warehouse_bot_id
	}
}`;

const DEFAULT_WAREHOUSE_QUERY=gql`
query DEFAULT_WAREHOUSE_QUERY($account_id:ID!){
	account(_id:$account_id) {
		_id
		default_warehouse_bot_id
		default_warehouse_bot{
			 _id
		 }
		 bots {
	 		_id
	 		label
	 		path
	 		definition {
				_id
	 			metadata {
	 				isWarehouse
	 				picture
	 				logo
	 				thumbnail
	 				channel
	 			}
	 		}
	 	}
	}

}`;

export default function(props){
	const {is_frakture_admin}=useUserContext();
	const account_id = useAccountId(props);
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();

	const {data,loading,error}=useQuery(DEFAULT_WAREHOUSE_QUERY,{variables:{account_id}});
	if(loading) return '';
	if(error) {
		console.error(error);
		return '';
	}
	if(!is_frakture_admin) return null;

	const {account}=data;
	let {bots,default_warehouse_bot_id}=account;
	//default_warehouse_bot=default_warehouse_bot||{};

	function mutateLocation(_id){
		if (_id==="__none__") _id="";
		client.mutate({mutation:ACCOUNT_UPDATE,
			refetchQueries:["DEFAULT_WAREHOUSE_QUERY"],
			variables:{_id:account_id,update:{default_warehouse_bot_id:_id}}}).then(notify,notifyErr);
	};
	return <CardContent>
		<FormControl fullWidth>
			<Select
				value={default_warehouse_bot_id || "__none__"}
				onChange={e => mutateLocation(e.target.value)}
				displayEmpty={true}
			>
				<MenuItem key={"inherit"} value={"__none__"}>(Default)</MenuItem>
				{bots.filter(d=>d.definition.metadata.isWarehouse).map(bot => {
					const {_id:i,label}=bot;
					return <MenuItem key={i} value={i}>{label}</MenuItem>;
				})}
			</Select>
		</FormControl>
	</CardContent>;
};
