import React from "react";
import Grid from '@mui/material/Grid';
import gql from "graphql-tag";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import FraktureDataTable from '../warehouse/FraktureDataTable';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CardContent from '@mui/material/CardContent';
import {FraktureSimpleQuery} from "../FraktureQuery.js";
import Inputs from "./inputs";
import {NavLink,useParams} from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Settings from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import {base32} from "../formatters";
import dayjs from "dayjs";
import json5 from "json5";
import urlParse from 'url-parse';

const accountGroupsQuery=gql`query accountGroupsQuery($account_id: ID!) {
	account(_id: $account_id) {
		_id
		groups {
			_id
			name
		}
	}
}`;


const formatsQuery=gql`query formats($account_id:String!,$group_id:ID){
	account(_id: $account_id) {
		source_code_config(group_id:$group_id){
			formats{
				_id
				format
				label
				description
			}
			bundles{
				_id
				label
				description
				definition
			}
		}
	}
}`;

const sourceCodeQuery=gql`query elements($account_id:String!,$group_id:ID){
	account(_id: $account_id) {
		source_code_element_sets{
	  _id
	  parent_id
	  label
	  elements{
		name
		label
				type
				description
				source_code_element_set_id
				values{
					label
					value
				}
	  }
	}
	}
}`;
const accountBots=gql(`
query botQuery($account_id: ID!) {
	account(_id: $account_id) {
	default_warehouse_bot{
	  _id
	  label
			global_table_prefix
	}
	}
}`);

function renderSourceCode(format,values){
	if (!format) return "No format";
	let f=format.replace(/{{(.+?)}}/g,(match,capture)=>{
		return values[capture]||"";
	});
	//return <div>{format}<br/>{JSON.stringify(values)}<br/>{f}</div>;
	return f;
	//Doesn't support underscores in element names
	//return format.replace(/%[a-z0-9]+/gi,v=>{return values[v.slice(1)]||"";});
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box p={3}>{children}</Box>
		</Typography>
	);
}

function BundleTabs(props){
	const {bundles,format,values}=props;

	const [landingPage, setLandingPage] = React.useState("");
	const [tab, setTab] = React.useState(0);
	//let source=u.query.refcode || u.query.source || u.query.src || u.query.s_src || u.query.utm_source || u.query.ref || u.query["ea.tracking.id"]||"";
	function getSourceParameter(parsed){
		switch (parsed.hostname){
		case "act.nrdc.org": return "source";
		case "www.nrdc.org": return "utm_source";
		default:
		}

		switch (parsed.hostname.split(".").slice(-2).join(".")){
		case "actblue.com": return "refcode";
		case "convio.net": return "s_src";
		default:
		}
		//Engaging networks
		if (parsed.query.ea_tracking_id) return "ea_tracking_id";
		return "utm_source";
	}

	function getLandingPage(s){
		if (!landingPage) return "";
		let parsed=new urlParse(landingPage,true);
		let param=getSourceParameter(parsed);
		parsed.query[param]=s;
		return parsed.toString();
	}
	let mainLandingPage=getLandingPage(renderSourceCode(format,values));
	return (
		<React.Fragment>
			<Tabs value={tab} onChange={(e,n)=>setTab(n)} aria-label="Prefill tabs">
				<Tab key={0} label="Landing page"/>
				{bundles.map((b,i)=>{
					return <Tab key={i} label={b.label}/>;
				})}
			</Tabs>
			<TabPanel value={tab} index={0}>
				{mainLandingPage?<div>
					<IconButton onClick={()=>setLandingPage("")} size="large"><CloseIcon/></IconButton>
					<b>{mainLandingPage}</b></div>:
					<TextField
						className="w-100"
						id="landing-page"
						label="Set Landing Page"
						defaultValue={landingPage}
						onBlur={e=>setLandingPage(e.target.value)}
						onKeyPress={(ev) => {
							if (ev.key === 'Enter') {
								setLandingPage(ev.target.value);
								ev.preventDefault();
							}
						}}
					/>
				}
			</TabPanel>
			{bundles.map((b,i)=>{
				try{
					let definition=json5.parse(b.definition||"{}");
					let arr=[];
					for (let el in definition.elements){
						definition.elements[el].forEach(v=>{
							let o=Object.assign({},values);
							o[el]=v;
							o.label=v;
							arr.push(o);
						});
					}
					return <TabPanel value={tab} index={i+1}><table>{arr.map((o,k)=>{
						return <tr key={k}><th>{o.label}</th><td>{getLandingPage(renderSourceCode(format,o))}</td></tr>;
					})}</table>
					</TabPanel>;
				}catch(e){
					console.error(b.definition,e);
					return b.label+" has an invalid definition";
				}
			})}</React.Fragment>
	);
};

export default function SourceCodeSummary() {
	const {account_id}=useParams();

	const [values, setValues] = React.useState(
		{uid:base32.encode(new Date().getTime()),
			date:dayjs().format("YYYYMMDD")
		});
	const [group_id, setGroupId] = React.useState(null);

	function changeVals(o){
		let newVals=Object.assign({},values);
		for (let i in o){
			newVals[i]=o[i];
		}
		newVals.uid=base32.encode(new Date().getTime());
		if (!newVals.date) newVals.date=dayjs(values.date).format("YYYYMMDD");
		if (newVals.date.length!==8) newVals.date=dayjs(newVals.date).format("YYYYMMDD");
		setValues(newVals);
	}

	return (
		<Grid container spacing={8} className="h-100">
			<Grid item xs={12}>
				<FraktureSimpleQuery query={accountGroupsQuery} variables={{account_id}}>
					{groupData=>{
						if (!groupData.account) return "No data";
						const groups=groupData.account.groups;

						return (
							<FraktureSimpleQuery query={formatsQuery} variables={{account_id,group_id}}>
								{data=>{
									let {formats,bundles}=data.account.source_code_config;
									if (formats.length===0) return "No formats";
									let source_code=renderSourceCode(formats[0].format,values) || "No code for "+formats[0].format;

									let configButton=<NavLink to={"/app/"+account_id+"/sourcecode/config"}>
										<Tooltip title='Edit'>
											<IconButton edge="end" aria-label="Settings" size="large">
												<Settings />
											</IconButton>
										</Tooltip>
									</NavLink>;
									if (true && configButton){}; //not using

									return <Grid item xs={12}>
										<Card>
											<CardContent>
												<Grid container>
													<Grid item md={6}>
														<h2>Source Code Generator</h2>
														<div>
															<h3><b>{source_code}</b></h3>
															<small>({source_code.length} chars)</small>
														</div>
													</Grid>
													<Grid item md={6}>
														{groups.length>0 &&
														<React.Fragment>
															<h4>Preview user group</h4>
															<Select value={group_id||""} onChange={e => setGroupId(e.target.value)}>
																<MenuItem key={0} value={""}>(Admin User Group)</MenuItem>
																{groups.map((g) => {
																	let {_id,name}=g;
																	return <MenuItem key={_id} value={_id}>{name}</MenuItem>;
																})}
															</Select>
														</React.Fragment>
														}
														<BundleTabs bundles={bundles} values={values} format={formats[0].format}/>
													</Grid>
												</Grid>
											</CardContent>
										</Card>
										<FraktureSimpleQuery query={sourceCodeQuery} variables={{account_id,group_id}}>
											{data=>{
												let elements=[];
												data.account.source_code_element_sets.forEach(set=>{
													elements=elements.concat(set.elements).filter(Boolean);
												});
												//let {elements, values:valueOptions}=data.account.source_code_config;
												//elements=elements.sort((a,b)=>a.sort_order<b.sort_order?-1:1);
												//valueOptions=valueOptions.sort((a,b)=>a.sort_order<b.sort_order?-1:1);
												return <Inputs values={values} account_id={account_id} source_code_elements={elements} onChange={vals=>changeVals(vals)}/>;
											}}
										</FraktureSimpleQuery>
									</Grid>;
								}}
							</FraktureSimpleQuery>
						);
					}}
				</FraktureSimpleQuery>
			</Grid>
			<Grid item xs={12}>
				<FraktureSimpleQuery query={accountBots} variables={{account_id}}>
					{data => {
						let {default_warehouse_bot:warehouse_bot}=data.account || {};
						let global_table_prefix=warehouse_bot.global_table_prefix;

						if (!warehouse_bot|| !warehouse_bot._id) return "Invalid warehouse bot id:"+warehouse_bot._id;
						return (
							<Card><CardHeader title="Recent Source Codes"/>
								<FraktureDataTable bot_id={warehouse_bot._id} table={global_table_prefix+"source_code_dictionary"} query={{
									elements:[
										{alias:"Source Code",fql:"source_code"},
										{alias:"Date Created",fql:"date_created"}
									],
									order_by:{fql:"date_created",order_by_direction:"DESC"},
									limit: 50
								}}/>
							</Card>
						);
					}}</FraktureSimpleQuery>
			</Grid>
		</Grid>
	);
};
