import React from 'react';
import Typography from '@mui/material/Typography';

export default function FraktureScorecard(props){
	let {label,size="md",data}=props;

	//'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'subtitle1', 'subtitle2', 'body1', 'body2', 'caption', 'button', 'overline', 'srOnly', 'inherit'
	let variants=['h6','h4'];
	switch(size){
	case "xxs": variants=['subtitle1','body1']; break;
	case "xs": variants=['subtitle1','body1']; break;
	case "sm": variants=['body1','h6']; break;
	case "md": variants=['h6','h4']; break;
	case "lg": variants=['h4','h2']; break;
	}

	if (data.length>1){return "Invalid label, more than one result";}
	if (data.length===0){return "Invalid label, no results";}
	let val=Object.values(data[0])[0];
	return (
		<div className="scorecard" style={{textAlign:"center"}}>
			{label && <Typography fontWeight="fontWeightBold" className="report-item-label" variant={variants[0]}><span className="resize1.5">{label}</span></Typography>}
			<Typography variant={variants[1]} className="report-scorecard-stat">{val}</Typography>
		</div>
	);
};
