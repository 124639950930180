import * as React from 'react';
import { Link,useLocation } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
/*import ListSubheader from '@mui/material/ListSubheader';*/
import {useAccountId} from '../account/AccountInfo';
import {useAdmin} from '../../AdminCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGaugeMax,faChartPie,faDatabase,faMessage,faBoxDollar,faCode,faBinaryCircleCheck,faBookSection,
	faMagnifyingGlass,faRobot,faForklift,faGear,
	faPersonFromPortal,faCircleQuestion,faTag,faChevronDown,faCircleExclamation
} from '@fortawesome/pro-light-svg-icons';
//import { LinkSharp } from '@mui/icons-material';


export function SidebarMenuItem({label,icon,path,exactPath,buttonSX,iconSX,tooltip,children,open=false}){
	const location = useLocation();
	if (children && children.length>0){
		return <Accordion
			className="menuAccordion sidebar-item"
			defaultExpanded={open}
		>
			<AccordionSummary
				expandIcon={<FontAwesomeIcon icon={faChevronDown} className="sidebar-icon"/>}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				<ListItem>
					{icon?<ListItemIcon className="sidebar-icon">
						<FontAwesomeIcon icon={icon} fixedWidth/>
					</ListItemIcon>:""}
					{label && <ListItemText primary={label}/>}
				</ListItem>
			</AccordionSummary>
			<AccordionDetails sx={{padding:"0x 12px"}}>
				{children.map((child,key)=><SidebarMenuItem key={key} {...child}/>)}
			</AccordionDetails>
		</Accordion>;
	}
	if (!path){
		return <ListItem>
			<ListItemText
				primary={label}
			/>
		</ListItem>;
	}
	let selected=false;
	let pathPart=path.split("?")[0];
	if (exactPath){
		if (location.pathname===path){
			selected=true;
		}
	}else if (location.pathname.indexOf(pathPart)===0){
		selected=true;
	}

	let o=<ListItemButton disableRipple className="sidebar-item" component={Link} to={path} sx={buttonSX} selected={selected}>
		{icon?<ListItemIcon className="sidebar-icon" sx={iconSX}>
			<FontAwesomeIcon icon={icon} fixedWidth/>
		</ListItemIcon>:""}
		{label && <ListItemText primary={label}/>}
	</ListItemButton>;
	if (tooltip) return <Tooltip placement="right" arrow title={tooltip}>{o}</Tooltip>;
	else return o;
}

export function useAccountMenuItems(props) {
	const isAdmin=useAdmin();
	const account_id = useAccountId(props||{});
	if (!account_id) return null;
	let links=[
		{
			label:"Dashboard",
			iconName:"gauge-max",
			icon:faGaugeMax,
			path:`/app/${account_id}`,
			exactPath:true
		},

		{label:"Reports",
			iconName:"chart-pie",
			icon:faChartPie,
			path:`/app/${account_id}/report`
		},

		{label:"Messages",
			iconName:"message",
			icon:faMessage,
			path:`/app/${account_id}/messages`
		},

		{label:"Transactions",
			iconName:"box-dollar",
			icon:faBoxDollar,
			path:`/app/${account_id}/sourcecode/transactions`
		},
		{label:"Source Codes",
			iconName:"code",
			icon:faCode,
			children:[
				{label:"Dictionary",
					iconName:"book-section",
					icon:faBookSection,
					path:`/app/${account_id}/sourcecode/dictionary`
				},
				{label:"Formats",
					iconName:"binary-circle-check",
					icon:faBinaryCircleCheck,
					path:`/app/${account_id}/sourcecode/formats`
				},
				{label:"By Message",
					iconName:"message",
					icon:faMessage,
					path:`/app/${account_id}/messages/sheet`
				},
				{label:"Labels",
					iconName:"tag",
					icon:faTag,
					path:`/app/${account_id}/sourcecode/labels`
				},
				{label:"Search",
					iconName:"magnifying-glass",
					icon:faMagnifyingGlass,
					path:`/app/${account_id}/sourcecode/search`
				},
			]
		},

		{label:"Bots",
			iconName:"robot",
			icon:faRobot,
			path:`/app/${account_id}/bot`
		},

		{label:"Jobs",
			iconName:"forklift",
			icon:faForklift,
			path:`/app/${account_id}/job`
		},
		{label:"Warehouse",
			iconName:"cloud",
			icon:faDatabase,
			path:`/app/${account_id}/warehouse`
		}
	];
	if (isAdmin){
		links.push(
			{label:"Settings",
				iconName:"wrench",
				icon:faGear,
				path:`/app/${account_id}/settings`
			});
	}
	return links;
}

export function useAgencyMenuItems(props) {
	const account_id = useAccountId(props||{});
	if (!account_id) return null;
	return [
		{label:"Errored Jobs",
			iconName:"forklift",
			icon:faCircleExclamation,
			path:`/app/${account_id}/multiaccount/job?status=error&parent_account_id=${account_id}`
		},
		{label:"Parent Settings",
			iconName:"gauge-max",
			icon:faGear,
			path:`/app/${account_id}/constellation/settings`
		}
	];
}

export function useUserMenuItems(){
	return [
		{label:"Log Out",
			iconName:"person-from-portal",
			icon:faPersonFromPortal},
		{label:"Help",
			iconName:"circle-question",
			icon:faCircleQuestion}
	];
}
