import React,{useState} from 'react';
import gql from "graphql-tag";

import {useMutation,useQuery,useApolloClient} from '@apollo/client';
import {useAccountId,AccountInfoWrapper} from '../account/AccountInfo';
import {Link,useNavigate,useParams} from 'react-router-dom';
import {ReportListItem} from './ReportSummaryCard';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import Async from 'react-select/async';


import useNotifiers from '../Notifiers';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';


import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import {TabContent,TabContainer} from '../FraktureTabs';
import {SidebarListHeader} from '../components/SidebarList';


import ChildAccountSelect from '../constellation/ChildAccountSelect';
import Button from '@mui/material/Button';

const REPORT_FOLDERS=gql`
query REPORT_FOLDERS($account_id:ID!) {
	account(_id: $account_id) {
		_id
		bots {
			_id
			label
			nickname
		}
		report_folders_with_standard{
			_id
			slug
			short
			label
			slugs
			type
			reports {
				_id
				account_id
				slug
				label
				admin_only
				version
				description
				categories
				subcategory
				visible
				data_sources_array{
					bot_id
					bot_path
					table
					table_exists
					table_has_data
				}
				tags
				icon
				
			}
			children{
				_id
				account_id
				short
				label
				reports{
					_id
					slug
					label
					admin_only
				}
			}
		}
	}
}`;



/*
const REPORT_FOLDER_CREATE=gql`mutation REPORT_FOLDER_CREATE($label:String,$disabled:Boolean,$visibility:String) {
		report_folder_create(label:$label,disabled:$disabled,visibility:$visibility) {
			_id
			label
		}
}`;
const REPORT_FOLDER_UPDATE=gql`mutation REPORT_FOLDER_UPDATE($folder_id:ID!,$label:String,$disabled:Boolean,$visibility:String) {
		report_folder_update(folder_id:$folder_id,label:$label,disabled:$disabled,visibility:$visibility) {
			_id
			label
		}
}`;
const REPORT_FOLDER_REMOVE=gql`mutation REPORT_FOLDER_REMOVE($folder_id:ID!) {
		report_folder_remove(folder_id:$folder_id) {
			_id
			report_ids
		}
}`;
const REPORT_FOLDER_REMOVE_REPORT=gql`mutation REPORT_FOLDER_REMOVE_REPORT($folder_id:ID!,$report_id:ID!) {
		report_folder_remove_report(folder_id:$folder_id,report_id:$report_id) {
			_id
			report_ids
		}
}`;

const REPORT_FOLDER_ADD_REPORT=gql`mutation REPORT_FOLDER_ADD_REPORT($folder_id:ID!,$report_id:ID!) {
		report_folder_add_report(folder_id:$folder_id,report_id:$report_id) {
			_id
			report_ids
		}
}`;
*/

const REPORT_FOLDER_ENSURE_CHILD=gql`mutation REPORT_FOLDER_ENSURE_CHILD($folder_id:ID!,$target_account_id:ID!) {
		report_folder_ensure_child(folder_id:$folder_id,target_account_id:$target_account_id) {
			_id
		}
}`;


export function useFolderOperation(props) {
	const {mutation,folder_id} = props;
	const {notify,notifyErr} = useNotifiers();
	const account_id = useAccountId(props);

	if (!account_id) throw new Error({message:"No account id"});
	if (!mutation) throw new Error("mutation is required");
	if (!folder_id) throw new Error("folder_id is required");
	function onCompleted(){notify({message:"Updated Folder"});}
	const [mutationFunction]=useMutation(mutation,{refetchQueries:["ACCOUNT_REPORTS"],onCompleted,onError:notifyErr});
	return ({report_id,label,disabled,visibility}) => {
		if (!report_id) return notifyErr("No report specified");
		let variables={account_id,folder_id,report_id,label,disabled,visibility};
		mutationFunction({variables});
	};
};

/*
export function AddToFolder(props){
	const {folder_id}=props;
	const {reports,loading,error}=useReports(props);
	const account_id=useAccountId();
	const addMutation=useFolderOperation({mutation:REPORT_FOLDER_ADD_REPORT,account_id,folder_id});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (event) => {setAnchorEl(event.currentTarget);};

	const handleClose = () => {setAnchorEl(null);};

	const open = Boolean(anchorEl);
	const id = open ? folder_id+'-add' : undefined;

	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	return <div>
		<Button onClick={handleClick}><AddIcon/></Button>

		<Menu
			id={id}
			open={open}
			anchorEl={anchorEl}
			getContentAnchorEl={null}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<MenuItem><i>Add report</i></MenuItem>
			{reports.map(({_id,label,slug}) => {return <MenuItem
				onClick={e=>addMutation({report_id:_id})}
				key={_id} value={_id}>{label} ({slug})</MenuItem>;}) }
		</Menu>
	</div>;
	}
*/

export function EnsureChildFolder(props){
	const {folder_id,account_id,existingAccounts}=props;
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();

	const [newChild,setNewChild]=useState('');

	return <div className="d-flex">
		<ChildAccountSelect account_id={account_id}
			fullWidth value={newChild}
			ignore={existingAccounts}
			onChange={e => setNewChild(e.target.value)}/>
		<Button variant="contained" onClick={() => {
			client.mutate({
				mutation: REPORT_FOLDER_ENSURE_CHILD,
				refetchQueries:["REPORT_FOLDERS"],
				variables: {folder_id,target_account_id: newChild}
			}).then(notify,notifyErr);
		}} disabled={!newChild}>Add</Button>
	</div>;
};

function getReportColor(short){
	switch(short){
	case 'warehouse':return "#d0d0d0"; //grey
	case 'transactions': return "#f89800"; //yellow
	case 'messages':return "#00b8e5"; //blue (dark)
	case 'attribution': return "#6ac481"; //green
	case 'origin': return "#ff5722"; //orange
	case 'segments': return "#bb49ea"; //purple
	default: return "#ff5722"; //orange
	}
}

export function useReportFolders(props){
	const account_id=useAccountId(props);

	const {data,error,loading}=useQuery(REPORT_FOLDERS,{variables:{account_id}});
	if (error) return {error:true};
	if (loading) return {loading:true};
	let folders=data.account.report_folders_with_standard;
	//all_public is no longer a usable folder
	const report_folders=JSON.parse(JSON.stringify(folders.filter(d=>d.type!=='all_public'))).map(d=>{
		(d.reports||[]).forEach(r=>{
			r.report_folder_short=d.short;
			r.color=d.color||getReportColor(d.short||d.slug);
		});
		return d;
	});

	/*
	let defaultFolderShort=report_folders.find(d=>d.is_default || !d.disabled);
	if (!defaultFolderShort) throw new Error("No default folder");
	defaultFolderShort=defaultFolderShort.short;
	*/


	return {report_folders};
}


export function ReportFolderSelector(){
	const {report_folder_short:value}=useParams();
	const navigate=useNavigate();
	const account_id = useAccountId();
	const {report_folders,error,loading}=useReportFolders();
	if (error){
		console.error(error);
		return "Error loading folder";
	}
	if (loading) {return "Loading folders .....";}
	if (!report_folders) return "Folders are null";
	const elements=report_folders.map(b=>{
		return {label:(b.label || "(No name)"),value:b._id};
	});
	let folder=report_folders.find(d=>d.short===value || d._id===value)||report_folders[1];
	let label=folder.label;
	const select = <Async className="w-100" defaultOptions={elements}
		cacheOptions
		value={(value && {value,label}) || elements[1]}
		onChange={option => {
			const{value}=option;
			navigate('/app/'+account_id+'/report/'+value);
		}} loadOptions={(input) => {
			const inputs = input.trim().toLowerCase().split(/\s+/g);
			const isMatch = (vars=[]) => {
				vars = vars.map(x=>x.toLowerCase());
				return inputs.every(inp => vars.find(v => v.indexOf(inp) !== -1));
			};

			return new Promise(resolve => resolve(elements.map(x => {
				if(!x.options) {
					if(isMatch([x.value, x.label])) {
						return x;
					}
					else return null;
				}
				return {
					label: x.label,
					options: x.options.filter(y => {
						return isMatch([x.label, y.value, y.label]);
						// return inSubmodule || y.value.toLowerCase().indexOf(input) != -1 || y.label.toLowerCase().indexOf(input) != -1;
					})
				};
			}).filter(x=>x)));
		}} placeholder='Select Report Stack ...' />;
	return <><SidebarListHeader
		primary={<Link to={"/app/"+account_id+"/reports"}>Reports</Link>}
		secondary={select}
	/><List>
		{(folder.reports||[{}]).map((report,i)=><ReportListItem report={report} folder={folder} key={i}/>)}
	</List></>;
}

export function Folder({report_folder_short}){
	const {report_folders,error,loading}=useReportFolders();
	const [showLinks,setShowLinks]=useState(false);
	const account_id=useAccountId();
	if (error){return "Error loading folder";}
	if (loading) {return "Loading folders .....";}

	const folder=report_folders.find(d=>d.short===report_folder_short || d._id===report_folder_short);
	return JSON.stringify(folder);
	const {label,children=[],reports:_reports=[],slugs={}}=folder;
	let existingAccounts=Object.keys(children.reduce((a,b)=>a[b.account_id]=true && a,{}));
	if (!folder) return "Could not find folder";
	let reports=JSON.parse(JSON.stringify(_reports));
	reports.forEach(r=>{
		if (!r){
			console.error(reports);
			throw new Error("Empty report?");
		}
		if (slugs && slugs[r.slug]) Object.assign(r,slugs[r.slug]);
	});

	let firstReport=null;
	if (reports.length>0) firstReport=reports[0];
	//return <AccountInfoWrapper>{({account})=> {return JSON.stringify(account);}}</AccountInfoWrapper>;
	return <AccountInfoWrapper>{({account})=> {
		let accountChildren=JSON.parse(JSON.stringify(account.children||[]));
		return <Card><CardHeader title={label}/>
			<TabContainer urlName="none">
				<TabContent label="Reports" >
				</TabContent>{account.children.length>0?<TabContent label="Sharing">
					<Card><CardHeader title="Child Folders"
						action={<span>Show Links <Switch
							checked={showLinks}
							onChange={e=>setShowLinks(!showLinks)}
							color="primary"
						/></span>}
					/><CardContent style={{overflow:'auto'}}>
						<EnsureChildFolder {...{account_id,existingAccounts,folder_id:folder._id}}/>
						<Table>{accountChildren.map((childAccount,i)=>{
							let child=children.find(c=>c.account_id===childAccount._id);
							if (!child) return null;
							let {short:childShort,account_id:child_account_id}=child;
							let domain=window.location.hostname.indexOf('local')>0?window.location.hostname:"report.frakture.app";
							let folder_url="https://"+domain+'/report/'+childShort;
							let report_url=null;
							let firstChildReport=(child.reports||[]).find(d=>d.slug===firstReport.slug);
							if (firstChildReport) report_url="https://"+domain+'/report/'+childShort+"/"+firstChildReport._id;

							return <TableRow key={i}><TableCell
							><a target="_blank" href={`/app/${child_account_id}/report`}>{childAccount.name}</a></TableCell><TableCell
							><a target="_blank" href={folder_url}>{label}</a></TableCell><TableCell>{child_account_id}</TableCell><TableCell
							>{report_url?(showLinks?report_url:<a target="_blank" href={report_url}>{firstChildReport.label} (Direct Link)</a>):""}</TableCell></TableRow>;
						})
						}</Table></CardContent>
					</Card>
				</TabContent>:null}
			</TabContainer>
		</Card>;
	}}</AccountInfoWrapper>;
}
