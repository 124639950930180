import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {fraktureQuery} from '../../FraktureQuery';

import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';
import OptionEditor from '../../inputs/OptionEditor';

import gql from 'graphql-tag';

const DATAFLOW_OPTIONS_QUERY=gql`
query DATAFLOW_OPTIONS_QUERY($dataflow_id:ID!) {
	dataflow(_id:$dataflow_id) {
		parent_id
		config {
			job_option_overrides {
				context_id
				overrides {
					option
					value
					override_value
					default_value
				}
			}
		}
	}
}
`;

const UPDATE_JOB_OPTION_OVERRIDE_MUTATION=gql`
mutation UPDATE_JOB_OPTION_OVERRIDE_MUTATION(
	$dataflow_id:ID!,
	$context_id:ID!,
	$update: DataflowOptionUpdate
) {
	dataflow_config_update_job_option_override(
		_id: $dataflow_id,
		context_id: $context_id,
		update: $update
	) {
		config {
			job_option_overrides {
				context_id
				overrides {
					option
					value
					override_value
					default_value
				}
			}
		}
	}
}
`;

export default function DataflowOptionOverrideEditor(props) {
	const{dataflow_id}=props;
	const client=useApolloClient();
	const {notify,notifyErr} = useNotifiers();

	return <Card style={{boxShadow:"none"}}>
		<CardHeader title='Dataflow Overrides' />
		<CardContent>
			{fraktureQuery({
				query: DATAFLOW_OPTIONS_QUERY,
				variables: {dataflow_id}
			}, data => {
				const jobOptionOverrides = data.dataflow.config.job_option_overrides;
				if(!jobOptionOverrides.length) return 'No Options to Override';
				const {overrides,context_id} = jobOptionOverrides[0] || {};
				return <React.Fragment>
					<span>Overrides for {context_id}</span>
					{overrides.map(override => {
						//console.log("Retrieve override:",typeof override,override);
						//debugger;
						let {option,override_value,default_value}=override;
						//These are because of some weirdness in production vs local work
						try{override_value=JSON.parse(override_value);}catch(e){}
						try{default_value=JSON.parse(default_value);}catch(e){}
						return <OptionEditor value={override_value}
							defaultValue={default_value}
							definition={{name:option}} key={option}
							onOptionSave={o => {
								const{value,key}=o;
								client.mutate({
									mutation: UPDATE_JOB_OPTION_OVERRIDE_MUTATION,
									variables: {
										context_id,
										dataflow_id,
										update: {key,value:JSON.stringify(value)}
									}
								}).then(notify, notifyErr);
							}}/>;
					})}
				</React.Fragment>;
			})}
		</CardContent>
	</Card>;
};
