import React from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Select from 'react-select';


export default function FraktureQuerySelectFilter(props){
	const navigate=useNavigate();
	const location=useLocation();
	if (!props.name) return "No name provided for Text Filter";

	let search=queryString.parse(location.search);
	let {data,field,target_field}=props;
	if (target_field) field=target_field;

	//return JSON.stringify(variables)
	let searchVal=(search['f.'+field]||"").split(",");
	let options=data.map(d=>{
		if (d[field]==='') return {value:'_blank',label:"(Blank) ("+d.count+")"};
		return {value:d.value,label:d.label+" ("+d.count+")"};
	});
	if (!options.find(v=>v.value==='_blank')){
		options.unshift({
			value:"_blank",
			label:"(Blank)"
		});
	}
	let value=options.filter(o=>{
		return searchVal.find(s=>o.value===s);
	});

	let currentVal=null;

	return <div style={{width:"300px"}}><Select
		variant="standard"
		defaultValue={value}
		onChange={v=>currentVal=v}
		onBlur={(e)=>{
			if (currentVal===null) return;
			let val=[].concat(currentVal).filter(Boolean);// eslint-disable-line react/no-this-in-sfc
			search['f.'+field]=val.map(f=>f.value).join(",");

			location.search=queryString.stringify(search);
			navigate(location);
		}}
		options={options}
		isMulti={true}
		closeMenuOnSelect={false}
	/>
	</div>;
};
