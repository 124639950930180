import React from 'react';
import {useAccountId} from '../../account/AccountInfo';
import Grid from '@mui/material/Grid';
import {formatValue} from '../../formatters';


import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';

import FraktureTable from '../../FraktureTable';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import SourceCodeAnalysis from '../SourceCodeAnalysis';
import {AdminOnly} from '../../../AdminCheck';
import {useAccountDetails} from '../../account/AccountInfo';

import {useWarehouseQuery} from '../../warehouse/WarehouseQuery';

import {useNavigate,useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {modelField} from '../../timeline/index.js';


function ResultItem({title,table,query,summary}){
	const account_id=useAccountId();
	const location=useLocation();

	if (!table) return "No table specified";

	let qs = queryString.parse(location.search);

	const o = useWarehouseQuery({account_id,table,limit:100,...query});
	const {loading,data,error}=o;
	if (loading) return "Loading...";
	if (error){
		console.error(title,error);
		return "Error loading search result for "+title;
	}
	if (!data){
		console.log("Warehouse query response=",o);
		return "Invalid response, no data available";
	}
	let columns=null;
	if (data && data.length){
		let keys=Object.keys(data[0]);
		if (qs.multi===undefined)keys=keys.filter(d=>(d.indexOf('source_code')<0 || d==='source_code_id'));
		columns=keys.filter(d=>d!=='id').map(d=>({
			title:d,
			field:d,
			format:["revenue","spend"].some(x=>d.toLowerCase().indexOf(x)>=0)?(v=>formatValue(v,"currency")):null
		}));
		columns.forEach(d=>{
			let fql=query?.fields?.find(a=>((a.field && a.field===d.field) || (a.alias && a.alias===d.title)));
			fql=fql?.fql || fql?.field || "";
			if (d.field==='message_id' || fql.endsWith('message_id')){
				d.render=({row})=><a href={`/app/${account_id}/messages#message_id=${row.message_id}`}>{row.message_id}</a>;
			}else if (d.field.split("-").pop()==='person_id' || fql.endsWith('person_id')){
				d.render=({row})=><a href={`/app/${account_id}/timeline?person_id=${row[d.field]}`}>{row[d.field]}</a>;
			}else if (d.field.split("-").pop()==='person_id_int' || fql.endsWith('person_id_int')){
				d.render=({row})=><a href={`/app/${account_id}/timeline?person_id_int=${row[d.field]}`}>{row[d.field]}</a>;
			}
		});
		//return JSON.stringify({columns,keys});
	}
	let subheader=null;
	if (typeof summary=='function' && data) subheader=summary(data);

	return <Card><CardHeader title={title} subheader={subheader}/>
		<CardContent style={{maxHeight: "400px",overflow: "auto"}}>
			{data && data.length>0 && <FraktureTable
				rows={data}
				columns={columns}
				includePagination={false}
				includeColumnPicker={false}
			/>}
			{data && !data.length && "No records"}
		</CardContent>
	</Card>;
}



function summarizeTrans(arr){
	let {transactions,revenue}=arr.reduce((a,b)=>{
		a.transactions++;
		a.revenue+=(b?.amount||0);
		return a;
	},{transactions:0,revenue:0});
	return formatValue(revenue,"currency")+" ("+formatValue(transactions,'long')+")";
}

function ResultsWrapper({source_code}){
	const {account,loading,error}=useAccountDetails();
	if (loading) return "Loading...";
	if (error) return "Error loading account";

	/*
	default_warehouse_bot{
		_id
		global_table_prefix
	}
	parents {
		_id
		name
	}
	children{
		_id
		name
		disabled
	}
	bots {
		_id
		label
		path
		warehouse_table_prefix
	}
	*/

	let pfx=account.default_warehouse_bot.global_table_prefix;

	let sc=source_code;
	if (source_code.indexOf('%')<0) sc=source_code+"%";
	function m(s){return s.split(",").map(d=>({field:d,
		//alias:d.split("-").pop().replace(/_/g,' ')
	}));}
	let searches=[
		{ title:"Dictionary",table:pfx+"source_code_summary",
			query:{
				conditions:[{fql:`source_code like '${sc}'`}],
				fields:m("source_code,source_code_last_used,impressions,spend,revenue,origin_person_count,origin_transaction_revenue,source_code_id")
			}
		},
		{title:"Primary Message Source",table:pfx+"global_message_summary",query:{conditions:[{fql:`primary_source_code like '${sc}'`}],
			fields:m("primary_source_code,message_id,bot_label,publish_date,label,impressions,spend,attributed_revenue")}},
		{title:"All Message Source Codes",table:pfx+"message_source_code",
			query:{
				joins:[{
					alias: "message",
					target:pfx+"global_message_summary",
					match_fql:pfx+"message_source_code.message_id=message.message_id"
				}],
				conditions:[{fql:`source_code like '${sc}'`}],
				fields:[{field:"source_code"},{field:"type"},
					{field:"message_id"},{fql:"message.label",alias:"Label"},{fql:"message.publish_date",alias:"Published"},
					{fql:"message.final_primary_source_code",alias:"Primary Message Source"}]
			}
		},
		{title:"Transactions",table:pfx+"transaction_summary",
			query:{
				conditions:[{fql:`transaction_source_code like '${sc}'`}],
				fields:m("transaction_source_code,remote_transaction_id,ts,amount")
			},
			summary:summarizeTrans

		},
		{title:"Attribution",table:pfx+"attribution_summary",
			query:{
				conditions:[{fql:`transaction_source_code like '${sc}'`}],
				fields:m("remote_transaction_id,transaction_source_code,ts,amount,message_id,publish_date").concat({
					field:"final_primary_source_code",alias:"Primary Message Source"
				})
			},
			summary:summarizeTrans
		},
		{title:"Transaction Overrides",table:pfx+"transaction_summary",
			query:{
				conditions:[{fql:`transaction_source_code_override like '${sc}'`}],
				fields:m("transaction_source_code_override,ts,amount")
			}
		}
	];

	searches=searches.concat({section:"CRM Origins"});

	account.bots.forEach(f=>{
		if (!["actionkit","global","ansql","actionnetwork","everyaction","ngp"].find(s=>f._id.indexOf(s)===0)) return;
		let prefix=f.warehouse_table_prefix;
		if (prefix && prefix.slice(-1)!=='_') prefix+='_';
		searches.push(
			{
				title:"People from "+f.label,
				table:prefix+"person",
				query:{
					conditions:[{fql:`source_code like '${sc}'`}],
					fields:[
						{fql:"source_code",alias:"source_code"},
						{fql:"count(*)",alias:"Count"},
						{fql:"count(distinct email)",alias:"Distinct Email"}
					],
					group_by:[
						{fql:"source_code"}
					]
				}
			});
	});

	searches=searches.concat([
		{section:"People and Transaction Models"},
		{
			title:"Transaction Models",
			table:pfx+"transaction_model_stats",
			md:12,
			query:{
				conditions:[{fql:`dictionary.source_code like '${sc}'`}],
				fields:[
					{fql:"dictionary.source_code",alias:"source_code"},
					modelField,
					{fql:"person_count",alias:"People"},
					{fql:"transactions",alias:"Transactions"},
					{fql:"revenue",alias:"Revenue"}
				],
				joins:[{
					alias:"dictionary",target:pfx+"source_code_dictionary",
					match_fql:pfx+"transaction_model_stats.source_code_id=dictionary.source_code_id"
				}]
			}
		},
		{
			title:"Transaction Model Sample",
			table:pfx+"transaction_model_source_code",
			md:12,
			query:{
				conditions:[{fql:`dictionary.source_code like '${sc}'`}],
				fields:[
					{fql:"dictionary.source_code",alias:"source_code"},
					modelField,
					{fql:"transaction_metadata.person_id_int",alias:"Person ID"},
					{fql:"count(*)",alias:"Transactions"},
					{fql:"sum(transaction_metadata.amount)",alias:"Revenue"}
				],
				joins:[{
					alias:"dictionary",
					target:pfx+"source_code_dictionary",
					match_fql:pfx+"transaction_model_source_code.source_code_id=dictionary.source_code_id"
				},
				{
					alias:"transaction_metadata",
					target:pfx+"transaction_metadata",
					match_fql:pfx+"transaction_model_source_code.transaction_id=transaction_metadata.transaction_id"
				}
				],
				group_by:[
					{fql:"dictionary.source_code"},
					modelField,
					{fql:"transaction_metadata.person_id_int"}
				],
				limit:10
			}
		},
		{
			title:"Timeline Counts",
			table:pfx+"timeline_v3_summary",
			md:12,
			query:{
				conditions:[{fql:`source_code like '${sc}'`}],
				fields:[
					{fql:"source_code",alias:"Source Code"},
					{fql:"source_code_id",alias:"Source Code ID"},
					{fql:"entry_type_label",alias:"Entry Type"},
					{fql:"count(*)",alias:"Count"}
				],
				group_by:[
					{fql:"source_code"},
					{fql:"source_code_id"},
					{fql:"entry_type_label"}
				]
			}
		},
		{
			title:"Timeline Sample",
			table:pfx+"timeline_v3_summary",
			md:12,
			query:{
				conditions:[{fql:`source_code like '${sc}'`}],
				fields:[
					{fql:"ts",alias:"Entry Date"},
					{fql:"date_created",alias:"Date Created"},
					{fql:"source_code",alias:"Source Code"},
					{fql:"source_code_id",alias:"Source Code ID"},
					{fql:"entry_type_label",alias:"Entry Type"},
					{fql:"person_id",alias:"Person"},
					{fql:"person_id_int",alias:"ID"}
				]
			}
		},
		{
			title:"Timeline Sample having Transactions",
			table:pfx+"timeline_v3_summary",
			md:12,
			query:{
				conditions:[
					{fql:`source_code like '${sc}'`},
					{fql:`transaction_metadata.transaction_id is not null`}
				],
				joins:[{
					alias:"transaction_metadata",
					target:pfx+"transaction_metadata",
					match_fql:pfx+"timeline_v3_summary.person_id_int=transaction_metadata.person_id_int"
				},
				],
				fields:[
					{fql:pfx+"timeline_v3_summary.source_code",alias:"source_code"},
					{fql:"source_code_id",alias:"Source Code ID"},
					{fql:pfx+"timeline_v3_summary.ts",alias:"Entry Date"},
					{fql:"date_created",alias:"Date Created"},
					{fql:"entry_type_label",alias:"Entry Type"},
					{fql:"person_id",alias:"Person"},
					{fql:"person_id_int",alias:"ID"},
					{fql:`min(transaction_metadata.ts)`,alias:"First Transaction date"},
					{fql:`sum(transaction_metadata.amount)`,alias:"Total Amount"},
				],
				group_by:[
					{fql:"source_code"},
					{fql:"person_id_int"},
				],
				limit:100
			}
		},
		{
			title:"Timeline CRM Origin but NOT Classic origin, with transactions",
			table:pfx+"timeline_v3_summary",
			md:12,
			query:{
				fields:[
					{fql:"source_code_id",alias:"Source Code ID"},
					{fql:"person_id",alias:"Person"},
					{fql:"person_id_int",alias:"ID"},
					{fql:"entry_type_label",alias:"Entry Type"},
					{fql:"person_metadata.final_origin_source_code_id",alias:"Classic Origin ID"},
					{fql:"source_code_dictionary.source_code",alias:"Classic Origin Source"},
					{fql:"sum(amount) as revenue"}
				],
				joins:[{
					alias:"person_metadata",
					target:pfx+"person_metadata",
					match_fql:pfx+"timeline_v3_summary.person_id_int=person_metadata.person_id_int"
				},
				{
					alias:"transaction_metadata",
					target:pfx+"transaction_metadata",
					match_fql:pfx+"timeline_v3_summary.person_id_int=transaction_metadata.person_id_int"
				},
				{
					alias:"source_code_dictionary",
					target:pfx+"source_code_dictionary",
					match_fql:pfx+"person_metadata.final_origin_source_code_id=source_code_dictionary.source_code_id"
				},
				],
				conditions:[
					{fql:`source_code like '${sc}'`},
					{fql:`entry_type_label='CRM_ORIGIN'`},
					{fql:`transaction_metadata.transaction_id is not null`},
					{fql:`source_code_id<>person_metadata.final_origin_source_code_id`}
				],

				group_by:[
					{fql:"source_code"},
					{fql:"person_id_int"},
				],
				limit:50
			}
		}
	]);

	searches=searches.concat({section:"Origin Classic Model"});
	searches=searches.concat([
		{
			title:"People Origin (before overrides)",
			table:pfx+"person_metadata",
			query:{
				conditions:[{fql:`origin_source_code like '${sc}'`}],
				fields:[
					{fql:"origin_source_code",alias:"origin_source_code"},
					{fql:"count(*)",alias:"Count"}
				],
				group_by:[
					{fql:"origin_source_code"}
				]
			}
		},
		{title:"People Overrides",table:pfx+"person_metadata_override",
			query:{
				conditions:[{fql:`origin_source_code_override like '${sc}'`}],
				fields:m("person_id,origin_source_code_override")
			}
		},
		{
			title:"People Origin (With Overrides - aka Final)",table:pfx+"person_metadata",
			query:{
				conditions:[{fql:`dictionary.source_code like '${sc}'`}],
				fields:[
					{fql:"dictionary.source_code",alias:"source_code"},
					{fql:"count(*)",alias:"Count"}
				],
				group_by:[
					{fql:"dictionary.source_code"}
				],
				joins:[{
					alias:"dictionary",target:pfx+"source_code_dictionary",
					match_fql:"final_origin_source_code_id=dictionary.source_code_id"
				}]
			}
		},
		{ title:"People Origin (After Rollup)",table:pfx+"source_code_summary",
			query:{
				conditions:[{fql:`source_code like '${sc}'`}],
				fields:m("source_code,origin_person_count")
			}
		},
		{
			title:"Transaction Origin",
			table:pfx+"transaction_metadata",
			query:{
				conditions:[{fql:`dictionary.source_code like '${sc}'`}],
				fields:[
					{fql:"dictionary.source_code",alias:"source_code"},
					{fql:"count(*)",alias:"Transactions"},
					{fql:"sum(amount)",alias:"Revenue"}
				],
				group_by:[
					{fql:"dictionary.source_code"}
				],
				joins:[{
					alias:"dictionary",target:pfx+"source_code_dictionary",
					match_fql:"origin_source_code_id=dictionary.source_code_id"
				}]
			}
		},
		{
			title:"Transaction Origin Sample",
			table:pfx+"transaction_metadata",
			query:{
				conditions:[{fql:`dictionary.source_code like '${sc}'`}],
				fields:[
					{fql:"dictionary.source_code",alias:"source_code"},
					{fql:"transaction_id",alias:"ID"},
					{fql:"person_id_int",alias:"Person ID"},
					{fql:"ts",alias:"Date"},
					{fql:"amount",alias:"Amount"}
				],
				joins:[{
					alias:"dictionary",target:pfx+"source_code_dictionary",
					match_fql:"origin_source_code_id=dictionary.source_code_id"
				}],
				limit:20
			}
		}
	]);
	//good for filtering some out for testing
	if (searches.find(d=>d.include)) searches=searches.filter(d=>d.include);

	return <Grid container spacing={2}>
		{searches.map((s,k)=>{
			if (s.section){
				return <Grid item key={k} md={12}><hr/><h2>{s.section}</h2></Grid>;
			}else{
				return <Grid item key={k} md={s.md || 4}><ResultItem {...s}/></Grid>;
			}
		})}
	</Grid>;
}

function MultiSwitch(){
	const navigate = useNavigate();
	const location=useLocation();
	let qs = queryString.parse(location.search);

	return <div>Include Source Codes?<Switch
		checked={qs.multi!==undefined}
		onChange={()=>{
			if (qs.multi===undefined){
				qs.multi=true;
			}else{
				delete qs.multi;
			}
			navigate(location.pathname+'?'+queryString.stringify(qs));
		}}
	/></div>;
}

export default function SourceCodeSummary() {
	const navigate = useNavigate();
	const location=useLocation();
	let qs = queryString.parse(location.search);
	let [sourceCode,setSourceCode]=React.useState(qs.source_code ||"");
	return <Paper className="p-4 app-main-content">
		<div className="d-flex justify-content-between">
			<h1>Source Code Search</h1>
			<MultiSwitch/>
		</div>
		<Grid container spacing={2} className="w-100">
			<Grid item sm={12}>
				<form onSubmit={e=>{
					e.preventDefault();
					qs.source_code=sourceCode;
					navigate(location.pathname+'?'+queryString.stringify(qs));
				}}>
					<TextField className="w-100" defaultValue={sourceCode} name="source_code" rows={10} aria-label="Source codes to test" placeholder="Testing Source Codes"
						onChange={e=>{
							let v=e.target.value;
							if (v) setSourceCode(v.trim());}}/>
					<Button variant="contained" color="primary" type="submit">Lookup Source codes</Button>
				</form>
			</Grid>
			<Grid item sm={12}>
				{qs.source_code && <ResultsWrapper source_code={qs.source_code}/>}
			</Grid>
			{false && sourceCode &&<AdminOnly><hr/><h1>Deep Analysis</h1>
				<Grid item sm={12}>
					<SourceCodeAnalysis source_code={sourceCode}/>
				</Grid>
			</AdminOnly>}
		</Grid>
	</Paper>;
};
