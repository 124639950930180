// eslint-disable-next-line no-unused-vars
import React,{useState} from 'react';
import {useColorFunc} from './colors';
import {
	Scatter,ScatterChart,
	ResponsiveContainer,
	Tooltip,
	Cell,
	LabelList,
	XAxis,CartesianGrid,
	YAxis,ZAxis
} from "recharts";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {formatValue} from '../../../formatters';



function CustomTooltip({ active, payload, label,field,xFormat,yFormat,zFormat }){
	const formatters=[xFormat,yFormat,zFormat];
	if (active) {
		return (
			<div className="frakture-report-custom-tooltip">
				<h3>{((payload[0]||{}).payload||{}).label}</h3>
				{payload.map((p,i)=>{
					let f=formatters[i] || (v=>{return v;});
					return <p key={i}><strong>{p.name}:</strong> {f(p.value)}</p>;
				})}
			</div>
		);
	}
	return null;
};

export default function FraktureBarChart(props){
	let {name,dimension,metrics,
		label="",data}=props;
	const get_color=useColorFunc();
	if (!name) return "Name is required in props";


	let fields=[].concat(dimension).concat(metrics);
	fields.forEach((d,i)=>d.alias=d.alias || d.label || "col"+i);

	let xAlias=metrics[0].alias;
	let yAlias=metrics[1].alias;
	let zAlias=metrics[2].alias;


	function xFormat(val){if (val===1){return formatValue(0,metrics[0].format);}
		return formatValue(val,metrics[0].format);}
	function yFormat(val){
		if (val===1){return formatValue(0,metrics[1].format);}
		return formatValue(val,metrics[1].format);}
	function zFormat(val){return formatValue(val,metrics[2].format);}

	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;
	data=data.map((_d,i)=>{
		let d=Object.assign({},_d);
		d.color=get_color(i,d[fields[0].alias]);
		d.label=d[fields[0].alias];
		//Because we're log charting, value 0=value 1
		if (d[xAlias]===0) d[xAlias]=1;
		if (d[yAlias]===0) d[yAlias]=1;
		//if (d[zAlias]===0) d[zAlias]=1;
		if (Object.values(d).every(v=>v!==null)) return d;//remove null values - they chart weird
		return null;
	}).filter(Boolean);
	data.sort((a,b)=>{
		return a[zAlias]>b[zAlias]?-1:1;
	});


	let maxX=Math.max(...data.map(d=>d[xAlias]));
	let maxY=Math.max(...data.map(d=>d[yAlias]));
	let minX=Math.min(...data.map(d=>d[xAlias]));
	let minY=Math.min(...data.map(d=>d[yAlias]));

	let xDomain=[(minX/10)+.01, maxX*10];
	let yDomain=[(minY/10)+.01, maxY*10];


	return <>
		{label && <Typography variant="h6">{label}</Typography>}
		<Grid container className="h-100 report-bubble-chart">
			<Grid item md={12} sm={12} style={{height:"100%"}}>
				<ResponsiveContainer>
					<ScatterChart
						height={600}
						margin={{top: 20, right: 20, bottom: 20, left: 20}}
					>
						<CartesianGrid />
						<XAxis
							type="number"
							domain={xDomain}
							scale="log"
							allowDataOverflow
							dataKey={xAlias}
							label={xAlias}
							tickFormatter={xFormat}
							padding={{ left: 40,right:20,top:10 }}
						/>
						<YAxis type="number"
							domain={yDomain}
							scale="log"
							allowDataOverflow
							dataKey={yAlias}
							label={yAlias}
							tickFormatter={yFormat}
							label={{ value: yAlias, angle: -90, position: 'insideLeft', textAnchor: 'middle' }}
							padding={{bottom:60 }}
						/>
						<ZAxis type="number" dataKey={zAlias} name={zAlias} range={[5000,50000]} />
						<Tooltip cursor={{strokeDasharray: '3 3'}} content={<CustomTooltip field={fields[0].alias} {...{xFormat,yFormat,zFormat}}/>}/>
						<Scatter name={zAlias} data={data} fill="#8884d8">
							<LabelList dataKey="label"
								style={{pointerEvents: 'none',
									fontWeight:"",fontSize:"16px",color:"#111",fill: "rgba(0, 0, 0, 0.87)"}}
							/>
							{data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
						</Scatter>
					</ScatterChart>
				</ResponsiveContainer>
			</Grid>
		</Grid></>;
};
