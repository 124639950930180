import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import DataflowOptionOverrideEditor from './DataflowOptionOverrideEditor';

import ReadOnlyJob from './ReadOnlyJob';

import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';
import { AdminOnly } from '../../../AdminCheck';

import ParentSelect from './ParentSelect';

import fragments from '../gql-fragments';

import gql from 'graphql-tag';

function ChildDataflowJob(props) {
	const{merged_job,error}=props.job;
	// console.log('merged_job',merged_job);
	if(merged_job) return <ReadOnlyJob {...props} job={merged_job}/>;
	if(error) return <ListItem>
		<ListItemText>
			{error}
		</ListItemText>
	</ListItem>;
};

const SET_BOT_OVERRIDE_MUTATION=gql`
mutation setBotOverride($dataflow_id:ID!, $update:DataflowBotOverrideUpdate!) {
	dataflow_config_update_bot_override(_id:$dataflow_id, update:$update) {
		... dataflowBotOverrides
		... dataflowMergeJobs
	}
}
${fragments.dataflowBotOverrides}
${fragments.dataflowMergeJobs}
`;

export function useBotOverrideMutation(props) {
	props = props || {};
	const client=useApolloClient();
	return (opts) => {
		const update = Object.assign({},props,opts);

		const{
			bot_id,bot_path,
			override_id='',override_path=''
		}=update;

		if(!bot_id||!bot_path) throw new Error('bot_id and bot_path are required in props or opts');
		const dataflow_id = opts.dataflow_id || props.dataflow_id;
		if(!dataflow_id) throw new Error('dataflow_id is required in props or opts');

		return client.mutate({
			mutation: SET_BOT_OVERRIDE_MUTATION,
			refetchQueries:["DATAFLOW_DETAILS"],
			variables: {
				dataflow_id,
				update: {
					bot_id,bot_path,
					override_id,override_path
				}
			}
		});
	};
}

export function BotOverrideSelect(props) {
	const {override,dataflow}=props;
	const {notify,notifyErr} = useNotifiers();

	const{
		_id,bot_path,bot_id,method,
		default_id,
		default_bot={label:'No Default'},
		override_id='',
		override_path='',
		override_options=[]
	}=override;

	const botOverride = useBotOverrideMutation({bot_path,bot_id, dataflow_id:dataflow._id});

	let value=null;
	if(override_id || override_path) value = override_id + '.' + override_path;
	let label = bot_path;
	if(method)label+='.'+method;
	if(default_id && !value) value = 'default';
	return <FormControl key={_id+'.'+bot_path} fullWidth>
		<Select value={value} onChange={e => {
			const selectedValue = e.target.value;
			let update={bot_id,bot_path};
			if(selectedValue === '') Object.assign(update,{override_id:'',override_path:'',bot_path,bot_id});
			else {
				const selected = override_options.find(option => (option.override_id+'.'+option.override_path) === selectedValue);
				Object.assign(update,{
					override_id:selected.override_id,
					override_path:selected.override_path
				});
			}
			botOverride(update).then(notify,notifyErr);
		}}>
			<MenuItem ley='default' value='default'><em>{(default_bot||{label:'No Default'}).label} (Default)</em></MenuItem>
			{override_options.map(option => {
				const value = option.override_id+'.'+option.override_path;
				const submodule = option.override_path.split('.')[2];
				let text = option.override.label;
				if(submodule) text += ' ('+submodule+')';
				return <MenuItem key={value} value={value}>{text}</MenuItem>;
			})}
		</Select>
		<FormHelperText>{label}</FormHelperText>
	</FormControl>;
};

function BotOverrideSelection(props) {
	const{dataflow}=props;
	const{bot_overrides}=dataflow.config;

	const overrideSelects = bot_overrides.map(override => {
		return <BotOverrideSelect {...{override,dataflow}} key={override._id} />;
	});

	return <React.Fragment>
		<h3 style={{marginTop:'10px'}}>Bot Overrides</h3>
		{overrideSelects}
	</React.Fragment>;
}

export default function ChildDataflowEditor(props) {
	const{dataflow}=props;
	const{merged_jobs}=dataflow;

	// return <Card style={{width:'100%'}}>
	// 	<CardContent>
	return <Grid container spacing={1}>
		<Grid item md={7}>
			<Card><CardContent>
				<AdminOnly>
					<ParentSelect {...props}/>
					<BotOverrideSelection {...props} />
				</AdminOnly>
			</CardContent></Card>
			<DataflowOptionOverrideEditor {...{dataflow_id:dataflow._id}} />
		</Grid><Grid item md={5}>
			<Card>
				<CardHeader title="Jobs"/>
				<CardContent>
					<List>
						{merged_jobs.map(job => <ChildDataflowJob key={job.context_id} {...props} {...{job}} />)}
					</List>
				</CardContent></Card>
		</Grid>
	</Grid>;
	// 	</CardContent>
	// </Card>;
};
