/* eslint-disable no-undef */
import React from 'react';

export default function HeroCard(props){
	let {title,children,category,className}=props;
	return <div onClick={props.onClick} className={`card hero-card${category?" category-"+category:""}${className?" "+className:""}`}>
		{title?<div className="card-header">{title}</div>:""}
		<div className="card-body">
			{children}
		</div>
	</div>;
}
