import React from 'react';
//import Badge from '@mui/material/Badge';


export default function JobStatusIcon(props){
	let {status}=props;
	let color,icon;
	if (!status) status="unknown";

	switch(status) {
	case 'ok': color="success"; icon="zmdi zmdi-check text-white"; break;
	case 'error': color="danger"; icon="zmdi zmdi-close text-white"; break;

	case 'started':
	case 'sent_to_queue':
	case 'pending': icon="zmdi zmdi-more";color='blue'; break;
	case 'paused': icon="zmdi zmdi-pause"; color='warning'; break;
	case 'complete': icon="zmdi zmdi-check"; color="green"; break;
	case 'unscheduled':
	case 'unknown':
	default: icon="zmdi zmdi-minus text-white"; color="grey"; break;
	}

	if (icon){};
	return "";
	return (<span alt={status} style={{color}}>{status}</span>);
};
