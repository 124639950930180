import React,{useEffect,useState} from 'react';
import gql from "graphql-tag";
import {useQuery} from '@apollo/client';
import {useAccountDetails} from '../account/AccountInfo';
import {useSearchParams} from 'react-router-dom';
import {relativeDate} from "../formatters.js";
import {Error} from '../Error';


export const PING_QUERY=gql`
query PING_QUERY($warehouse_bot_id:ID!){
  warehouse(bot_id:$warehouse_bot_id) {
		bot_id,
    warehouse_bot_id,
    bot_token,
    graphql_uri,
		ping
  }
}`;

export const WAREHOUSE_QUERY=gql`
query WAREHOUSE_QUERY(
	$warehouse_bot_id:ID!,
	$table: String!,
	$join: String,
	$joins: [WarehouseJoinInput],
	$conditions: [WarehouseConditionInput],
	$group_by: [WarehouseFieldReferenceInput],
	$order_by:[WarehouseFieldReferenceInput],
	$offset: Int,
	$limit: Int,
	$fields:[WarehouseFieldReferenceInput]
) {
  warehouse(bot_id:$warehouse_bot_id) {
		bot_id,
    warehouse_bot_id,
    bot_token,
    graphql_uri,
		table(name:$table) {
		  name
			data_grid_batch(
				limit: $limit,
				offset: $offset,
				join: $join,
				joins: $joins,
				fields:$fields,
				conditions:$conditions,
				group_by:$group_by,
				order_by:$order_by
			) {
				data
				length
				sql
        primary_field

			}
		}
  }
}`;
/*
fields{
  name
  type
}
*/

export const WAREHOUSE_DESCRIBE=function(tables){
	if (typeof tables=='string') tables=tables.split(",");
	let q=`query WAREHOUSE_DESCRIBE(
  	$warehouse_bot_id:ID!
  ) {
    warehouse(bot_id:$warehouse_bot_id) {
  		bot_id,
      warehouse_bot_id,
      bot_token,
      graphql_uri,
  		${tables.map(t=>t.replace(/\$\{global_table_prefix\}/g,"")+":"+`table(name:"${t}") {
  		  name
  			type
        fields{
          name
  	      sql_type
  	      data_type
        }
  		}`).join("\n")}
    }
  }`;
	return gql(q);
};

export function useConditionsFromQueryString({parameter,date_field="date",default_query={}}){
	const [searchParams]=useSearchParams();
	const [conditions,setConditions]=useState([]);
	const [limit,setLimit]=useState();
	default_query=default_query || {};
	//handles the weird truthy case
	function getParam(k){
		let v=searchParams.get(k);
		if (v===true) return "";
		return v;
	}
	useEffect(()=>{
		let l=getParam("limit") || default_query.limit;

		// eslint-disable-next-line eqeqeq
		if (l && parseInt(l)==l){
			setLimit(parseInt(l));
		}

		let c=[];
		/* not yet implemented */
		if (parameter){
			//lookup=queryString.parse(getParam(parameter));
		}
		let keys=Object.assign({},default_query);
		for (const [k] of searchParams.entries()){
			keys[k]=true;
		};

		Object.keys(keys).forEach(k=>{
			if (["start","end","bot_ids","limit","override","view"].indexOf(k)>=0) return;
			let value=getParam(k) || default_query[k];
			let operator="=";
			c.push({fql:`${k.replace("-",".")}${operator}'${value}'`});
		});
		let s=getParam("start")||default_query.start;
		if (s){
			s=relativeDate(s);
			c.push({fql:`${date_field}>='${s.toISOString()}'`});
		}
		let e=getParam("end")||default_query.end;
		if (e){
			e=relativeDate(e);
			c.push({fql:`${date_field}<'${e.toISOString()}'`});
		}
		let bot_ids=getParam("bot_ids") || default_query.bot_ids;
		if (bot_ids){
			let queries=bot_ids.split(",").map(bot_id=>{
				return `bot_id='${bot_id}'`;
			});
			if (queries.length>0) c.push({fql:queries.join(" OR ")});
		}
		setConditions(c);
	},[searchParams]);
	if (!conditions) return {loading:true};
	return {conditions,limit};
}

const alreadySeen={};
const loadingIcon="Loading ...";

export function useWarehouseQuery(props) {
	let p=JSON.parse(JSON.stringify(props));
	let {account_id,table,query,join,joins,conditions,group_by,order_by,offset,limit,fields,skip}=p;
	const {account,loading,error}=useAccountDetails({account_id});
	skip=!!(skip || loading || error);
	let warehouse_bot_id=account?.default_warehouse_bot?._id;
	let variables=JSON.parse(JSON.stringify({warehouse_bot_id,table,
		query,
		fields,
		join,joins,
		group_by,
		order_by,offset,
		limit:limit||100,
		conditions,
	}));
	//return "Variables:"+JSON.stringify(variables)+new Date().toISOString();
	if (skip)console.log("Skipping query");
	else console.log("Running WAREHOUSE_QUERY with variables",variables);
	const {data,loading:loading2,error:error2}=useQuery(WAREHOUSE_QUERY, {variables,
		fetchPolicy: "no-cache",
		errorPolicy: "none",
		skip});
	if (skip) return {loading,error:error?<Error error={error}/>:null};
	if (loading2){
		return {loading:loadingIcon};
	}

	if (error2){
		let e=error2?.error?.props?.error || error2;
		return {error:<Error error={e}/>};
	}

	let key=JSON.stringify({account_id,table,query,join,joins,conditions,order_by,offset,limit,fields});
	if (alreadySeen[key]){
		console.error("Already seen:",JSON.parse(key));
		throw new Error("Already seen warehouse request");
	}

	let w = data?.warehouse?.table?.data_grid_batch?.data ||[];
	if (typeof w=='string'){
		console.log("String returned from useQuery,cloning");
		let clone=JSON.parse(JSON.stringify(data));
		w = clone.warehouse.table?.data_grid_batch?.data ||[];
		if (typeof w=='string') w=JSON.stringify(w);
	}
	return {data:w,
		sql:data?.warehouse?.table?.data_grid_batch?.sql,
		fields:data?.warehouse?.table?.data_grid_batch?.fields
	};
};

export function useWarehouseDescribe(props) {
	let {account_id,tables,skip}=props;
	//if (!account_id) throw new Error("useWarehouseQuery: account_id is required");
	const {account,loading,error}=useAccountDetails({account_id});
	skip=skip || loading || error;
	let warehouse_bot_id=account?.default_warehouse_bot?._id;
	let variables={warehouse_bot_id};
	let tArr=tables;
	if (typeof tables=='string') tArr=tables.split(",");
	const retVal=useQuery(WAREHOUSE_DESCRIBE(tArr), {variables,
		fetchPolicy: "no-cache",
		skip});
	let {data,loading:loading2,error:error2,called}=retVal;
	if (skip) return {loading:loading,error:error?<Error error={error}/>:null};
	if (!called) return {loading:true};
	if (loading2) return {loading:loadingIcon};
	if (error2){
		return {error:error2?<Error error={error2}/>:null};
	}
	let {global_table_prefix}=account?.default_warehouse_bot;
	let describes=Object.entries(data?.warehouse||{}).map(([table,v])=>{
		if (!v?.fields) return;
		return v;
	}).filter(Boolean);


	return {data:{tables:describes,global_table_prefix,warehouse_bot_id}};
};
