import React,{useState} from 'react';

import gql from 'graphql-tag';
import {fraktureQuery} from '../FraktureQuery';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import {useUserGroupId} from './UserGroupProvider';

import {TabContent,TabContainer} from '../FraktureTabs';
import FraktureTable from '../FraktureTable';
import useNotifiers from '../Notifiers';
import {useApolloClient} from '@apollo/client';
import LabelEdit from '../inputs/LabelEdit';

// import PermissionsList from '../permissions/PermissionsList';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import Checkbox from '@mui/material/Checkbox';

import ChildAccountSelect from '../constellation/ChildAccountSelect';
import AccountUserSearch from '../account/AccountUserSearch';

import Tooltip from '@mui/material/Tooltip';

const USER_GROUP_EDIT_LABEL_MUTATION=gql`
mutation USER_GROUP_EDIT_LABEL_MUTATION($user_group_id:ID!, $label:String!) {
	user_group_set_label(user_group_id:$user_group_id, label:$label) {
		_id
		label
	}
}
`;
function UserGroupLabel(props) {
	const{notify,notifyErr}=useNotifiers();
	const client=useApolloClient();
	return <LabelEdit value={props.label} onChange={value => {
		client.mutate({
			mutation: USER_GROUP_EDIT_LABEL_MUTATION,
			variables: {user_group_id:props.user_group_id,label:value}
		}).then(notify,notifyErr);
	}} />;
}

// const USER_GROUP_ADD_PERMISSION=gql`
// mutation USER_GROUP_ADD_PERMISSION($user_group_id:ID!, $permission:String!) {
// 	user_group_add_permission(user_group_id:$user_group_id, permission:$permission) {
// 		_id
// 		permissions { permission }
// 	}
// }`;
// const USER_GROUP_REMOVE_PERMISSION=gql`
// mutation USER_GROUP_REMOVE_PERMISSION($user_group_id:ID!, $permission:String!) {
// 	user_group_remove_permission(user_group_id:$user_group_id, permission:$permission) {
// 		_id
// 		permissions { permission }
// 	}
// }`;

const USER_GROUP_SET_PERMISSION=gql`
mutation USER_GROUP_SET_PERMISSION($input:UserGroupPermissionUpdate!) {
	user_group_set_permission(input:$input) {
		_id
		permissions(v2:true) {
			object_type
			enabled
			enabled_by_default
			verb
		}
	}
}`;

function ObjectTypePermissionGroup(props) {
	const {permissions,setPermission}=props;
	const byObjectType = {};
	permissions.forEach(x => {
		(byObjectType[x.object_type] = byObjectType[x.object_type] || [])
			.push(x);
	});
	const rows = [];
	Object.entries(byObjectType).forEach(([object_type,perms]) => {
		rows.push(<TableRow key={object_type+'-header'}>
			<TableCell style={{verticalAlign:'top'}}>{object_type.toUpperCase()}</TableCell>
			<TableCell style={{verticalAlign:'top'}}>
				<Table size="small">
					<TableBody>
						{perms.map(x => {
							const checkbox = <Checkbox
								checked={x.enabled || x.enabled_by_default}
								disabled={x.enabled_by_default}
								onClick={() => {
									setPermission({
										object_type: x.object_type,
										verb: x.verb,
										enabled: !x.enabled
									});
								}} />;
							let title;
							if(x.enabled_by_default) title='This permission is enabled by default and currently not editable';
							else if(x.enabled) title='Disable this permission';
							else title='Enable this permission';

							return <TableRow key={x.verb} style={{borderBottom:'none'}}>
								<TableCell style={{borderBottom:'none',verticalAlign:'top'}}>{x.verb.toUpperCase()}</TableCell>
								<TableCell padding='checkbox' style={{borderBottom:'none'}}>
									<Tooltip title={title}>
										<span>{checkbox}</span>
									</Tooltip>
								</TableCell>
							</TableRow>;
						})}
					</TableBody>
				</Table>
			</TableCell>
		</TableRow>);
	});
	return <Table size="small">
		<TableBody>
			{rows}
		</TableBody>
	</Table>;
};

function UserGroupPermissionsList(props) {
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();

	const {permissions}=props;
	const user_group_id=useUserGroupId(props);

	function setPermission({object_type, enabled, verb}) {
		if(!permissions.find(x => {
			return x.object_type === object_type && x.verb === verb;
		})) {
			console.error('invalid object_type/verb');
			return;
		}
		enabled = !!enabled;
		client.mutate({
			mutation: USER_GROUP_SET_PERMISSION,
			variables: {
				input: {
					user_group_id,
					object_type,
					verb,
					enabled
				}
			}
		}).then(notify,notifyErr);
	}

	return <ObjectTypePermissionGroup {...{permissions,setPermission}} />;
}

const USER_GROUP_ADD_ACCOUNT=gql`
mutation USER_GROUP_ADD_ACCOUNT($user_group_id:ID!, $account_id:ID!) {
	user_group_add_account(user_group_id:$user_group_id, account_id:$account_id) {
		_id
		accessible_accounts { name }
	}
}`;
const USER_GROUP_REMOVE_ACCOUNT=gql`
mutation USER_GROUP_REMOVE_ACCOUNT($user_group_id:ID!, $account_id:ID!) {
	user_group_remove_account(user_group_id:$user_group_id, account_id:$account_id) {
		_id
		accessible_accounts { name }
	}
}`;
function UserGroupAccountList(props) {
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();
	const {account_id, accessible_accounts, user_group_id, account}=props;

	const [newChild,setNewChild]=useState('');

	return <React.Fragment>
		<ChildAccountSelect account_id={account_id}
			fullWidth value={newChild}
			ignore={accessible_accounts.map(x=>x._id)}
			onChange={e => setNewChild(e.target.value)}/>
		<Button onClick={() => {
			client.mutate({
				mutation: USER_GROUP_ADD_ACCOUNT,
				variables: {user_group_id,account_id: newChild}
			}).then(notify,notifyErr);
		}} disabled={!newChild}>Add</Button>
		<Button onClick={() => {
			const addAllChildren = account.children.map(x => {
				return client.mutate({
					mutation: USER_GROUP_ADD_ACCOUNT,
					variables: {user_group_id,account_id: x._id}
				});
			});
			Promise.all(addAllChildren).then(notify,notifyErr);
		}} >Add All</Button>
		<FraktureTable includeColumnPicker={false}
			rows={accessible_accounts}
			columns={[
				{field:"name",title:"Name"},
				{title:"Remove",render:({row:a})=><Button onClick={() => {
					client.mutate({
						mutation: USER_GROUP_REMOVE_ACCOUNT,
						variables: {user_group_id, account_id:a._id}
					});
				}}>Remove</Button>}
			]}/>
	</React.Fragment>;
}

const USER_GROUP_ADD_USER=gql`
mutation USER_GROUP_ADD_USER($user_group_id:ID!, $user_id:ID!) {
	user_group_add_user(user_group_id:$user_group_id, user_id:$user_id) {
		_id
		users { _id username }
	}
}`;
const USER_GROUP_REMOVE_USER=gql`
mutation USER_GROUP_REMOVE_USER($user_group_id:ID!, $user_id:String!) {
	user_group_remove_user(user_group_id:$user_group_id, user_id:$user_id) {
		_id
		users { _id username }
	}
}`;
function UserList(props) {
	const client = useApolloClient();
	const {notify,notifyErr}=useNotifiers();
	const{users,user_group_id,account_id}=props;
	const [newUser,setNewUser]=useState('');
	return <React.Fragment>
		<AccountUserSearch {...{account_id}}
			value={newUser} onChange={e=>setNewUser(e.target.value)}/>
		<Button onClick={() => {
			client.mutate({
				mutation: USER_GROUP_ADD_USER,
				variables: {user_group_id,user_id: newUser}
			}).then(notify,notifyErr);
		}}>Add</Button>
		<FraktureTable rows={users}
			columns={[
				{title:"User Id",field:"_id"},
				{title:"User",field:"username"},
				{title:"Remove",render:({row:x})=><Button onClick={() => {
					client.mutate({
						mutation:USER_GROUP_REMOVE_USER,
						variables: {user_group_id, user_id: x._id}
					}).then(notify,notifyErr);
				}}>Remove</Button>}
			]}
		/>
	</React.Fragment>;
}

const USER_GROUP_QUERY=gql`query USER_GROUP_QUERY($user_group_id:ID!){
	user_group(_id: $user_group_id) {
		_id
		label
		permissions(v2:true) {
			object_type
			verb
			enabled
			enabled_by_default
		}
		account_id
		account {
			children {
				_id
			}
		}
		accessible_accounts {
			_id
			name
		}
		users {
			_id
			username
		}
	}
}`;
export default function UserGroupList(props) {
	const user_group_id=useUserGroupId(props);
	return fraktureQuery({
		query: USER_GROUP_QUERY,
		variables: {user_group_id}
	}, data => {
		const {user_group}=data;
		const {account_id,accessible_accounts,account,users}=user_group;
		return <Card className="app-main-content">
			<CardHeader title={<UserGroupLabel label={user_group.label} user_group_id={user_group_id}/>} />
			<CardContent>
				<TabContainer urlName='ug'>
					<TabContent label="Users">
						<UserList {...{account_id,users,user_group_id}}/>
					</TabContent>
					<TabContent label="Permissions">
						<Grid container spacing={2}>
							<Grid md={6} item>
								<div>Accounts accessible by users in group</div>
								<UserGroupAccountList {...{
									account_id,
									user_group_id,
									accessible_accounts,
									account
								}} />
							</Grid>
							<Grid md={6} item>
								<div>Permissions applied to users in group</div>
								<UserGroupPermissionsList
									permissions={user_group.permissions}
									user_group_id={user_group_id} />
							</Grid>
						</Grid>
					</TabContent>
				</TabContainer>
			</CardContent>
		</Card>;
	});
};
