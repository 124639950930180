import React from 'react';
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const isAdminQuery = gql`{
	currentUser {
		_id
		is_frakture_admin
	}
}`;

const UserFlagContext = React.createContext();

export function UserContextProvider(props) {
	const {data,loading,error}=useQuery(isAdminQuery);
	if(error) throw error;
	if(loading) return null;
	const{currentUser}=data;
	return <UserFlagContext.Provider value={currentUser}>
		{props.children}
	</UserFlagContext.Provider>;
};

export function useUserContext() {
	return React.useContext(UserFlagContext);
}

export function IsAdmin(props) {
	const context = useUserContext();
	return props.children(!!context.is_frakture_admin);
};

export function useAdmin() {
	const context = useUserContext();
	return !!context.is_frakture_admin;
};

export class AdminOnly extends React.Component {
	render() {
		const{children}=this.props;
		if(!children) return null;
		return <IsAdmin>
			{(isAdmin) => {
				if(!isAdmin) return this.props.nonAdminMessage || '';

				if(typeof children != 'function') {
					return children;
				}

				return children();
			}}
		</IsAdmin>;
	}
};
