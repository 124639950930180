import React from 'react';
//import React,{useEffect,useState} from 'react';

import SqlEditor from './SqlEditor';

/*
import gql from 'graphql-tag';
import {useApolloClient} from '@apollo/client';

import CodeMirror from 'codemirror';

const TABLE_SEARCH_QUERY=gql`
query TABLE_SEARCH_QUERY($bot_id:ID!,$text:String) {
	bot(_id:$bot_id) {
		warehouse {
			table_search(text:$text) {
				name
			}
		}
	}
}
`;

const TABLE_FIELD_QUERY=gql`
query TABLE_FIELD_QUERY($bot_id:ID!, $table:String) {
	bot(_id:$bot_id) {
		warehouse {
			table(name: $table) {
				fields {
					name
				}
			}
		}
	}
}
`;

function useSqlSearchFunction({bot_id,default_table,aliases, allowed_tables=[]}) {

	const client = useApolloClient();
	const[settings]=useState({default_fields:[]});

	useEffect(() => {
		console.log('(re)building fields',default_table,aliases);
		Object.assign(settings,{default_fields: []});
		// console.log('default_table:',default_table);
		if(default_table) {
			client.query({
				query: TABLE_FIELD_QUERY,
				variables: {bot_id, table: default_table}
			}).then(({data}) => {
				console.log(data);
				const default_fields = data.bot.warehouse.table.fields.map(x=>x.name);
				// console.log('loaded:', default_fields);
				Object.assign(settings, {default_fields: settings.default_fields.concat(default_fields)});
			}).catch(e => console.error('gql-error',e));
		}

		if(aliases) {
			const inverse = {};
			Object.keys(aliases).forEach(alias => {
				const deref = aliases[alias];
				(inverse[deref]=inverse[deref]||[]).push(alias);
			});
			Object.keys(inverse).forEach(x => {
				client.query({
					query: TABLE_FIELD_QUERY,
					variables: {bot_id, table: x}
				}).then(({data}) => {
					const fields = data.bot.warehouse.table.fields.map(y=>y.name);
					inverse[x].forEach(alias => Object.assign(settings, {
						default_fields: settings.default_fields.concat(fields.map(f => ({
							value: `\`${alias}\`.\`${f}\``
						})))
					}));
					console.log('set fields for',inverse[x],settings);
				}).catch(e => console.error('gql-error',e));
			});
		}
	}, [default_table,aliases,bot_id,client,settings]);
	if(!bot_id) return;

	if(!settings.default_fields) return null;

	return (cm) => {
		const cursor=cm.getCursor();
		const line=cm.getLine(cursor.line);
		// console.log('cm:',cm);
		// console.log('options:',options);
		// console.log('default_fields', settings.default_fields);

		let {ch}=cursor;
		if(ch === 0) return {
			list: settings.default_fields.map(x=>x.value || `\`${x}\``),
			from: CodeMirror.Pos(cursor.line, 0),
			to: CodeMirror.Pos(cursor.line, 1)
		};

		let start=ch;
		while(start > 0 && line[start-1].match(/[\w\d_.]/)) start --;
		const token = line.slice(start,ch).trim();
		console.log('token:',token);

		const [table,field]=token.split('.');
		let query,text;
		if(field == null) {
			query=TABLE_SEARCH_QUERY;
			text=table;
		}
		else {
			start = start + table.length + 1;
			query = TABLE_FIELD_QUERY;
			text=field;
		}

		console.log('cursor:',cursor,'line:',line);

		if(!text) return {
			list: settings.default_fields.map(x=>x.value || `\`${x}\``),
			from: CodeMirror.Pos(cursor.line, start),
			to: CodeMirror.Pos(cursor.line, ch)
		};

		const variables={table,bot_id,text};
		console.log({query,variables});
		return client.query({
			query,
			variables
		}).then(({data}) => {
			console.log('data',data);
			let results;
			if(field == null) {
				const defFields = settings.default_fields.filter(x => x.indexOf(text) !== -1);
				let tables = data.bot.warehouse.table_search.map(x=>x.name);
				if(allowed_tables) tables = tables.filter(x=>allowed_tables.find(y=>x===y));
				results = defFields.concat(tables);
			}
			else results = data.bot.warehouse.table.fields.map(x=>x.name).filter(x => !field || x.indexOf(field) !== -1);
			return {
				list: results.map(x=>`\`${x}\``),
				from: CodeMirror.Pos(cursor.line, start),
				to: CodeMirror.Pos(cursor.line, ch)
			};
		}).catch(e => console.error('failed query:',e));
	};
};
*/

export default function(props) {
	/*
	const{value,onChange, bot_id, default_table,aliases, allowed_tables, defaultValue}=props;
	let sqlHintFn = useSqlSearchFunction({bot_id,default_table,aliases,allowed_tables});

	return <SqlEditor {...props} size={props.size} value={value}
		sqlHintFn={sqlHintFn} bot_id={bot_id} key={default_table} defaultValue={defaultValue}
		lineNumbers={props.lineNumbers} onChange={v => onChange(v)} />;
	*/
	return <SqlEditor {...props}/>;
};
