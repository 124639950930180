import React from 'react';

import StubQuery from "../StubQuery";
import StubMutation from "../StubMutation";
import gql from "graphql-tag";
import {useQuery} from '@apollo/client';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import {useParams,Link} from 'react-router-dom';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import SearchBox from '../SearchBox';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FraktureQuery from '../FraktureQuery';

import UserPermissionsList from './UserPermissionsList';


dayjs.extend(calendar);

const USER_INFO_QUERY=gql`{
	currentUser {
		_id
		username
		email
	}
}`;
export function useUserInfo() {
	const {data,loading,error}=useQuery(USER_INFO_QUERY);
	if(loading) return {};
	if(error) throw error;
	return data.currentUser;
}


const userListQuery=gql`query userList($limit:Int) {
	page: users(limit:$limit) {
		page {
			_id
			username
			email
			is_frakture_admin
			last_seen
			account_ids
		}
	}
}`;
const userSearchQuery=gql`query search($text: String!) {
	users_search(text:$text) {
		_id
		username
		email
		is_frakture_admin
		last_seen
		account_ids
	}
}`;


const userAccountsSearchQuery=gql`query userAccountsSearch($user_id:ID!, $text: String) {
	account_search(text:$text){
		_id
		name
		can_user_access(user_id: $user_id)
		warehouses {
			_id
			warehouse_bot {
				_id
				label
			}
			can_user_access(user_id: $user_id)
		}
	}
}
`;

const userAccountsQuery=gql`query userAccounts($user_id:ID!) {
	user(_id: $user_id) {
		accounts {
			page {
				_id
				name
				can_user_access(user_id: $user_id)
				warehouses {
					_id
					warehouse_bot {
						_id
						label
					}
					can_user_access(user_id: $user_id)
				}
			}
		}
	}
}`;

const addUserToAccountMutation=gql` mutation addUserToAccount($user_id:ID!, $account_id:ID!) {
	account_add_user(user_id: $user_id, account_id: $account_id) {
		_id
		can_user_access(user_id: $user_id)
		users {
			page {
				_id
			}
		}
	}
}
`;
const removeUserFromAccountMutation=gql` mutation removeUserFromAccount($user_id:ID!, $account_id:ID!) {
	account_remove_user(user_id: $user_id, account_id: $account_id) {
		_id
		can_user_access(user_id: $user_id)
		users {
			page {
				_id
			}
		}
	}
}
`;

const userAccountAccessQuery=gql`query canUserAccessAccount($user_id: ID!,$account_id: ID!) {
	user(_id: $user_id) {
		_id
		can_access_account(account_id:$account_id)
	}
}`;
export class AccountAccessCheckbox extends React.Component {
	render() {
		const{user_id,account_id}=this.props;
		const variables={user_id,account_id};
		return <StubQuery query={userAccountAccessQuery} variables={variables}>
			{({data,error,loading}) => {
				if(error) return 'Error loading information';
				if(loading) return '';
				const {can_access_account}=data.user;
				let mutation;
				if(can_access_account) mutation = removeUserFromAccountMutation;
				else mutation = addUserToAccountMutation;
				return <StubMutation mutation={mutation} refetchQueries={() => [{query: userAccountAccessQuery,variables}]}>
					{(modify) => {
						return <Checkbox checked={can_access_account} onChange={() => {
							modify({variables:{user_id,account_id}});
						}} />;
					}}
				</StubMutation>;
			}}
		</StubQuery>;
	}
};

const addUserToWarehouseMutation=gql`mutation addUserToWarehouse($user_id:ID!, $bot_id:ID!){
	warehouse_add_user(user_id: $user_id, bot_id: $bot_id) {
		_id
		can_user_access(user_id:$user_id)
	}
}`;
const removeUserFromWarehouseMutation=gql`mutation removeUserFromWarehouse($user_id:ID!, $bot_id:ID!){
	warehouse_remove_user(user_id: $user_id, bot_id: $bot_id) {
		_id
		can_user_access(user_id:$user_id)
	}
}`;

export class UserAccountList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: ''
		};
	}

	render() {
		return [
			this.renderSearchBar(),
			this.renderAccountList()
		];
	}

	renderSearchBar() {
		return <SearchBox value={this.state.searchText} onChange={e => this.setState({searchText: e.target.value})} />;
	}

	renderGrantAccess(account_id) {
		const{user_id}=this.props;
		return <StubMutation mutation={addUserToAccountMutation}>
			{(addUser) => {
				return <button className="anchor" onClick={() => addUser({variables:{user_id,account_id}})}>Grant Access?</button>;
			}}
		</StubMutation>;
	}

	renderRemoveAccess(account_id) {
		const{user_id}=this.props;
		return <StubMutation mutation={removeUserFromAccountMutation}>
			{(removeUser) => {
				return <button className="anchor" onClick={() => removeUser({variables:{user_id,account_id}})}>Remove Access?</button>;
			}}
		</StubMutation>;
	}

	renderWarehouseAccess({_id:bot_id, can_user_access}) {
		let mutation = addUserToWarehouseMutation;
		const{user_id}=this.props;
		if(can_user_access) mutation = removeUserFromWarehouseMutation;
		return <StubMutation mutation={mutation}>
			{(modify) => <Checkbox checked={can_user_access} onChange={() => {
				console.log({bot_id, user_id});
				modify({variables:{user_id,bot_id}});
			}} /> }
		</StubMutation>;
	}

	renderAccountList() {
		const {user_id} = this.props;
		const {searchText}=this.state;

		let query = userAccountsQuery;
		if(searchText) query = userAccountsSearchQuery;

		return <StubQuery key='account-list' query={query} variables={{user_id, text:searchText}} errorPolicy="all">
			{({error,loading,data}) => {
				if(error) return 'Error: '+error;
				if(loading) return loading;
				const accounts=data.account_search || (data.user.accounts||{}).page || [];
				if(!accounts.length) return 'This user does not have access to any accounts';
				return accounts.map(account => {
					if(!account.can_user_access) return <Card key={account._id} elevation={0} style={{marginBottom:'15px'}}>
						<Typography gutterBottom variant="h6">
							{account.name}
						</Typography>
						<div>The user does not have access to this account. {this.renderGrantAccess(account._id)}</div>
					</Card>;

					return <Card key={account._id} elevation={0} style={{marginBottom:'15px'}}>
						<Typography gutterBottom variant="h6">
							{account.name}
						</Typography>
						{this.renderRemoveAccess(account._id)}
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Warehouse ID</TableCell>
									<TableCell>Label</TableCell>
									<TableCell>Access</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(account.warehouses||[]).map(warehouse => {
									// const accessControl = <Checkbox checked={!!warehouse.can_user_access} />;
									return <TableRow key={warehouse._id}>
										<TableCell>{warehouse._id}</TableCell>
										<TableCell>{warehouse.warehouse_bot.label}</TableCell>
										<TableCell>{this.renderWarehouseAccess(warehouse)}</TableCell>
									</TableRow>;
								})}
							</TableBody>
						</Table>
					</Card>;
				});
			}}
		</StubQuery>;
	}
}

const userQuery=gql`query userInformation($user_id: ID!) {
	user(_id: $user_id) {
		_id
		username
		email
	}
}`;
export function UserAdministration(){
	const {user_id} = useParams();
	if(!user_id) return "Invalid - user_id required";
	return <Card>
		<CardContent>
			<Typography gutterBottom variant="h5" component="h1">
				User Administration
			</Typography>
			<StubQuery query={userQuery} variables={{user_id}} errorPolicy="all">
				{({data,error,loading}) => {
					if(loading) return 'Loading user info..';
					if(error && !data) return "Error..."+JSON.stringify(error);
					return <div>{data.user.username} ({data.user._id})</div>;
				}}
			</StubQuery>
			<Grid container>
				<Grid item md={6}>
					<h3> Accounts </h3>
					<UserAccountList user_id={user_id} />
				</Grid>
				<Grid item md={6}>
					<h3> Permissions </h3>
					<UserPermissionsList user_id={user_id} />
				</Grid>
			</Grid>
		</CardContent>
	</Card>;
}

export class UserAdministrationList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ''
		};
	}

	render() {
		let query = userListQuery, variables={};
		if(this.state.search) {
			query = userSearchQuery;
			variables.text=this.state.search;
		}
		return <Card>
			<CardContent>
				<Typography gutterBottom variant="h5" component="h1">
					User Administration
				</Typography>
				<SearchBox value={this.state.search} onChange={e => this.setState({
					search: e.target.value
				})} />
				<FraktureQuery query={query} variables={variables} pageOnScroll={!this.state.search}>
					{data => {
						let users;

						if(this.state.search) users = data.users_search;
						else users = data;
						return <Table>
							<TableHead>
								<TableRow>
									<TableCell>User ID</TableCell>
									<TableCell>Username</TableCell>
									<TableCell>Accounts</TableCell>
									<TableCell>Last Seen</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map(user => {
									let last_seen=user.last_seen;
									if(last_seen) last_seen=dayjs(last_seen).calendar();
									let userCol=user.username;
									if(user.is_frakture_admin) userCol = <b>{user.username}</b>;
									let accountsCol;
									if(user.is_frakture_admin) accountsCol='All Accounts (Admin)';
									else {
										const fullCount = user.account_ids.length;
										if(!fullCount) accountsCol='None';
										else if(fullCount < 4) accountsCol=user.account_ids.join(', ');
										else {
											let remainder=fullCount-4;
											accountsCol = user.account_ids.slice(0,4).join(', ')+`... ${remainder} more`;
										}
									}

									return <TableRow key={user._id}>
										<TableCell><Link to={'/app/users/'+user._id}>{user._id}</Link></TableCell>
										<TableCell>{userCol}</TableCell>
										<TableCell>{accountsCol}</TableCell>
										<TableCell>{last_seen}</TableCell>
									</TableRow>;
								})}
							</TableBody>
						</Table>;
					}}
				</FraktureQuery>
			</CardContent>
		</Card>;
	}
};


