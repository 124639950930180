import React from 'react';

export default function DonorCategoryHelp() {
	return <table class="table table-striped">
		<thead>
			<tr>
				<th>Category</th>
				{/*<th>Minor Category</th>*/}
				<th>This Year</th>
				<th>Last Year</th>
				<th>Two Years Ago</th>
				<th>3+ Years Ago</th>
			</tr>
		</thead>
		<tbody>
			<tr id="5be40bf6-8c00-4fb0-b8db-508afec3a88f">
				<td >New</td>
				{/*<td >New</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td >-</td>
				<td >-</td>
			</tr>
			<tr id="7fb98135-d99a-4c90-98d5-2d07b8865d28">
				<td >Second Year</td>
				{/*<td >Second Year</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td >-</td>
			</tr>
			<tr id="95e1a8d9-a48b-4eb4-bb61-f65a2a85e885">
				<td >Multi Year</td>
				{/*<td >Multi-Year Every</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="c59abd4a-7f2d-470e-bb81-926510ce755e">
				<td ></td>
				{/*<td >Multi-Year New</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
			</tr>
			<tr id="adc755e5-085e-4b63-95a9-f3abc3c6bae7">
				<td ></td>
				{/*<td >Multi-Year Gap</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="3ec20184-4c51-41e4-8b3f-25b086f7f64a">
				<td >Recaptured</td>
				{/*<td >Recaptured Gap</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="d326aded-8c47-4701-ba6f-60496541c7a3">
				<td ></td>
				{/*<td >Recaptured Extended</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="f6b97358-6b94-42c0-b333-6051047e8991">
				<td ></td>
				{/*<td >Recaptured New</td>*/}
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
			</tr>
			<tr id="bfe97af1-f959-4134-986c-faf4149d9594">
				<td >Lapsed</td>
				{/*<td >Lapsed New</td>*/}
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td >-</td>
			</tr>
			<tr id="16d2b80e-3729-4dcc-b52d-1f5a959167a0">
				<td ></td>
				{/*<td >Lapsed Extended New</td>*/}
				<td >-</td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
			</tr>
			<tr id="0423ade1-90e4-4b5b-82c5-41b65a859a52">
				<td ></td>
				{/*<td >Lapsed Extended Multi</td>*/}
				<td >-</td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="42b80988-335f-4f35-869c-18dd3a1f19ef">
				<td ></td>
				{/*<td >Lapsed Multi New</td>*/}
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="52b26446-80f3-4601-bdb6-ca84992734db">
				<td ></td>
				{/*<td >Lapsed Multi Every</td>*/}
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="e9e6f088-c29f-4f21-b980-f70706ecbc04">
				<td ></td>
				{/*<td >Lapsed Second Year</td>*/}
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
				<td ><strong><mark >$</mark></strong></td>
				<td >-</td>
			</tr>
			<tr id="1356ddfd-a9d9-45b4-b9d3-1ed33856b542">
				<td >Deep Lapsed</td>
				{/*<td >Deep Lapsed</td>*/}
				<td >-</td>
				<td >-</td>
				<td >-</td>
				<td ><strong><mark >$</mark></strong></td>
			</tr>
			<tr id="610d3889-6af7-4e8c-ba38-5dc8b2794d3c">
				<td >Non-Donor</td>
				{/*<td >Non-Donor</td>*/}
				<td >-</td>
				<td >-</td>
				<td >-</td>
				<td >-</td>
			</tr>
		</tbody>
	</table>;
}
