import React,{useState,useEffect} from 'react';

import SaveOnBlur from '../../inputs/SaveOnBlur';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import MenuItem from '@mui/material/MenuItem';

import sourceCodeFields from '../FieldDefinitions';

export default function DefaultValues(props) {
	const {defaultValues,setDefaultValues} = props;

	const [nextValue,setNextValue]=useState('');
	const [nextSelect,setNextSelect]=useState('');

	useEffect(() => {
		if(nextValue && nextSelect) {
			setDefaultValues(Object.assign({},defaultValues, {
				[nextSelect]: nextValue
			}));
			setNextValue(null);
			setNextSelect(null);
		}
	}, [nextValue,nextSelect]);

	return <React.Fragment>
		{Object.keys(defaultValues).map((name,i) => {
			const value=defaultValues[name];
			return <TableRow key={i}>
				<TableCell style={{width:'50%'}}>{name}</TableCell>
				<TableCell style={{width:'50%'}}>
					<SaveOnBlur value={value} onChange={n => {
						const save = Object.assign({}, defaultValues, {
							[name]: n
						});
						if(!n) delete save[name];
						setDefaultValues(save);
					}} />
				</TableCell>
			</TableRow>;
		})}
		<TableRow>
			<TableCell >
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Field for Default Value</InputLabel>
					<Select value={nextSelect||undefined} style={{width:'100%'}} onChange={e => setNextSelect(e.target.value)}>
						{Object.keys(sourceCodeFields).filter(x => !defaultValues[x]).map(x => {
							return <MenuItem key={x} value={x}>{x}</MenuItem>;
						})}
					</Select>
				</FormControl>
			</TableCell>
			<TableCell>
				<SaveOnBlur onChange={value => setNextValue(value)}/>
			</TableCell>
		</TableRow>
	</React.Fragment>;
}
