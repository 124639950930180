import React from 'react';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";

import {useAccountId} from '../account/AccountInfo';

import {QueryStringForm,QuickDateRange2,TextFieldInput} from '../inputs/QueryStringInputs';
import {BotSelect} from '../bots/BotSelect';


const FILTER_QUERY = gql(`
query FILTER_QUERY($account_id: ID!, $method:String!) {
	account(_id: $account_id) {
		_id
		bots_with_method(method: $method) {
			_id
			path
			bot {
				label
			}
		}
		source_code_elements {
			_id
			source_code_element_set_id
			name
			label
			description
			type
			fql
			values{
				name
				value
				label
			}
		}
		default_warehouse_bot{
			_id
			global_table_prefix
		}
	}
}`);
/*
function SourceCodeSelect(props){
	const {data,loading,error}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (loading) return "Loading ...";
	if (error){
		console.error(error);
		return "There was an error loading source codes";
	}
	if (!data){return "No data found";}
	const {account:{default_warehouse_bot:{_id:warehouse_bot_id,global_table_prefix},source_code_elements}}=data;
	return JSON.stringify(data);
}
*/

export default function MessageFilters(props){
	const {default_query}=props;
	const account_id=useAccountId();
	const { loading, error, data }=useQuery(
		FILTER_QUERY,
		{variables: {
			account_id,
			method:"listMessages"
		}});
	if (loading){return "Loading ...";}
	if (error) return "Error:"+(error.message || JSON.stringify(error));
	return <div className="message-filters">
		<QueryStringForm>{ ({values,onChange:_onChange}) => {
			let onChange=(e)=>{
				_onChange(e);
			};
			return (
				<React.Fragment>
					<BotSelect bots={data.account.bots_with_method}/>
					<TextFieldInput name="primary_source_code" label="Primary Source Code" onChange={onChange} value={values.primary_source_code}/>
					<QuickDateRange2 start={default_query.start} end={default_query.end}/>
				</React.Fragment>
			);
		}}
		</QueryStringForm>
	</div>;
}
