import React,{useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Popover from '@mui/material/Popover';
import Fab from '@mui/material/Fab';
import {ErrorBoundary} from './Error';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function checkHTML(s){
	if (typeof s=='string') return <div dangerouslySetInnerHTML={{__html:s}}/>;
	return s;
}

export function ColumnOptions(){
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				id="demo-positioned-button"
				aria-controls={open ? 'demo-positioned-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				...
			</Button>
			<Menu
				id="demo-positioned-menu"
				aria-labelledby="demo-positioned-button"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuItem onClick={handleClose}>Profile</MenuItem>
				<MenuItem onClick={handleClose}>My account</MenuItem>
				<MenuItem onClick={handleClose}>Logout</MenuItem>
			</Menu>
		</div>);
}



export function ColumnPicker({columns,selectedFields,setSelectedFields}){
	const [anchorEl, setAnchorEl] = React.useState(null);
	let initSelected={};
	if (selectedFields?.length>0){
		selectedFields.forEach(f=>initSelected[f]=true);
	}else{
		initSelected=columns.reduce((a,b)=>{if(!b.hidden){a[b.name]=true;} return a;},{});
	}

	const [selected, setSelected] = React.useState(initSelected);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		if (Object.entries(selected).length>0){
			let finalSelected={};
			columns.forEach(c=>{
				if (selected[c.name]) finalSelected[c.name]=selected[c.name];
			});
			let selectedReturnValue=Object.entries(finalSelected).map(([key,value])=>value?key:null).filter(Boolean);
			setSelectedFields(selectedReturnValue);
		}else{
			//console.log("handleClose does not have selected");
		}
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;
	function toggle(name,include){
		let before=JSON.stringify(selected);
		if (include){
			selected[name]=true;
		}else{
			delete selected[name];
		}
		let after=JSON.stringify(selected);
		setSelected(JSON.parse(JSON.stringify(selected)));
	}
	let allChecked=Object.values(selected).filter(Boolean).length===columns.length;
	function toggleAll(){
		let newVisible={};
		columns.forEach(b=>{
			newVisible[b.name]=allChecked?false:true;
		});
		setSelected(newVisible);
	}
	let columnGroups={};

	columns.forEach(c=>{
		if (c.alwaysHidden) return false;
		let group=c.group || "Other";
		columnGroups[group]=columnGroups[group]||[];
		columnGroups[group].push(c);
	});
	if (Object.keys(columnGroups).length===1){
		columnGroups={"":Object.values(columnGroups)[0]};
	}
	return <div>
		<Button onClick={handleClick} style={{padding:"4px"}}>Columns&nbsp;<MenuIcon/></Button>
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
			<Card className="p-2">
				<CardHeader title="Pick Columns" action={<span>Select All?<Checkbox checked={allChecked} onChange={e=>toggleAll()}/></span>}/>
				<CardContent className="p-0">
					{Object.keys(columnGroups).map((key,j)=><Accordion elevation={0} defaultExpanded={j===0} key={j}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
						>{key || "Fields"}</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{columnGroups[key].map((col,i)=>{
									return <FormControlLabel
										key={i}
										control={<Checkbox checked={!!selected[col.name]} value={i} onChange={e=>toggle(col.name,e.target.checked)}/>}
										label={col.title}
									/>;
								})}
							</FormGroup>
						</AccordionDetails>
					</Accordion>)}
				</CardContent>
			</Card>
		</Popover>
	</div>;
}



export default function FraktureTable({
	header,
	columns,
	rows:propRows,
	renderRow,
	onSelectedChange,
	onRowClick,
	onRowChange,
	onRowDelete,
	onFilter,
	getRowClass,
	size="small",
	className,
	includeSelectBox=false,
	includeEditing=false,
	includeColumnPicker=false,
	includeGroupBy=false,
	includeFilter=false,
	includePagination=true,
	selectedFields=null,
	setSelectedFields,
	sort:defaultSort="",
	sortDirection:defaultSortDirection="asc",
	sortFunction=(a,b,sortField,desc)=>{return (a[sortField]||"")<(b[sortField]||"")?desc:-desc;},
	onSort,
	rowsPerPage:startingRowsPerPage=50,
	padding="normal",
	stickyHeader=false,
	stickyColumn=false
}){
	const [sort, setSort] = useState(defaultSort);
	const [sortDirection, setSortDirection] = useState(defaultSortDirection);
	const [groupBy, setGroupBy] = useState("");
	const [groupByDisplay, setGroupByDisplay] = useState(null);
	const [editing, setEditing] = useState(false);
	const [selectedRows, setInternalSelectedRows] = useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(parseInt(startingRowsPerPage));

	if (!propRows) return "No data rows provided";
	const rows=JSON.parse(JSON.stringify(propRows));

	const _columns=(columns || Object.keys(rows[0]||{"No Data":1}).map(k=>{return {title:k.split("-").pop(),field:k};})).map(c=>{
		c.format=c.format|| (f=>f);
		return c;
	});

	let sortedRows=rows;
	if (sort){
		let desc=sortDirection==="desc"?1:-1;
		sortedRows=rows.slice(0).sort((a,b)=>{
			return sortFunction(a,b,sort,desc);
		});
	}
	rows.forEach((r,i)=>{
		if (typeof r!=='string') r.id=r.id || i;
	});

	//Set the state, and then optionally pass up the chain
	function setSelectedRows(s){
		//filter out rows the may have been removed
		s=s.filter(selectedRow=>rows.find(r=>r.id===selectedRow));
		setInternalSelectedRows(s);
		if (typeof onSelectedChange=='function') onSelectedChange(s);
	}

	let finalRows=null;
	if (includeGroupBy && groupBy){
		const values={};
		sortedRows.forEach(r=>{
			values[r[groupBy]]=(values[r[groupBy]]||[]).concat(r);
		});
		finalRows=[];
		let grouped=Object.keys(values).map(v=>{
			return {value:v,rows:values[v],count:values[v].length};
		}).sort((a,b)=>a.count<b.count?1:-1);
		//keep this log in here -- useful for lots of groupings
		console.log(Object.keys(values).join(","));

		grouped.forEach(v=>{
			finalRows.push({component:<TableRow onClick={e=>setGroupByDisplay(groupByDisplay===v.value?null:v.value)}><TableCell colSpan={_columns.length+1}>
				<h4>{(_columns.find(col=>col.field===groupBy)||{title:groupBy}).title+" ("+v.rows.length+" records) : "+v.value}</h4>
			</TableCell></TableRow>
			});
			if (groupByDisplay!=null && groupByDisplay===v.value){
				finalRows=finalRows.concat(v.rows);
			}
		});
	}else{
		finalRows=sortedRows;
	}

	function handlePaste(e){
		console.log(e.clipboardData.getData('Text'));
		console.log(e.clipboardData.getData('text/plain'));
		console.log(e.clipboardData.getData('text/html'));
		console.log(e.clipboardData.getData('text/rtf'));

		console.log(e.clipboardData.getData('Url'));
		console.log(e.clipboardData.getData('text/uri-list'));
		console.log(e.clipboardData.getData('text/x-moz-url'));
	}

	function handleSelectAllRowsClick(event) {
		let displayedRows=finalRows.map(n => n.id).filter(d=>d!==undefined);
		if (event.target.checked && selectedRows.length!==displayedRows.length) {
			const newSelecteds = displayedRows;
			setSelectedRows(newSelecteds);
			return;
		}
		setSelectedRows([]);
	}

	function handleSelectRowClick(event, id) {
		const selectedIndex = selectedRows.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedRows, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedRows.slice(1));
		} else if (selectedIndex === selectedRows.length - 1) {
			newSelected = newSelected.concat(selectedRows.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selectedRows.slice(0, selectedIndex),
				selectedRows.slice(selectedIndex + 1),
			);
		}
		setSelectedRows(newSelected);
	}

	function handleCellChange(row,event){
		if (row[event.target.name]!==event.target.value){
			row[event.target.name]=event.target.value;
			if (onRowChange) onRowChange(row);
		}
	}
	function rowClass(row,i){
		if (getRowClass) return getRowClass(row,i);
		else return null;
	}

	let _renderRow=function(row,i){
		let newValues={};
		return <React.Fragment key={i}>{row.component || <TableRow
			key="row"
			selected={selectedRows.indexOf(row.id)>=0}
			className={rowClass(row,i)}
			onClick={e=>{
				return typeof onRowClick=='function'?onRowClick(row):null;
			}
			}
		>{includeSelectBox &&<TableCell
				role="checkbox"
				aria-checked={selectedRows.indexOf(row.id)>=0}
				padding="checkbox"
				onClick={event =>{handleSelectRowClick(event, row.id);event.stopPropagation();}}>
				<Checkbox
					checked={selectedRows.indexOf(row.id)>=0}
				/>
			</TableCell>
			}{_columns.map((c,j)=> {
				if (selectedFields?.length>0 && selectedFields.indexOf(c.alias)<0) return null;
				let val=c.render?checkHTML(c.render({row})):
					(editing&c.field!=="id")?<input
						type="text"
						name={c.field}
						onBlur={e=>handleCellChange(row,e)}
						onPaste={handlePaste}
						key={`${c.field}:${newValues[c.field] || row[c.field]}`}
						defaultValue={newValues[c.field] || row[c.field]}
					/>:c.format(row[c.field]);

				if (val && typeof val=='object' && !React.isValidElement(val)){
					console.error("Error in table, an object was specified for row:",row,val,typeof val);
					try {
						val=JSON.stringify(val);
					} catch(e) {
						val = val.toString();
					}
				}
				return <TableCell key={j} align={c.align} className={`text-nowrap${(stickyColumn&&j===0)?" stickyColumn":""}`} onClick={e=>c.onClick?c.onClick(row,c):{}}>{val}</TableCell>;
			})}{editing && <TableCell>{(onRowDelete)?<DeleteIcon onClick={()=>onRowDelete(row)}/>:""}</TableCell>}</TableRow>}</React.Fragment>;
	};
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	let rowsPerPageOptions=[5,10,25,100,500,1000];
	if (rowsPerPageOptions.indexOf(rowsPerPage)<0) rowsPerPageOptions=rowsPerPageOptions.concat(parseInt(rowsPerPage)).sort((a,b)=>(parseInt(a)<parseInt(b)?-1:1));
	//let fields=_columns.map(c=>({name:c.field,label:c.title}));

	return (
		<div className="w-100 frakture-table">
			{(header || includePagination || includeColumnPicker)&&<div className="frakture-table-header">
				{header}
				{includePagination && <TablePagination
					rowsPerPageOptions={rowsPerPageOptions}
					component="div"
					count={finalRows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>}
				{includeColumnPicker && <ColumnPicker columns={_columns} selectedFields={selectedFields} setSelectedFields={setSelectedFields}/>}
			</div>}
			<div style={{overflowX: 'auto',maxHeight:stickyHeader?'1000px':''}}>
				<Table size={size} stickyHeader={stickyHeader} padding={padding} style={{minWidth: 500}} className={`auto-table ${editing?"auto-table-editing":""} ${className}`}>
					<TableHead>
						<TableRow>{includeSelectBox && <TableCell padding="checkbox"><Checkbox
							checked={selectedRows.length === finalRows.length}
							onChange={handleSelectAllRowsClick}
							inputProps={{ 'aria-label': 'Select All' }}
						/></TableCell>}{_columns.map((c,i)=>{
							if (selectedFields?.length>0 && selectedFields.indexOf(c.alias)<0) return null;
							return <TableCell key={i} align={c.align} className={`text-nowrap${(stickyColumn&&i===0)?" stickyColumn":""}`}
							><Tooltip title={c.description||""} disableHoverListener={!c.description} placement="top"><TableSortLabel
									active={c.field === sort}
									direction={sortDirection||'asc'}
									onClick={e => {
										let dir=sortDirection===('asc')?'desc':'asc';
										setSort(c.field);
										setSortDirection(dir);
										if (typeof onSort=='function') onSort(c.field,dir);
									}}>{typeof c.title=='string'?c.title:"(no label)"}</TableSortLabel></Tooltip>{
									includeGroupBy?<Button className={c.field===groupBy?"active":""} size="small"><ListIcon onClick={e => setGroupBy(c.field===groupBy?"":c.field)} color={groupBy===c.field?"primary":"action"}/></Button>:""
								}{onFilter && <Button onClick={e=>onFilter([{fql:(c.fql || c.field)+"=''"}])}>Is Blank</Button>}</TableCell>;
						})}{includeEditing?(<TableCell padding="checkbox" className="d-flex"><IconButton
							color={editing?"secondary":"primary"}
							onClick={e=>setEditing(!editing)}
							size="large"><EditIcon/></IconButton></TableCell>):null}</TableRow>
					</TableHead>
					<ErrorBoundary>
						<TableBody>{includePagination?
							finalRows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map((row,i)=>checkHTML(_renderRow(row,i)))
							:
							finalRows.map((row,i)=>checkHTML(_renderRow(row,i)))
						}
						</TableBody>
					</ErrorBoundary>
				</Table>
				{editing?
					<Fab color="primary" aria-label="add" onClick={()=>{if (onRowChange)onRowChange({});}}>
						<AddIcon />
					</Fab>
					:""}
			</div>
		</div>
	);
}
