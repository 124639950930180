import React from 'react';
import {useQuery} from '@apollo/client';

import {DICTIONARY_BOT_QUERY,useAccountWarehouseInfo} from '../warehouse/account-warehouse-info.js';
import {useParams,useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {relativeDate} from '../formatters';
import {QuickDateRange2} from '../inputs/QueryStringInputs';
import {useNavigateToMessage} from './Message.js';
import FraktureDataTable from '../warehouse/FraktureDataTable';
import {Header} from '../components/Header';


export function Cleanup() {
	let {account_id}=useParams();
	const {data}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (!data) return "";
	const {account:{default_warehouse_bot:{_id:warehouse_bot_id,global_table_prefix}}}=data;

	let _props={global_table_prefix,warehouse_bot_id};

	return <div className="d-flex flex-column h-100 cleanup-wrapper"><MessageSheet {..._props}/></div>;
};

export default function MessageSheet(props){
	const {account_id}=useParams(props);
	const location = useLocation();
	const qs = queryString.parse(location.search);
	const {start="-3M",end}=qs;
	const navToMessage=useNavigateToMessage();
	const {loading,error,data:accountInfo} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return error;

	//return <button onClick={e=>navToMessage({message_id:1910746})}>Test {new Date().toISOString()}</button>;

	const {default_warehouse_bot, source_code_elements} = accountInfo;
	if(!default_warehouse_bot) return 'loading';
	const {global_table_prefix, _id:warehouse_bot_id}=default_warehouse_bot;

	// let join=global_table_prefix+"global_message_stats";
	let fields=[
		{
			title:"Link",
			editable:false,
			hidden:true,
			cellBackgroundColor:"#EEE",
			width:200,
			value:function(){
				return "Link";
			}
		},
		{field:"bot_id",editable:false,title:"Bot",width:80,cellBackgroundColor:"#FCFCFC"},
		{field:"submodule",editable:false,title:"Submodule",width:80,cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"channel",editable:false,title:"Channel",width:80,cellBackgroundColor:"#FCFCFC"},
		{field:"message_id",editable:false,title:"Id",width:120,cellBackgroundColor:"#FCFCFC"},
		{field:"remote_id",editable:false,title:"Remote Id",width:200,cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"publish_date",editable:false,title:"Publish Date",cellBackgroundColor:"#FCFCFC",width:120,type:"date"},
		{field:"label",editable:false,title:"Label",cellBackgroundColor:"#FCFCFC"},
		{field:"subject",editable:false,title:"Subject",cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"from_name",editable:false,title:"From",cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"campaign_id",editable:false,title:"Campaign Id",cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"campaign_name",editable:false,title:"Campaign Name",cellBackgroundColor:"#FCFCFC",hidden:true},
		{field:"primary_source_code",alwaysHidden:true},
		{
			title:"Primary Source Override",
			field:"primary_source_code_override",
			width:200,
			table: global_table_prefix+'global_message',
			editable: true
		},
		{
			title:"Primary Source Code",
			editable:false,
			cellBackgroundColor:"#EEE",
			width:200,
			value:function(row){
				let over=row[global_table_prefix+'global_message-primary_source_code_override'];
				if (over) return over;
				else return row[global_table_prefix+'global_message_summary-primary_source_code'];
			}
		},
		{group:"Statistics",field:"sent",editable:false,title:"Sent",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"impressions",editable:false,title:"Impressions",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"clicks",editable:false,title:"Clicks",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"revenue",editable:false,title:"Revenue",type:"currency",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"attributed_revenue",editable:false,title:"Attributed Revenue",type:"currency",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"spend",editable:false,title:"Spend",type:"currency",width:120,cellBackgroundColor:"#EFE"},
		{group:"Statistics",field:"spend_override",editable:true,title:"Spend Override",type:"currency",width:120},
		{group:"Statistics",field:"source_code_date_parsed",editable:false,title:"Source Code Parsed Date",width:120,cellBackgroundColor:"#EFE"},
	];
	fields.forEach(d=>{
		d.group=d.group||"Message Info";
	});

	source_code_elements.forEach(def => {
		if (def.name==='date') return; //some legacy handling for elements named 'date'
		def={
			group:"Source Code Elements",
			auto_show_if_present: !accountInfo.parent,
			hidden:true,
			editable:false,
			field: def.name,
			title: def.title || def.name,
			cellBackgroundColor: "#EEE"
		};
		fields.push(def);
	});
	let conditions=[
		{fql:global_table_prefix+"global_message_summary.publish_date>='"+relativeDate(start||"-3M").toISOString()+"'"},
		(end?{fql:global_table_prefix+"global_message_summary.publish_date<'"+relativeDate(end).toISOString()+"'"}:null)
	].filter(Boolean);
	let popover_items=[];
	if(accountInfo.childSelectPopover) popover_items.push(accountInfo.childSelectPopover);
	function onClick(e) {
		if (e.cell && e.cell.header && e.cell.header.title==='Link'){
			navToMessage({message_id:e.cell.data[global_table_prefix+"global_message_summary-message_id"]});
		}
	}

	return <React.Fragment>
		<Header
			primary={[
				"Messages",
				<QuickDateRange2 key="date" start={start} end={end}/>
			]}
		/><FraktureDataTable
			table={global_table_prefix+"global_message_summary"}
			warehouse_bot_id={warehouse_bot_id}
			limit={1000}
			fields={fields}
			date_field="publish_date"
			join={global_table_prefix+'global_message'}
			order_by={[{table:global_table_prefix+"global_message_summary",
				field:"publish_date",order_by_direction:"DESC"}]}
			conditions={conditions}
			popover_items={popover_items}
			onClick={onClick}
			view="grid"
		/></React.Fragment>;
}
