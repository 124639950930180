import React,{useState} from 'react';
import {humanize,formatValue} from '../../../formatters';
import FraktureTable from '../../../FraktureTable';

export default function FraktureReportTable(props){
	let {dimensions,metrics,label,height=530,data,renderRow,className="table table-striped",display_max}=props;
	if (!height || parseInt(height)<530) height=530;//minimum height

	const [sort, setSort] = useState(null);
	const [sortDirection, setSortDirection] = useState('asc');

	let fields=(dimensions||[]).map(d=>{return {label:d.label,alias:d.alias || d.fql || d.label,fql:d.fql,format:d.format,description:d.description,col:d.col};})
		.concat(metrics.map(d=>{return {label:d.label,alias:d.alias || d.fql || d.label,fql:d.fql,format:d.format,description:d.description,col:d.col};}));

	let columns=[];

	//establish column and field specific formats
	fields.forEach((f,i)=>{
		f.formatter=(f.format)?(typeof f.format=='function'?f.format:x=>formatValue(x,f.format)):x=>typeof x=='number'?formatValue(x):humanize(x,60);
		let col=f.col===undefined?i:f.col;
		columns[col]=columns[col]||{colId:col,title:(f.label || f.alias),description:f.description,field_list:[]};
		columns[col].field_list.push(f);
	});

	const wrappers=["b","span","i","span"];
	columns.forEach(col=>{
		col.field=col.field_list[0].alias; //for sorting, etc, needs the field
		col.render=({row})=>{
			let v=col.field_list.map((field,i)=>{
				let val=field.formatter(row[field.alias]);
				if (col.field_list.length===1) return val;
				return `<${wrappers[i]}>${val}</${wrappers[i]}>`;
			}).join("<br/>");
			return v;
		};
	});
	let _renderRow=null;
	try{
		if (renderRow && typeof renderRow=='string'){
			eval("_renderRow="+renderRow);
		}else _renderRow=renderRow;
	}catch(e){
		console.error(renderRow);
		console.error(e);
		return "Invalid render function";
	}

	if (!height) return "No height specified for report";

	let collapsedData=data;
	//If there's too much data, sort it high to low, then group the other records
	if (display_max && data.length>display_max){
		let name=fields[0].alias;
		let key=fields[1].alias;
		data.sort((a,b)=>{
			if (!a[name]) return 1;//blanks go to the end, to maybe be included in 'Other'
			if (!b[name]) return -1;//blanks go to the end, to maybe be included in 'Other'
			return b[key]<a[key]?-1:1;});
		collapsedData=data.slice(0,display_max); //only show the first display_max records
		let remainder=data.slice(display_max).reduce((a,b)=>{a[key]+=b[key];return a;},{[name]:"(Other)",[key]:0});
		if (remainder[key]>0) collapsedData.push(remainder);
		collapsedData.forEach(a=>a[name]=!a[name]?"(Blank)":a[name]);
	}

	let rowsPerPage=Math.floor((height-80)/45);
	if (rowsPerPage<3) rowsPerPage=3;
	let includePagination=true;
	if (collapsedData.length<=rowsPerPage) includePagination=false;

	return <React.Fragment>
		<div style={{height:"100%",overflow:"auto"}}>
			<FraktureTable
				key="table"
				header={label && <span className="report-item-header">{label}</span>}
				rows={collapsedData}
				columns={columns}
				className={className}
				includePagination={includePagination}
				includeColumnPicker={false}
				rowsPerPage={rowsPerPage}
				sort={sort}
				sortDirection={sortDirection}
				onSort={(field,dir)=>{setSort(field);setSortDirection(dir);}}
				renderRow={_renderRow}
				padding="normal"
			/></div>
	</React.Fragment>;
};
