import React from 'react';
import {AccountInfoWrapper} from '../account/AccountInfo';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export default function ChildAccountSelect(props) {
	const{ignore}=props;
	return <AccountInfoWrapper>{({account})=> {
		const children = account.children||[];;
		return <FormControl fullWidth>
			<InputLabel>{props.label}</InputLabel>
			<Select {...props}>
				{children.filter(x => {
					if(ignore && ignore.length) return !ignore.find(y=>y===x._id);
					return true;
				}).map(c => {
					return <MenuItem key={c._id} value={c._id}>
						{c.name}
					</MenuItem>;
				})}
			</Select></FormControl>;
	}}</AccountInfoWrapper>;
};
