import React from 'react';
import Grid from '@mui/material/Grid';
import './CompetitiveSpend.scss';
import ReportCard from '../ReportCard';

export default function ReportTemplate(props){
	let {components}=props;
	let {
		compete_a_advertiser,
		compete_a_spend,
		compete_a_impressions,
		compete_a_timeline_spend,
		compete_b_spend,
		compete_b_impressions,
		compete_b_timeline_spend,
		compete_ind_spend,
		compete_ind_impressions,
		compete_ind_timeline_spend,
		compete_timeline,
		compete_timeline_spend,
		compete_top_ads_a,
		compete_top_ads_b,
		compete_top_ads_ind,
		region}=components;

	return <div className="report-body" id="report-competitive-spend">
		{compete_a_advertiser}
		<Grid container>
			<Grid item md={4} xs={12}>
				<ReportCard title="Liberal or Democratic">
					<Grid container>
						<Grid item md={6} xs={12}>{compete_a_spend}</Grid>
						<Grid item md={6} xs={12}>{compete_a_impressions}</Grid>
						<Grid item md={12} xs={12}>{compete_a_timeline_spend}</Grid>
					</Grid>
				</ReportCard>
			</Grid>
			<Grid item md={4} xs={12}>
				<ReportCard title="Independent">
					<Grid container>
						<Grid item md={6} xs={12}>{compete_ind_spend}</Grid>
						<Grid item md={6} xs={12}>{compete_ind_impressions}</Grid>
						<Grid item md={12} xs={12}>{compete_ind_timeline_spend}</Grid>
					</Grid>
				</ReportCard>
			</Grid>
			<Grid item md={4} xs={12}>
				<ReportCard title="Conservative or Republican">
					<Grid container>
						<Grid item md={6} xs={12}>{compete_b_spend}</Grid>
						<Grid item md={6} xs={12}>{compete_b_impressions}</Grid>
						<Grid item md={12} xs={12}>{compete_b_timeline_spend}</Grid>
					</Grid>
				</ReportCard>
			</Grid>
			{/*
			<Grid item md={6} xs={12} className="compete-b">
				<Grid container><Grid item md={6} xs={12}>Spend{compete_b_spend}</Grid>
					<Grid item md={6} xs={12}>Picker{compete_b_picker}</Grid>
				</Grid>
			</Grid>
			*/}
			<Grid item md={12} xs={12} className="compete-both">{compete_timeline}</Grid>
			<Grid item md={12} xs={12} className="compete-both">{compete_timeline_spend}</Grid>
			<Grid item md={4} xs={12} className="compete-both">{compete_top_ads_a}</Grid>
			<Grid item md={4} xs={12} className="compete-both">{compete_top_ads_ind}</Grid>
			<Grid item md={4} xs={12} className="compete-both">{compete_top_ads_b}</Grid>
			<Grid item md={12} xs={12} className="compete-both">{region}</Grid>
		</Grid>
	</div>;
};
