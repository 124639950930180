import React from 'react';

import {JobDisplay} from './Job';
import JobListDetail from './JobListDetail';

import {useLocation,useNavigate} from 'react-router-dom';

export function useNavigateToJobList() {
	const location=useLocation();
	const navigate = useNavigate();
	return ({job_list_id,job_id}) => {
		const urlParts = [
			location.pathname,
			location.search,
			'#job_list_id=',
			job_list_id,
			'&job_id=',
			job_id
		];
		navigate(urlParts.join(''));
	};
};

export default function JobModal(props) {
	const location=useLocation();
	const navigate=useNavigate();
	const {job_id,job_list_id}=props;

	function closeModal() {
		//Get rid of the hash
		const urlParts = [
			location.pathname,
			location.search
		];
		navigate(urlParts.join(''));
	};

	function closeJob() {
		const urlParts = [
			location.pathname,
			location.search,
			'#job_list_id=',
			job_list_id
		];
		navigate(urlParts.join(''));
	}
	//let style={overflow:"auto",zIndex:1120,background:"#FFF",height:"100%"};
	return <div className="app-job-wrapper">
		<div id="sidebar2">
			{job_list_id?<JobListDetail {...{closeModal}} job_list_id={job_list_id} selected_id={job_id}/>:""}
		</div>
		<div className="app-main-content">
			{job_id?<JobDisplay key={job_id} job_id={job_id} onClose={()=>closeJob()}/>:"Select a job"}
		</div>
	</div>;
}
