import React from 'react';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/client';
import Select from 'react-select';
import {useAccountId} from './AccountInfo';

const SHARED_ACCOUNTS_QUERY=gql`
query SHARED_ACCOUNTS_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		_id
		name
		children {
			_id
			name
		}
	}
}
`;

export default function(props) {
	const account_id = useAccountId(props);
	if(!account_id) return "Account ID is required";

	const {data={},error,loading}=useQuery(SHARED_ACCOUNTS_QUERY, {
		variables: {account_id}
	});

	if(error) return error.toString();

	let account = data.account || {};

	let options = [{
		value: account_id,
		label: account.name ? `${account.name} (${account_id})` : account_id
	}];
	if(!loading) data.account.children.forEach(({_id,name}) => {
		options.push({
			value: _id,
			label: `${name} ${_id}`
		});
	});

	return <Select value={props.value||''} options={options} onChange={x => props.onChange(x)} />;
};
