
const sourceCodeFields = {
	source_code_channel: {
		name: 'source_code_channel',
		description: 'Specific user-defined channel prefix, as distinct from Frakture defined channels',
		values: [],
		title: 'Channel'
	},
	campaign: {
		name: 'campaign',
		description: 'Top level of hierarchy',
		title: 'Campaign'
	},
	message_set: {
		name: 'message_set',
		description: 'Multiple grouped messages, like AdSet',
		title: 'Message Set'
	},
	variant: {
		name: 'variant',
		description: 'Specific message variant',
		title: 'Variant'
	},
	account_prefix: { name: 'account_prefix', description: '', title: 'Account Prefix' },
	source_code_date: {
		name: 'source_code_date',
		description: 'YYYYMMDD format of the source code date.  Perhaps different from message publish date',
		title: 'Source Code Date'
	},
	subchannel: {
		name: 'subchannel',
		description: 'More details on the communication channel',
		title: 'Channel subtype'
	},
	media: {
		name: 'media',
		description: 'Video, text, image, etc. aka MIME type',
		title: 'Media type'
	},
	appeal: {
		name: 'appeal',
		description: 'A specific grouping of content, e.g.',
		title: 'Appeal'
	},
	fund: {
		name: 'fund',
		description: 'The fund this is targeted to',
		title: 'Fund'
	},
	issue: { name: 'issue', description: 'Broad issue area', title: 'Issue' },
	theme: {
		name: 'theme',
		description: 'Broad theme of the message',
		title: 'Theme'
	},
	policy: {
		name: 'policy',
		description: 'Policy area, e.g. Federal/State/International policy',
		title: 'Policy'
	},
	goal: {
		name: 'goal',
		description: 'e.g. Advocacy, Fundraising, Retention, Acquisition, Ticket sales',
		title: 'Goal'
	},
	goal_2: {
		name: 'goal_2',
		description: 'Secondary Goal',
		title: 'Secondary Goal'
	},
	audience: {
		name: 'audience',
		description: 'Target audience',
		title: 'Audience'
	},
	targeting: {
		name: 'targeting',
		description: 'More targeting details',
		title: 'Targeting Details'
	},
	targeting_2: {
		name: 'targeting_2',
		description: 'More targeting details',
		title: 'Additional Targeting Details'
	},
	author: {
		name: 'author',
		description: 'The human credited sender of the message - John Smith, Mary Jane',
		title: 'Author'
	},
	signer: {
		name: 'signer',
		description: "The human at the bottom of the letter -- often a celebrity -- ‚'Prince','Gandhi','The Rolling Stones', etc.",
		title: 'Signer'
	},
	agency: {
		name: 'agency',
		description: 'The responsible marketing agency',
		title: 'Agency'
	},
	partner: {
		name: 'partner',
		description: 'Sending organization, used for list rentals, partnerships, etc',
		title: 'Partner'
	},
	affiliation: {
		name: 'affiliation',
		description: 'High level affiliation',
		title: 'Affiliation'
	},
	department: {
		name: 'department',
		description: 'Responsible department within the organization',
		title: 'Organization/Department'
	},
	geo: { name: 'geo', description: 'Target geography', title: 'Geography' },
	organic: {
		name: 'organic',
		description: 'Organic or paid message',
		title: 'Organic/Paid'
	},
	recurtype: {
		name: 'recurtype',
		description: 'If this is a recurring campaign effort, please select from the following options',
		title: 'Recurring Campaign Type'
	},
	additional_info: {
		name: 'additional_info',
		description: 'Additional info',
		title: 'Additional'
	}
};
Object.keys(sourceCodeFields).forEach(x => {
	sourceCodeFields[x].value = x;
	sourceCodeFields[x].field = x;
});

export default sourceCodeFields;
