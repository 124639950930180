import React,{useState,useEffect} from 'react';

import SaveOnBlur from '../inputs/SaveOnBlur';
import './sourcecode.css';
import {useUserContext} from '../../AdminCheck';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import sourceCodeFields from './FieldDefinitions';

export default function SourceCodeEditor(props) {
	const {source_code_id,source_codes}=props;
	const sc = (source_codes||[]).find(x => x._id === source_code_id);

	const {is_frakture_admin} = useUserContext();

	const [sourceCode] = useState(sc);
	const [rawFormat,setRawFormat] = useState('');
	const [defaultValues,setDefaultValues]=useState({});
	const [nextSelect,setNextSelect]=useState('');

	useEffect(() => {
		if(!sourceCode) return;
		setRawFormat(sourceCode.format);
		setDefaultValues(sourceCode.default_values||{});
	}, [sourceCode]);

	if(!is_frakture_admin) return '';

	if(source_code_id && !sc) return 'invalid source code id '+source_code_id;

	const formatInput = <SaveOnBlur value={rawFormat} onChange={n => setRawFormat(n)} />;

	return <React.Fragment>
		{formatInput}
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Field</TableCell>
					<TableCell>Value</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{Object.keys(defaultValues).map((name,i) => {
					const value=defaultValues[name];
					return <TableRow key={i}>
						<TableCell>{name}</TableCell>
						<TableCell>
							<SaveOnBlur value={value} onChange={n => {
								const save = Object.assign({}, defaultValues, {
									[name]: n
								});
								if(!n) delete save[name];
								console.log('setting to:',n);
								setDefaultValues(save);
							}} />
						</TableCell>
					</TableRow>;
				})}
				<TableRow>
					<TableCell>
						<Select value={nextSelect} onChange={e => setNextSelect(e.target.value)}>
							{Object.keys(sourceCodeFields).filter(x => !defaultValues[x]).map(x => {
								return <MenuItem key={x} value={x}>{x}</MenuItem>;
							})}
						</Select>
					</TableCell>
					<TableCell>
						<SaveOnBlur onChange={value => setDefaultValues(Object.assign({},defaultValues, {
							[nextSelect]: value
						}))}/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	</React.Fragment>;
}
