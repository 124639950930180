import React from 'react';
import {useQuery} from '@apollo/client';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {useChangeURL} from '../inputs/QueryStringInputs';
import {Header} from '../components/Header';

//import SegmentList from './SegmentList';
import {useAccountId} from '../account/AccountInfo';

import gql from "graphql-tag";
import {GlobalSegmentList,GlobalSegmentDetails} from './global';

const BOT_WITH_METHOD_QUERY = gql(`
query BOT_WITH_METHOD_QUERY($account_id: ID!, $method:String!) {
	account(_id: $account_id) {
		_id
		bots_with_method(method: $method) {
			_id
			path
			bot {
				label
				warehouse_table_prefix
			}
		}
	}
}`);

export function SegmentBotList(){
	const {query,setURLParameters}=useChangeURL();
	const account_id=useAccountId();
	const { loading, error, data }=useQuery(
		BOT_WITH_METHOD_QUERY,
		{variables: {
			account_id,
			method:"listSegments"
		}});
	if (loading){
		return "Loading ...";}
	if (error) return "Error:"+(error.message || JSON.stringify(error));
	let {account={}}=data;
	let {bots_with_method:submodules}=account;
	let bots={};
	submodules.forEach(m=>{
		let bot_id=m._id.split('.')[0];
		bots[bot_id]=bots[bot_id]||{label:m.bot.label,bot_id,prefix:m.bot.warehouse_table_prefix?m.bot.warehouse_table_prefix+"_":""};
		m.label=m.path.split(".").pop();
		m.prefix=m.bot.warehouse_table_prefix;
		bots[bot_id].submodules=(bots[bot_id].submodules || []).concat(m);
	});
	bots=Object.values(bots);
	return <Grid>
		{bots.map((b,i)=><Card key={i}>
			<CardHeader title={b.label}/>
			<CardContent>
				<List>
					{b.submodules.map((s,j)=><ListItem button
						selected={query.bot_id===b.bot_id && query.path===s.path}
						key={j}>
						<ListItemText primary={s.label}
							onClick={e=>setURLParameters({bot_id:b.bot_id,path:s.path,prefix:b.prefix})}/></ListItem>
					)}</List>
			</CardContent>
		</Card>)}
	</Grid>;
}

export default function Segments(){
	return <div className="app-main-content-wrapper">
		<Header
			primary={[
				<h2 key="title">Global Segments</h2>
			]}
		/>
		<div className="app-main-content-ltr">
			<div id="sidebar2"><GlobalSegmentList/></div>
			<div className="app-main-content"><GlobalSegmentDetails/></div>
		</div>
	</div>;
};
