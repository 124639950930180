import React from 'react';
import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';
import gql from "graphql-tag";
import Button from '@mui/material/Button';

const runDataflowMutation = gql(`mutation run($dataflow_id:ID!,$pause_first_job:Boolean) {
	dataflow_run(dataflow_id:$dataflow_id,pause_first_job:$pause_first_job)
}`);


export default function RunButton(props){
	const client=useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const {dataflow_id,pause_first_job=false}=props;
	let className=pause_first_job?"bg-warning":"bg-success";
	let label=pause_first_job?"Run Paused":"Run Immediately";

	return <Button onClick={(e)=>{
		e.stopPropagation();
		client.mutate({
			mutation:runDataflowMutation,
			refetchQueries:["DATAFLOW_DETAILS","ALL_JOBLIST_QUERY"],
			variables:{dataflow_id:dataflow_id,pause_first_job}})
			.then(notify,notifyErr);
		return false;}}
	className={`${className} text-nowrap text-white`} aria-label={label}>
		<i className="zmdi zmdi-play"/>
		<span>&nbsp;{label}</span>
	</Button>;
}
