import React from 'react';
import gql from 'graphql-tag';
import {useAccountId} from '../account/AccountInfo';
import {useQuery} from '@apollo/client';
import {Error} from "../Error";
import {useNavigate,useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FraktureTable from '../FraktureTable';
import SimpleCollapse from '../SimpleCollapse';
import Tooltip from '@mui/material/Tooltip';
import {humanize} from "../formatters";
//import RefreshIcon from '@mui/icons-material/Refresh';
//import {QuickJob} from '../../job';


const CHILD_ACCOUNT_MONITOR=gql`
query CHILD_ACCOUNT_MONITOR($account_id:ID!) {
	account(_id:$account_id) {
		_id
		disabled
		children{
			_id
			name
			disabled
			default_warehouse_bot {
				_id
				global_table_prefix
			}
		}
		warehouse_monitor_child_stats {
			_id
			stat_id
			account_id
			label
			description
			group
			status
			error
			value
		}
	}
}`;

const MONITOR_DETAIL=gql`
	query ACCOUNT_MONITOR($account_id:ID!) {
	account(_id:$account_id) {
		_id
		warehouse_monitor_stats {
			_id
			stat_id
			account_id
			label
			description
			group
			status
			error
			value

			last_complete
			last_error
			last_pass
			last_fail

			query
			sampleQuery
			samples

		}
	}
}`;

function MonitorDetail(props){
	const {stat_id,account_id}=props;
	const{data,error,loading}=useQuery(MONITOR_DETAIL,{variables: {account_id}});
	if (loading) return "Loading details ...";
	if (error) return <Error error={error}/>;
	const {account:{warehouse_monitor_stats:stats}}=data;
	if (!stats){
		return "No additional stats";
	}
	let s=stats.find(x=>x.stat_id===stat_id);
	let err=null;
	if (s.error){
		err=<div className="alert-danger">{s.error.message || s.error.code || s.error || "Unknown error"}</div>;
	}
	return <div>
		{err}
		<SimpleCollapse title="All data for this stat">
			<pre>{JSON.stringify(s,null,4)}</pre>
		</SimpleCollapse>
	</div>;
}

function MonitorModal() {
	const location=useLocation();
	const {hash}=useLocation();
	const navigate=useNavigate();
	const {stat_id,account_id}=queryString.parse(hash.slice(1));
	function closeModal() {
		const urlParts = [
			location.pathname,
			location.search
		];
		navigate(urlParts.join(''));
	};

	return (
		<Dialog
			open={!!stat_id}
			onClose={closeModal}
		>
			<DialogTitle id="monitor-title">
				<Box display="flex" alignItems="center">
					<Box flexGrow={1}>Monitor Details</Box>
					<Box>
						<IconButton onClick={closeModal} size="large">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<Box className="p-3">{stat_id?<MonitorDetail stat_id={stat_id} account_id={account_id} onClose={()=>closeModal()}/>:""}</Box>
		</Dialog>
	);
}

function useNavigateToMonitor() {
	const location=useLocation();
	const navigate = useNavigate();
	return ({stat_id,account_id}) => {
		const urlParts = [
			location.pathname,
			location.search,
			'#account_id=',
			account_id,
			'&stat_id=',
			stat_id
		];
		navigate(urlParts.join(''));
	};
};


export default function Monitor(props){
	const account_id=useAccountId(props);
	const navToMonitor=useNavigateToMonitor();
	const {hash}=useLocation();
	const{data,error,loading}=useQuery(CHILD_ACCOUNT_MONITOR,{variables: {account_id}});
	if (loading) return "Loading ...";
	if (error) return <Error error={error}/>;
	const {account:{warehouse_monitor_child_stats:stats,children:childAccounts}}=data;

	/*
	let groups=stats.reduce((a,b)=>{
		a[b.group||"other"]=a[b.group||"other"]||{};
		a[b.group||"other"][b.stat_id]=b;
		return a;
	},{});
	*/


	let accounts=childAccounts.reduce((a,b)=>{
		if (b.disabled) return a;
		a[b._id]=b;
		return a;
	},{});
	let {account_ids=""}=queryString.parse(hash.slice(1));
	if (account_ids) account_ids=account_ids.split(",").map(d=>d.trim());

	if (account_ids.length>0){
		accounts=account_ids.map(d=>accounts[d]).filter(Boolean);
	}else{
		accounts=Object.values(accounts).sort((a,b)=>a.name<b.name?-1:1);
	}

	let rows={};
	stats.forEach(s=>{
		rows[s.stat_id]=rows[s.stat_id]||{
			stat_id:s.stat_id,
			label:s.label,
			group:s.group,
			description:s.description,
			accounts:{}
		};
		rows[s.stat_id].accounts[s.account_id]=s;
	});
	rows=Object.values(rows);

	let columns=[
		{
			title:'Stat',
			field:'label',
			render2:({row})=><div><Tooltip title={row.description}>{row.label}</Tooltip></div>
		},
		{
			title:'Group',
			field:'group',
		}
	];

	columns=columns.concat(accounts.map(a=>{
		return {
			title:a.name,
			field:a._id,
			render:({row})=>{
				let stat=row.accounts[a._id];
				if (!stat) return "-";
				if (stat.error) return <span className="text-danger">!</span>;
				if (stat.status==='pass'){
					return <span className="text-success">{humanize(stat.value)}</span>;
				}else if (stat.status==='fail'){
					return <span className="text-warning">{humanize(stat.value)}</span>;
				}else{
					return <span>{humanize(stat.value)}</span>;
				}
			},
			onClick:(row)=>{
				navToMonitor({account_id:a._id,stat_id:row.stat_id});
			}
		};
	}));

	/*
	let rows=accounts.map(a=>{
		let o={account_id:a.account_id||"None"};
		Object.values(groups).forEach((group)=>{
			Object.entries(group).forEach(([stat_id,stat])=>{
				o[stat_id]=stat.value;
			});
		});
		return o;
	});

	let columns=[{
		title:"Account",
		field:"account_id",
		render:({row})=><div>
			<QuickJob label={<RefreshIcon/>} {...{account_id:row.account_id,bot_id:row.warehouse_bot_id,method:"runAndSaveMonitor"}}/>
			<a target="_blank" href={`/app/${row.account_id}`}>{row.account_id}</a>
		</div>
	}];

	Object.values(groups).forEach((group)=>{
		Object.entries(group).forEach(([stat_id,stat])=>{
			columns.push({
				title:stat.label,
				field:stat_id,
				render:({row})=>{
					let s=row.stats[stat_id];
					if (!s) return "?";
					if (s.error) return <span className="text-danger">E</span>;
					return s.value || "-";
				},
				onClick:(row)=>{
					navToMonitor({account_id:row.account_id,stat_id});
				}
			});
		});
	});
	*/
	const sortFunction=(a,b,sortField,desc)=>{
		let x=a.stats[sortField];
		let y=b.stats[sortField];
		if (!x) return 1;
		if (!y) return -1;
		// eslint-disable-next-line eqeqeq
		let x1=parseFloat(x.value)==x.value?parseFloat(x.value):x.value;
		// eslint-disable-next-line eqeqeq
		let y1=parseFloat(y.value)==y.value?parseFloat(y.value):y.value;
		return x1<y1?desc:-desc;
	};

	return <>
		<MonitorModal/>
		<div className="">
			<FraktureTable stickyHeader={true} stickyColumn={true} {...{rows,columns,sortFunction,includePagination:false,includeColumnPicker:false,includeGroupBy:true,stickyHeader:true}}/>
		</div>
	</>;
}
