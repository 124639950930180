import React from 'react';
import { Route, Routes} from 'react-router-dom';
import Builder from './builder.js';
import Dictionary from './Dictionary.js';
import SourceCodeDateRange from './SourceCodeDateRange.js';
import Transactions from './Transactions.js';
import Formats from './Formats.js';
import Labels from './Labels';
import Search from './search';

export default function(){
	return <Routes>
		<Route path='*'element={<Dictionary/>}/>
		<Route path='builder'element={<Builder/>}/>
		<Route path='transactions'element={<Transactions/>}/>
		<Route path='source_code_date_range'element={<SourceCodeDateRange/>}/>
		<Route path='formats/:source_code_id'element={<Formats/>}/>
		<Route path='formats'element={<Formats/>}/>
		<Route path='search'element={<Search/>}/>
		<Route path='labels'element={<Labels/>}/>
		<Route path='dictionary'element={<Dictionary/>}/>
	</Routes>;
}
