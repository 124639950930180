import React from 'react';
import gql from "graphql-tag";

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ReactMarkdown from 'react-markdown';
import TableDocumentationDialog from './TableDocumentation';
import WarehouseObjectTypeIcon from './WarehouseObjectTypeIcon';

import FraktureQuery from '../FraktureQuery';
import SearchBox from '../SearchBox';

const botInfoQuery = gql`
query botInfo($bot_id:ID!) {
	bot(_id:$bot_id) {
		_id
		account_id
		label
	}
}
`;

const tablesFragment=`
	name
	description
	frakture_table_type
	object_type
	owner_bot {
		_id
		definition {
			_id
			metadata {
				logo
			}
			object_types {
				object_type
				fields {
					field
					description
	      }
	    }
		}
	}
	fields {
		name
		description
		sql_type
	}
`;

const documentationTableQuery=gql(`
query documentation($warehouse_bot_id:ID!, $cursor: Cursor) {
	warehouse(bot_id: $warehouse_bot_id) {
		_id
		page: documented_tables(cursor: $cursor) {
			page {
				${tablesFragment}
			}
			cursor
		}
	}
}`);

const warehouseTableSearchQuery=gql(`
query warehouseTableSearch($warehouse_bot_id:ID!, $search_text: String!) {
	warehouse(bot_id:$warehouse_bot_id) {
		_id
		documented_tables: table_search(text:$search_text) {
			${tablesFragment}
		}
	}
}
`);

class WarehouseTableDocumentation extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			expanded: false
		};
	}

	renderFieldTable() {
		const{fields, object_type, owner_bot}=this.props.table;
		if(!this.state.expanded) {
			return <button onClick={() => {this.setState({expanded: true});}}>
				<div>
					<span style={{top:'2px',paddingLeft:'3px'}}><i className="zmdi zmdi-caret-right zmdi-hc-2x" /></span>
					<span style={{paddingLeft: '10px', top: '-5px', position: 'relative'}}>
						There are {fields.length} columns in this table. (Click to expand)
					</span>
				</div>
			</button>;
		}

		let refType;
		if(object_type) refType = owner_bot.definition.object_types.find(x => x.object_type === object_type);
		// console.log(object_type, owner_bot.definition.object_types);
		return <React.Fragment>
			<button onClick={() => {this.setState({expanded: false});}}>
				<div>
					<span style={{top:'2px'}}><i className="zmdi zmdi-caret-down zmdi-hc-2x" /></span>
					<span style={{paddingLeft: '7px', top: '-5px', position: 'relative'}}>
						There are {fields.length} columns in this table. (Click to collapse)
					</span>
				</div>
			</button>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Field</TableCell>
						<TableCell>Type</TableCell>
						<TableCell>Description</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{fields.map(x => {
					let description = x.description;
					if(!description && refType) {
						// console.log('looking for: ',x.name,'in',refType.fields);
						const typeField = refType.fields.find(f => f.field === x.name);
						// console.log('found:',typeField);
						if(typeField) description = typeField.description;
					}
					return <TableRow key={x.name}>
						<TableCell style={{paddingBottom:'0px'}}>{x.name}</TableCell>
						<TableCell style={{paddingBottom:'0px'}}>{x.sql_type}</TableCell>
						<TableCell style={{width:'50%',paddingTop:'15px',paddingBottom:'0px'}}><ReactMarkdown source={description} /></TableCell>
					</TableRow>;
				})}</TableBody>
			</Table>
		</React.Fragment>;
	}

	render() {
		const{table,warehouse_bot_id}=this.props;
		const{name,description, owner_bot}=table;
		let action;
		if(owner_bot) {
			// eslint-disable-next-line no-unused-vars
			const{definition={}, definition:{metadata={}, metadata:{logo}}}=owner_bot;
			if(logo) action=<img alt="logo" src={logo} width={100} height={50}/>;
		}
		let documentation=<TableDocumentationDialog table={name} warehouse_bot_id={warehouse_bot_id}/>;
		return <Card key={name} elevation={1} square style={{marginTop:'10px'}}>
			<CardHeader title={<React.Fragment>
				<WarehouseObjectTypeIcon table={table} size='tiny' /> {name} {documentation}
			</React.Fragment>} action={action}/>
			<CardContent>
				<ReactMarkdown source={description} />
				{this.renderFieldTable()}
			</CardContent>
		</Card>;
	}

};

export default class WarehouseDocumentation extends React.Component {

	constructor(props) {
		super(props);
		this.state={
			search_text:""
		};
	}

	render() {
		const {account_id, warehouse_bot_id}=this.props.match.params;
		return <FraktureQuery query={botInfoQuery} variables={{bot_id:warehouse_bot_id}}>
			{data => {
				const{label}=data.bot;
				const {search_text}=this.state;

				let query = documentationTableQuery;
				if(search_text) query=warehouseTableSearchQuery;

				return <React.Fragment>
					<Card square>
						<CardHeader title={label+ ' Documentation'}/>
						<CardContent>
							<SearchBox placeholder='Search Table Documentation' value={search_text} onChange={e => this.setState({search_text:e.target.value})} />
						</CardContent>
					</Card>
					<FraktureQuery query={query} variables={{warehouse_bot_id,search_text}} pageOnScroll={!search_text} pagePath={['warehouse','page']}>
						{data => {
							let tables;
							if(!search_text) tables = data;
							else tables = data.warehouse.documented_tables;

							const tableDocs = tables.map(table => <WarehouseTableDocumentation key={table.name} table={table} account_id={account_id} warehouse_bot_id={warehouse_bot_id} />);
							return tableDocs;
						}}
					</FraktureQuery>
				</React.Fragment>;
			}}
		</FraktureQuery>;
	}
};
