import React,{useState} from 'react';
import Grid from '@mui/material/Grid';
import { useApolloClient } from '@apollo/client';
//import SelectObject from "./SelectObject";
import gql from "graphql-tag";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import FraktureQuery from "../FraktureQuery.js";
import FraktureTable from '../FraktureTable';

//import useNotifiers from '../Notifiers';

import { useSnackbar } from 'notistack';

const sourceCodeQuery=gql`query elements($account_id:String!){
	account(_id: $account_id) {
		source_code_element_sets{
      elements{
				source_code_element_set_id
        name
        label
        values{
        	label
          value
        }
      }
    }
		source_code_config{
			formats{
				_id
				format
				label
				description
			}
			bundles{
				_id
				label
				description
				definition
			}
		}
	}
}`;

/*
const SOURCE_CODE_ELEMENT_UPSERT=gql(`mutation upsertfield($account_id:ID!,$data:JSON!) {
		source_code_element_upsert(
			account_id:$account_id,data:$data){
				_id,label,description
		}
}`);
*/


const SOURCE_CODE_VALUE_ADD=gql(`mutation upsertValue($_id:ID!,$element:String!,$label:String,$value:String!){
		source_code_element_set_add_value(
			_id:$_id,element:$element,label:$label,value:$value
			){
				_id
				elements{
					source_code_element_set_id
					name
					label
					values{
						label
						value
					}
				}
		}
}`);



export default function(props) {
	const {enqueueSnackbar}=useSnackbar();
	const {match:{params:{account_id}}}=props;
	const client=useApolloClient();
	if (!account_id) return "account_id is required";
	const [elementName, setElementName] = useState(null);

	return( <div id="flex-grid-wrapper">
		<FraktureQuery query={sourceCodeQuery} variables={{account_id}}>
			{data=>{
				let elements=[];
				(data.account.source_code_element_sets||[]).forEach(set=>{
					elements=elements.concat(set.elements);
				});
				let element=null;
				if (elementName){
					element=elements.find(el=>el.name===elementName);
					if (!element) return "Could not find element "+elementName;
				}

				return <Grid container spacing={1} className="h-100 flex-grid-container">
					<Grid item xs={12} md={3} className="flex-grid-item">
						<Card>
							<CardHeader title="Source Code Elements"/>
							<List>
								{elements.map((s,i)=>{
									return <ListItem selected={element && element.name === s.name} onClick={e=>{setElementName(s.name);}} key={i}>
										<ListItemText primary={s.label} secondary={s.description} />
										<Divider/>
									</ListItem>;
								})}
							</List>
						</Card>
					</Grid>
					<Grid item xs={12} md={9} className="flex-grid-item">
						<Card>
							{(!elementName)?"Select an element":""}
							{elementName &&
							(<FraktureTable
								title={element.label+' values'}
								data={element.values || []}
								columns={[
									{ field: 'value', title: 'Value' },
									{ field: 'label', title: 'Label' },
								]}
								onRowAdd={(v)=>{
									const data=Object.assign({},v,{element:element.name,_id:element.source_code_element_set_id});
									return client.mutate({mutation:SOURCE_CODE_VALUE_ADD,
										refetchQueries:["elements"],
										variables:data,
									})
										.then(result=>enqueueSnackbar("Saved field",{ variant: 'success'}),
											error=>{console.error(error);enqueueSnackbar("Error adding field",{ variant: 'error'});},
										);
								}}

							/>)}
						</Card>
					</Grid>
				</Grid>;
			}}
		</FraktureQuery>;
	</div>
	);
};

export {SOURCE_CODE_VALUE_ADD};
