import React,{useState,useEffect} from 'react';
import {useParams,useNavigate} from 'react-router-dom';

import {acceptInvite,logout,useCheckUserExists} from './auth';
import {useLogin} from './login';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Invite() {
	const {invite_token} = useParams();

	const [{authenticated,token},loginElement]=useLogin({allowCreateNewAccount:true});
	const [result,setResult] = useState();
	const navigate = useNavigate();

	const userExists = useCheckUserExists();

	useEffect(() => {
		if(!token) return;

		(async () => {
			const r = await acceptInvite(invite_token);
			setResult(r);
			if(r.success) {
				const {account_id}=r;
				if(account_id) navigate(`/app/${account_id}`);
				else navigate('/app');
			}
		})();
	}, [authenticated,token,history,invite_token]);

	if(loginElement) return loginElement;

	if(!result){
		return <main style={{
			width: 400,
			display: 'block', // Fix IE11 issue.
			marginLeft: 'auto',
			marginRight: 'auto'
		}}>
			<Paper style={{
				marginTop: 80,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: `20px 30px 30px`,
			}}>
				<Typography variant="h5">Processing Invite</Typography>
			</Paper>
		</main>;
	}else if(result.error) return <main style={{
		width: 400,
		display: 'block', // Fix IE11 issue.
		marginLeft: 'auto',
		marginRight: 'auto'
	}}>
		<Paper style={{
			marginTop: 80,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `20px 30px 30px`,
		}}>
			<Typography variant="h5">Error Processing Invite</Typography>
			<div style={{marginTop:'10px'}}>{result.error}</div>
			{userExists && <a href='/app'>Go to Console</a>}
			<div style={{marginTop:'10px'}}>
				If you recently verified your email, you may need to logout and log back in.
			</div>
			<Button onClick={logout}>Logout</Button>
		</Paper>
	</main>;

	return 'Error processing';
};
