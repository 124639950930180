import gql from 'graphql-tag';


export const dataflowJob = gql`fragment dataflowJob on DataflowJob {
	_id
	context_id
	label
	bot {
		_id
		label
		path
		definition{
			_id
			metadata {
				thumbnail
				logo
			}
		}
	}
	method
	submodule
	options
}
`;

export const dataflowBotOverrides = gql`fragment dataflowBotOverrides on Dataflow {
	config {
		bot_overrides {
			_id
			bot_id
			bot_path
			method
			default_id
			default_bot {
				label
			}
			override_path
			override_id
			override_bot {
				label
			}
			override_options {
				bot_id
				bot_path
				override_id
				override_path
				override {label}
			}
		}
	}
}`;

export const dataflowChildren = gql`fragment dataflowChildren on Dataflow {
	children {
		_id
		label
		schedule
		account {
			_id
			name
			disabled
		}
	}
}`;

export const dataflowMergeJobs = gql`
fragment dataflowMergeJobs on Dataflow {
	merged_jobs {
		context_id
		merged_job {
			... dataflowJob
		}
		error
	}
}
${dataflowJob}
`;

export const fullDataflow=gql`fragment fullDataflow on Dataflow {
	_id
	label
	description
	disabled
	schedule
	jobs {
		... dataflowJob
	}

	... dataflowBotOverrides

	most_recent_job_list {
		_id
	}

	job_lists {
		_id
		label
		status
		account_id
		last_update {
      		ts
    	}
		jobs {
			_id
			status
		}
	}
	... dataflowMergeJobs
	parent{
		_id
		label
		account{
			_id
			name
		}
	}
}
${dataflowBotOverrides}
${dataflowMergeJobs}
`;

export default {
	dataflowChildren,
	dataflowBotOverrides,
	dataflowJob,
	dataflowMergeJobs,
	fullDataflow
};
