import { ApolloLink } from "@apollo/client";
import {onError} from "@apollo/client/link/error";

import {visit,TypeInfo} from 'graphql';
import {print} from 'graphql/language/printer';

export default function() {
	function ensureFieldsInRequest({schema,fields}) {
		return new ApolloLink((operation, forward) => {
			const typeInfo = new TypeInfo(schema);
			if(operation.log) operation.log('converting',print(operation.query));
			function visitFn(node, key, parent, path, ancestors) {
				if (path){}
				if(node.kind === 'SelectionSet') {
					const type = typeInfo.getType();
					if(operation.log) operation.log('type',type, 'node',node);
					function ensureField(fieldName) {
						if(operation.log) operation.log('fieldName',fieldName);

						let _fields;
						if(type && type._fields) _fields = type._fields;
						else if(type && type.ofType) _fields = type.ofType._fields;

						if(_fields && _fields[fieldName]) {
							if(operation.log) operation.log('checking',fieldName);
							const existing = node.selections.find(x => x.kind === 'Field' && x.name.kind === 'Name' && x.name.value===fieldName);
							if(!existing) {
								if(operation.log) operation.log('adding',fieldName);
								node.selections.push({
									kind: 'Field',
									name: {
										kind: 'Name',
										value: fieldName
									}
								});
							}
						}
					}
					fields.forEach(ensureField);
				};
			};
			operation.query=visit(operation.query, visit(typeInfo, {enter: visitFn}));
			if(operation.log) operation.log('converted to',print(operation.query));
			return forward(operation);
		});
	};

	function validateRequest({schema}) {
		return new ApolloLink((operation, forward) => {
			const typeInfo = new TypeInfo(schema);
			function visitFn(node, key, parent, path, ancestors) {
				if (path){};
				let type = typeInfo.getType();
				if(node.kind === 'SelectionSet' && type) {
					let _fields = type._fields;
					if(!_fields) {
						if(type.ofType && type.ofType._fields) {
							type = type.ofType;
							_fields = type._fields;
						}
					}

					if(_fields) {
						const invalid = node.selections.filter(x => {
							return x.kind === 'Field' && !(x.directives||[]).length && !Object.keys(_fields)
								.concat(['__typename','__schema']).find(y => y === x.name.value);
						});
						if(invalid.length) {
							throw new Error(`Error in request - ${invalid.map(x => `'${x.name.value}'`)} is/are invalid selection fields for ${type.name}`);
						}
					}
				}
			};
			visit(operation.query, visit(typeInfo, {enter: visitFn}));
			return forward(operation);
		});
	};

	function logError() {
		return onError(({graphQLErrors, networkError})=>{
			console.error('Error:',{graphQLErrors, networkError});
		});
	}

	return {
		ensureFieldsInRequest,
		logError,
		validateRequest
	};
};
