import React from 'react';
import {useAccountId} from '../account/AccountInfo';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import dayjs from "dayjs";


const SMS_INBOUND = gql(`
query SMS_INBOUND($account_id: ID!) {
	account(_id: $account_id) {
		_id
		sms_inbound
	}
}
`);

export function SMSInbound(){
	const account_id=useAccountId();
	const {data,loading,error}=useQuery(SMS_INBOUND,{variables:{account_id}});
	if (error) return JSON.stringify(error);
	if (loading){
		return "Loading...";
	}
	return <div className="gallery p-3">
		<h2>Recent SMS messages</h2>
		<div className="gallery-row-wrapper">
			{data.account.sms_inbound?.map((_d,i)=>{
				let d=_d;
				if (typeof d=='string')d=JSON.parse(d);
				return <Card className="p-1" key={i}>
					<CardContent><b>{dayjs(new Date(d.ts)).format("MMM DD, HH:mm a")}</b>
						{d.body.Body}</CardContent>
				</Card>;
			})}
		</div>
	</div>;
};
