/* eslint-disable no-undef */
import React from 'react';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import HeroCard from '../components/HeroCard';
import FraktureBarChart from '../report/display/components/FraktureBarChart';
import FraktureScorecard from '../report/display/components/FraktureScorecard';
import {AllAccounting} from './settings/Accounting';
import FraktureTable from '../FraktureTable';
import {useBots} from '../bots/BotList';
import Usage from './settings/Usage';
import ConstellationHome from '../constellation';
import {Header} from '../components/Header';


import { JsonViewer } from '@textea/json-viewer';

import {useAccountDetails} from './AccountInfo';

const WAREHOUSE_SUMMARY = gql(`query WAREHOUSE_SUMMARY($warehouse_bot_id: ID!) {
	warehouse(bot_id:$warehouse_bot_id) {
		bot_id
    warehouse_bot_id
		bot_token
    graphql_uri
		summary
	}
}`);

function BotStatusCard(){
	const {bots,loading,error}=useBots();
	if (loading) return loading;
	if (error) return "Error loading bots";
	/*
	8:{
"__typename":"Bot"
"_id":"ngp_hw6"
"label":"NgpVanBot"
"path":"channelbots.NgpVanBot"
"account_id":"bluestate_fairfight"
"parent":{
"__typename":"Bot"
"_id":"ngp_hxm"
"account_id":"bluestate"
}
"children":[]
"status":{
"__typename":"BotStatus"
"status":"active"
"last_updated":Fri, Mar 24, 2023, 04:33 PM
}
"definition":{
"__typename":"BotDefinition"
"_id":"channelbots.NgpVanBot"
"metadata":{
"__typename":"BotMetadata"
"picture":"/images/bots/ngp-van-bot.jpg"
"logo":"/images/logos/ngpvan.png"
"thumbnail":"/images/bots/ngp-van-bot-icon.png"
"categories":[
0:"ecrm"
]
}
}*/
	let columns=[
		{render:({row})=>row.image?<img src={row.image} width="60"/>:"",title:""},
		{field:"status",title:"Status"},
		{field:"label",title:"Name"},
		{field:"categories",title:"Category"},
		{field:"last_updated",title:"Last Status Update"}
	];
	let rows=bots.map(d=>({
		label:d.label,
		status:d?.status?.status||"N/A",
		last_updated:d?.status?.status_last_updated || "",
		image:d?.definition?.metadata?.logo || "",
		categories:(d.categories||[]).join(",")
	}));
	return <FraktureTable
		key="table"
		rows={rows}
		columns={columns}
		includePagination={false}
		includeColumnPicker={false}
		sort={false}
	/>;
	return <JsonViewer value={bots}/>;
}

function MessageCard({data}={}){
	/*"bot_id":string"actionnetwork_inr"
	"bot_path":string"channelbots.ActionNetworkBot"
	"label":string"UWDA Donate 230206 SOTU"
	"date":string"2023-02-06"
	"impressions":int70908
	"clicks":int247
	"attributed_revenue":int0
	*/
	const {bots,loading,error}=useBots();
	if (loading || error) return loading || error;

	let columns=[
		{render:({row})=>row.image?<img src={row.image} width="60"/>:"",title:""},
		{field:"label",title:"Label"},
		{field:"date",title:"Date"},
		{field:"impressions",title:"Impressions"},
		{field:"clicks",title:"Clicks"},
		{field:"attributed_revenue",title:"Revenue"},
	];
	const dataWithImage=data.map(row=>Object.assign(row,{
		image:(bots.find(d=>row.bot_id===d._id)||{}).definition?.metadata?.logo || ""
	}));
	columns=Object.values(columns);
	return <FraktureTable
		key="table"
		rows={dataWithImage}
		columns={columns}
		includePagination={false}
		includeColumnPicker={false}
		sort={false}
	/>;
}


function SourceCodeCard({data}={}){
	//return JSON.stringify(data);
	let columns=data.reduce((a,b)=>{
		Object.entries(b).forEach(([k,v])=>{
			if (["min_date","max_date"].indexOf(k)>=0) return;
			if (typeof v=='string' || parseFloat(v)>0) a[k]={
				field:k,
				title:k
			};
		});
		return a;
	},{source_code:{
		title:"Source Code",
		field:"source_code"
	}});
	columns=Object.values(columns);
	return <FraktureTable
		key="table"
		rows={data}
		columns={columns}
		includePagination={false}
		includeColumnPicker={false}
		sort={false}
	/>;

}

function Summarize(props){
	let {warehouse_bot_id}=props;
	const {data,loading,error}=useQuery(WAREHOUSE_SUMMARY,{
		variables: {warehouse_bot_id,text:'transaction'}
	});
	if(loading) return 'Loading summary ...';
	if(error) {
		console.error(error);
		return '';
	}
	let {warehouse:{summary}}=data;
	let chartStyle={height:"250px"};

	if (typeof summary=='string') summary=JSON.parse(summary);
	return <Grid container spacing={4} className="account-home p-2">
		{summary?.transactions && <Grid item md={6}>
			<HeroCard title="Transactions" category="transactions">
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<FraktureScorecard label="Last 30 days" metric={{name:"revenue",format:"long_currency"}} data={summary.transactions}/>
					</Grid>
					<Grid item xs={6}>
						<FraktureScorecard label="Transactions" metric={{name:"transactions",format:"long"}} data={summary.transactions}/>
					</Grid>
					<Grid item xs={12}>
						<div style={chartStyle}><FraktureBarChart data={summary.recent_transactions} is_date={true}
							dimension_alias="date" metrics={[{alias:"transactions",type:"bar"},{alias:"revenue",format:"currency"}]}/></div>
					</Grid>
				</Grid>
			</HeroCard>
		</Grid>}
		{summary?.recent_messages_by_date?.length ? <Grid item md={6}><HeroCard title="Recent Messages" category="messages">
			<MessageCard data={summary?.recent_messages_by_date}/>
		</HeroCard></Grid>:null}
		{summary?.source_code_stats_by_date?.length ?
			<Grid item md={6}><HeroCard title="Recent Source Codes" category="origin">
				<SourceCodeCard data={summary?.source_code_stats_by_date}/>
			</HeroCard>
			</Grid>:""}
		<Grid item md={6}><HeroCard title="Bot Status">
			<BotStatusCard/>
		</HeroCard>
		</Grid>
	</Grid>;
}


export default function(){
	const {account,loading,error}=useAccountDetails();
	if (loading){
		return "Loading...";
	}
	if (error) return "Pick an account";
	if (!account || !account.name){return "Pick an account";}
	let {_id:account_id,default_warehouse_bot,type}=account;
	let default_warehouse_bot_id=default_warehouse_bot?._id;
	if (account_id==="system"){
		return <><Header primary="Frakture System Ops"/>
			<Paper><h1>Usage and Accounting</h1><AllAccounting/></Paper>
		</>;
	}else if (account_id==="frakture_master"){
		return <Header primary="Frakture Master Parent"/>;
	}else if (type==='agency' || type==="constellation"){
		return <ConstellationHome/>;
	}else{
		return <>
			<Header
				primary={[
					<h2 key="title">Dashboard</h2>
				]}
			/>
			<Summarize account_id={account_id} warehouse_bot_id={default_warehouse_bot_id}/>
			<div className="card">
				<div className="card-header">Account Usage</div>
				<div className="card-body"><Usage {...{account_id}} include_table={false} /></div></div>
		</>;
	}
};
