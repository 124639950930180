import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {withRouter} from '../../util/history.js';
import BotHome from '../bots/index.js';
import JobPanel from '../job/index.js';
import AccountSettings from './settings';
import MessageHome from '../messages';

import SegmentBrowser from '../segments';
import Attribution from '../attribution/index.js';
import Warehouse from '../warehouse/index.js';
import Timeline from '../timeline/index.js';
import SourceCode from '../sourcecode/index.js';
import RecordView from '../warehouse/RecordView';
import UserGroup from '../usergroup/UserGroup';
import WarehouseDocumentation from '../warehouse/WarehouseDocumentation';
import ReportMain from '../report';
import EntityReferenceDiagram from '../warehouse/EntityReferenceDiagram';
import ReportSettings from '../report/settings';
import AccountFileList from '../files/AccountFileList';
import DeployedPackage from '../packages/DeployedPackage';
import DeployedList from '../packages/DeployedList';

import FraktureWizard from '../wizard/index';
import Constellation from '../constellation/index';
import Dashboard from './Dashboard.js';

function Account() {
	return <div className="app-main-content-wrapper account-wrapper">
		<Routes key="content">
			<Route path='*' element={<Dashboard/>} />
			<Route path='constellation' element={<Constellation/>} />
			<Route path='constellation/*' element={<Constellation/>} />
			<Route path='setup/*' element={<FraktureWizard/>}/>
			<Route path='dataflow/:dataflow_id' element={<JobPanel/>}/>
			<Route path='dataflow' element={<JobPanel/>}/>
			<Route path='job/:job_list_id/:job_id' element={<JobPanel/>}/>
			<Route path='job/:job_list_id' element={<JobPanel/>}/>
			<Route path='job' element={<JobPanel/>}/>
			<Route path='settings' element={<AccountSettings/>}/>
			<Route path='user_group/:user_group_id' element={<UserGroup/>}/>
			<Route path='attribution/:warehouse_bot_id' element={<Attribution/>}/>
			<Route path='attribution' element={<Attribution/>}/>
			<Route path='sourcecode' element={<SourceCode/>}/>
			<Route path='sourcecode/*' element={<SourceCode/>}/>
			<Route path='timeline' element={<Timeline/>}/>
			<Route path='timeline/*' element={<Timeline/>}/>
			<Route path='warehouse/:warehouse_bot_id/:table/row' element={<RecordView/>}/>
			<Route path='warehouse/:warehouse_bot_id/:table' element={<Warehouse/>}/>
			<Route path='warehouse/:warehouse_bot_id' element={<Warehouse/>}/>
			<Route path='warehouse' element={<Warehouse/>}/>
			<Route path='warehouse_docs/:warehouse_bot_id' element={<WarehouseDocumentation/>}/>
			<Route path='warehouse_erd/:warehouse_bot_id' element={<EntityReferenceDiagram/>}/>
			<Route path='report/:report_folder_short/:report_id' element={<ReportMain/>}/>
			<Route path='report/:report_id/settings' element={<ReportSettings/>}/>
			<Route path='report/:report_folder_short' element={<ReportMain/>}/>
			<Route path='report' element={<ReportMain/>}/>
			<Route path='messages' element={<MessageHome/>}/>
			<Route path='messages/*' element={<MessageHome/>}/>
			<Route path='segments/*' element={<SegmentBrowser/>}/>
			<Route path='segments' element={<SegmentBrowser/>}/>
			<Route path='bot/:bot_id' element={<BotHome/>}/>
			<Route path='bot' element={<BotHome/>}/>
			<Route path='file' element={<AccountFileList/>}/>
			<Route path='package/:deployed_package_id' element={<DeployedPackage/>}/>
			<Route path='package' element={<DeployedList/>}/>
		</Routes>
	</div>;
};

export default withRouter(Account);
