import React,{useState,useEffect} from 'react';

import SaveOnBlur from './SaveOnBlur';
import SmartSqlEditor from './SmartSqlEditor';

import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

import FileUploadDialogButton from '../files/FileUpload';

import WarehouseTableSearch from '../warehouse/WarehouseTableSearch';

export function TableEditor(props) {
	const{value,onChange,bot_id}=props;
	const [input,setInput]=useState('select');
	useEffect(() => {
		if(!bot_id) setInput('text');
	},[bot_id]);

	let editor;
	if(false && input==='select') editor = <WarehouseTableSearch {...{bot_id,value,onChange}} />; //disabled as not that useful
	else editor = <SaveOnBlur {...props} />;

	return <Grid container spacing={2}>
		<Grid item>
			<IconButton size='small' onClick={() => setInput(input === 'select' ? 'text' : 'select')}><EditIcon/></IconButton>
		</Grid>
		<Grid item xs>
			{editor}
		</Grid>
	</Grid>;
}

export function ListEditor(props) {
	const{value,onChange}=props;
	const [input,setInput]=useState('text');
	// const [list,setList]=useState([]);
	//
	// useEffect(() => {
	// 	setList(value.split(','));
	// }, [value||'']);
	let list;
	if(!value) list = [];
	if(typeof value == 'string') list = value.split(',');
	if(Array.isArray(value)) list = value;
	else list = [(value||'').toString()];

	let editor;
	if(input === 'text') editor = <SaveOnBlur {...props} />;
	else editor = <Grid container spacing={1}>
		{list.map((item,i) => {
			return <React.Fragment key={i}>
				<Grid item xs={12}>
					<SaveOnBlur value={item} onChange={(n) => {
						const values = list.map((x,j) => {
							if(j!==i) return x;
							return n;
						}).filter(x => !!x);
						onChange(values.join(','));
					}}/>
				</Grid>
			</React.Fragment>;
		})}
		<Grid item xs={12} key={list.length}>
			<SaveOnBlur value='' onChange={(n) => {
				const values = list.concat([n]);
				onChange(values.join(','));
			}}/>
		</Grid>
	</Grid>;

	return <Grid container spacing={2}>
		<Grid item>
			<Tooltip title='Edit as list'>
				<IconButton size='small' onClick={() => setInput(input === 'list' ? 'text' : 'list')}>
					<ListIcon/>
				</IconButton>
			</Tooltip>
		</Grid>
		<Grid item xs>
			{editor}
		</Grid>
	</Grid>;
}

export function FilenameEditor(props) {
	const{onChange, bot_id}=props;
	const button = <FileUploadDialogButton {...{bot_id}} onUpload={(upload) => {
		console.log('upload:',upload);
		if(upload.filename) onChange(upload.filename);
	}} />;

	return <Grid container spacing={2}>
		<Grid item>{button}</Grid>
		<Grid item xs><SaveOnBlur {...props} /></Grid>
	</Grid>;
}

export function MapEditor(props) {
	const {onChange}=props;
	const [input,setInput]=useState('map');
	const [value,setValue]=useState({});

	useEffect(() => {
		if(input === 'text') return;

		if(!props.value) {
			setInput('map');
			setValue({});
			return;
		}

		if(typeof props.value == 'object') {
			setInput('map');
			setValue(props.value);
			return;
		}

		if(typeof props.value == 'string') {
			try {
				const v = JSON.parse(props.value);
				setValue(v);
				setInput('map');
			} catch(e) {
				console.error(e);
				setValue(props.value);
				setInput('text');
			}
			return;
		}
	}, [props.value,input]);

	const [newKey,setNewKey]=useState('');
	const [newValue,setNewValue]=useState('');


	if(input === 'text') return <SaveOnBlur {...props} />;

	const addButton = <IconButton
		onClick={() => {
			onChange(Object.assign(value,{[newKey]:newValue||''}));
			setNewKey('');
			setNewValue('');
		}}
		size="large"><AddIcon/></IconButton>;
	const newEntryRow = <TableRow>
		<TableCell>
			<SaveOnBlur value={newKey} onChange={setNewKey} placeholder='Enter Key' />
		</TableCell>
		<TableCell>
			<SaveOnBlur value={newValue} onChange={setNewValue} placeholder='Enter Value' />
		</TableCell>
		<TableCell padding='none'>{addButton}</TableCell>
	</TableRow>;

	const entries = Object.entries(value).map(([k,v]) => {
		return (
			<TableRow key={k}>
				<TableCell>{k}</TableCell>
				<TableCell>
					<SaveOnBlur value={v} onChange={nv => onChange(Object.assign({},value,{[k]:nv||''}))} />
				</TableCell>
				<TableCell padding='none'>
					<IconButton
						onClick={() => {
							const x = Object.assign({},value);
							delete x[k];
							onChange(x);
						}}
						size="large">
						<RemoveIcon/>
					</IconButton>
				</TableCell>
			</TableRow>
		);
	});

	return <React.Fragment>
		<Table size="small" >
			<TableBody>
				{entries}
				{newEntryRow}
			</TableBody>
		</Table>
	</React.Fragment>;
}

export default function(props) {
	const {value,onChange, type,bot_id,defaultValue}=props;
	if(type === 'sql' || type === 'textarea') return <SmartSqlEditor value={value}
		onChange={onChange} bot_id={bot_id} defaultValue={defaultValue}
		lineNumbers />;
	if(type === 'fql') return <SmartSqlEditor value={value}
		onChange={onChange} bot_id={bot_id} size='small' defaultValue={defaultValue}
		lineNumbers />;
	if(type === 'table') return <TableEditor {...props} />;
	if(type === 'list') return <ListEditor {...props} />;
	if(type === 'filename') return <FilenameEditor {...props} />;
	if(type === 'map') return <MapEditor {...props} />;
	return <SaveOnBlur placeholder={defaultValue} value={value} onChange={onChange} />;
};
