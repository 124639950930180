import React from 'react';
import {useLocation,useNavigate,useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import {Header} from '../components/Header';
import {ChannelAvatar} from './index.js';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { JsonViewer } from '@textea/json-viewer';
import FraktureTable from '../FraktureTable';
import {Error} from "../Error";
import gql from "graphql-tag";
import queryString from 'query-string';
import {useWarehouseQuery} from '../warehouse/WarehouseQuery';
import {casify,formatValue} from '../formatters';

export function useNavigateToMessage() {
	//const account_id = useAccountId();
	let location=useLocation();
	const navigate = useNavigate();
	return ({message_id}) => {
		location.hash="message_id="+message_id;
		navigate(location);
	};
};

function MessageStat({name,value,format}){
	let label=casify(name.split("-").pop());
	if (!format){
		if (name.indexOf('revenue')>=0) format="currency";
	}
	let display=formatValue(value,format);
	return <div className="message-statistic">
		<span className="label" >{label}</span>
		<span className="value">{display}</span>
	</div>;
}

function MessageStatistics({global_table_prefix,message_id}){
	const { loading, error, data }=useWarehouseQuery(
		{
			table:global_table_prefix+"global_message_stats",
			conditions:[{fql:"message_id="+message_id}],
			limit:100
		});
	if (loading) return loading;
	if (error) {return error;}
	if (!data || data.length===0) return "No statistics";


	return <Card>
		<CardContent>
			{Object.entries(data[0])
				.filter(d=>d[0].indexOf("_id")<0)
				.filter(d=>d[0].indexOf("modified")<0)
				.filter(d=>d[0].indexOf("created")<0)
				.map(([name,value],i)=><MessageStat key={i} name={name} value={value}/>)}
		</CardContent>
	</Card>;
}

function WarehouseSourceCodes({global_table_prefix,message_id}){
	const { loading, error, data }=useWarehouseQuery(
		{
			table:global_table_prefix+"global_message_summary",
			fields:[{field:"primary_source_code"},{field:"primary_source_code_override"},{field:"final_primary_source_code"}],
			conditions:[{fql:"message_id="+message_id}],
			limit:100
		});
	if (loading) return loading;
	if (error) {return error;}
	if (!data || data.length===0) return "No statistics";
	return <Card>
		<CardContent>
			<div>Primary Source Code: <b>{data[0].primary_source_code}</b></div>
			<div>Override: <b>{data[0].primary_source_code_override}</b></div>
			<div>Final Source Code: <b>{data[0].final_primary_source_code}</b></div>
		</CardContent>
	</Card>;
}



function WarehouseMessageTable({title,message_id,query,table}){
	const { loading, error, data }=useWarehouseQuery(
		{table,
			conditions:[{fql:"message_id="+message_id}],
			limit:100
		});
	if (loading) return loading;
	if (error) {return error;}
	let columns=null;
	if (data && data.length){
		let keys=Object.keys(data[0]);
		columns=keys.filter(d=>d!=='id').map(d=>({
			title:d,
			field:d
		}));
	}

	return <Card><CardHeader title={title}/>
		<CardContent>
			{loading && "Loading...."}
			{data && data.length>0 && <FraktureTable
				rows={data}
				columns={columns}
				includePagination={false}
				includeColumnPicker={false}
			/>}
			{data && !data.length && "No records"}
			{error && <Error error={error}/>}
		</CardContent>
	</Card>;
}


const MESSAGE_QUERY=gql`query messageQuery($account_id: ID!,$message_id:ID!) {
	account(_id: $account_id) {
		_id
		default_warehouse_bot {
			_id
			global_table_prefix
		}
		message(_id:$message_id){
			_id
			remote_id
			bot_id
			submodule
			label
			channel
			publish_date
			content
			links
			remote_data
		}
	}
}`;


function TabPanel(props) {
	const { children, value, index, className, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			className={`p-100 h-100${(className?` ${className}`:``)}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

/*
import {MessageModal} from '../messages/Message';
<MessageModal/>
*/
//Popup modal
export function MessageModal() {
	const location=useLocation();
	const params=useParams();
	const {account_id}=params;
	const {hash}=useLocation();
	const navigate=useNavigate();
	const {message_id}=queryString.parse(hash.slice(1));
	function closeModal() {
		const urlParts = [
			location.pathname,
			location.search
		];
		navigate(urlParts.join(''));
	};

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"xl"}
			open={!!message_id}
			onClose={closeModal}
		>
			<DialogTitle id="message-title">
				<Box display="flex" alignItems="center">
					<Box flexGrow={1}>Message Details</Box>
					<Box>
						<IconButton onClick={closeModal} size="large">
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			{message_id && account_id?<Message message_id={message_id} account_id={account_id} onClose={()=>closeModal()}/>:""}
		</Dialog>
	);
}

const IFrame = (props) => {
	const writeHTML = (frame) => {
		if (!frame) {
			return;
		}
		let doc = frame.contentDocument;
		doc.open();
		doc.write(props.content);
		doc.close();
		if (frame.style){
			let height=frame?.contentWindow?.document?.body?.scrollHeight||300;
			frame.style.width = '100%';
			frame.style.height =`${height}px`;
		}
		if (frame?.contentWindow?.document?.body?.style){
			frame.contentWindow.document.body.style.zoom = 0.8;
		}
	};

	return (
		<iframe
			//referrerPolicy="unsafe-url" --this doesn't actually fix anything about unsafe links
			className="message-html" src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML}/>
	);
};
function m(s){return s.split(",").map(d=>({field:d}));}
export default function Message(props){
	const {message_id,account_id}=props;
	const {data,error,loading}=useQuery(MESSAGE_QUERY, {
		variables: {message_id,account_id},
		errorPolicy:"none"
	});
	const [tab, setTab] = React.useState(0);
	const handleChange = (event, newTab) => {setTab(newTab);};
	if(loading) return "Loading ....";
	if(error) throw error;

	let message=(data.account ||{}).message;
	if (!message) return "Could not find message";

	try{message.remote_data=JSON.parse(message.remote_data);}catch(e){}
	if (typeof message.content=='string'){
		try{
			message.content=JSON.parse(message.content);
		}catch(e){
		}
	}

	let content=message.content|| {};
	let {label,links=[],channel}=message;
	let {global_table_prefix}=data.account.default_warehouse_bot;
	let tabStart=1;
	if (!content.html) tabStart=0;

	let sourceCodeTable=
		{
			query:{
				conditions:[{fql:`message_id=${parseInt(message_id)}`}],
				fields:m("source_code,type")
			},
			table:global_table_prefix+"message_source_code"
		};

	return <div className={`message`}>
		<Header primary={[
			<h2 key="label" className="message-label">{label}</h2>,
			<ChannelAvatar key="avatar" channel={channel}/>
		]}
		/>

		{content.subject?<div className="message-subject"><b>Subject:</b> {content.subject}</div>:<div className="message-subject"><b>Label:</b> {content.label}</div>}
		{(content.from_name||content.from_email)?<div className="message-from"><b>From:</b> {content.from_name}{content.from_email?("<"+content.from_email+">"):""}</div>:""}
		<Tabs value={tab} onChange={handleChange} aria-label="Message content">
			{content.html?<Tab label="HTML" />:""}
			<Tab label="Text" />
			<Tab label="Statistics" />
			<Tab label="Links" />
			<Tab label="Source Codes" />
			<Tab label="Full Content" />
		</Tabs>
		{content.html?<TabPanel value={tab} index={0} className="visual">
			<IFrame content={content.html}/>
		</TabPanel>:""}
		<TabPanel value={tab} index={0+tabStart}>
			<div className="message-text">{content.text?content.text:"No text content"}</div>
		</TabPanel>
		<TabPanel value={tab} index={1+tabStart}>
			<MessageStatistics global_table_prefix={global_table_prefix} message_id={message_id}/>
		</TabPanel>
		<TabPanel value={tab} index={2+tabStart}>
			<TableContainer>
				<Table sx={{ minWidth: 650 }} aria-label="link table">
					<TableHead>
						<TableRow>
							<TableCell>Link</TableCell>
							<TableCell>Content</TableCell>
							<TableCell>Is Redirect</TableCell>
							<TableCell>Original URL</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(links || []).map((_l,i)=>{
							let l=_l;
							if (typeof l=='string'){
								l=JSON.parse(l);
							}
							/*
							{label, link, not_navigable, is_redirect, original_link, status_code}
							*/
							return <TableRow key={i}>
								<TableCell><a href={l.link} target="_blank">{l.link}</a></TableCell>
								<TableCell>{l.label}</TableCell>
								<TableCell>{l.is_redirect?"Yes":""}</TableCell>
								<TableCell>{l.original_link}</TableCell>
							</TableRow>;
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</TabPanel>
		<TabPanel value={tab} index={3+tabStart}>
			<WarehouseSourceCodes global_table_prefix={global_table_prefix} message_id={message_id}/>
			<h3>All Source Codes</h3>
			<WarehouseMessageTable {...sourceCodeTable} account_id={account_id} message_id={message_id}/>
		</TabPanel>
		<TabPanel value={tab} index={4+tabStart}>
			<JsonViewer enableClipboard={false} displayDataTypes={false} value={message}/>
		</TabPanel>

	</div>;
}
