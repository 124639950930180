import React,{createRef} from 'react';
//import React,{createRef,useEffect,useState} from 'react';

/*
import 'codemirror/lib/codemirror.js';
import 'codemirror/mode/sql/sql.js';
import 'codemirror/addon/hint/sql-hint.js';
import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/hint/show-hint.css';

import 'codemirror/lib/codemirror.css';

import './sql-editor.css';

import CodeMirror from 'codemirror';
*/

export default function SqlEditor(props) {
	const textArrayRef = createRef();
	const {defaultValue}=props;
	//const readOnly=props.readOnly;
	/*
	// console.log('SqlEditor props.value:',props.value);

	// const codeMirrorRef = createRef();
	const [codeMirror,setCodeMirror]=useState();

	let hintOptions;
	if(props.sqlHintFn) hintOptions = {
		hint: props.sqlHintFn
	};

	useEffect(() => {
		const cm = CodeMirror.fromTextArea(textArrayRef.current, {
			mode: 'text/x-sql',
			smartIndent: true,
			matchBrackets : true,
			tabSize: 2,
			value: props.value||defaultValue||'',
			lineNumbers: props.lineNumbers,
			extraKeys: {"Ctrl-Space": "autocomplete"},
			hintOptions,
			readOnly: readOnly ? 'nocursor' : false
		});

		if(props.setFocus) cm.focus();

		let lastValue = props.value;
		cm.on('blur', (instance, event) => {
			lastValue = instance.doc.getValue();
			if(lastValue === '' && defaultValue) {
				// console.log('overriding new value:',lastValue);
				instance.doc.setValue(defaultValue);
			}
			if(props.onChange && lastValue !== props.value) {
				props.onChange(lastValue);
			}
		});
		cm.on('focus', (instance, event) => {
			instance.doc.setValue(lastValue||'');

			if(!props.onFocus) return;
			props.onFocus();
		});

		setCodeMirror(cm);

		return () => cm.toTextArea();
	}, []); // MUST RUN ONLY ONCE

	useEffect(() => {
		if(props.acceptUpdates && props.value && codeMirror) codeMirror.setValue(props.value);
	}, [props.value,codeMirror,props.acceptUpdates]);
*/
	let className='medium-editor';
	if(props.size === 'small') className='small-editor';
	let style={};
	let inherited="";
	if (defaultValue && !props.value){
		inherited=<i>Inherited from parent:</i>;
		style={color:"#777"};
	}

	return <div className={`${className} sql-editor`} >
		{inherited}
		<textarea ref={textArrayRef}
			style={style}
			rows={props.rows || 12}
			defaultValue={props.value||defaultValue||''}
			onBlur={(e)=>props.onChange(e.target.value)}/>
	</div>;
};
