import {graphql} from 'graphql';
import { delegateToSchema } from '@graphql-tools/delegate';
// const{}=require('graphql/language');
const botCache=require("memory-cache");

export default function(whSchema,dataLayerSchema){
	function traverseObject(obj, fn) {
		const newObj = fn(obj);
		if(newObj && (Array.isArray(newObj) || typeof newObj === 'object')) {
			Object.keys(newObj).forEach(x => newObj[x] = traverseObject(newObj[x],fn));
		}
		return newObj;
	}
	/*
	const getWarehouseTransforms = (bot_id) => [{
		transformResult(result) {
			if (result.errors && result.errors.length>0){
				result.errors=result.errors.map(d=>typeof d=='string'?{message:d}:d);
				return result;
			}
			try {
				const newResult = traverseObject(result, (obj) => {
					if(!obj) return obj;
					if (typeof obj!='object') return obj;
					obj.bot_id = bot_id;
					return obj;
				});
				return newResult;
			} catch(e) {
				console.error('Error transforming warehouse result: ',e);
				throw e;
			}
		}
	}];
	*/

	function getWarehouseBotResolver({fieldName}) {
		return (root, args, context, info) => {
			let{bot_id,warehouse_bot_id,bot_token,graphql_uri}=Object.assign({},root,info.variableValues);
			let id=warehouse_bot_id || bot_id;
			if(!id && !bot_token){
				console.error("Passed in variables:",root);
				throw new Error('no warehouse_bot_id,bot_id and/or bot_token in '+Object.keys(root).join()+', why?!');
			}
			context=Object.assign({},context,{
				bot_id:id,
				bot_token,
				graphql_uri
			});
			let x= delegateToSchema({
				schema: whSchema,
				operation: 'query',
				fieldName,
				args,
				context,
				info,
				//transforms: getWarehouseTransforms(bot_id)
			});
			return x;
		};
	};

	function getNonBotWarehouseResolver({fieldName, operation='query'}) {
		return async function(root,args,context,info) {
			const{bot_id}=args;
			if(!bot_id) throw new Error('bot_id _must_ be an arg');
			let botInfo=botCache.get(bot_id);
			if (!botInfo){
				botInfo = await graphql({
					schema: dataLayerSchema,
					source: `query warehouseInfo($bot_id: ID!) {
						warehouse(bot_id:$bot_id) {
							graphql_uri
							bot_token
						}
					}`,
					variableValues: {bot_id}
				});
				botCache.put(bot_id,botInfo,3*60*1000);
			}
			const{bot_token,graphql_uri}=botInfo.data.warehouse;
			context=Object.assign({},context,{
				bot_id:bot_id,
				bot_token,
				graphql_uri
			});
			return delegateToSchema({
				schema: whSchema,
				operation,
				fieldName: fieldName,
				args,
				context,
				info,
				//transforms: getWarehouseTransforms(bot_id)
			});
		};
	}

	const whMutationFields = whSchema._mutationType._fields;
	const delegateMutations = [];
	Object.keys(whMutationFields).filter(x=>x!=='ping').forEach(x => {
		const mutation = whMutationFields[x];
		const args = mutation.args.map(a=>({name: a.name, type: a.type.toString()})).concat({name:'bot_id',type:'ID!'});
		delegateMutations.push({
			name: x,
			definition: `  ${x}(${args.map(a=>a.name+':'+a.type).join(',')}): ${mutation.type}`
		});
	});

	const whQueryFields = whSchema._queryType._fields;
	const delegateWhQueries = [];
	Object.keys(whQueryFields).forEach(x => {
		const query = whQueryFields[x];
		const args = query.args.map(a=>({name: a.name, type: a.type.toString()}));
		let argDefinition = '';
		if(args.length) argDefinition = `(${args.map(a=>a.name+':'+a.type).join(',')})`;
		delegateWhQueries.push({
			name: x,
			definition: `  ${x}${argDefinition}: ${query.type}`
		});
	});

	const typeDefs=`
extend type BotDefinition {
	documentation: String
}

extend type Warehouse {
	${delegateWhQueries.map(x => x.definition).join('\n')}
}

extend type WarehouseTable {
	owner_bot: Bot
}

extend type BotAuthValue {
	oauth_uri: String
}

extend type Bot {
	set_oauth_uri: String
	summary:JSON
	auth_ok:JSON
}

extend type Mutation {
	${delegateMutations.map(x=>x.definition).join('\n')}
}`;

	const DelegateMutationResolver = {}, DelegateWarehouseResolver = {};
	delegateMutations.forEach(({name:fieldName}) => DelegateMutationResolver[fieldName] =
		getNonBotWarehouseResolver({fieldName, operation:'mutation'}));
	delegateWhQueries.forEach(({name:fieldName}) => DelegateWarehouseResolver[fieldName] = getWarehouseBotResolver({fieldName}));

	// support pulling information for the owner bot for a warehouse table
	const WarehouseTable = {
		owner_bot: {
			fragment: `... on WarehouseTable { owner_bot_id }`,
			resolve: async (table,args,context,info) => {
				console.log("Resolving ",table);
				const{owner_bot_id}=table;
				if(!owner_bot_id) return null;
				return delegateToSchema({
					schema: dataLayerSchema,
					operation: 'query',
					fieldName: 'bot',
					args: {_id: owner_bot_id},
					context,
					info
				});
			}
		}
	};

	const BotDefinition = {
		documentation: {
			fragment: `... on BotDefinition { metadata { doc_uri } }`,
			resolve: async (definition) => {
				const {metadata}=definition;
				if(!metadata || !metadata.doc_uri) return '';
				try {
					// eslint-disable-next-line no-undef
					const response = await fetch(metadata.doc_uri);
					return response.body();
				} catch(e) {
					console.error(e);
					return null;
				}
			}
		}
	};

	return {
		typeDefs,
		resolvers: {
			Mutation: DelegateMutationResolver,
			Warehouse: DelegateWarehouseResolver,
			BotDefinition,
			WarehouseTable,
			Bot: {
				summary: async({api_endpoint,token}) => {
					if(!api_endpoint) return null;
					if(!token) {
						console.error('No token');
						return {};
					}
					const summary_url=api_endpoint+'/summary';
					console.log('Warehouse delegate fetching:',summary_url);
					// eslint-disable-next-line no-undef
					const response = await fetch(summary_url, {
						method: 'post',
						// mode: 'no-cors',
						headers: {
							'x-bot-auth-token':token
						}
					});
					let o=await response.json();
					try{if (typeof o==='string') JSON.parse(o);}catch(e){return {parsing_error:e,response:o};}
					return o;
				}
				,
				set_oauth_uri: async(data) => {
					const{api_endpoint}=data;
					if(!api_endpoint){
						console.error("No api_endpoint specified when requesting set_oauth_uri, returning null");
						return null;
					}
					return api_endpoint+'/getAuth';
				}
				,
				auth_ok:  async({api_endpoint,token}) => {
					if(!api_endpoint) return null;
					if(!token) {
						console.error('No token');
					}
					const authed_url=api_endpoint+'/auth_ok';

					// eslint-disable-next-line no-undef
					const response = await fetch(authed_url, {
						method: 'post',
						// mode: 'no-cors',
						headers: {
							'x-bot-auth-token':token
						}
					});
					let o=await response.json();
					if (response.status!==200){
						console.error("Error retrieving auth url",response);
						o.error=o.error || "Error authenticating";
						return o;
					}
					return o;
				}
			},
			BotAuthValue: {
				oauth_uri: async(input) => {
					if (!input.bot){
						throw new Error("Make sure to include { field { type } bot { api_endpoint token } } on BotAuthValue requests");
					}
					let {field:{type}, bot:{api_endpoint,token}}=input;
					if(type !== 'link') return null;
					if(!api_endpoint) return null;
					if(!token) {
						console.error('No token');
					}
					const get_auth_url=api_endpoint+'/getAuthURL';
					console.log('Warehouse delegate fetching:',get_auth_url);

					// eslint-disable-next-line no-undef
					const response = await fetch(get_auth_url, {
						method: 'post',
						// mode: 'no-cors',
						headers: {
							'x-bot-auth-token':token
						}
					});
					if (response.status!==200){
						console.error("Error retrieving auth url");
						return "";
					}
					let o=await response.json();
					if (o.url) o=o.url;
					return o;
				}
			}
		}
	};
};
