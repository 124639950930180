import React from 'react';
import Grid from '@mui/material/Grid';
import ReportCard from '../ReportCard';
import {getEvenGrid} from './Util.js';

export default function ReportTemplate(props){
	let {components}=props;
	let {top,a_title,a_subheader,a1,a2,a3,a4,a5,a6,a7,a8,
		b_title,b_subheader,b1,b2,b3,b4,b5,b6,
		c_title,c_subheader,c1,c2,c3,c4,c5,c6,
		d_title,d_subheader,d1,d2,d3,d4,d5,d6,
		e_title,e_subheader,e1,e2,e3,e4,e5,e6,
		f_title,f_subheader,f1,f2,f3,f4,f5,f6,
		g_title,g_subheader,g1,g2,g3,g4,g5,g6,
		h_title,h_subheader,h1,h2,h3,h4,h5,h6,
		i_title,i_subheader,i1,i2,i3,i4,i5,i6,
		j_title,j_subheader,j1,j2,j3,j4,j5,j6,
		k_title,k_subheader,k1,k2,k3,k4,k5,k6,
		l_title,l_subheader,l1,l2,l3,l4,l5,l6
	}=components;
	let a=[a1,a2,a3,a4,a5,a6,a7,a8].filter(Boolean),
		b=[b1,b2,b3,b4,b5,b6],
		c=[c1,c2,c3,c4,c5,c6],
		d=[d1,d2,d3,d4,d5,d6],
		e=[e1,e2,e3,e4,e5,e6],
		f=[f1,f2,f3,f4,f5,f6],
		g=[g1,g2,g3,g4,g5,g6],
		h=[h1,h2,h3,h4,h5,h6],
		i=[i1,i2,i3,i4,i5,i6],
		j=[j1,j2,j3,j4,j5,j6],
		k=[k1,k2,k3,k4,k5,k6],
		l=[l1,l2,l3,l4,l5,l6];

	return <div className="report-body" id="report-template-1">
		{top}
		{a.filter(Boolean).length?<ReportCard title={a_title} subheader={a_subheader} className={a.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid(a)}</Grid>}/>:""}
		{b.filter(Boolean).length?<ReportCard title={b_title} subheader={b_subheader} className={b.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid(b)}</Grid>}/>:""}
		{c.filter(Boolean).length?<ReportCard title={c_title} subheader={c_subheader} className={c.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid(c)}</Grid>}/>:""}
		{d.filter(Boolean).length?<ReportCard title={d_title} subheader={d_subheader} className={d.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid(d)}</Grid>}/>:""}
		{e.filter(Boolean).length?<ReportCard title={e_title} subheader={e_subheader} className={e.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid(e)}</Grid>}/>:""}
		{f.filter(Boolean).length?<ReportCard title={f_title} subheader={f_subheader} className={f.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([f1,f2,f3,f4,f5,f6])}</Grid>}/>:""}
		{g.filter(Boolean).length?<ReportCard title={g_title} subheader={g_subheader} className={g.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([g1,g2,g3,g4,g5,g6])}</Grid>}/>:""}
		{h.filter(Boolean).length?<ReportCard title={h_title} subheader={h_subheader} className={h.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([h1,h2,h3,h4,h5,h6])}</Grid>}/>:""}
		{i.filter(Boolean).length?<ReportCard title={i_title} subheader={i_subheader} className={i.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([i1,i2,i3,i4,i5,i6])}</Grid>}/>:""}
		{j.filter(Boolean).length?<ReportCard title={j_title} subheader={j_subheader} className={j.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([j1,j2,j3,j4,j5,j6])}</Grid>}/>:""}
		{k.filter(Boolean).length?<ReportCard title={k_title} subheader={k_subheader} className={k.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([k1,k2,k3,k4,k5,k6])}</Grid>}/>:""}
		{l.filter(Boolean).length?<ReportCard title={l_title} subheader={l_subheader} className={l.map(x=>x?.props?.component?.component).join(" ")} contents={<Grid container>{getEvenGrid([l1,l2,l3,l4,l5,l6])}</Grid>}/>:""}
	</div>;
};
