import React from 'react';

import {useWarehouseQuery,useConditionsFromQueryString} from '../warehouse/WarehouseQuery';

import {} from 'react-router-dom';
import List from '@mui/material/List';
import {SidebarListItem} from '../components/SidebarList';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

import {humanize} from '../formatters';
import {useSetHash} from '../inputs/QueryStringInputs';

import {useBots} from '../bots/BotList';
import FraktureDataTable from '../warehouse/FraktureDataTable';

import useAccountWarehouseInfo from '../warehouse/account-warehouse-info';
import {useAccountId} from '../account/AccountInfo.js';

export function GlobalSegmentList(props){
	const {default_query}=props;
	const {bots}=useBots();
	const location = useLocation();
	const setHash=useSetHash();
	let hash=queryString.parse(location.hash);
	const {conditions,loading:loadingConditions,limit}=useConditionsFromQueryString({default_query});
	let order_by=[];
	const table='${global_table_prefix}global_segment';

	let fields=[
		{field:"segment_id",title:"Segment ID"},
		{field:"bot_id",alias:'segment_bot_id',title:"Bot ID"},
		{field:"remote_segment_id",title:"Remote ID"},
		{field:"label",title:"Label"},
		{field:"reported_people",title:"People (as reported by source)"},
		{field:"people",title:"People (loaded by Frakture)"},
		{field:"load_reported_stats",title:"Load Reported Stats?"},
		{field:"load_all_people",title:"Load All People?"}].map(d=>({field:d.field,alias:d.alias}));

	const { loading, error, data:segments }=useWarehouseQuery(
		{table,
			fields,
			conditions,
			order_by,
			limit:limit || 1000,
			skip:loadingConditions||!bots
		});
	if (loading){
		return "Loading...";
	}
	if (error) {
		console.error("Error loading segments:",error);
		return "There was an error loading segments";
	}
	return <List>
		{segments.length===0?<div className="p-2">No global segments</div>:""}
		{(segments||[]).map((m,i)=>{
			return <SidebarListItem key={i}
				selected={parseInt(hash.segment_id)===m.segment_id}
				primary={[humanize(m.label,45)]}
				secondary={[m.segment_bot_id,m.remote_segment_id]}
				footer={[(m.people||0)+" in Frakture",(m.reported_people||0)+" reported"]}
				onClick={()=>{
					setHash("segment_id",m.segment_id);
				}}
			/>;
		})}
	</List>;
}


function SegmentInfo(props){
	const {segment_id}=props;

	const table='${global_table_prefix}global_segment';

	let fields=[
		{field:"segment_id"},
		{field:"bot_id"},
		{field:"remote_segment_id"},
		{field:"label"},
		{field:"reported_people"},
		{field:"people"},
		{field:"load_reported_stats"},
		{field:"load_all_people"}
	];

	const { loading, error, data:segment }=useWarehouseQuery(
		{table,
			fields,
			conditions:[{fql:"segment_id="+segment_id}],
		});
	if (loading){
		return "Loading...";
	}
	if (error) {
		console.error("Error loading segments:",error);
		return "There was an error loading segments";
	}
	return JSON.stringify(segment);
}
function SegmentHistory(props){
	const {segment_id}=props;
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	let table=global_table_prefix+"global_segment_stats_by_month";

	let fields=[
		{field:"month",title:"Month",width:""},
		{fql:"people",alias:"people",title:"People in Frakture"},
		{fql:"reported_people",alias:"reported_people",title:"Reported People"},
	];

	let conditions=[{fql:`segment_id=${parseInt(segment_id)}`}];

	return <FraktureDataTable
		title={"Segment History"}
		table={table}
		conditions={conditions}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		order_by={[{table,
			field:"month",order_by_direction:"DESC"}]}
		limit={1000}
	/>;
}
export function GlobalSegmentDetails(){
	const location = useLocation();
	let hash=queryString.parse(location.hash);
	if (!hash.segment_id) return "Pick a segment";
	return <div className="app-main-content">
		<SegmentInfo segment_id={hash.segment_id}/>
		<SegmentHistory segment_id={hash.segment_id}/>
	</div>;
}

