import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import FraktureTable from '../FraktureTable';
import {useAccountDetails} from '../account/AccountInfo';
import {humanize,formatValue} from '../formatters';

import {useWarehouseQuery,useConditionsFromQueryString} from '../warehouse/WarehouseQuery';

function MessageSummary(props){
	const {global_table_prefix,account_id,default_query,bots}=props;
	const {conditions,loading:loadingConditions,limit}=useConditionsFromQueryString({date_field:"publish_date",default_query});

	let query={
		account_id,
		table:global_table_prefix+"global_message_summary",
		conditions,
		fields:[
			{fql:"month(publish_date)",alias:"Month"},
			{fql:"bot_id",alias:"bot_id"},
			{fql:"count(message_id)",alias:"Messages"},
			{fql:"sum(sent)",alias:"Sent"},
			{fql:"sum(impressions)",alias:"Impressions"},
			{fql:"sum(clicks)",alias:"Clicks"},
			{fql:"sum(attributed_revenue)",alias:"Attributed Revenue"}
		],
		group_by:[
			{fql:"month(publish_date)"},
			{fql:"bot_id"}
		],
		order_by:[
			{fql:"month(publish_date)",order_by_direction:"DESC"},
			{fql:"bot_id"}
		],
		skip:loadingConditions,
		limit
	};
	let formatMap={
		Month:v=>formatValue(v,"date"),
		Messages:v=>humanize(v),
		Sent:v=>humanize(v),
		Impressions:v=>humanize(v),
		Clicks:v=>humanize(v),
		"Attributed Revenue":v=>formatValue(v,'currency')
	};


	const o = useWarehouseQuery(query);
	const {loading,data,error}=o;
	if (loading) return "Loading...";
	if (error){
		console.error(error);
		return "Error loading search result";
	}
	if (!data){
		console.log("Warehouse query response=",o);
		return "Invalid response, no data available";
	}

	let columns=null;
	if (data && data.length){
		let keys=Object.keys(data[0]);
		columns=keys.map(d=>({
			title:d,
			field:d
		}));
		columns.forEach(x=>{
			if (x.field==='bot_id'){
				x.title="Bot";
				x.render=({row})=><a href={`/app/${account_id}/bot/${row.bot_id}`}>{bots.find(d=>d._id===row.bot_id)?.label||"?"}</a>;
			}else{
				x.format=formatMap[x.field];
			}
		});
	}

	return <>
		{data && data.length>0 && <FraktureTable
			rows={data}
			columns={columns}
			includePagination={false}
			includeColumnPicker={false}
		/>}
		{data && !data.length && "No records"}
	</>;
}


export default function({bots}){
	const {account,loading,error}=useAccountDetails();
	if (loading) return "Loading Message Details...";
	if (error) return "Error loading account";
	let global_table_prefix=account.default_warehouse_bot.global_table_prefix;
	return <Card><CardHeader title={"Message Summary"}/>
		<CardContent style={{maxHeight: "400px",overflow: "auto"}}>
			<MessageSummary {...{global_table_prefix,account_id:account._id,bots}}/>
		</CardContent>
	</Card>;
}