import React from 'react';
import Async from 'react-select/async';
import {useChangeURL} from '../inputs/QueryStringInputs';
import gql from "graphql-tag";

export const BOT_WITH_METHOD_SELECT_QUERY = gql(`
query BOT_WITH_METHOD_SELECT_QUERY($account_id: ID!, $method:String!) {
	account(_id: $account_id) {
		_id
		bots_with_method(method: $method) {
			_id
			path
			bot {
				label
				warehouse_table_prefix
			}
		}
	}
}
`);

/*
import {useState,useEffect} from 'react';
import StubQuery from "../StubQuery";
import { useQuery, } from '@apollo/client';


import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


export function BotWithMethodSelect(props) {
	const {account_id,method}=props;
	if(!account_id) throw new Error('account_id is required');

	const {data,error,loading}=useQuery(BOT_WITH_METHOD_SELECT_QUERY,{
		variables: {account_id,method}
	});

	const [opts,setOpts]=useState([]);
	useEffect(() => {
		if(loading||!data) return;
		const {account:{bots_with_method=[]}={}}=data;
		setOpts(bots_with_method);
	},[loading,data]);

	if(error) return JSON.stringify(error);

	return <Select {...props} onChange={e => {
		const val = e.target.value;
		const m = opts.find(x => x._id === val);

		props.onChange(m);
	}}>
		{opts.map(o => {
			return <MenuItem key={o._id} value={o._id}>
				{o.label} ({o.path})
			</MenuItem>;
		})}
	</Select>;
};

const warehouseSelectQuery = gql(`
query warehouseQuery($account_id: ID!) {
	account(_id: $account_id) {
		_id
		warehouses {
			_id
			warehouse_bot {
				_id
				label
			}
		}
	}
}
`);
export function WarehouseSelect(props) {
	const {value,onChange,account_id, selectDefault=false}=props;
	const {data,loading,error}=useQuery(warehouseSelectQuery,{
		variables: {account_id},
		onCompleted: (data) => {
			if(selectDefault && data.account.warehouses.length) onChange(data.account.warehouses[0]._id);
		}
	});
	if(!account_id) return "account_id is required";
	if(loading) return 'Loading bot picker ...';
	if(error) {
		console.error(error);
		return 'There was an error loading warehouses';
	}
	const {account={}}=data;
	const {warehouses=[]}=account;
	const bots = warehouses.map(x => x.warehouse_bot);

	return <Select fullWidth value={value} onChange={e => onChange(e)}>
		{bots.map(({_id,label}) => {
			return <MenuItem key={_id} value={_id}>{label}</MenuItem>;
		})}
	</Select>;
};

const botsInWarehouseSelectQuery = gql(`
query channelBotsInWarehouseQuery($warehouse_bot_id: ID!) {
	warehouse(bot_id:$warehouse_bot_id) {
		_id
		channel_bots {
			_id
			label
			path
			warehouse_table_prefix
		}
	}
}
`);

export class ChannelBotSelect extends React.Component {
	render() {
		const {value,onChange,warehouse_bot_id}=this.props;
		if(!warehouse_bot_id) return "You must select a Warehouse";
		return <StubQuery query={botsInWarehouseSelectQuery} variables={{warehouse_bot_id}}>
			{({data,loading,error}) => {
				if(loading) return 'Loading bot selector ...';
				if(error) {
					console.error(error);
					return 'There was an error loading bots within this warehouse';
				}
				const {warehouse={}}=data;
				const {channel_bots=[]}=warehouse;
				console.log({warehouse_bot_id,data,warehouse,channel_bots,value});
				return <Select value={value} onChange={e => {
					const bot_id = e.target.value;
					const bot = channel_bots.find(x=>x._id === bot_id);
					onChange(e, bot);
				}}>
					{channel_bots.map(({_id,label}) => {
						return 	<MenuItem key={_id} value={_id}>{label}</MenuItem>;
					})}
				</Select>;
			}}
		</StubQuery>;
	}
};
*/

export function BotSelect(props){
	const {query,setURLParameters}=useChangeURL();
	let {bots}=props;
	let searchVal=(query.bot_ids||"").split(",");
	let options=Object.values(bots.reduce((a,b)=>{
		let bot_id=b._id.split(".")[0];
		a[bot_id]={value:bot_id,label:b?.bot?.label || b.label || "(No label)"};
		return a;
	},{}));
	let blankOption={
		value:"(blank)",
		label:"All Bots"
	};

	if (!options.find(v=>v.value==='')){
		options.unshift(blankOption);
	}
	let value=options.filter(o=>{
		return searchVal.find(s=>o.value===s);
	});
	/*
	value=(value[0] || );
	*/
	if (value.length===0){
		value=blankOption;
	}


	let currentVal=null;

	return <div style={{width:"300px"}}><Async
		variant="standard"
		defaultValue={value}
		menuPortalTarget={document.body}
		styles={{ menuPortal: base => ({ ...base,
			zIndex: 9999,
		}) }}
		onChange={v=>{
			currentVal=v;
			let val=[].concat(currentVal).filter(Boolean);
			setURLParameters({bot_ids:val.map(f=>f.value==='(blank)'?'':f.value).join(",")});
		}}
		onBlur={(e)=>{
			if (currentVal===null) return;
			let val=[].concat(currentVal).filter(Boolean);
			setURLParameters({bot_ids:val.map(f=>f.value==='(blank)'?'':f.value).join(",")});
		}}
		defaultOptions={options}
		isMulti={false}
		closeMenuOnSelect={true}
	/>
	</div>;
};
