import React from 'react';
import { useQuery } from "@apollo/client";
//import {useParams} from 'react-router-dom';
import gql from "graphql-tag";
import FraktureTable from '../../FraktureTable';
import FraktureBarChart from '../../report/display/components/FraktureBarChart';

import {humanize,formatValue} from '../../formatters';
const USAGE_QUERY = gql(`
query USAGE_QUERY($account_ids: [ID],$start:String,$end:String) {
	usage(account_ids: $account_ids,start:$start,end:$end) {
		account_id
		date
		values
	}
	invoices(account_ids: $account_ids){
		_id
		account_id
		date
		amount
		balance
		content
	}
}`);

export default function({account_id,include_table=false}){
	const {data,loading,error}=useQuery(USAGE_QUERY,{variables:{account_ids:[account_id],start:"-90d"}});
	if(loading) return "";
	if(error) throw error;
	let columns=[
		{field:"date",title:"Date",format:f=>formatValue(f,"date")},
		{field:"realtime_hits",title:"Realtime Hits"},
		{field:"realtime_dataflows",title:"Realtime Dataflows"},
		{field:"jobs",title:"Jobs"},
		{field:"job_dataflows",title:"Dataflows"},
		{field:"job_bots",title:"Dataflows"},
		{field:"job_records",title:"Records"},
		{field:"job_http_counter",title:"HTTP Hits"},
		{field:"job_sql_counter",title:"SQL Hits"},
		{render:({row})=>humanize(parseInt(row.job_runtime)/(1000*60)),title:"Runtime (Mins)"},
	];
	let summary={};
	let rows=data.usage.map(({date,values})=>{
		let v=values;
		if (typeof v=='string') v=JSON.parse(v);
		Object.entries(v).forEach(([k,val])=>{summary[k]=(summary[k]||0)+parseInt(val);});
		return Object.assign({},{date},v);
	}).sort((a,b)=>a.date<b.date?1:-1);

	return <>
		{summary.realtime_hits &&<div style={{height:"250px"}}><FraktureBarChart label="Realtime Activity" data={rows} is_date={true}
			dimension_alias="date" metrics={[{alias:"realtime_hits",type:"bar"},
			]}/></div>}
		{summary.jobs &&<div style={{height:"250px"}}><FraktureBarChart label="Jobs and Records" data={rows} is_date={true}
			dimension_alias="date" metrics={[{alias:"jobs",type:"bar",yaxis:"left"},{alias:"job_records",yaxis:"right"},
			]}/></div>}
		{include_table&&<FraktureTable
			key="table"
			rows={rows}
			columns={columns}
			includePagination={false}
			includeColumnPicker={false}
			sort={false}
		/>}</>;
};
