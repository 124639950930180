import React,{useState} from 'react';
import {fraktureQuery} from '../FraktureQuery';
import gql from "graphql-tag";
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import ValidatePermission from '../permissions/ValidatePermission';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
dayjs.extend(calendar);

const PERMISSION_FRAGMENT=`
permissions {
	_id
	permission
	definition {
		_id
    type
    identifier
		path {
	    type
	    identifier
		}
	}
}`;
const PERMISSION_QUERY = gql`query permissions($user_id:ID!) {
	user(_id: $user_id) {
		${PERMISSION_FRAGMENT}
		accounts {
			page {
				_id
				name
			}
		}
  }
}`;

const ADD_PERMISSION=gql`mutation add($permission:String!, $user_id:ID!) {
	permission_add(user_id:$user_id, permission:$permission) {
		permission {
			_id
			user_id
		}
		user {
			_id
			${PERMISSION_FRAGMENT}
		}
	}
}`;
const REMOVE_PERMISSION=gql`mutation remove($permission_id: ID!) {
	permission_remove(_id:$permission_id) {
		user {
			_id
			${PERMISSION_FRAGMENT}
		}
	}
}`;

function PermissionEditor(props) {
	const {user_id}=props;
	const [permission,setPermission]=useState('');
	const [addPermission,{loading,error}]=useMutation(ADD_PERMISSION, {
		onCompleted: (data) => {
			console.log('finished:',data);
		}
	});
	console.log(!permission || loading,{permission,loading});
	return <React.Fragment>
		<ValidatePermission {...{user_id, onValid:setPermission, permission}} />
		<div>{error}</div>
		<Button disabled={!permission || loading} onClick={() => {
			console.log('saving permission:',permission,'for:',user_id);
			addPermission({variables: {user_id,permission}});
		}}>Save</Button>
	</React.Fragment>;
};

function PermissionRow(props) {
	const{permission,_id,definition}=props.permission;
	const[removePermission,{loading}]=useMutation(REMOVE_PERMISSION);
	const removal=<Button onClick={() => {
		const confirm = window.confirm("Are you sure you want to remove this permission?");
		if(!confirm) return;
		removePermission({variables: {permission_id:_id}});
	}}>Remove</Button>;
	const {path}=definition||{};
	return <TableRow>
		<TableCell>
			<div>{removal} {loading ? 'Removing...' : permission}</div>
			<div>{definition && path.concat(definition).map(x => x.identifier).join(' -> ')}</div>
		</TableCell>
	</TableRow>;
};

export default function UserPermissionsList(props) {
	const{user_id}=props;
	return fraktureQuery({
		query: PERMISSION_QUERY,
		variables: {
			user_id
		}
	}, data => {
		return <React.Fragment>
			<PermissionEditor {...{user_id}} />
			<Table>
				<TableBody>
					{data.user.permissions.map(x => <PermissionRow key={x._id} permission={x}/>)}
				</TableBody>
			</Table>
		</React.Fragment>;
	});
};
