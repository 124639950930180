// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReportCard from '../ReportCard';
import FiscalYearSelect from './FiscalYearSelect';
import FraktureScorecard from './FraktureScorecard.js';
import FraktureVerticalBarChart from './FraktureVerticalBarChart.js';
import FraktureBarChart from './FraktureBarChart.js';
//import FraktureStackedBarChart from './FraktureStackedBarChart.js';
import FraktureReportTable from './FraktureReportTable';
import {TabContent,TabContainer} from '../../../FraktureTabs';
import {toFiscalYear} from '../../../../util/fiscal';

import DonorCategoryHelp from './DonorCategoryHelp.js';

const currencyFormat="long_currency";
const longFormat="long";

function CustomerTimeline(props){
	let {data:_data,now,year}=props;

	let data=_data.filter(d=>d.unit==='fiscal');

	function getSummary(date){
		let current=data.find(d=>d.date===date+"-01-01") ||{};
		let summary={
			customer_total:
								current.transaction_unique_person_1_0_0_0+
								current.transaction_unique_person_1_0_0_1+
								current.transaction_unique_person_1_0_1_0+
								current.transaction_unique_person_1_0_1_1+
								current.transaction_unique_person_1_1_0_0+
								current.transaction_unique_person_1_1_0_1+
								current.transaction_unique_person_1_1_1_0+
								current.transaction_unique_person_1_1_1_1,
			last_year_customers:
								current.transaction_unique_person_0_1_0_0+
								current.transaction_unique_person_0_1_0_1+
								current.transaction_unique_person_0_1_1_0+
								current.transaction_unique_person_0_1_1_1+
								current.transaction_unique_person_1_1_0_0+
								current.transaction_unique_person_1_1_0_1+
								current.transaction_unique_person_1_1_1_0+
								current.transaction_unique_person_1_1_1_1,

			customer_new:current.transaction_unique_person_1_0_0_0,
			customer_second_year: current.transaction_unique_person_1_1_0_0,
			customer_multiyear: current.transaction_unique_person_1_1_1_1+ current.transaction_unique_person_1_1_1_0 + current.transaction_unique_person_1_1_0_1,
			customer_recaptured:current.transaction_unique_person_1_0_1_1 + current.transaction_unique_person_1_0_0_1 + current.transaction_unique_person_1_0_1_0,

			revenue_new:current.transaction_revenue_1_0_0_0,
			revenue_second_year: current.transaction_revenue_1_1_0_0,
			revenue_multiyear: current.transaction_revenue_1_1_1_1+ current.transaction_revenue_1_1_1_0 + current.transaction_revenue_1_1_0_1,
			revenue_recaptured:current.transaction_revenue_1_0_1_1 + current.transaction_revenue_1_0_0_1 + current.transaction_revenue_1_0_1_0,

			customer_lapsed: current.transaction_unique_person_0_1_0_0+ current.transaction_unique_person_0_1_0_1 + current.transaction_unique_person_0_1_1_0+current.transaction_unique_person_0_1_1_1,
		};
		summary.net_customer=summary.customer_new+summary.customer_recaptured-summary.customer_lapsed;

		summary.customer_retention_rate=(summary.last_year_customers-summary.customer_lapsed)/summary.last_year_customers;
		return summary;
	}



	data.forEach(d=>Object.assign(d,{col0:d.date.slice(0,4)},getSummary(d.date.slice(0,4))));

	let revenueFields=[{
		"alias": "revenue_new",
		"label": "New",
		"stackId": "a",
		"format": "long_currency",
		"type": "bar",
	},
	{
		"alias": "revenue_second_year",
		"label": "Second Year",
		"stackId": "a",
		"format": "long_currency",
		"type": "bar",
	},
	{
		"alias": "revenue_multiyear",
		"label": "Multi Year",
		"stackId": "a",
		"format": "long_currency",
		"type": "bar",
	},
	{
		"alias": "revenue_recaptured",
		"label": "Recaptured",
		"stackId": "a",
		"format": "long_currency",
		"type": "bar"
	}];

	let uniqueCustomerFields=[
		{
			"alias": "customer_new",
			"label": "New",
			"stackId": "a",
			"format": "long",
			"type": "bar",
		},
		{
			"alias": "customer_second_year",
			"label": "Second Year",
			"stackId": "a",
			"format": "long",
			"type": "bar",
		},
		{
			"alias": "customer_multiyear",
			"label": "Multi Year",
			"stackId": "a",
			"format": "long",
			"type": "bar",
		},
		{
			"alias": "customer_recaptured",
			"label": "Recaptured",
			"stackId": "a",
			"format": "long",
			"type": "bar",
		}
	];


	let x=[getSummary(year)];
	let y=[getSummary(year-1)];
	let filteredData=data.filter(d=>(d.date>'2003-01-01' && d.date<=now+"-01-01"));
	let reverseSortData=filteredData.slice(0).sort((a,b)=>a.date<b.date?1:-1);
	//return <pre>{JSON.stringify(revenueFields,null,4)}</pre>;

	return <ReportCard title="Breakdown by Donor Type (All Donations)">
		<Grid container>
			<Grid item xs={2}><FraktureScorecard label="Total Donors" metric={{name:"customer_total",format:longFormat}} data={x} include_previous={false} previousData={y}/></Grid>
			<Grid item xs={2}><FraktureScorecard label="New Donors" metric={{name:"customer_new",format:longFormat}} data={x} include_previous={false} previousData={y}/></Grid>
			<Grid item xs={2}><FraktureScorecard label="Recaptured Donors" metric={{name:"customer_recaptured",format:longFormat}} data={x} include_previous={false} previousData={y}/></Grid>
			<Grid item xs={2}><FraktureScorecard label="Newly Lapsed" metric={{name:"customer_lapsed",format:longFormat}} data={x} include_previous={false} previousData={y}/></Grid>
			<Grid item xs={2}><FraktureScorecard label="Net Change in Donors" metric={{name:"net_customer",format:longFormat}} data={x} include_previous={false} previousData={y}/></Grid>
			<Grid item xs={2}><FraktureScorecard label="Retention Rate" metric={{name:"customer_retention_rate",format:"percent"}} data={x}/></Grid>
		</Grid>
		<Grid container spacing={3}>
			<Grid item xs={12}><FraktureBarChart label="Revenue by Donor Type" data={filteredData} dimension={{alias:"date"}} metrics={revenueFields}/></Grid>
			<Grid item xs={12}><FraktureBarChart label="Donor Counts by Donor Type" data={filteredData} dimension={{alias:"date"}} metrics={uniqueCustomerFields}/></Grid>
			<Grid item xs={12}>
				<FraktureReportTable label="Revenue by Donor Type" height={600} data={reverseSortData} dimensions={[{label:"Fiscal Year",alias:"date",format:"year"}]} metrics={revenueFields}/>
			</Grid>
			<Grid item xs={12}>
				<FraktureReportTable label="Donor Counts by Donor Type" height={600} data={reverseSortData} dimensions={[{label:"Fiscal Year",alias:"date",format:"year"}]} metrics={uniqueCustomerFields}/>
			</Grid>
		</Grid>
	</ReportCard>;
}

export default function Component(props){
	let {data,label="",report}=props;
	const yearByYear=data.reduce((a,b)=>{
		let fy=b.date.slice(0,4);
		a[fy]=a[fy]||{min:null,max:null,count:0};
		a[fy].count++;
		a[fy].min=(a[fy].min==null || b.date<a[fy].min)?b.date:a[fy].date;
		a[fy].max=(a[fy].max==null || b.date>a[fy].max)?b.date:a[fy].date;
		return a;
	},{});

	let years=Object.keys(yearByYear);
	//if (years.length>1) years=years.slice(0,-1);//remove the last year

	const location=useLocation();
	let params = queryString.parse(location.hash);
	let now=parseInt(new Date().toISOString().slice(0,4));
	let fiscal_year=report?.account?.fiscal_year;

	params.year=params.year || toFiscalYear(fiscal_year,new Date());
	params.segment=params.segment || "all";

	function getInt(s){
		if (!s) return 0;
		return s.indexOf('<')===0?-1:parseInt(s.replace(/[^0-9.-]/g,"").split("-")[0]);}

	function sort(a,b){
		return getInt(a.col0)<getInt(b.col0)?-1:1;
	}

	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;

	let thisYearAggregate={};
	try{
		thisYearAggregate=JSON.parse(JSON.stringify((data||[]).find(d=>d.unit==='fiscal' && d.date===params.year+"-01-01")));
		thisYearAggregate.customer_by_size_bracket=JSON.parse(thisYearAggregate.customer_by_size_bracket);
		thisYearAggregate.customer_by_size_bracket.forEach(d=>{d.col0=d.bracket;});
		thisYearAggregate.customer_by_size_bracket.sort(sort);
	}catch(e){
		console.error(e);
		console.error("Bad Customer by size bracket values for year=",thisYearAggregate.date,thisYearAggregate.customer_by_size_bracket);
		thisYearAggregate.customer_by_size_bracket=[];
	}

	return <div>
		<div className="report-large-select d-flex justify-content-between w-100">
			<FiscalYearSelect years={years} fiscal_year={fiscal_year}/>
		</div>
		<TabContainer useHash={true}>
			<TabContent label="Donors By Amount">
				<ReportCard title="Donors broken down by total gifts (All Donations)">
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<FraktureVerticalBarChart bars={12} label="Donor Counts by Cumulative Giving" data={thisYearAggregate.customer_by_size_bracket} metric={{label:"Donors",alias:"unique_people",format:"long"}}/>
						</Grid>
						<Grid item xs={6}>
							<FraktureVerticalBarChart bars={12} label="Gross Revenue by Cumulative Giving" data={thisYearAggregate.customer_by_size_bracket} metric={{label:"Gross Revenue",alias:"total_revenue",format:currencyFormat}}/>
						</Grid>
						<Grid item xs={12}>
							<FraktureReportTable height={600} data={thisYearAggregate.customer_by_size_bracket} dimensions={[{label:"Cumulative Yearly Giving",alias:"col0"}]} metrics={[{label:"Donors",alias:"unique_people",format:"long"},{label:"Gross Revenue",alias:"total_revenue",format:currencyFormat}]}/>
						</Grid>
					</Grid>
				</ReportCard>
			</TabContent>
			<TabContent label="Donor Type" >
				<CustomerTimeline data={data} now={now} year={params.year}/>
			</TabContent>
			<TabContent label="Help">
				<ReportCard title="Donor Categories">
					<p>Donor categories are calculated based on a running 3+ years of donation history.  For each definition, a '$' means a donor gave in that fiscal year.
						For example, to be categorized as a Second Year donor, a donor would have to give at least one gift this fiscal year, and at least one last fiscal year.</p>
					<DonorCategoryHelp/>
				</ReportCard>
			</TabContent>
		</TabContainer>
	</div>;
};
