// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReportCard from '../ReportCard';
import FiscalYearSelect from './FiscalYearSelect';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FraktureScorecard from './FraktureScorecard.js';
import FraktureVerticalBarChart from './FraktureVerticalBarChart.js';
import SourceCodePicker from './SourceCodePicker.js';
//import FraktureStackedBarChart from './FraktureStackedBarChart.js';
import FraktureReportTable from './FraktureReportTable';
import {TabContent,TabContainer} from '../../../FraktureTabs';
import dayjs from 'dayjs';
import {toFiscalYear} from '../../../../util/fiscal';


const currencyFormat="long_currency";
const longFormat="long";

export default function Component(props){
	let {data,label="",report}=props;

	const yearByYear=data.reduce((a,b)=>{
		a[b.fiscal_year]=a[b.fiscal_year]||{min:null,max:null,count:0};
		a[b.fiscal_year].count++;
		a[b.fiscal_year].min=(a[b.fiscal_year].min==null || b.ts<a[b.fiscal_year].min)?b.ts:a[b.fiscal_year].ts;
		a[b.fiscal_year].max=(a[b.fiscal_year].max==null || b.ts>a[b.fiscal_year].max)?b.ts:a[b.fiscal_year].ts;
		return a;
	},{});

	let years=Object.keys(yearByYear).sort((a,b)=>a<b?-1:1);
	//if (years.length>1) years=years.slice(1);//remove the first year unless there's only one year

	const location=useLocation();
	let params = queryString.parse(location.hash);
	const ytdDay=dayjs().subtract(1,'day').format("MMM D");

	let fiscal_year=report?.account?.fiscal_year;

	params.year=params.year || years.slice(-1)[0] || toFiscalYear(fiscal_year,new Date());
	params.segment=params.segment || "all";

	function setParam(name,val){
		params[name]=val;
		document.location.hash=queryString.stringify(params);
	}

	const segments=[
		{value:"all",label:"All Donors"},
		{value:"sustainer",label:"Sustainers Only"},
		{value:"nonsustainer",label:"1x Donors (aka non-Sustainers)"},
		{value:"customer_1200",label:"Major Donors ($1,200+ cumulative)"}
	];

	function calculateByYear(_data,distinctData,y,_segment){
		let segment=_segment || params.segment || "all";
		let values=_data.filter(d=>parseInt(d.fiscal_year)===parseInt(y)).reduce((out,b)=>{
			let transactions=b[segment+"$transactions"];
			let revenue=b[segment+"$revenue"];
			let recurring_revenue=b[segment+"$recurring_revenue"];

			out.transactions+=transactions;
			out.revenue+=revenue;
			out.recurring_revenue+=recurring_revenue;
			out.bracket[b.amount_group]=out.bracket[b.amount_group]||{transactions:0,revenue:0,recurring_revenue:0};
			out.bracket[b.amount_group].transactions+=transactions;
			out.bracket[b.amount_group].revenue+=revenue;
			out.bracket[b.amount_group].recurring_revenue+=recurring_revenue;

			out.recurring_bracket[b.recurring_group]=out.recurring_bracket[b.recurring_group]||{transactions:0,revenue:0,recurring_revenue:0};
			out.recurring_bracket[b.recurring_group].transactions+=transactions;
			out.recurring_bracket[b.recurring_group].revenue+=revenue;
			out.recurring_bracket[b.recurring_group].recurring_revenue+=recurring_revenue;
			return out;
		},{transactions:0,revenue:0,recurring_revenue:0,
			bracket:{
				'Refund':{transactions:0,revenue:0,recurring_revenue:0},
				'<$5':{transactions:0,revenue:0,recurring_revenue:0},
				'$5-$9.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$10-$24.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$25-$34.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$35-$59.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$60-$99.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$100-$249.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$250-$999.99':{transactions:0,revenue:0,recurring_revenue:0},
				'$1,000-$2,499':{transactions:0,revenue:0,recurring_revenue:0},
				'$2,500-$4,999':{transactions:0,revenue:0,recurring_revenue:0},
				'$5,000+':{transactions:0,revenue:0,recurring_revenue:0}
			},
			recurring_bracket:{
				'Refunds':{transactions:0,revenue:0,recurring_revenue:0},
				'New Recurring':{transactions:0,revenue:0,recurring_revenue:0},
				'Rollover Recurring':{transactions:0,revenue:0,recurring_revenue:0},
				'1x Gifts From Sustainers':{transactions:0,revenue:0,recurring_revenue:0},
				'1x Gifts From Nonsustainers':{transactions:0,revenue:0,recurring_revenue:0}
				//'Unknown':{transactions:0,revenue:0,recurring_revenue:0}
			}
		});
		let distinctRecords=distinctData.find(d=>parseInt(d.fiscal_year)===parseInt(y)) ||{};
		values.customers=distinctRecords[segment+"$transaction_unique_person"];

		values.average=values.revenue/values.transactions;
		values.frequency=values.transactions/values.customers;
		values.revenue_per_customer=values.revenue/values.customers;

		delete values.bracket["Refund"]; //Don't include refunds
		values.bracket=Object.keys(values.bracket).map(k=>{
			values.bracket[k].col0=k;
			return values.bracket[k];
		});

		values.recurring_bracket=Object.keys(values.recurring_bracket).map(k=>{
			values.recurring_bracket[k].col0=k;
			return values.recurring_bracket[k];
		});

		return values;
	}
	let ytdData=report.components.ytd.data;
	if (!ytdData){
		return "Error calculating YTD data";
	}

	let byYear=[params.year,parseInt(params.year)-1].map(y=>calculateByYear(data,(report?.components?.distinct?.data||[]),y));
	let byYTD=[params.year,parseInt(params.year)-1].map(y=>calculateByYear(ytdData,ytdData,y));

	let segmentPicker=<Select variant="standard" value={params.segment||"all"}>{segments.map((s,i)=><MenuItem key={i} value={s.value} onClick={e=>setParam('segment',s.value)}>{s.label}</MenuItem>)}</Select>;

	return <div>
		<div className="report-large-select d-flex justify-content-between w-100">
			<FiscalYearSelect years={years} fiscal_year={fiscal_year}/>
			{segmentPicker}
			<div/>
			<SourceCodePicker source_code_config={report.source_code_config}/>
		</div>
		{(data.length===0)?<>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>:
			<TabContainer useHash={true}>
				<TabContent label="Year To Date" >
					{(byYTD[0] && byYTD[1])?
						<ReportCard title={`Key Performance Indicators (Through ${ytdDay})`}>
							<Grid container spacing={3}>
								<Grid item xs={4}><FraktureScorecard label="Gross Revenue" metric={{name:"revenue",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={4}><FraktureScorecard label="Recurring Revenue" metric={{name:"recurring_revenue",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={4}><FraktureScorecard label="Average Gift" metric={{name:"average",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={3}><FraktureScorecard label="Total Gifts" metric={{name:"transactions",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={3}><FraktureScorecard label="Donors" metric={{name:"customers",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={3}><FraktureScorecard label="Gifts/Donor" metric={{name:"frequency",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={3}><FraktureScorecard label="Revenue/Donor" metric={{name:"revenue_per_customer",format:"long_currency"}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
								<Grid item xs={12}></Grid>
								<Grid item xs={12}><hr/></Grid>
								<Grid item xs={12}></Grid>
							</Grid>
						</ReportCard>:<div className="alert alert-info">No YTD info for this year</div>}
				</TabContent>
				<TabContent label="Yearly Summary" >
					<ReportCard title={`Key Performance Indicators (Yearly)`}>
						<Grid container spacing={3}>
							<Grid item xs={4}><FraktureScorecard label="Gross Revenue" metric={{name:"revenue",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={4}><FraktureScorecard label="Sustainer Revenue" metric={{name:"recurring_revenue",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={4}><FraktureScorecard label="Average Gift" metric={{name:"average",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Total Gifts" metric={{name:"transactions",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Donors" metric={{name:"customers",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Gifts/Donor" metric={{name:"frequency",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Revenue/Donor" metric={{name:"revenue_per_customer",format:"long_currency"}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={12}><hr/></Grid>
							<Grid item xs={12}></Grid>
						</Grid>
					</ReportCard>
				</TabContent>
				<TabContent label="Gift Analysis" >
					<ReportCard title="Gift Breakdown">
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FraktureVerticalBarChart label="Gifts by Range" data={byYear[0].bracket} metric={{label:"Gifts",alias:"transactions",format:"long"}}/>
							</Grid>
							<Grid item xs={6}>
								<FraktureVerticalBarChart label="Gross Revenue by Gift Size" data={byYear[0].bracket} metric={{label:"Revenue",alias:"revenue",format:currencyFormat}}/>
							</Grid>
							<Grid item xs={12}>
								<FraktureReportTable height={600} data={byYear[0].bracket} dimensions={[{label:"Range",alias:"col0"}]} metrics={[{label:"Gifts",alias:"transactions",format:"long"},{label:"Revenue",alias:"revenue",format:currencyFormat}]}/>
							</Grid>
						</Grid>
					</ReportCard>
				</TabContent>
				<TabContent label="Sustainer Analysis" >
					<ReportCard title="Sustainer Revenue by Gift Type">
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FraktureVerticalBarChart label="Gifts breakdown" data={byYear[0].recurring_bracket} metric={{label:"Gifts",alias:"transactions",format:"long"}} />
							</Grid>
							<Grid item xs={6}>
								<FraktureVerticalBarChart label="Revenue breakdown" data={byYear[0].recurring_bracket} metric={{label:"Revenue",alias:"revenue",format:currencyFormat}} />
							</Grid>
							<Grid item xs={12}>
								<FraktureReportTable height={600} data={byYear[0].recurring_bracket} dimensions={[{label:"Range",alias:"col0"}]} metrics={[{label:"Gifts",alias:"transactions",format:"long"},{label:"Revenue",alias:"revenue",format:currencyFormat}]}/>
							</Grid>
						</Grid>
					</ReportCard>
				</TabContent>
			</TabContainer>
		}
	</div>;
};
