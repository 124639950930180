import React from 'react';
import gql from 'graphql-tag';

import useNotifiers from '../../Notifiers';
import {fraktureQuery} from '../../FraktureQuery';
import {useApolloClient} from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';

const BOT_SHARABLE = gql`query BOT_SHARABLE($bot_id: ID!) {
	bot(_id:$bot_id) {
		_id
		sharable
	}
}`;

const BOT_SET_SHARABLE = gql`mutation BOT_SET_SHARABLE($bot_id:ID!, $sharable:Boolean!) {
	bot_set_sharable(_id:$bot_id, sharable:$sharable) {
		_id
		sharable
	}
}`;

export default function BotSharableCheckbox(props) {
	const{bot_id}=props;
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();
	return fraktureQuery({
		query: BOT_SHARABLE,
		variables:{bot_id}
	}, ({bot:{sharable}}) => {
		return <Checkbox checked={!!sharable} onChange={() => {
			client.mutate({
				mutation: BOT_SET_SHARABLE,
				variables: {bot_id, sharable: !sharable}
			}).then(notify,notifyErr);
		}} />;
	});
};
