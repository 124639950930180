import React from 'react';
import gql from "graphql-tag";
import {useQuery} from '@apollo/client';


export const DICTIONARY_BOT_QUERY=gql`
query DICTIONARY_BOT_QUERY($account_id: ID!) {
	account(_id: $account_id) {
		_id
		children {
			_id
			name
			disabled
			default_warehouse_bot{
				_id
				global_table_prefix
			}
		}

		parents {
			_id
			name
		}

		source_code_elements {
			_id
			source_code_element_set_id
			name
			label
			description
			type
			fql
			values{
				name
				value
				label
			}
		}
		default_warehouse_bot{
			_id
			global_table_prefix
		}
	}
}`;

export function useAccountWarehouseInfo(props) {
	const{account_id}=props;

	const {data,loading,error}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (loading) return {loading};
	if(error) return {error};

	return {data:data.account};
};
export default useAccountWarehouseInfo;
