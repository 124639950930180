import React,{useState} from 'react';

import useNotifiers from '../Notifiers';
import gql from "graphql-tag";
import { useApolloClient } from '@apollo/client';
import { fraktureQuery } from '../FraktureQuery';
import OptionEditor,{RemovableOptionEditor} from '../inputs/OptionEditor';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

export function TransformSelectEditor({job,updateOption,removeOption}) {
	const {values}=job;
	const{table,include_unspecified_fields,limit,additional_configured_outputs, ...rest}=values;

	const selects = Object.keys(rest).filter(x=>x.indexOf('output_') === 0);

	const [newRow,setNewRow]=useState('');
	let editorToolTip = 'Click to add new output';
	if(!(newRow.key && newRow.value)) editorToolTip = 'Key and Value must both be specified';
	const newOptionEditor = <div className="form-group row">
		<div className="col-sm-2">
			<TextField fullWidth value={newRow.key} placeholder='Output key' onChange={e => {
				setNewRow(Object.assign({},newRow,{key:e.target.value}));
			}} />
		</div>
		<div className="col-sm-9">
			<TextField fullWidth value={newRow.value} placeholder='Output FQL' onChange={e => {
				setNewRow(Object.assign({},newRow,{value:e.target.value}));
			}} />
		</div>
		<div className="col-sm-1">
			<Tooltip title={editorToolTip}>
				<span><IconButton
					disabled={!(newRow.key && newRow.value)}
					onClick={() => {
						updateOption('output_'+newRow.key, newRow.value);
					}}
					size="large"><AddIcon/></IconButton></span>
			</Tooltip>
		</div>
	</div>;

	return <React.Fragment>
		<OptionEditor value={table} definition={{name:'table'}}
			onOptionSave={({value}) => updateOption('table',value)} />
		<OptionEditor value={include_unspecified_fields} definition={{name:'include_unspecified_fields'}}
			onOptionSave={({value}) => updateOption('include_unspecified_fields',value)} />
		<OptionEditor value={additional_configured_outputs} definition={{name:'additional_configured_outputs'}}
			onOptionSave={({value}) => updateOption('additional_configured_outputs',value)} />
		<OptionEditor value={limit} definition={{name:'limit'}}
			onOptionSave={({value}) => updateOption('limit',value)} />
		<h4>Outputs</h4>
		{selects.map(key => {
			const name = key.slice('output_'.length);
			return <RemovableOptionEditor value={values[key]} key={key} definition={{name}} type='fql'
				onOptionSave={({value}) => updateOption(key,value)} removeOption={() => {
					removeOption(key);
				}} />;
		})}
		{newOptionEditor}
	</React.Fragment>;
};

export function DefaultJobOptions(props) {
	let {options,values,bot_id,method,updateOption}=props;
	switch(method) {
	//case 'applyTransformSelect': return <TransformSelectEditor {...props} />;
	default:
	};
	values=JSON.parse(JSON.stringify(values || {}));
	let opts=options || [];
	if (typeof options=='string' && options.slice(0,1)==="{"){
		try{
			opts=JSON.parse(options);
		}catch(e){
			opts=[{name:"OPTIONS PARSING ERROR",value:e.toString()}];
		}
	}
	if (!Array.isArray(opts)) opts=Object.keys(opts).map(d=>({name:d,value:opts[d]}));

	if(method === 'configStep'){
		Object.keys(values).forEach(x => {
			if(!options.find(y=>y.name === x)) options.push({name:x});
		});
	}

	let items=(opts||[]).map((opt)=>{
		let k=opt.name;
		let v=values[k]||"";
		if (typeof v=='object') v=JSON.stringify(v);

		return <OptionEditor key={opt.name}
			value={v}
			bot_id = {bot_id}
			definition={opt}
			onOptionSave={({value}) => updateOption(opt.name,value)} />;
	});
	return items;
}

/*********** Buttons **************/
const UPDATE_OPTION=gql(`mutation job_update_options($_id: ID!,$values:JSON) {
	job_update_options(_id: $_id,values:$values) {
	  _id
	  options
		resolved_options
	}
}`);

const JOB_OPTIONS_QUERY=gql`query JOB_OPTIONS_QUERY($job_id:ID!) {
	job(_id: $job_id) {
		_id
		options
		method
		bot {
			_id
		}
		bot_method {
			options {
				name
				type
				description
			}
		}
	}
}`;

export default function Options({job_id,values}){
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();
	if (!job_id) throw new Error("Options requires a job_id");
	return fraktureQuery({
		query: JOB_OPTIONS_QUERY,
		variables: {job_id}
	}, ({job}) => {
		const {bot_method:{options}}=job;
		const updateOption = (name,value) => {
			const v=values[name];
			if(v===value) {
				return;
			}
			let mod={[name]:value};
			client.mutate({
				mutation: UPDATE_OPTION,
				variables:{_id:job_id,values:JSON.stringify(mod)}
			}).then(notify,notifyErr);
		};
		return <DefaultJobOptions {...{bot_id:job.bot._id,method:job.method,options,values, updateOption,job}} />;
	});
};
