import React,{useState} from 'react';

import gql from "graphql-tag";

import { useApolloClient } from '@apollo/client';
import {useParams} from 'react-router-dom';
import FraktureTable from '../FraktureTable';
import useNotifiers from '../Notifiers';





const ANALYZE_QUERY=gql`query ANALYZE_QUERY($account_id: ID!,$source_code:String!) {
	account(_id: $account_id) {
		_id
		source_code_analyze(source_code:$source_code)
	}
}`;

export default function SourceCodeAnalysis({source_code}){
	const client=useApolloClient();
	let [results,setResults]=useState(null);
	let [loading,setLoading]=useState(false);
	const {account_id}=useParams();
	const {notifyErr}=useNotifiers();
	if (!source_code) return "";
	if (loading) return "Loading ...";
	client.query({
		query: ANALYZE_QUERY,
		variables: {account_id,source_code}
	}).then(({data,error,loading}) => {
		if(error){return notifyErr(error);}
		if (loading){return setLoading(true);}
		else setLoading(false);
		setResults(data);
	});
	if (loading) return "Analyzing source code ....";
	if (!results) return "";
	let analysis=results.account.source_code_analyze;
	let rows=[];
	Object.entries(analysis).forEach(([item,value])=>rows.push({item,value}));

	return <div>
		<div className="w-100">{JSON.stringify()}
			<FraktureTable {...{
				sort:"match_order",
				columns:[
					{title:"ID",field:"key"},
					{title:"Value",render:({row})=>JSON.stringify(row)},
				],
				rows}} />
		})}</div>
		}</div>;
}
