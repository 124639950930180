import React from 'react';

import gql from "graphql-tag";
// import { useQuery } from '@apollo/client';
import {fraktureQuery} from '../FraktureQuery';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Grid from '@mui/material/Grid';

import FraktureDataTable from './FraktureDataTable';

const TABLE_DETAILS = gql`query tableInformation($warehouse_bot_id: ID!, $table: String!){
	warehouse(bot_id: $warehouse_bot_id) {
		table(name:$table) {
		  type
			primary_index {
				fields {
					name
				}
			}
			links {
        source_fields {
          name
        }
        target_table_name
        target_fields {
          name
        }
      }
		}
	}
}`;
const RECORD_DETAILS = gql`query recordDetails($warehouse_bot_id: ID!, $table: String!, $query: WarehouseQueryInput!) {
	warehouse(bot_id: $warehouse_bot_id) {
		table(name:$table) {
			query(query: $query) {
				values
			}
			fields {
				name
			}
		}
	}
}`;

function RecordDisplayTable(props) {
	const{values}=props;
	return <Table>
		<TableHead>
			<TableRow><TableCell>Field</TableCell><TableCell>Value</TableCell></TableRow>
		</TableHead>
		<TableBody>
			{Object.keys(values).map((x,key) => {
				return <TableRow key={key}>
					<TableCell>{x}</TableCell>
					<TableCell>{values[x]}</TableCell>
				</TableRow>;
			})}
		</TableBody>
	</Table>;
};

function RelatedRecordsDisplay(props) {
	const{links,values,warehouse_bot_id}=props;
	return links.map(x => {
		const{target_table_name, source_fields, target_fields}=x;
		const fql = target_fields.map((src,i) => {
			return `${src.name} = "${values[source_fields[i].name]}"`;
		}).join(' and ');
		return <React.Fragment key={target_table_name}>
			<h3>{target_table_name}</h3>
			<h4>{fql}</h4>
			<FraktureDataTable
				key={target_table_name} bot_id={warehouse_bot_id}
				table={target_table_name} query={{conditions:[{fql}]}} />
		</React.Fragment>;
	});
};

function RecordView(props) {
	const{warehouse_bot_id,table,identifiers}=props;
	if(!warehouse_bot_id || !table || !identifiers) return <div>
		Table and Identifiers are required parameters
	</div>;
	return fraktureQuery({
		query: TABLE_DETAILS,
		variables: {warehouse_bot_id,table}
	}, data => {
		const {primary_index, links}=data.warehouse.table;
		if(!primary_index || !(primary_index.fields||[]).length) return 'Unable to show records for table with no primary key';
		const fql = primary_index.fields.map(x => `${x.name} = "${identifiers[x.name] || ''}"`).join(' and ');
		console.log(fql);
		return fraktureQuery({
			query: RECORD_DETAILS,
			variables: {warehouse_bot_id,table,query:{conditions:[{fql}]}}
		}, details => {
			const {values} = details.warehouse.table.query[0];
			return <Grid container>
				<Grid md='6'><RecordDisplayTable {...{values}} /></Grid>
				<Grid md='6'><RelatedRecordsDisplay {...{links, values, warehouse_bot_id}} /></Grid>
			</Grid>;
		});
	});
};

export default function WarehouseTableRecordUi(props) {
	const location=useLocation();

	const query=queryString.parse(location.search || "");

	const{table,warehouse_bot_id}=props.match.params;
	return <Card>
		<CardHeader title={`Record View (${table})`}/>
		<CardContent>
			<RecordView identifiers={query} {...{warehouse_bot_id,table}} />
		</CardContent>
	</Card>;
};
