import React from 'react';

import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import useNotifiers from '../../Notifiers';
import { useUserContext } from '../../../AdminCheck';

import {Link} from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import {fraktureQuery} from '../../FraktureQuery';

import fragments from '../gql-fragments';

const UPDATE_DATAFLOW_PARENT_MUTATION = gql`
mutation UPDATE_DATAFLOW($dataflow_id:ID!, $update: DataflowUpdate!) {
	dataflow_update(_id: $dataflow_id, update:$update) {
		_id
		parent{
			_id
		}
		... dataflowBotOverrides
	}
}
${fragments.dataflowBotOverrides}
`;

const VALID_PARENT_DATAFLOWS_QUERY=gql`
query VALID_PARENT_DATAFLOWS($dataflow_id:ID!) {
	dataflow(_id:$dataflow_id) {
		_id
		account {
			_id
			valid_parent_dataflows {
				_id
				label
				account {
					_id
				}
			}
		}
	}
}
`;

export default function ParentSelect(props) {
	const {notify,notifyErr} = useNotifiers();
	const {is_frakture_admin} = useUserContext();
	const client = useApolloClient();

	if(!is_frakture_admin) return null;

	const{dataflow}=props;
	const{parent,_id}=dataflow;
	let link;
	if(parent) link = <p><Link to={`/app/${parent.account._id}/dataflow/${parent._id}`}>View Parent</Link></p>;

	let commonParents=[
		"5da875c9651c5b3ca4f71670",
		"5daa12cf7be7f9769fda34ef",
		"5dcdc30fe110ee2323ca1c92",
		"5faec3b1ea0318165227dec0",
		"626012c6ae33624cf460651a"
	];

	const select = fraktureQuery({
		query: VALID_PARENT_DATAFLOWS_QUERY,
		variables: {dataflow_id:dataflow._id}
	}, data => {
		const validParents = data.dataflow.account.valid_parent_dataflows;
		validParents.sort((a,b)=>a.label<b.label?-1:1);
		return <Select value={(parent||{})._id} onChange={e =>{
			client.mutate({
				mutation:UPDATE_DATAFLOW_PARENT_MUTATION,
				variables:{dataflow_id:_id,update:{parent_id:e.target.value}}}
			).then(notify,notifyErr);}
		}>
			<MenuItem value="">(None)</MenuItem>
			<ListSubheader>Common Parents</ListSubheader>
			{validParents.filter(df=>commonParents.indexOf(df._id)>=0).sort((a)=>a.account._id==='frakture_master'?1:-1).map(df => {
				return <MenuItem key={df._id} value={df._id}>{df.label} ({df.account._id})</MenuItem>;
			})}
			<ListSubheader>Frakture</ListSubheader>
			{validParents.filter(df=>commonParents.indexOf(df._id)<0).filter((a)=>a.account._id==='frakture_master').map(df => {
				return <MenuItem key={df._id} value={df._id}>{df.label} ({df.account._id})</MenuItem>;
			})}
			<ListSubheader>Other</ListSubheader>
			{validParents.filter(df=>commonParents.indexOf(df._id)<0).filter((a)=>a.account._id!=='frakture_master').sort((a)=>a.account._id==='frakture_master'?1:-1).map(df => {
				return <MenuItem key={df._id} value={df._id}>{df.label} ({df.account._id})</MenuItem>;
			})}
		</Select>;
	});

	return <React.Fragment>
		{link}
		<FormControl fullWidth className="w-100">
			<InputLabel htmlFor="age-simple">Set Parent Dataflow</InputLabel>
			{select}
		</FormControl>
	</React.Fragment>;
}
