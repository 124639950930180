/* global module */
module.exports = function() {
	function addFieldToTypesTransform({name,type: transformType}) {
		if(!name || !transformType) throw new Error('name and type required');
		return {
			transformSchema: (schema) => {
				const typeList = Object.keys(schema._typeMap);
				typeList.forEach(x => {
					const type = schema._typeMap[x];
					if(type && type._fields && !type._fields.bot_id) {
						type._fields.bot_id = {
							name,
							type:transformType,
							args: []
						};
					}
				});
				return schema;
			}
		};
	};

	return {
		addFieldToTypesTransform
	};
};
