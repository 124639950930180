import React from 'react';
import TypeAwareInput from './TypeAwareInput';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

export default function OptionEditor(props) {
	const {definition,onOptionSave}=props;

	props = Object.assign({},props);
	props.defaultValue = (definition||{}).default || props.defaultValue;

	let content;
	let type = definition.type || "";
	if(!type && definition.name?.slice(-3) === 'sql') type = 'sql';
	if(!type && (definition.name === 'table' || definition.name.slice(-'_table'.length) === '_table')) type='table';
	if(!type && definition.name.slice(-'_fql'.length) === '_fql') type='fql';
	if(!type && definition.name==='frakture_transform_id') type='frakture_transform_id';
	if(!type && (definition.name === 'map' || definition.name.slice(-'_map'.length) === '_map')) type='map';

	// because why not?
	if(!type && definition.name.slice(-1)[0] === 's') type='list';
	if(!type && definition.name === 'filename') {
		type = 'filename';
		//console.log('Options editor:type = filename');
	}

	props.type = props.type || type;

	const input=<div className="col-sm-9">
		<TypeAwareInput
			{...props}
			onChange={
				(value) => onOptionSave({value,key:definition.name})
			}/>
	</div>;

	let labelText = definition.name;
	if(definition.required) {
		labelText = <span>{definition.name}<span style={{color:'red'}}>*</span></span>;
	}

	content = <div className="form-group row">
		<Tooltip title={labelText}>
			<label className="col-sm-3 col-form-label col-form-label-sm" style={{
				textAlign:'right',
				fontSize:'15px',
				overflow:'hidden'
			}}>
				{labelText}
			</label>
		</Tooltip>
		{input}
	</div>;
	return content;
};

export function RemovableOptionEditor(props) {
	const{removeOption,...rest}=props;
	const optionEditor = <OptionEditor{...rest}/>;
	const removeOptionButton = <IconButton onClick={() => removeOption(props.definition.name)} size="large">
		<CloseIcon />
	</IconButton>;
	return <div className='form-group row'>
		<div style={{width:'50px'}}>
			{removeOptionButton}
		</div>
		<div style={{width:'calc(100% - 50px)'}}>{optionEditor}</div>
	</div>;
}
