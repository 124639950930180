import React,{useState} from 'react';
import StubQuery from "../../StubQuery";
import gql from "graphql-tag";


import { useApolloClient,useQuery } from '@apollo/client';
import useNotifiers from '../../Notifiers';


import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import TextField from '@mui/material/TextField';

import SearchBox from '../../SearchBox';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {AccountAccessCheckbox} from '../../users/UserAdmin';

import {USER_GROUPS_QUERY} from '../../usergroup';


const USER_SEND_INVITE=gql`mutation USER_SEND_INVITE($account_id:ID!,$email:String!,$user_group_id:ID) {
	account_send_invite(_id: $account_id,email:$email,user_group_id:$user_group_id) {
		_id
		expires
	}
}`;




function SendInviteForm({account_id}) {
	let [email,setEmail]=useState(null);
	let [user_group_id,setUserGroupId]=useState(null);
	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const {data,error,loading}=useQuery(USER_GROUPS_QUERY,{variables:{account_id}});
	if(error) throw error;
	if(loading || !data) return "Loading...";

	return <div style={{verticalAlign:"bottom"}}>
		<form onSubmit={e=>{
			e.preventDefault();
			client.mutate({
				mutation: USER_SEND_INVITE,
				variables: {account_id,email,user_group_id}
			}).then(e=>{
				notify("Sent invite to "+email);
				setEmail("");
			},notifyErr);
		}}>
			<FormControl className="col-md-6 align-baseline">
				<InputLabel id="email-id">Email</InputLabel>
				<TextField labelId="email-id" type="email" name="email" aria-label="Email to Invite" onChange={e=>{setEmail(e.target.value);}}/>
			</FormControl>
			<FormControl className="col-md-4 align-baseline">
				<InputLabel id="user-group-select">Add to Group (optional)</InputLabel>
				<Select
					labelId="user-group-select"
					value={user_group_id}
					onChange={e => setUserGroupId(e.target.value)}>
					<MenuItem value=""></MenuItem>
					{data.account.user_groups.map(ug => <MenuItem key={ug._id} value={ug._id}>
						{ug.label}
					</MenuItem>)}
				</Select>
			</FormControl>
			<Button className="col-md-2 align-baseline" variant="contained" color="primary" type="submit">Send Invite</Button>
		</form>
	</div>;
}



const accountUserListQuery=gql`query accountUserList($account_id: ID!) {
	account(_id: $account_id) {
		users {
			page {
				_id
				username
				email
				can_access_account(account_id: $account_id)
			}
		}
	}
}`;
const accountUserSearchListQuery=gql`query accountUserSearchList($account_id: ID!, $text: String!) {
	users_search(text: $text) {
		_id
		username
		email
		can_access_account(account_id: $account_id)
	}
}`;
export class AccountUserList extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			search: ''
		};
	}
	render() {
		const{account_id}=this.props;
		let query = accountUserListQuery;
		let variables={account_id};
		if(this.state.search) {
			query=accountUserSearchListQuery;
			variables.text = this.state.search;
		}
		return <Grid container>
			<Grid item xs={12} md={3}>
				<SearchBox key='searchBox' value={this.state.search} onChange={e => this.setState({
					search: e.target.value
				})} />
			</Grid>
			<Grid item xs={12} md={9}><SendInviteForm account_id={account_id}/></Grid>
			<Grid item xs={12}>
				<StubQuery key='list' query={query} variables={variables}>
					{({data,error,loading}) => {
						if(error) return 'Error retrieving users: '+JSON.stringify(error);
						if(loading) return 'Loading ... ';
						let users;
						if(this.state.search) users = data.users_search;
						else users = data.account.users?.page||[];
						return <Table>
							<TableHead>
								<TableRow>
									<TableCell>User ID</TableCell>
									<TableCell>Username</TableCell>
									<TableCell>Access</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.map(user => <TableRow key={user._id}>
									<TableCell>{user._id}</TableCell>
									<TableCell>{user.username}</TableCell>
									<TableCell><AccountAccessCheckbox user_id={user._id} account_id={account_id} /></TableCell>
								</TableRow>)}
							</TableBody>
						</Table>;
					}}
				</StubQuery>
			</Grid>
		</Grid>;
	}
};
