import React from 'react';
/*
import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react';
import trianglify from 'trianglify';

function Trianglify ({
	output = 'canvas',
	width= 300,
	height= 100,
	cellSize= 40,
	variance= 0.9,
	seed= null,
	xColors= 'random',
	yColors= 'match',
	fill= true,
	palette= trianglify.colorbrewer,
	colorSpace= 'lab',
	colorFunction= trianglify.colorFunctions.interpolateLinear(0.5),
	strokeWidth= 0,
	points= null
}){
	const ref = useRef();
	const [, forceUpdate] = useState({});
	const svgOutput = useCallback((generateOutput) => {
		generateOutput(ref.current);
	}, []);

	const canvasOutput = useCallback((generateOutput) => {
		const canvas = generateOutput();
		const ctx = ref.current.getContext('2d');
		ctx.drawImage(canvas,0, 0, width, height);
	}, []);

	const choosenOutput = useMemo(() => ({
		canvas: {
			output: 'toCanvas',
			method: canvasOutput,
		},
		svg: {
			output: 'toSVG',
			method: svgOutput,
		},
	}), []);

	useEffect(() => {
		const pattern = trianglify({
			width,
			height,
			cellSize,
			variance,
			seed,
			xColors,
			yColors,
			fill,
			palette,
			colorSpace,
			colorFunction,
			strokeWidth,
			points
		});
		if (false){
			console.log({
				width,
				height,
				cellSize,
				variance,
				seed,
				xColors,
				yColors,
				fill,
				palette,
				colorSpace,
				colorFunction,
				strokeWidth,
				points
			});
		}

		const generateOutput = pattern[choosenOutput[output].output];

		if (!generateOutput) {
			throw new Error('Invalid output. Only "canvas" or "svg" are allowed.');
		}

		//const outputMethod =
		choosenOutput[output].method(generateOutput);

		forceUpdate({});
	}, []);

	const Tag = output;
	return <Tag ref={ref} width={width} height={height} />;
}
*/
export default function({color="#fca",width= 300,height= 100}){
	return <div style={{
		width,
		height,
		background: `linear-gradient(35deg,${color} 0%, #FFF 100%)`
	}}></div>;
};
