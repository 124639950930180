import React,{useEffect} from 'react';
import {useQuery} from '@apollo/client';
import Cookie from 'js-cookie';
import queryString from 'query-string';
import gql from "graphql-tag";
import {useNavigate,useLocation} from 'react-router-dom';

const info = gql `query botInfo($bot_id:ID!) {
	bot(_id:$bot_id) {
		account_id
		api_endpoint
		set_oauth_uri
		token
	}
}`;

export default function BotOauth() {
	const location=useLocation();
	const query=queryString.parse(location.search) ||{};
	const navigate=useNavigate();
	const bot_id=Cookie.get('last_bot_id');
	console.log("Retrieved bot_id from cookie:",bot_id);
	// const {queryString}=
	if(!bot_id) throw new Error("No recent bot id found in cookies, you'll need to retry");

	const {data,error,loading}=useQuery(info, {
		variables: {bot_id}
	});

	useEffect(() => {
		if(error) throw error;
		if(loading) return;
		const{token,set_oauth_uri,account_id}=data.bot||{};
		if (!set_oauth_uri) throw new Error("No set_oauth_uri specified in response");
		console.log("Received a token, posting with bot_id ",bot_id);
		fetch(set_oauth_uri, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				'x-bot-auth-token':token
			},
			body: JSON.stringify({options:query})
		}).then(response =>{
			if (!response.ok) {
				throw new Error('Error setting oauth data');
			}
			return response.json();
		})
			.then(() => {
				navigate(`/app/${account_id}/bot/${bot_id}`);
			})
			.catch(e => {
				console.error(e);
			});
	},[loading,error,data]);

	return '';
};
