import React,{useState,useEffect} from 'react';
import StubQuery from "../../StubQuery";
import gql from "graphql-tag";

import LabelEdit from '../../inputs/LabelEdit';
import OptionEditor from '../../inputs/OptionEditor';

import useNotifiers from '../../Notifiers';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useApolloClient } from '@apollo/client';

import {DefaultJobOptions} from '../../job/Options';
import {useDataflowId} from '../Links';

const getJobsForBotQuery=gql(`query GET_JOBS_FOR_BOT($bot_id:ID!) {
	bot(_id: $bot_id) {
		_id
		label
		definition {
			_id
			methods {
				... jobBotMethod
			}
			submodules {
				name
				methods {
					... jobBotMethod
				}
			}
		}
	}
}
fragment jobBotMethod on BotMethod {
	name
	options {
		name
		description
		type
		required
		default
	}
}
`);

const REMOVE_JOB_OPTION_MUTATION=gql`
mutation REMOVE_JOB_OPTION_MUTATION($_id:ID!,$context_id:ID!,$key:String!) {
	dataflow_job_option_delete(_id:$_id,context_id:$context_id,key:$key) {
		_id
		jobs {
			context_id
			options
		}
	}
}
`;
function useRemoveOption(props) {
	const client = useApolloClient();
	const{notify,notifyErr}=useNotifiers();
	const dataflow_id = useDataflowId(props);
	return ({context_id,key}) => client.mutate({
		mutation: REMOVE_JOB_OPTION_MUTATION,
		variables: {_id:dataflow_id,context_id,key}
	}).then(notify,notifyErr);
}

function RemoveOptionButton({_id,context_id,field:key}) {
	const removeOption = useRemoveOption({_id});
	return (
		<IconButton onClick={() => removeOption({context_id,key})} size="large">
			<CloseIcon />
		</IconButton>
	);
};

function ConfigStepJobEditor(props) {
	const{job,dataflow_id}=props;
	let{label,context_id,bot,options={}}=job;

	const [newRow,setNewRow]=useState({key:'',value:''});
	useEffect(() => {
		if(options[newRow.key] === newRow.value) setNewRow({key:'',value:''});
	}, [options,newRow.key,newRow.value]);

	const fields = Object.keys(options);

	const optionEditors = fields.map(key => {
		const value = options[key];
		return <div key={key} className="form-group row">
			<div style={{width:'calc(100% - 50px)'}}>
				<OptionEditor value={value} key={key}
					bot_id={bot._id}
					definition={{name:key}}
					onOptionSave={o => props.onOptionSave(o)}/>
			</div>
			<div style={{width:'50px'}}>
				<RemoveOptionButton {...{context_id,field:key,_id:dataflow_id}} />
			</div>
		</div>;
	});

	const newOptionEditor = <div className="form-group row">
		<div className="col-sm-3">
			<TextField fullWidth label='New Option Label' value={newRow.key} onChange={e => {
				setNewRow(Object.assign({},newRow,{key:e.target.value}));
			}} />
		</div>
		<div className="col-sm-7">
			<TextField fullWidth label='New Option Value' value={newRow.value} onChange={e => {
				setNewRow(Object.assign({},newRow,{value:e.target.value}));
			}} />
		</div>
		<div className="col-sm-2"><Button variant="contained" onClick={()=>{
			props.onOptionSave(newRow);
		}}>Save</Button></div>
	</div>;

	return <div className="w-100" >
		<h3>
			<LabelEdit value={label} style={{width:'calc(100% - 20px)'}}
				onChange={v=>props.onJobSave({update:{label:v}})} />
		</h3>
		<h4 style={{color:'gray'}}>{context_id}</h4>
		{optionEditors}
		{newOptionEditor}
	</div>;
};

export default function JobEditor(props) {
	let{job:_job}=props;
	let{label,bot,submodule,method,options,context_id}=_job;
	const removeOption = useRemoveOption(props);
	options = options || {};
	if(method === 'configStep') return <ConfigStepJobEditor {...props} />;
	return <StubQuery query={getJobsForBotQuery} variables={{bot_id:bot._id}}>
		{({data,error,loading}) => {
			if(error||loading) return JSON.stringify(loading || error);

			const{definition,label:bot_label}=data.bot;
			let methods;
			if(submodule) methods = definition.submodules.find(x=>x.name === submodule).methods;
			else methods = definition.methods;

			const methodDefinition = methods.find(x=>x.name === method);
			if (!methodDefinition){
				return "Invalid method:"+method;
			}
			const subheader = <React.Fragment>
				{context_id} - {[bot_label, submodule, method].filter(x=>x).join('.')}
			</React.Fragment>;

			let job = Object.assign({},_job,{values:options||{}},{options:methodDefinition.options}); // for Options;
			if (!job.values){
				return "No values for this job, but there should be";
			}
			//return JSON.stringify(job);

			return <div className="w-100" >
				<h3><LabelEdit value={label} style={{width:'calc(100% - 20px)'}}
					onChange={v=>props.onJobSave({update:{label:v}})}
				/></h3>
				<h4 style={{color:'gray'}}>{subheader}</h4>
				{job.options.length} options
				<DefaultJobOptions {...job} job={job} removeOption={key => removeOption({key,context_id})}
					updateOption={(key,value) => {
						props.onOptionSave({key,value});
						// {methodDefinition.options.map(def => {
						// 	const value = options[def.name];
						// 	return <OptionEditor value={value} key={def.name}
						// 		bot_id={bot._id}
						// 		definition={def}
						// 		onOptionSave={o => props.onOptionSave(o)}/>;
						// })}
					}} />
			</div>;
		}}
	</StubQuery>;
};
