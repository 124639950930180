import React from 'react';
import AsyncSelect from 'react-select/async';
import {useApolloClient} from '@apollo/client';
import gql from 'graphql-tag';

const WAREHOUSE_TABLE_QUERY=gql`
query WAREHOUSE_TABLE_QUERY($bot_id:ID!, $text:String) {
	warehouse(bot_id: $bot_id) {
		table_search(text: $text) {
			name
			count
			type
			owner_bot {
				_id
				label
			}
		}
	}
}
`;

export default function WarehouseTableSearch(props) {
	const client=useApolloClient();

	const {bot_id,value,onChange}=props;
	if(!bot_id) return 'bot_id is required';

	return <AsyncSelect cacheOptions defaultOptions={[{value,label:value}]} value={{value,label:value}}
		onChange={o => onChange && onChange(o.value)}
		loadOptions={text => {
			return client.query({
				query: WAREHOUSE_TABLE_QUERY,
				variables: {bot_id,text}
			}).then(r => {
				const tables = r.data.warehouse.table_search;
				return tables.map(table => {
					return {
						value: table.name,
						label: table.name
					};
				});
			});
		}} />;
};
