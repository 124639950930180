import React,{useState} from 'react';
import gql from "graphql-tag";
import FraktureQuery from "../FraktureQuery";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import StubMutation from "../StubMutation";
import { Link,useNavigate} from "react-router-dom";
import {withRouter} from '../../util/history.js';
import dayjs from 'dayjs';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const accountPackagesQuery=gql`query accountPackages($account_id:ID!) {
	account(_id: $account_id) {
		_id
		name
		deployed_packages {
			account_id
			_id
			label
			status
			last_deployed
			need_redeploy
			deployed_dataflows {
				type
				dataflow {
					_id
					label
				}
			}
			deployed_tables {
				bot_id
				table_name
			}
		}
	}
}`;

class DeployedPackageItem extends React.Component {
	renderTables(tables) {
		const{account_id}=this.props;
		if(!tables.length) return 'No Tables';
		return <React.Fragment>
			<h3 style={{marginTop:'5px'}}>Tables</h3>
			{tables.map(({table_name,bot_id}) => {
				return <div key={table_name} >
					<Link to={`/app/${account_id}/warehouse/${bot_id}/${table_name}`} >
						{table_name}
					</Link>
				</div>;
			})}
		</React.Fragment>;
	}
	renderDataflows(dataflows) {
		const{account_id}=this.props;
		if(!dataflows.length) return 'No Dataflows';
		return <React.Fragment>
			<h3 style={{marginTop:'5px'}}>Dataflows</h3>
			{dataflows.map(({dataflow},i) => {
				if(!dataflow) return <div key={i}>Missing Datafow</div>;
				const{_id,label}=dataflow;
				return <div key={_id} >
					<Link to={`/app/${account_id}/dataflow/${_id}/edit`} >
						{label}
					</Link>
				</div>;
			})}
		</React.Fragment>;
	}

	getHref() {
		const{deployed_package}=this.props;
		return `/app/${deployed_package.account_id}/package/${deployed_package._id}`;
	}

	renderDeployStatus(){
		const{deployed_package,deployed_package:{status}}=this.props;

		if(status === 'configuration') {
			return <React.Fragment>Last Deployed: <Link to={this.getHref()}>Configuration Active</Link></React.Fragment>;
		}

		let last_deployed;
		if(deployed_package.last_deployed) last_deployed=dayjs(deployed_package.last_deployed).calendar();
		else last_deployed='N/A';

		return <React.Fragment>Last Deployed: <Link to={this.getHref()}>{last_deployed}</Link></React.Fragment>;
	}

	render() {
		const{deployed_package}=this.props;

		let icon;
		if(!deployed_package.need_redeploy) icon=<i className="zmdi zmdi-check-circle" style={{color:'green'}}/>;
		else icon=<i className="zmdi zmdi-alert-triangle" style={{color:'red'}} />;

		return <Card square={true} style={{margin:'auto', marginTop:'10px', maxWidth:'1000px'}}>
			<CardHeader title={<React.Fragment>
				{icon} {deployed_package.label}
			</React.Fragment>} subheader={this.renderDeployStatus()} />
			<CardContent>
				{this.renderDataflows(deployed_package.deployed_dataflows)}
				{this.renderTables(deployed_package.deployed_tables)}
			</CardContent>
		</Card>;
	}
};

const startPackageConfigureMutation=gql`mutation($package_id:ID!,$account_id:ID!) {
	package_start_configure(package_id: $package_id, account_id: $account_id) {
		_id
	}
}`;
const fullPackageListQuery=gql`{
	packages(limit:1000) {
		page{
			_id
			label
		}
	}
}`;
const NewPackageDialog = withRouter(function({close,/*begin,*/account_id}) {
	const [selected,setSelected]=useState("");
	const navigate=useNavigate();
	return <Dialog open onClose={() => close()}>
		<DialogTitle>Select Package to Deploy</DialogTitle>
		<DialogContent>
			<FraktureQuery query={fullPackageListQuery}>
				{(data) => {
					return <Select value={selected} onChange={e => setSelected(e.target.value)} fullWidth>
						<MenuItem key={-1} value="" disabled>Select Package</MenuItem>
						{data.packages.page.map(x => {
							return <MenuItem key={x._id} value={x._id}>{x.label}</MenuItem>;
						})}
					</Select>;
				}}
			</FraktureQuery>
			<DialogActions>
				<StubMutation mutation={startPackageConfigureMutation} onCompleted={(data) => {
					const deployed_package_id=data.package_start_configure._id;
					navigate(`/app/${account_id}/package/${deployed_package_id}`);
				}}>
					{ (start, {error,loading}) => {
						if(error) return <div>{JSON.stringify(error)}</div>;
						if(loading) return <i className='zmdi zmdi-hc-5x zmdi-spinner zmdi-hc-spin'/>;
						function begin(package_id) { start({variables:{account_id,package_id}}); };
						return <Button disabled={!selected} onClick={() => begin(selected)} variant='contained' color='primary' style={{marginTop:'10px'}}>
							Begin Configuration
						</Button>;
					}}
				</StubMutation>
			</DialogActions>
		</DialogContent>
	</Dialog>;
});

const DeployedPackageListHeader = function (props) {
	const[addNew,setAddNew]=useState(false);
	const{name,deployed_packages,_id:account_id}=props.account;
	let dialog;
	if(addNew) dialog= <NewPackageDialog close={()=>setAddNew(false)} account_id={account_id} />;
	return <React.Fragment>
		<Card square={true} style={{margin:'auto', marginTop:'10px', maxWidth:'1000px'}}>
			<CardHeader title={name}
				action={<Button onClick={()=>setAddNew(true)}><i className="zmdi zmdi-hc-2x zmdi-plus-square"/></Button>}
				subheader={<React.Fragment>Packages [{deployed_packages.length}]</React.Fragment>} />
		</Card>
		{dialog}
	</React.Fragment>;
};

export default class DeployedPackageList extends React.Component {
	render() {
		const{account_id}=this.props.match.params;
		return <FraktureQuery query={accountPackagesQuery} variables={{account_id}}>
			{data => {
				const{deployed_packages}=data.account;
				return <React.Fragment>
					<DeployedPackageListHeader account={data.account}/>
					{deployed_packages.map((x,key) => <DeployedPackageItem key={key} deployed_package={x} account_id={account_id}	/>)}
				</React.Fragment>;
			}}
		</FraktureQuery>;
	}
};
