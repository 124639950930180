import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import gql from "graphql-tag";
import FraktureTable from '../FraktureTable';
import {FraktureSimpleQuery} from "../FraktureQuery.js";
import { useApolloClient } from '@apollo/client';
import {useParams,useNavigate,Link} from 'react-router-dom';

import useNotifiers from '../Notifiers';
import Button from '@mui/material/Button';
import SaveOnBlur from '../inputs/SaveOnBlur';
import './sourcecode.css';
import TestSourceCodes from './format-editor/TestSourceCodes';


import SourceCodeEditor from './FormatEditor';

const FORMAT_QUERY=gql`query formatQuery($account_id: ID!) {
	account(_id: $account_id) {
		_id
		default_warehouse_bot{
      _id
      label
    }
		source_code_config{
			formats{
				_id
				label
				format
				regex
				regex_elements
				match_order
				default_values
			}
		}
	}
}`;

const SOURCE_CODE_UPSERT = gql`
mutation SOURCE_CODE_UPDATE_INDEX($account_id:ID!,$data:JSON!) {
	source_code_format_upsert(account_id:$account_id, data:$data) {
		_id
		match_order
		format
		default_values
	}
}`;

export default function Formats(){
	const {notify,notifyErr} = useNotifiers();
	const client=useApolloClient();
	const {account_id,source_code_id}=useParams();
	const navigate = useNavigate();

	const saveFormat = async (format,default_values) => {
		//if(error||loading) return;
		client.mutate({
			mutation: SOURCE_CODE_UPSERT,
			variables: {
				account_id,
				data:{
					_id: source_code_id,
					format: format,
					default_values: default_values
				}
			},
			refetchQueries: ['FORMAT_QUERY']
		}).then(({data}) => {
			if(!source_code_id) {
				const{_id}=data.source_code_format_upsert;
				navigate(`/app/${account_id}/sourcecode/formats/${_id}`);
			} else notify();
		}).catch(notifyErr);
	};


	return (<FraktureSimpleQuery query={FORMAT_QUERY} variables={{account_id}}>
		{data=>{
			let {formats}=data.account.source_code_config;
			const sortedFormats=JSON.parse(JSON.stringify(formats)).sort((a,b)=>a.match_order<b.match_order?-1:1);
			sortedFormats.forEach(f=>{
				if (f.format){
					f.sample=f.format.replace(/{{(.+?)}}/g,(match,capture)=>{
						return capture.replace(/_/g,"");
					});
				}
			});
			//let {default_warehouse_bot}=data.account;
			if (formats.length===0) return "No formats";

			let editor = <Card>
				<CardContent>
					<SourceCodeEditor key={source_code_id} source_codes={formats} {...{account_id,source_code_id}} />
				</CardContent>
				<CardActions>
					<Button color='primary' onClick={saveFormat}>Save</Button>
				</CardActions>
			</Card>;

			return <Paper><Grid container spacing={8}>
				<Grid item xs={12}>
					<TestSourceCodes/>
				</Grid>
				<Grid item xs={12}>
					<Card><CardContent>
						<FraktureTable {...{
							sort:"match_order",
							// includeEditing:true,
							columns:[
								//{field:"_id",title:"ID"},
								{field:"match_order",title:"Match Order", render: x => {
									const {_id,match_order}=x.row;
									return <SaveOnBlur value={match_order} onChange={value => {
										client.mutate({
											mutation: SOURCE_CODE_UPSERT,
											variables: {
												account_id,
												data:{
													_id,
													match_order: value
												}
											}
										}).then(notify,notifyErr);
									}} />;
								}},
								{field:"format",title:"Format", render: x => {
									const {_id,format}=x.row;
									return <Link to={`/app/${account_id}/sourcecode/formats/${_id}`}>{format}</Link>;
								}},
								{title:'Default Values',render:({row}) => {
									return JSON.stringify(row.default_values);
								}},
								{field:"regex",title:"Regex"},
								{field:"sample",title:"Sample"},
								{title:"Elements",render:({row})=>(row.regex_elements||[]).join(",")},
							],
							rows:sortedFormats}} />
					</CardContent></Card>
				</Grid>
				<Grid item xs={12}>{editor}</Grid>
			</Grid></Paper>;
		}}
	</FraktureSimpleQuery>
	);
};
