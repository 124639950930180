import React from 'react';
import {useParams} from 'react-router-dom';
import {CandidatePicker} from './settings/ReportCreate';
import {useNavigate} from 'react-router-dom';

import {ReportDisplay} from './display/index.js';
import {useReportFolders} from './ReportFolders';
//import {ReportFolderSelector} from './ReportFolders';
//import {Folder} from './ReportFolders';
import {ReportSummaryCard} from './ReportSummaryCard';

import Divider from '@mui/material/Divider';
import {useAccountId} from '../account/AccountInfo';
import {Header} from '../components/Header';
import './display/styles.scss';

function ReportHome(){
	const {report_folders,error,loading}=useReportFolders();
	const navigate=useNavigate();
	const account_id = useAccountId();
	if (error) return error;
	if (loading) return loading;
	return <div className="app-main-content-wrapper">
		<Header
			primary={[
				<h2 key="title">Report Gallery</h2>
			]}
		/>
		<div id="report-gallery" className="gallery p-3">
			{report_folders.filter(d=>d.reports?.length).map((folder,i)=>{
				return <React.Fragment key={i}>
					<Divider textAlign="left">{folder.label}</Divider>
					<div className="gallery-row-wrapper">
						{(folder.reports||[]).map((report,j)=>{
							let folderPath=folder.short|| folder._id;
							return <div key={j} className="gallery-item">
								<ReportSummaryCard
									category={folder.slug}
									onClick={()=>navigate('/app/'+account_id+'/report/'+folderPath+'/'+report._id)}
									report={report}
									folder={folder}/></div>;
						})}
					</div>
				</React.Fragment>;
			})}
			{/*<Grid item xs={12} md={4}>
						<AdminOnly key="layout" ><ReportCard title={"Deploy Report"} contents={<PickAndAddReport/>} icon={"zmdi-folder"}/></AdminOnly>
					</Grid>*/}
		</div>
	</div>;
};

function DemoNotice(props){
	const {slug}=props;

	return <div className="report-demo-notice alert alert-danger">
		<div><b>This report is using demo data.</b></div>
		<CandidatePicker slug={slug} redirect={true}/>
	</div>;
}
function SlugReport(props){
	let report_id=props.report_id;
	return <>
		<DemoNotice slug={report_id}/>
		<ReportDisplay header_variant="small" report_id={report_id} editable={true} report_folder_short={"slug"}/>
	</>;
}


export default function ReportMain(props){
	let {report_id,report_folder_short}=useParams();

	let newProps=Object.assign({},props,{report_id});
	return <div className='app-main-content-ltr'>
		{/*<div id="sidebar2">
			<ReportFolderSelector/>
			<AdminOnly><ReportTheme/></AdminOnly>
		</div>
		*/}
		<div id="console-report-wrapper">
			{report_folder_short==='slug'?<SlugReport {...newProps}/>:
				report_id?<div className="console-report-col"><ReportDisplay header_variant="small" report_id={report_id} editable={true}/></div>:(report_folder_short?
					<ReportHome {...props}/>
					:<ReportHome {...props}/>)}
			{/*<Folder report_folder_short={report_folder_short}/>*/}
		</div>
	</div>;
}
