import React,{useState,useEffect} from 'react';

import {Route, Routes,BrowserRouter as Router,Navigate} from 'react-router-dom';
import history from "./util/history.js";
import {HelmetProvider} from 'react-helmet-async';

import { ApolloProvider as ApolloHookProvider } from '@apollo/client';

import DataLayer from './client-library';
import {getToken,currentUser,logout} from './auth';
// import Login,{VerifyEmail} from './login.js';
import {useLogin} from './login';
import {UserContextProvider} from './AdminCheck';
import Test from './app/Test';

/* Theme imports */
import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider,
	responsiveFontSizes
} from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import Invite from './Invite';

import './styles/bootstrap.scss';
import './styles/app.scss';
//import theme from './app/themes/defaultTheme';

//import MainApp from './app/index.js';
import {DashboardV2 as MainApp} from './app/index.js';
import SetOauth from './app/bots/SetOauth';
import {ReportDisplay} from './app/report/display';


export const themeOptions= {
	palette: {
		type: 'light',
		primary: {
			main: '#3f51b5',
		},
		secondary: {
			main: '#f50057',
		},
	},
	typography: {
		fontSize: 11,
		fontFamily:"Inter var, sans-serif"
	},
	spacing: 6,
	shape: {
		borderRadius: 4,
	},
};

function AllReport(){
	return <div className="app-main" id="public-report-wrapper">
		<div className="app-container">
			<div className="app-main-content-wrapper">
				<div className="report-wrapper">
					<ReportDisplay/>
				</div></div></div></div>;
}


function Okay(){return "ok";}

function CurrentUser(){
	let {uid,displayName,email}=currentUser()||{};
	return JSON.stringify({uid,displayName,email},null,4);
}
<Route path="/user" element={<CurrentUser/>} />;

function ApolloWrapper() {
	const [client,setClient]=useState();
	const [loadingClient,setLoadingClient]=useState(false);

	const [{token,authenticated}, loginElement]=useLogin();

	useEffect(() => {
		if(client) {
			if(loadingClient) setLoadingClient(false);
			return;
		}

		if(loadingClient) {
			return;
		}

		if(token && authenticated === 'yes') {
			setLoadingClient(true);

			const uri=window.fraktureUiInfo.dataLayerEndpoint;
			const subscription_uri=window.fraktureUiInfo.subscriptionEndpoint;
			//Temporarily disable subscriptions until we can figure out the auth
			// const subscription_uri=null;
			const start = new Date();
			DataLayer({uri,getToken, subscription_uri}, (e,c) => {
				if(e) throw new Error('failed to get client:'+e);
				const end = new Date();
				console.log('online:',end-start);
				setClient(c);
			});
		}
	}, [client,token,loadingClient,authenticated]);

	if(loginElement){
		console.log("loginElement retrieved",{token,authenticated});
		return loginElement;
	}

	if(authenticated === 'yes') {
		if(!client) return ""; //loading, but don't skip around
		const theme = responsiveFontSizes(createTheme(themeOptions));
		//let applyTheme = createTheme(adaptV4Theme(theme));
		//applyTheme=(applyTheme);
		let element=<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={3}>
					<MainApp/>
				</SnackbarProvider>
			</ThemeProvider>
		</StyledEngineProvider>;

		return (
			<ApolloHookProvider client={client}>
				<UserContextProvider>
					<Routes>
						<Route path='*' element={<Navigate to='/app/'/>}/>
						<Route path='/ok' element={<Okay/>} />
						<Route path='/user' element={<CurrentUser/>} />
						<Route path="/test" element={<Test/>}></Route>
						<Route path='/app/bot/oauth' element={<SetOauth/>} />
						<Route path='/app/*' element={element}/>
					</Routes>
				</UserContextProvider>
			</ApolloHookProvider>
		);
	}else{
		console.log("Not authenticated:",{authenticated});
	}

	return <div>Loading authentication ....</div>;
}


export default function AuthWrapper(){
	return <HelmetProvider><Router history={history}>
		<Routes>
			<Route path='/invite/:invite_token/*' element={<Invite/>} />
			<Route path="/report/:report_folder_short/:report_id" element={<AllReport/>} />
			<Route path="/report/:report_folder_short" element={<AllReport/>} />
			<Route path="/report">Folder ID is requird</Route>
			<Route path="*" element={<ApolloWrapper/>} />
		</Routes>
	</Router>
	</HelmetProvider>;
}
