import React,{useRef,useState,useEffect} from 'react';
import gql from "graphql-tag";
import DataGrid from '../DataGrid';
import {useQuery,useApolloClient} from '@apollo/client';
import Button from '@mui/material/Button';
import useNotifiers from '../Notifiers';
import {WAREHOUSE_QUERY} from './WarehouseQuery';
import ReactPivot from 'react-pivot-webpack';

const DATA_GRID_UPDATE=gql`
mutation DATA_GRID_UPDATE(
	$warehouse_bot_id:ID!,
	$update:DataGridUpdate!
) {
	data_grid_batch_update(
		bot_id:$warehouse_bot_id,
		update:$update
	) {
		uuid
	}
}
`;
const DATA_GRID_REMOVE=gql`
mutation DATA_GRID_REMOVE(
	$warehouse_bot_id:ID!,
	$update:DataGridRemove!
) {
	data_grid_batch_remove(
		bot_id:$warehouse_bot_id,
		update:$update
	) {
		uuid
	}
}
`;


const DATA_GRID_INSERT=gql`
mutation DATA_GRID_INSERT(
	$warehouse_bot_id:ID!,
	$update:DataGridUpdate!
) {
	data_grid_batch_insert(
		bot_id:$warehouse_bot_id,
		update:$update
	) {
		uuid
	}
}
`;

function InsertButton(props){
	const {table,warehouse_bot_id,fields=[]}=props;
	const client=useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	let defaultFunction=props.insertDefaultFunction || (()=>{
		/* Fill with dummy blank data*/
		let d={};
		fields.forEach(f=>{
			if (f==='id') return;
			if (f.slice(-3)==="_id") return ;
			d[f]="";
		});
	});

	return <Button key="add" style={{whiteSpace:'nowrap',padding:'4px'}} onClick={(e) => {
		let defaultInsertObject=defaultFunction();
		let update={
			table,
			data:[defaultInsertObject]
		};
		client.mutate({
			mutation: DATA_GRID_INSERT,
			refetchQueries:["WAREHOUSE_QUERY"],
			variables: {
				warehouse_bot_id,
				update
			}
		}).then(e=>{
			notify("Successfully inserted records update");
		},notifyErr);
	}}>Add Row</Button>;
}

function DataGridWarehouseTable(props) {
	let {warehouse_bot_id,table,join,joins,conditions=[],order_by,offset=0,limit=300,
		fields,
		selectedFields,
		setSelectedFields,
		height,menu,editable=true,primary_field}=props;

	if (!limit)limit=1000;
	const client=useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	function isNumber(value){return typeof value === 'number' && isFinite(value);}

	let graphqlFields=null;
	if (fields){
		if (selectedFields?.length>0){
			//we always need to request alwaysHidden fields in case they're used for calculations
			graphqlFields=fields.filter(d=>d.alwaysHidden || selectedFields.find(f=>d.alias===f));
		}else{
			graphqlFields=fields;
		}
		//transform to the fields we need
		graphqlFields=graphqlFields.map(d=>({table:d.table,field:d.field,fql:d.fql,alias:d.alias}))
			.filter(d=>d.field || d.fql);//If there isn't a field or fql, don't request from source but filter this out later, because there may be hidden fields
	}

	const { loading, error, data }=useQuery(
		WAREHOUSE_QUERY,
		{fetchPolicy: "network-only",
			variables: {
				warehouse_bot_id,
				table,
				join,
				joins,
				conditions: conditions.map(fql => {
					if(typeof fql == 'string') return {fql};
					return fql;
				}),
				order_by,
				offset,
				limit,
				fields:graphqlFields
			}});
	if (loading) return "Loading records ...";
	if (error){
		console.error(error);
		return "Error loading";
	}
	let gridData=data?.warehouse?.table?.data_grid_batch;
	if (!gridData){
		throw new Error("No data returned from data grid warehouse query");
	}
	window.gridData = gridData;
	if(!gridData.data) return "No records";
	if (editable && !gridData.primary_field) return "editable requires a primary_field";
	if (typeof gridData.data=='string') gridData.data=JSON.parse(gridData.data);

	const hasValues = (fields||[]).filter(x => x.auto_show_if_present)
		.filter(x => {
			if(!x.field&&!x.value) return false;
			const internal = (x.table||table)+'-'+x.field;
			// console.log('checking:',x,internal);
			const match = gridData.data.find(y => {
				return (x.field&&y[internal]) || (x.value&&x.value(y));
			});
			// if(match) console.log('found:',match);
			return match;
		});

	const withValue = {};
	hasValues.forEach(x => withValue[x]=1);

	let columns;

	if (fields){
		columns=fields.map((_f,i)=>{
			let f=Object.assign({},_f);
			let {table:t=table,field,alias}=f;
			if (!t) t=table;
			if (alias){
				f.name=alias;
			}else if (field){
				f.name=t+"-"+field;
			}else{
				f.name="calc_"+i;
			}
			if (!f.type && gridData.data && gridData.data[0]){
				f.type=isNumber(gridData.data[0][f.name])?"number":"";
			}

			if(f.auto_show_if_present&&hasValues.find(y=>y===_f)) {
				//f.hidden = false;
			}
			//console.log(_f,"->",f);

			return f;
		});
	} else if (gridData.data && gridData.data[0]){
		let dataToAnalyze=gridData.data.slice(0,100).reduce((x,y)=>{
			Object.keys(y).forEach(k=>x[k]=x[k]||y[k]);
			return x;
		},{});
		columns = Object.keys(dataToAnalyze).map(name => {
			let type=isNumber(dataToAnalyze[name])?"number":"";
			return {title:name.split("-")[1],name, field:name, table,type};
		});
	}

	let onUpdate=null;
	let onRemove=null;
	if (editable){
		onUpdate=raw => {
			try{
				const{type} = raw;
				primary_field=primary_field || gridData.primary_field;

				let tables={};
				if(type === 'update') {
					const{changes}=raw;
					if (!changes || changes.length===0){
						console.log("No updates to make");
						return;
					}
					changes.forEach(x=>{
						const{data:original,change}=x;
						if (!original) throw new Error("data is now required for an update");
						if (!primary_field) throw new Error("No primary field");
						let primaryObject=primary_field.split(",").reduce((a,field)=>{a[field]=original[table+'-'+field];return a;},{});
						let invalidValues=Object.entries(primaryObject).filter((a)=>a[1]===undefined);
						if (invalidValues.length>0){
							console.error("Invalid submission, primary keys are required",invalidValues);
							throw new Error("Error submitting request, there are no primary keys in the request");
						}
						let valueObject={};
						Object.keys(change).forEach(f=>{
							if (primaryObject[f]) throw new Error("Cannot change primary key field");
							let parts=f.split("-");
							let t=table; if (parts.length>1)t=parts[0];
							valueObject[parts.pop()]=change[f];
							tables[t]=(tables[t] || []).concat(Object.assign(valueObject,primaryObject));
						});
					});
				}else if(type === 'remove') {
					const{rows}=raw;
					if (!rows){
						throw new Error("rows is required to remove");
					}
					tables[table]=[];

					rows.forEach(row=>{
						let primaryObject=primary_field.split(",").reduce((a,field)=>{a[field]=row[table+'-'+field];return a;},{});
						tables[table].push(primaryObject);
					});
				}else{
					console.log("Not an update, returning");
					return;
				}
				if (Object.keys(tables).length>1){
					//assuume we're going to update the first one
					//throw new Error("Currently cannot update multiple tables -- tables="+Object.keys(tables).join());
				}
				let updateTable=Object.keys(tables)[0];
				let data=tables[updateTable];
				if (data.length===0){
					return notify("No records to update");
				}

				let update={
					table:updateTable,
					data
				};
				client.mutate({
					mutation: type==='remove'?DATA_GRID_REMOVE:DATA_GRID_UPDATE,
					variables: {
						warehouse_bot_id,
						update
					}
				}).then(e=>{
					notify("Successfully "+type==='remove'?'removed':'updated');
				},notifyErr);
			}catch(e){
				if (e) notifyErr(e);
			}
		};
		onRemove=onUpdate;
	}else{
		columns.forEach(c=>c.editable=false);
	}
	const buttons=[];
	if (props.includeInsert){
		buttons.push(<InsertButton key="insert" table={table} warehouse_bot_id={warehouse_bot_id} fields={fields && fields.map(d=>d.field)} insertDefaultFunction={props.insertDefaultFunction}/>);
	}
	return <>
		<DataGrid
			data={gridData.data}
			columns={columns}
			selectedFields={selectedFields}
			setSelectedFields={setSelectedFields}
			width={props.width}
			height={height+"px"}
			menu={menu}
			autoFocus
			popover_items={props.popover_items}
			buttons={buttons}
			onUpdate={onUpdate}
			onRemove={onRemove}
			onClick={props.onClick}
		/>
	</>;
}

export default function HeightProvider(props){
	const refContainer = useRef();
	const [dimensions, setDimensions] =useState({ width: 0, height: 0 });
	useEffect(() => {
		if (refContainer.current) {
			setDimensions({
				width: refContainer.current.offsetWidth,
				height: refContainer.current.offsetHeight,
			});
		}
	}, []);

	let {height}=dimensions;
	if (!height){
		return <div ref={refContainer} className="warehouse-data-grid">Resizing...</div>;
	}
	let p=Object.assign({},props,{height:height-50}); //top and bottom for header/footer

	if(props.data_grid_height) p.height = props.data_grid_height-50;

	return <div ref={refContainer} className="warehouse-data-grid"><DataGridWarehouseTable {...p}/></div>;
}
