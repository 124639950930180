import React from 'react';

import gql from "graphql-tag";
import {useQuery} from '@apollo/client';
import {useMatch} from 'react-router-dom';

import {Error} from '../Error';

const ACCOUNT_QUERY = gql(`query ACCOUNT_QUERY($account_id: ID!) {
		account(_id: $account_id) {
			_id
			name
			type
			fiscal_year
			settings
			disabled
			parents {
				_id
				name
			}
			children{
				_id
				name
				type
				disabled
			}
		}
	}
`);


const ACCOUNT_DETAILS_QUERY = gql(`query ACCOUNT_DETAILS_QUERY($account_id: ID!) {
		account(_id: $account_id) {
			_id
			name
			type
			fiscal_year
			disabled
			default_warehouse_bot{
				_id
				global_table_prefix
			}
			parents {
				_id
				name
				type
			}
			children{
				_id
				name
				type
				disabled
				children{
					_id
					name
					disabled
				}
			}
			bots {
				_id
				label
				nickname
	      		path
	      		warehouse_table_prefix
			}
			uninherited_settings
			settings
		}
	}
`);

const ALL_ACCOUNTS_QUERY=gql(`query ALL_ACCOUNTS_QUERY($cursor: Cursor) {
	page: accounts(cursor: $cursor, disabled:false,limit: 2000) {
		cursor
		page {
			_id
			name
			type
			fiscal_year
			disabled
			parent_ids
			parents {
				_id
				name
			}
			children{
				_id
				name
				type
				disabled
			}
		}
	}
}`);


export function useAccountTypes(){
	return ["","Candidate","PAC","Public Media","Nonprofit","Company","Agency","Constellation","Nonprofit Constellation",
		"State Party",
		"State Party Affiliate"
	]
		.map(d=>({
			name:d,
			value:d.toLowerCase().replace(' ','_')
		}));
}

//let allAccounts={};

export function useAllAccounts(){
	//console.log("Calling useAllAccounts");
	const {data,loading,error}=useQuery(ALL_ACCOUNTS_QUERY);
	if (error) return {error:true};
	if (loading) return {loading:true};
	let accounts = (data||{}).page.page;
	//accounts.forEach(x=>{allAccounts[x._id]=x;});
	//console.log("Loaded "+Object.keys(allAccounts).length+" accounts");
	return {accounts};
}


export function useAccountId(props={}) {
	if(props.account_id) {return props.account_id;}
	const match=useMatch({
		path: '/app/:account_id/*',
		strict: false,
		sensitive: false,
		exact:false
	});
	if (!match || !match.params){
		//console.error("UseFrakture error: Could not find route");
		return null;
	}
	const{account_id}=match.params;
	if (account_id.indexOf('multiaccount')===0) return null;
	if(!account_id) throw new Error('account_id param not specified and not given as prop');
	return account_id;
}

export function useAccountInfo(props){
	const account_id=useAccountId(props);

	let cachedAccount=null;//allAccounts[account_id];
	let skip=account_id==='multiaccount' || account_id==="users" || !account_id || !!cachedAccount;

	if (!skip){
		//console.error("Looking up",account_id,"Could not find in ",Object.keys(allAccounts));
	}

	const {data,loading,error}=useQuery(ACCOUNT_QUERY,{variables:{account_id},skip});
	if (error) return {error:true};
	if (loading) return {loading:true};
	if (cachedAccount) return {account:cachedAccount};

	let account = (data||{}).account;
	return {account};
}
export function useAccountDetails(props){
	const account_id=useAccountId(props);
	const {data,loading,error}=useQuery(ACCOUNT_DETAILS_QUERY,{variables:{account_id},skip: account_id==='multiaccount' || !account_id});
	if (error) return {error:true};
	if (loading) return {loading:true};
	let account = (data||{}).account;
	return {account};
}

export function AccountInfoWrapper({children}){
	const account_id=useAccountId();
	const {data,loading,error}=useQuery(ACCOUNT_QUERY,{variables:{account_id},skip: account_id==='multiaccount' || !account_id});
	if (error) return <Error error={error}/>;
	if (loading) return "Loading...";
	if (typeof children!=='function') return "Error with AccountInfoWrapper children, should be a function";
	return children(data);
	/*
	return React.Children.map(children,child=>{
		if (React.isValidElement(child)) {
			return "Is valid element";
			return React.cloneElement(child, { account:data.account });
		}
		if (typeof child==='function'){
			return child(data);
		}
		return "NOt a valid element";
		return child;
	});
	*/
}
