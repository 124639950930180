import React,{useEffect} from 'react';
import gql from "graphql-tag";

import Button from '@mui/material/Button';
import Input from '@mui/material/Input';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FraktureQuery from '../FraktureQuery';
import {AdminOnly} from '../../AdminCheck';

const botUriQuery = gql`query botData($bot_id:ID!) {
	bot(_id:$bot_id) {
		_id
		warehouse {
			_id
			bot_token
			upload_uri
		}
	}
}`;


function FileUploadDialog(props) {
	const[file,setFile]=React.useState(null);
	const[uploadStatus,setUploadStatus]=React.useState(null);

	const {onUpload} = props;
	useEffect(() => {
		if(uploadStatus && onUpload) onUpload(uploadStatus);
	}, [uploadStatus,onUpload]);

	if(!props.open) return null;
	console.log(file);
	return <Dialog open={true} close={() => props.close()}>
		<DialogTitle>Upload File</DialogTitle>
		<FraktureQuery query={botUriQuery} variables={props}>
			{data => {
				const upload_uri = data.bot.warehouse.upload_uri;
				const bot_token = data.bot.warehouse.bot_token;
				if(!upload_uri) return 'Missing upload_uri';

				if(uploadStatus) {
					return <React.Fragment>
						<DialogContent>
							Results {JSON.stringify(uploadStatus)}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => props.close()}>Close</Button>
						</DialogActions>
					</React.Fragment>;
				}

				return <React.Fragment>
					<DialogContent>
						<Input type='file' name='upload_file' onChange={e=>setFile(e.target.files[0])}/>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.close()}>Close</Button>
						<Button color='primary' onClick={() => {
							const formData = new FormData();
							formData.append('upload_file',file);
							fetch(upload_uri, {
								method: 'post',
								headers: {
									'Authorization': 'BEARER '+bot_token
								},
								body: formData
							}).then(x => {
								console.log(x);
								return x.json();
							}).then(x => {
								setUploadStatus(x);
							}).catch(e => {
								console.error("error:", e);
								setUploadStatus(e);
							});
						}}>Upload</Button>
					</DialogActions>
				</React.Fragment>;
			}}
		</FraktureQuery>
	</Dialog>;
};

export default function FileUploadDialogButton(props) {
	const[dialogOpen,setDialogOpen]=React.useState(false);
	return <AdminOnly>
		<React.Fragment>
			<Button onClick={() => setDialogOpen(true)}>Upload File</Button>
			<FileUploadDialog {...props} bot_id={props.bot_id || props.warehouse_bot_id} open={dialogOpen} close={() => setDialogOpen(false)}/>
		</React.Fragment>
	</AdminOnly>;
};
