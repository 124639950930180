// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import queryString from 'query-string';
import FilterListIcon from '@mui/icons-material/FilterList';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
/*import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

*/
import Button from '@mui/material/Button';
//import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

//import IconButton from '@mui/material/IconButton';
//import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function SourceCodePicker(props){
	const {source_code_config}=props;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selected, setSelected] = React.useState({});
	const [label,setLabel]=React.useState("Filter");

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const navigate=useNavigate();
	const location=useLocation();
	let qs=queryString.parse(location.search || "");

	let {elements,groups}=source_code_config;

	/*
	let params = queryString.parse(location.hash);
	function setParam(name,val){
		params[name]=val;
		document.location.hash=queryString.stringify(params);
	}
	*/

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		//Object.keys(selected).forEach(s=>{setParam(s.name,s.value);});
		setAnchorEl(null);
	};

	const onApply=()=>{
		let newQS=JSON.parse(JSON.stringify(qs));
		for (let k in newQS){
			if (k.indexOf("f.0.")===0) delete newQS[k];
		}
		Object.keys(selected).forEach(s=>{
			newQS["f.0."+s]=Object.keys(selected[s]).join();
		});
		//alert(JSON.stringify(qs));
		navigate(Object.assign({},location,{search:queryString.stringify(newQS)}));
		handleClose();
	};


	function setGroup(group_id){
		let newDefaults={};
		if (group_id!=="all"){
			let group=groups.find(d=>d._id===group_id);
			elements.forEach(el=>{
				let name=el.name;
				Object.keys(el.values).forEach(value=>{
					if (group && group.query && group.query[name]){
						//so, the $in may have been parsed by mongo, and could be "＄in" (unicode)
						let array=group.query[name].$in || group.query[name]["＄in"];
						if (array.indexOf(value)>=0){
							newDefaults[name]=newDefaults[name]||{};
							newDefaults[name][value]=true;
						}else{
							delete (newDefaults[name]||{})[value];
						}
					}
				});
			});
		}

		setSelected(newDefaults);
	};
	useEffect(() => {
		let newSelected={};
		Object.entries(qs).forEach(([k,v])=>{
			if (k.indexOf('f.0.')!==0) return;
			let name=k.split(".").pop();
			newSelected[name]=newSelected[name]||{};
			v.split(",").filter(Boolean).forEach(val=>{
				newSelected[name][val]=true;
			});
		});
		setSelected(newSelected);
		if (Object.keys(newSelected).length>0){
			let l=[];
			Object.keys(newSelected).forEach(name=>{
				let el=elements.find(d=>d.name===name);
				if (!el) return;
				let vals=Object.keys(newSelected[name]).map(v=>el.values[v]||v);
				if (vals.length>0) l.push(el.label+":"+vals.join(","));
			});
			setLabel(l.join("; "));
		}
	},[location.search]);

	const displayGroups=[{
		_id:"",
		label:"Quick Pick by Group",
	},
	{
		_id:"all",
		label:"All Transactions",
	}
	].concat(groups);

	return <div>
		<Button onClick={handleClick} style={{padding:"4px",textTransform:"initial"}}>{label}&nbsp;<FilterListIcon/></Button>
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
			<Card className="p-2">
				<CardHeader title="Filter Transactions"/>
				<CardContent className="p-0">
					{displayGroups && displayGroups.length>2 &&
					<React.Fragment><Select style={{fontSize:"1em"}}
						displayEmpty={true}
						value="">{
							displayGroups.map((x,i)=><MenuItem key={i} value={x._id} onClick={e=>setGroup(x._id)}>{x.label}</MenuItem>)}
					</Select><Divider/>
					</React.Fragment>
					}
					<div style={{maxHeight:"600px",overflow:"auto"}}>
						<SourceCodePickerElements {...props} selected={selected} setSelected={setSelected}/>
					</div>
				</CardContent>
				<CardActions>
					<Button style={{marginLeft: 'auto'}}variant="contained" color="primary" onClick={onApply}>Apply</Button>
				</CardActions>
			</Card>
		</Popover>
	</div>;
}


function SourceCodePickerElements({mode="hash",source_code_config,selected,setSelected}){
	let {elements}=source_code_config;

	function toggle(name,value,checked){
		let newSelected=JSON.parse(JSON.stringify(selected));
		if (checked){
			newSelected[name]=selected[name]||{};
			newSelected[name][value]=true;
		}else{
			delete (newSelected[name]||{})[value];
		}
		setSelected(newSelected);
	}
	return elements.filter(el=>Object.keys(el.values).length>0).map((el,j)=><Accordion elevation={0} key={j}>
		<AccordionSummary
			expandIcon={<ExpandMoreIcon />}
		>{el.label || el.name}</AccordionSummary>
		<AccordionDetails>
			<FormGroup>
				{Object.keys(el.values).map((key,i)=><FormControlLabel
					key={i}
					control={<Checkbox checked={!!(selected[el.name]||{})[key]} value={key} onChange={e=>toggle(el.name,key,e.target.checked)}/>}
					label={el.values[key]||key}
				/>)}
			</FormGroup>
		</AccordionDetails>
	</Accordion>);
}
