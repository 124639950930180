import React from 'react';
import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {DICTIONARY_BOT_QUERY,useAccountWarehouseInfo} from '../warehouse/account-warehouse-info.js';
import {useAccountId} from '../account/AccountInfo';
import FraktureDataTable from '../warehouse/FraktureDataTable';

function MessageSourceCode(){
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return error;
	let {global_table_prefix,warehouse_bot_id}=data.default_warehouse_bot;

	let table=global_table_prefix+"message_source_code";
	let join=global_table_prefix+"global_message";
	let joins=[
		{target:join,alias:join,match_fql:table+".message_id="+join+".message_id"}
	];
	let fields=[
		{table:join,field:"bot_id",editable:false,title:"Bot",width:140,cellBackgroundColor:"#FCFCFC"},
		{table:join,field:"submodule",editable:false,title:"Submodule",width:80,cellBackgroundColor:"#FCFCFC"},
		{table:join,field:"message_id",editable:false,title:"Id",width:120,cellBackgroundColor:"#FCFCFC"},
		{table:join,field:"remote_id",editable:false,title:"Remote Id",width:200,cellBackgroundColor:"#FCFCFC",hidden:true},
		{table:join,field:"publish_date",editable:false,title:"Publish Date",cellBackgroundColor:"#FCFCFC",width:120,type:"date"},
		{table:join,field:"label",editable:false,title:"Label",cellBackgroundColor:"#FCFCFC"},
		{ table,title:"Source Code",
			field:"source_code",
			width:700
		},
		{	table,
			title:"Code Type",
			field:"type",
			width:100
		},
		{table:join,field:"primary_source_code",editable:false,title:"Primary Message Source",cellBackgroundColor:"#FCFCFC",hidden:true},
	];

	return <Grid container className="h-100">
		<Grid item><FraktureDataTable
			table={table}
			joins={joins}
			warehouse_bot_id={warehouse_bot_id}
			order_by={[{table:global_table_prefix+"global_message",
				field:"publish_date",order_by_direction:"DESC"}]}
			limit={10000}
			fields={fields}
		/></Grid>
	</Grid>;
}

export default function Wrapper() {
	let {account_id}=useParams();
	const {data}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (!data) return "Loading data...";
	const {account:{default_warehouse_bot:{_id:warehouse_bot_id,global_table_prefix}}}=data;

	let _props={global_table_prefix,warehouse_bot_id};
	return <MessageSourceCode {..._props}/>;
};
