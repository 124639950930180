import React from 'react';
import {useUserContext} from '../../AdminCheck';
import {SidebarMenuItem}from './Menu';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';


export default function AdminLinks(){
	const{is_frakture_admin}=useUserContext();
	if(!is_frakture_admin) return null;

	return <List subheader={<ListSubheader>Frakture Admin</ListSubheader>}>
		<SidebarMenuItem label="Users" path={`/app/users`}/>
		<SidebarMenuItem label="All Jobs" path={`/app/frakture_master/multiaccount/job`}/>
		<SidebarMenuItem label="Error Jobs" path={`/app/frakture_master/multiaccount/job?status=error`}/>
		<SidebarMenuItem label="System SMS & Bots" path={`/app/system/bot`}/>
	</List>;
}
