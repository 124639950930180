import React from 'react';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.scss';
import dayjs from 'dayjs';
import {useAccountId} from '../../account/AccountInfo';

import {useParams} from 'react-router-dom';
import {useLogin} from '../../../login';

import {useNavigate,useLocation} from 'react-router-dom';
import {Responsive,WidthProvider} from 'react-grid-layout';
import ReportHeader from './ReportHeader';

import Template1 from './templates/Template1.js';
import CompetitiveSpend from './templates/CompetitiveSpend.js';
import FilterDrawer from './FilterDrawer';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Slide';

import {useFetch} from '../../../useScript.js';

import queryString from 'query-string';

import { SnackbarProvider } from 'notistack';

import ComponentWrapper from './ComponentWrapper';

const ResponsiveGridLayout = WidthProvider(Responsive);

export function useUrlParamFilters() {
	const navigate=useNavigate();
	const location=useLocation();

	const rawQS=queryString.parse(location.search);
	const filters = {};
	Object.entries(rawQS)
		.filter(([k]) => k.indexOf('f.')===0)
		.forEach(([k,v]) => filters[k.split('f.')[1]]=v);
	return [filters, (filter,value) => {
		const realTimeQS = queryString.parse(location.search);
		realTimeQS['f.'+filter]=value||'';
		location.search = queryString.stringify(realTimeQS);
		setTimeout(()=>navigate(location),0);
	}];
}

function ReportFooter(props){
	const {report}=props;
	return <div className="frakture-report-footer">
		<div>Data by <a target="_blank" href="https://www.frakture.com/">Frakture</a></div>
		{report.last_refresh && <div className="report-refresh">Last refreshed: {dayjs(report.last_refresh).calendar()}</div>}
	</div>;
}

function useReportRequest(props){
	const location=useLocation();
	const account_id=useAccountId();
	let {data=true,report_folder_short,report_id,token,skip,authenticated}=props;
	let short=props.report_folder_short;
	short=short || report_folder_short;
	if (!report_id || report_id==='undefined') throw new Error("No report_id");
	let headers={};
	if (authenticated==='yes') headers={Authorization:"BEARER "+token};

	//eslint-disable-next-line
	let domain=process.env.REACT_APP_REPORT_ENDPOINT;

	if (window.location.hostname.indexOf("frakture.com")>=0){
		//domain=domain.replace(".frakture.app",".frakture.com");//if we're on .com, just use .com, don't go to .app
	}
	let meta="";
	if (!data) meta='/meta';
	let _url=domain;
	if (short.length<36 && account_id){
		_url+='/'+account_id;
	}

	_url+='/'+short+"/"+report_id+meta+(location.search);
	let url=null;
	if (!skip) url=_url+(_url.indexOf('?')>=0?"&":"?")+"authed="+(authenticated==="yes"?"1":"0");

	//console.log("Calling useFetch:",{skip,url,headers});
	return useFetch(url,{headers,skip});
}

function ReportBody(props){
	const {report,editing,qs,filterOpen}=props;
	const {components,template}=report;
	let componentObjects={};
	Object.keys(components).forEach(k=>{
		let c=components[k];
		if (!c) return;
		if (typeof c=='object'){
			c=<ComponentWrapper name={"no name"} component={c} report={report} md={c.md} editing={editing?true:undefined} qs={qs}/>;
		}
		componentObjects[k]=c;
	});
	return <>
		<div className="report-center-wrapper">
			{template==='CompetitiveSpend'?
				<CompetitiveSpend components={componentObjects} label={report.label} logo={report.logo}/>
				:<Template1 components={componentObjects} label={report.label} logo={report.logo}/>
			}
		</div>
		{/*
		<Slide direction="left" in={filterOpen} mountOnEnter unmountOnExit>
			<Paper className="report-filter-drawer">
				<FilterDrawer filters={report.filters}/>
			</Paper>
		</Slide>
		*/}
	</>;
};


function Report(props){
	//console.log("Start Report...");
	let {editing=false,onLayoutChange,header_variant="full"}=props;
	let [filterOpen,setFilterOpen]=React.useState(false);
	let {report_folder_short,report_id}=useParams();
	const [{token,authenticated,loading:loginLoading}, loginElement]=useLogin();
	let skip=false;
	if (loginLoading || authenticated==='unknown') skip=true;

	let {response,error:e,progress,loading}=useReportRequest({report_folder_short,report_id,token,skip,authenticated});

	if (loginLoading) return "Loading login";
	if (loading) return "Loading Report";
	/*
	This person could be anonymous, so don't sweat a user error
	if (loginError){
		console.error("Error loading login:",loginError);
		return "Error loading login";
	}
	*/

	//let [reportListOpen,setReportListOpen]=React.useState(true);
	function toggleFilter(){
		setFilterOpen(!filterOpen);
	}

	//console.log("Report state:",{e,progress,token,authenticated,response});

	if (e && !response){
		if (e==="Invalid status code:401"){
			if (authenticated!=="yes") return loginElement;
			return <div className="alert alert-error">You do not have access to this report.</div>;
		}else{
			return <div className="alert alert-error">Error loading report</div>;
		}
	}

	//return JSON.stringify({token,authenticated,response});

	if (!response){
		return <div className={`frakture-report`}>
			<h3 className="p-5">
			Loading report...
			</h3>
		</div>;
	}
	if (progress){
		return <div className={`frakture-report`}>
			<h3 className="p-5">
			Loading report...
			</h3>
		</div>;
	}

	let report=response;
	let qs=Object.assign({},report.qs);

	let arr=Object.keys(report.components||{}).map(name=>{
		let config=report.components[name];

		if (!config.component){
			//could be a string or other item, not a real issue
			//console.error("Could not find a component with keys:",Object.keys(config));
			return config;
		}
		config.name=name;
		return config;
	}).filter(Boolean);
	let folder=report.folder || {};
	let folderOverrides=(folder.reports||[]).find(r=>r._id===report_id) || {};
	let label=folderOverrides.label || report.label;
	if (folder.header) label=folder.header+" - "+label;

	if (report.template || (report.slug.indexOf('v2')===0) || (report.version||'').indexOf('3')===0){
		return <div className={`w-100 frakture-report${editing?" frakture-report-editing":""}`}>
			<ReportHeader
				logo={false}
				account={report.account}
				header_variant={header_variant}
				editing_tools={props.editing_tools}
				label={label}
				toggleFilter={toggleFilter}
				filters={report.filters}
				include_date={report.include_date}
				query_string={report.qs}
				folder={header_variant==='full'?report.folder:null}
			/>
			<div className="report-body-wrapper">
				<ReportBody report={report} editing={editing} qs={qs} filterOpen={filterOpen}/>
			</div>
			<ReportFooter report={report}/>
		</div>;
	};

	return "Please specify a template in the report";


	//Try just the lg
	let layouts={lg:(report.layouts||{}).lg};


	return <div className={`frakture-report-v1 frakture-report${editing?" frakture-report-editing":""}`}>
		{header_variant==='full'?<ReportHeader editing_tools={props.editing_tools} {...report}/>:<ReportHeader logo={false} editing_tools={props.editing_tools} label={report.label} filters={report.filters}/>}
		<ResponsiveGridLayout
			isDraggable={editing}
			isResizable={editing}
			layouts={layouts}
			layout={layouts.lg}
			margin={[20,20]}
			cols={{xl:12,lg:12,md:12,sm:1,xs:1,xxs:1}}
			rowHeight={50}
			onLayoutChange={onLayoutChange}
		>{arr.map((a,i)=>{
				return <ComponentWrapper key={i} name={a.name} component={a} editing={editing?true:undefined} qs={qs}/>;
			}).filter(Boolean)}
		</ResponsiveGridLayout>
		<ReportFooter report={report}/>
	</div>;
}

export function ReportDisplay(props){
	let short=props.report_folder_short;
	let {report_folder_short,report_id}=useParams();
	if (!short) short=report_folder_short;
	if (!report_id) return "Select a report";
	if (!short){return "No such folder";}

	return <SnackbarProvider>
		<div className="report-inner-wrapper" id="report-inner-wrapper">
			<Report {...props}/>
		</div>
	</SnackbarProvider>;
};
