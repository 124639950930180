import React from 'react';
import gql from "graphql-tag";
import { useApolloClient } from '@apollo/client';

import SaveOnBlur from '../../inputs/SaveOnBlur';

import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import {fraktureQuery} from '../../FraktureQuery';

const CONFIG_QUERY=gql`
	query CONFIG_QUERY($dataflow_id:ID!) {
		dataflow(_id:$dataflow_id) {
			_id
			spawn_from_email
			spawn_from_email_subject
		}
	}
`;

const CONFIG_MUTATION=gql`
	mutation CONFIG_MUTATION($dataflow_id:ID!,$subject:String,$email:String) {
		dataflow_update_spawn_from_email(_id:$dataflow_id, subject:$subject, email:$email) {
			_id
			spawn_from_email
			spawn_from_email_subject
		}
	}
`;

export default function DataflowConfig(props) {
	const {dataflow_id}=props;
	const client=useApolloClient();

	const save = ({email,subject}) => client.mutate({
		mutation:CONFIG_MUTATION,
		variables: {dataflow_id,email,subject}
	});

	const spawnConfig = fraktureQuery({
		query: CONFIG_QUERY,
		variables: {dataflow_id}
	}, data => {
		const {spawn_from_email,spawn_from_email_subject}=data.dataflow;

		return <Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<label>Email</label>
				<SaveOnBlur value={spawn_from_email} onChange={email=>save({email})} />
			</Grid>
			<Grid item xs={12} md={12}>
				<label>Subject</label>
				<SaveOnBlur value={spawn_from_email_subject} onChange={subject=>save({subject})} />
			</Grid>
		</Grid>;
	});

	return <Card>
		<CardHeader title='Email Spawn Config' subheader='Enter configuration to use for spawning dataflow from an email' />
		<CardContent>
			{spawnConfig}
		</CardContent>
	</Card>;
};
