import React from 'react';
import FraktureQuery from '../FraktureQuery';

import gql from 'graphql-tag';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Tooltip from '@mui/material/Tooltip';

import WarehouseObjectTypeIcon from './WarehouseObjectTypeIcon';

const tablesWithConnections=gql(`
query linkTables($warehouse_bot_id:ID!) {
	warehouse(bot_id:$warehouse_bot_id) {
		linked_tables {
			name
			object_type
			frakture_table_type
			links {
				source_fields {
					name
				}
				target_table {
					object_type
				}
				target_table_name
				target_fields {
					name
				}
				type
			}
		}
	}
}
`);

class EntityRow extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			expanded:true
		};
	}

	renderLinkFields(link) {
		const sources = link.source_fields.map(x => x.name).join(',');
		const targets = link.target_fields.map(x => x.name).join(',');
		if(sources === targets) return sources;
		return <span>{sources} -> {targets}</span>;
	}

	renderLinkType(type) {
		if(!type) return '';
		const rendered = {
			'one_to_one': () => [
				<i key={0} className='zmdi zmdi-long-arrow-right zmdi-hc-2x'/>,
				<i key={1} className='zmdi zmdi-long-arrow-left zmdi-hc-2x'/>
			],
			'one_to_many': () => [
				<i key={0} className='zmdi zmdi-long-arrow-right zmdi-hc-2x'/>,
				<i key={1} className='zmdi zmdi-arrow-merge zmdi-hc-rotate-270 zmdi-hc-2x'/>
			],
			'many_to_one': () => [
				<i key={0} className='zmdi zmdi-arrow-merge zmdi-hc-rotate-90 zmdi-hc-2x'/>,
				<i key={1} className='zmdi zmdi-long-arrow-left zmdi-hc-2x'/>
			],
			'many_to_many': () => [
				<i key={0} className='zmdi zmdi-arrow-merge zmdi-hc-rotate-270 zmdi-hc-2x'/>,
				<i key={1} lassName='zmdi zmdi-arrow-merge zmdi-hc-rotate-90 zmdi-hc-2x'/>
			]
		}[type]();
		return <Tooltip title={type}><span>{rendered.map((x,i) =><span key={i}>{x}</span>)}</span></Tooltip>;
	}

	renderExpansion(links) {
		if(!this.state.expanded)return null;

		return links.map(link => {
			return <TableRow key={link.target_table_name}>
				<TableCell style={{width:'50%'}}>
					<span style={{width:'50px'}}>
						{this.renderLinkType(link.type)}
					</span>
					<span style={{marginLeft: '50px'}}>
						<WarehouseObjectTypeIcon table={link.target_table} size='small' width='30px' />
						<span>{link.target_table_name}</span>
					</span>
				</TableCell>
				<TableCell>
					{this.renderLinkFields(link)}
				</TableCell>
			</TableRow>;
		});
	}

	render() {
		const{links,name}=this.props.table;
		return <React.Fragment>
			<TableRow>
				<TableCell><WarehouseObjectTypeIcon table={this.props.table} size='small' width='30px' /> {name} ({links.length} links)</TableCell>
				<TableCell />
			</TableRow>
			{this.renderExpansion(links)}
		</React.Fragment>;
	}
};

export default class EntityReferenceDiagram extends React.Component {
	render() {
		const{warehouse_bot_id}=this.props.match.params;
		console.log(warehouse_bot_id);
		return <FraktureQuery query={tablesWithConnections} variables={{warehouse_bot_id}}>
			{(data) => {
				const tables = data.warehouse.linked_tables;
				return <Card>
					<CardHeader title='Entity Relationships'/>
					<CardContent>
						<Table>
							<TableBody>
								{tables.map(x=><EntityRow key={x.name} table={x}/>)}
							</TableBody>
						</Table>
					</CardContent>
				</Card>;
			}}
		</FraktureQuery>;
	}
};
