import React from 'react';
import componentMap from './ComponentMap';
import ReportError from './ReportError';
import {useColorFunc} from './components/colors';

export default function ComponentWrapper(_props){
	let props=Object.assign({},_props);
	const get_color=useColorFunc();
	const {component:initComponent,editing,style={},children,qs,report}=props;
	if (typeof initComponent=='string') return initComponent;

	if (!initComponent) return "Could not find component";
	let component=null;
	try{
		component=JSON.parse(JSON.stringify(initComponent));
	}catch(e){
		console.log(e,initComponent);
		return "Could not parse component";
	}
	//if (!style || !style.width) return "No style with width/height specified";

	if (!component) return "Component prop must be specified";
	const data=component.data || [];
	if (!data) return "No data";
	delete props.component;

	component.width=parseInt(style.width || 300);
	component.height=parseInt(style.height || 300);

	let c=componentMap[component.component];
	if (!c){
		console.error("Could not find component for:",c);
		return <span>Component {component.component || '(not specified)'} not available</span>;
	}
	component.data=data;
	component.report=report;
	component.get_color=get_color;
	component.qs=qs;
	if (!component.name) return "Could not find a name in the component";

	let element=null;
	let err=(<span>{component.label?<h4>{component.label}</h4>:""}Could not render</span>);

	if (editing){ //go ahead and use the element until we know we don't have to
		element=(<span>{component.label?<h4>{component.label}</h4>:""}{component.component}{component.value?" - "+component.value:""}</span>);
	}else{
		try{
			element=React.createElement(c,component);
		}catch(e){
			console.error(e);
			return "Error with "+component.component;
		}
	}
	//delete props.component;
	return <div onClick={()=>console.log(component.sql)}
		className={(props.className||"")+" component-wrapper"}
		style={style}
		onMouseDown={props.onMouseDown}
		onMouseUp={props.onMouseUp}
		onTouchEnd={props.onTouchEnd}><ReportError contents={err}>{element}</ReportError>{children}</div>;
}
