import React from 'react';
import StubQuery from "./StubQuery";
import gql from "graphql-tag";
import {Error,defaultLoadIcon} from './Error';
import { print } from 'graphql/language/printer';
import Skeleton from '@mui/material/Skeleton';

const onScrollQuery=gql(`{
	scrollTop @client
	scrollHeight @client
	offsetHeight @client
}`);

class OnScrollComponent extends React.Component {
	render() {
		const {children,onScroll}=this.props;
		return <React.Fragment>
			<StubQuery query={onScrollQuery} onCompleted={(a) => onScroll(a)}>
				{() => null}
			</StubQuery>
			{children}
		</React.Fragment>;
	}
};

class RegisterSubscription extends React.Component {
	componentDidMount() {
		if(this.props.registerSubscriptions) this.props.registerSubscriptions();
	}
	render() {
		return this.props.children ||"";
	}
};

class OnScrollPagingList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	onScroll(scroll, fetchMore) {
		if(scroll.offsetHeight + scroll.scrollTop + 20 >= scroll.scrollHeight) {
			fetchMore();
		}
	}

	render() {
		let{variables={}, subscriptions, pagePath}=this.props;

		let {loadIcon}=this.props;
		if(loadIcon) loadIcon = <div style={{textAlign:'center', width:'100%', marginTop:'10px'}}>{loadIcon}</div>;

		const wrappedFn = this.props.children;
		const query = this.props.query;
		if(typeof wrappedFn != 'function') throw new Error('children must be a function');
		return (<StubQuery query={query} variables={variables} errorPolicy="all">
			{({loading,data,error, fetchMore,client, subscribeToMore,refetch}) => {
				if(loading && loadIcon) return loadIcon;
				if(loading || error) return wrappedFn({loading, error});
				if(!data.page && !pagePath){
					console.error("Invalid query:",query,variables);
					throw new Error('query must have a "page" result, or pagePath must be specified');
				}

				let onScroll = () => {};

				pagePath = pagePath || ['page'];
				let rawPage = data;
				pagePath.forEach(x=>{
					rawPage=rawPage[x];
				});

				const{page,cursor} = rawPage;

				if(cursor && !this.state.loading) onScroll = (scroll) => this.onScroll(scroll,() => {
					this.setState({loading:true});
					fetchMore({
						query: query,
						variables: Object.assign({}, variables, {cursor}),
						updateQuery: (previous, {fetchMoreResult}) => {
							let newPage=fetchMoreResult;
							pagePath.forEach(x=>{newPage=newPage[x];});

							let newObject=Object.assign({}, previous);
							let track=newObject;
							pagePath.forEach(x=>{
								previous = previous[x];
								track[x] = Object.assign({}, previous);
								track = track[x];
							});

							track.cursor = newPage.cursor;
							track.page = track.page.concat(newPage.page);

							this.setState({loading:false});
							return newObject;
						}
					});
				});

				let registerSubscriptions;
				if(subscriptions && !Array.isArray(subscriptions))subscriptions=[subscriptions];
				if(subscriptions) registerSubscriptions = () => {
					console.log('subscribing', subscriptions[0]);
					subscribeToMore({
						document: subscriptions[0],
						variables,
						updateQuery: (previous,{subscriptionData}) => {
							if(!subscriptionData.data.update) {
								console.log('subscriptionData',subscriptionData);
								console.error('Invalid - subscription must return top level field named update');
								return previous;
							}
							const{object,_id,type}=subscriptionData.data.update;

							if(!type || !_id) {
								console.error('Should return with _id and type');
								return previous;
							}

							let currentPage;
							if(type === 'DELETE') {
								currentPage = previous.page.page.filter(x => {
									return x._id !== _id;
								});
							} else {
								if(!object) {
									console.error('Should return a non-null object field');
									return previous;
								}
								if(type === 'CREATE') {
									currentPage = [object].concat(previous.page.page).slice(0,previous.page.page.length);
								} else if(type === 'UPDATE') {
									currentPage = [object].concat(previous.page.page.filter(x => x._id !== _id)).slice(0,previous.page.page.length);
								} else {
									console.error('Invalid type:',type);
									return previous;
								}
							}
							currentPage.refetch=refetch;

							return Object.assign({}, previous, {
								page: Object.assign({}, previous.page, {
									page:currentPage,
									refetch
								})
							});
						}
					});
				};

				return <RegisterSubscription registerSubscriptions={registerSubscriptions}>
					<OnScrollComponent onScroll={onScroll}>
						{wrappedFn({loading,page,error,client,refetch})}
						{(() => {
							if(this.state.loading && loadIcon) return loadIcon;
						})()}
					</OnScrollComponent>
				</RegisterSubscription>;
			}}
		</StubQuery>);
	}
};

function wrapperHandler({query,children,loadIcon,errorHandler}) {
	return ({data, page,loading,error,refetch}) => {
		if(loading){
			if (loadIcon===null) return null;
			return loadIcon || defaultLoadIcon;
		}

		if(error){
			console.log('failed on query',print(query),query);
			console.error("Remote error executing query:",error);
			if (errorHandler) return errorHandler(error);
			if (!data && !page){
				console.error("There was a remote error, and no data or page returned from FraktureQuery");
				return <Error error={error}/>;
			}
			//Still not great here
			return <Error error={error}/>;
		}

		if (!data && !page){
			if (errorHandler) return errorHandler("No data or page returned from query");
			return <Error error="No data or page returned from query"/>;
		}

		let o=data || page;
		o.refetch=refetch;

		return children(o);
	};
};

export function fraktureQuery(props, fn) {
	if(!fn || typeof fn != 'function') return 'function fn is required';
	return <FraktureQuery {... props}>{fn}</FraktureQuery>;
};

export default class FraktureQuery extends React.Component {
	renderPageOnScroll() {
		const{query,children,variables,loadIcon, subscriptions, pagePath} = this.props;
		return <OnScrollPagingList query={query} variables={variables} loadIcon={loadIcon} subscriptions={subscriptions} pagePath={pagePath}>
			{wrapperHandler({children,loadIcon,query})}
		</OnScrollPagingList>;
	}

	render() {
		const{query,children,onCompleted,variables,fetchPolicy, loadIcon,pageOnScroll,errorHandler,pollInterval=0} = this.props;
		if(!query) throw new Error('query is a required property');
		if(!children || typeof children != 'function'){
			console.error(children);
			throw new Error('FraktureQuery error:children must be a non-null function, not an array, it is '+typeof children);
		}

		if(pageOnScroll === true) return this.renderPageOnScroll();

		return <StubQuery query={query}
			onCompleted={onCompleted}
			variables={variables}
			errorPolicy="all"
			fetchPolicy={fetchPolicy}
			pollInterval={pollInterval}
		>
			{o => {
				let registerSubscriptions;

				let{subscriptions}=this.props;
				if(subscriptions && !Array.isArray(subscriptions))subscriptions=[subscriptions];
				if(subscriptions) registerSubscriptions = () => {
					console.log('subscribing', subscriptions[0]);
					o.subscribeToMore({
						document: subscriptions[0],
						variables,
						updateQuery: (previous,{subscriptionData}) => {
							if(!subscriptionData.data.update) {
								console.log('subscriptionData',subscriptionData);
								console.error('Invalid - subscription must return top level field named update');
								return previous;
							}
							const{object,_id,type}=subscriptionData.data.update;
							if(!type || !_id) {
								console.error('Should return with _id and type');
								return previous;
							}
							if(type !== 'UPDATE') throw new Error('Expected only updates');
							return object;
						}
					});
				};

				return <RegisterSubscription {...{registerSubscriptions}}>
					{wrapperHandler({query,loadIcon,children,errorHandler})(o)}
				</RegisterSubscription>;
			}}
		</StubQuery>;
	}
};
function FraktureSimpleQuery({query,variables,errorHandler,children,width,height,loadIcon=defaultLoadIcon}){
	return (
		<StubQuery query={query} variables={variables}>
			{({loading,data,error}) => {
				if(loading){
					if (width && height){
						return (
							<React.Fragment>
								<Skeleton variant="rectangular" width={width} height={height} />
							</React.Fragment>
						);
					};
					return loadIcon;
				}
				if(error){
					console.error("Error from query:",error);
					if (errorHandler) return errorHandler(error);
					return <Error error={error}/>;
				}

				if (typeof children=='function'){
					return children(data);
				}else{
					return children.map(c=>{
						if (typeof c=='function') return c(data);
						return "Child is not a function";
					});
					//return "Children is not a function, it's "+typeof children+",Array:"+Array.isArray(children);
				}
			}}</StubQuery>
	);
}

export {FraktureSimpleQuery};
