import {useQuery} from "@apollo/client";

//A quick hack to move to hooks from <StubQuery>
export default function Query(props){
	const {query,variables}=props;
	const {data,error,loading,client,refetch}=useQuery(query,{variables});
	//if (error) return "";
	//if (loading) return "Loading...";
	function parseJSON(d){
		if (!d) return d;
		if (typeof d=='object'){
			if (Array.isArray(d)){
				return d.map(parseJSON);
			}else if (d instanceof Date){
				return d;
			}else{
				return Object.keys(d).reduce((o,k)=>{o[k]=parseJSON(d[k]);return o;},{});
			}
		}else if (typeof d=='string' && ((d[0]==='{' && d[1]!=='{') || d[0]==='[')){
			try{
				return JSON.parse(d);
			}catch(e){
				return d;
			}
		}
		return d;
	}
	let output=parseJSON(data);
	return props.children({data:output,error,loading,client,refetch});
}
