import React from 'react';
import FraktureQuery from "../FraktureQuery";
import gql from "graphql-tag";

import {NavLink,useSearchParams} from 'react-router-dom';

import DataflowListItem from './DataflowListItem';
import {SidebarListItem} from '../components/SidebarList';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import {useAccountId} from '../account/AccountInfo';
import {useBots} from '../bots/BotList';
import {BotSelect} from '../bots/BotSelect';



const DATAFLOW_LIST=gql(`
	query DATAFLOW_LIST($account_id: ID!) {
		account(_id: $account_id) {
			_id
			dataflows {
				_id
				account_id
				label
				schedule
				disabled
				next_run
				can_run
				last_completed
				status
				jobs{
					bot{
						_id
					}
				}
				config{
					bot_overrides{
						bot_id
						default_id
						override_id
					}
				}
				full_status {
					errored_job_lists
					expected_last_completed
					status
					message
				}
			}
		}
	}
`);

export default function Menu(props){

	let [searchValue,setSearchValue]=React.useState("");

	const account_id=useAccountId();
	if (!account_id) return "";
	const {fullItem=false}=props;
	const [params]=useSearchParams();
	const {bots,loading,error}=useBots();
	if (error || loading) return error ||loading;
	let bot_ids=[].concat((params.get("bot_ids")||"").split(",").filter(Boolean));

	return <FraktureQuery query={DATAFLOW_LIST} variables={{account_id}} errorHandler={e=>console.error(e)}>
		{({account}) => {
			if (!account || !account.dataflows){
				return "Could not find account";
			}
			let searchArray=[];
			if (searchValue){
				searchArray=searchValue.toLowerCase().split(/\s+/);
			}
			let dataflows=account.dataflows.map(d=>{
				d.bot_ids={};
				(d.jobs||[]).forEach(j=>{
					d.bot_ids[j?.bot?._id ||'']=true;
				});
				(d.config?.bot_overrides||[]).forEach(o=>{
					d.bot_ids[o?.bot_id||""]=true;
					d.bot_ids[o?.default_id||""]=true;
					d.bot_ids[o?.override_id||""]=true;
				});
				d.bot_ids=Object.keys(d.bot_ids).filter(Boolean);
				d.search_terms=[d.label?.toLowerCase(),
				].filter(Boolean);

				return d;
			}).filter(d=>{
				if (bot_ids.length===0) return true;
				let match=bot_ids.find(id=>d.bot_ids.find(x=>x===id));
				return match;
			}).filter(d=>{
				if (searchArray.length===0) return true;
				return searchArray.every(v=>{
					return d.search_terms?.some(x=>x.indexOf(v)>=0);
				});
			});
			dataflows.sort((a,b)=>a.label<b.label?-1:1);

			let statii={
				new:{label:"New",dataflows:[]},
				ok:{label:"OK",dataflows:[]},
				error:{label:"Recent Errors",dataflows:[]},
				unscheduled:{label:"Unscheduled/Disabled",dataflows:[]}
			};
			dataflows.forEach(d=>{
				if (d.disabled){
					statii.unscheduled.dataflows.push(d);
				}else if (!d.schedule){statii.unscheduled.dataflows.push(d);
				}else{
					if (statii[d.status]){
						statii[d.status].dataflows.push(d);
					}else{
						statii.new.dataflows.push(d);
					}
				}
			});

			return <React.Fragment>
				<BotSelect bots={bots}/>
				<SidebarListItem
					key={"dataflow-quick-filter"}
					primary={
						<TextField
							key="dataflow-quick-filter"
							id="dataflow-quick-filter"
							className="w-100"
							variant="standard"
							value={searchValue}
							name="dataflow-quick-filter"
							aria-label="Quick Filter" placeholder="Quick Filter"
							onChange={e=>{setSearchValue(e.target.value);}}
							InputProps={{disableUnderline: true}}
						/>
					}
				/>
				{Object.entries(statii).map(([status,{label,dataflows:dfs}])=>{
					if (dfs.length===0) return "";
					return <React.Fragment key={status}>
						<ListSubheader className={`status-${status}`}>{label}</ListSubheader>
						<Divider/>
						{dfs.map((df,key)=>{
							return fullItem?<DataflowListItem key={key} dataflow={df} />:<li key={key} >
								<NavLink to={"/app/"+account_id+"/job?dataflow_id="+df._id}>
									<span className="nav-text">{df.label}</span>
								</NavLink>
							</li>;
						})}
					</React.Fragment>;
				} ) }
			</React.Fragment>;
		}}
	</FraktureQuery>;
}
