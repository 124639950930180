import React from 'react';
import gql from "graphql-tag";

import {FraktureSimpleQuery} from '../../FraktureQuery';
import {useApolloClient} from '@apollo/client';

import useNotifiers from '../../Notifiers';
import Checkbox from '@mui/material/Checkbox';

import FraktureTable from '../../FraktureTable';
import {useReportId} from './ReportProvider';
import {ReportLink,ReportListLink} from '../Links';

import WarehouseTableSearch from '../../warehouse/WarehouseTableSearch';

import {CreateChildReportButton} from './ReportCreate';
import {DeleteReportIconButton} from './ReportDelete';

const REPORT_DETAIL_QUERY=gql`
query REPORT_DETAIL_QUERY($report_id:ID!) {
	report(_id:$report_id) {
		_id

		data_sources_array {
      alias
      table
      bot_path
      method
      warehouse_bot_id
    }

		account {
			children {
				_id
				name
				reports {
					_id
					label
					parent_id
				}
			}
		}

		shareable
		children {
			_id
			account_id
			account {
				_id
				name
			}
			label

			data_sources_array {
	      alias
	      table
	      bot_path
	      method
	      warehouse_bot_id
	    }

		}
	}
}
`;

const REPORT_SET_SHAREABLE = gql`
mutation REPORT_SET_SHAREABLE($report_id:ID!, $shareable:Boolean!) {
	report_set_shareable(_id:$report_id, shareable:$shareable) {
		_id
		shareable
	}
}`;

function ReportShareable(props) {
	const report_id = useReportId(props);
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();
	return <FraktureSimpleQuery query={REPORT_DETAIL_QUERY} variables={ {report_id}}>
		{data=>{
			const {shareable}=data.report;
			return <Checkbox checked={!!shareable} onChange={() => {
				client.mutate({
					mutation: REPORT_SET_SHAREABLE,
					variables: {report_id, shareable: !shareable}
				}).then(notify,notifyErr);
			}} />;
		}}
	</FraktureSimpleQuery>;
}

export default function ReportSharing(props) {
	const report_id = useReportId(props);

	return <FraktureSimpleQuery query={REPORT_DETAIL_QUERY} variables={ {report_id}}>
		{data=>{
			const {report}=data;
			if (!report) return "Error reloading reports";
			const {children,data_sources_array} = report;
			const accounts = report.account.children;
			return <React.Fragment>
				<div>Shareable?<ReportShareable {...props}/></div>
				<FraktureTable columns={[{
					title: 'Account',
					render: ({row}) => {
						const {account:{_id}}=row;
						return <ReportListLink account_id={_id} />;
					}
				}, {
					title: 'Report',
					render: ({row}) => {
						const{_id,label, account}=row;
						if(!_id) return <CreateChildReportButton account_id={account._id} parent_id={report_id}/>;

						return <React.Fragment>
							<DeleteReportIconButton report_id={_id}/>
							<ReportLink report_id={_id} label={label} />
						</React.Fragment>;
					}
				}].concat(data_sources_array.map(x => {
					return {
						title: x.alias,
						render: ({row}) => {
							if(!row._id) return null;
							const match = row.data_sources_array.find(y=>x.alias === y.alias) || {};
							const {warehouse_bot_id,table}=match;
							return <WarehouseTableSearch value={table} bot_id={warehouse_bot_id} />;
						}
					};
				}))} rows ={children.map(child => {
					return {
						_id: child._id,
						label: child.label,
						account: child.account,
						data_sources_array: child.data_sources_array
					};
				}).concat(accounts.filter(account => {
					return !account.reports.find(r=>r.parent_id === report_id);
				}).map(account => {
					return {
						account:account
					};
				}))}/>
			</React.Fragment>;
		}}
	</FraktureSimpleQuery>;
}
