import React from 'react';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useApolloClient } from '@apollo/client';
import {useSnackbar} from 'notistack';

import {SOURCE_CODE_VALUE_ADD} from './config.js';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const AddModal=function(props){
	const {enqueueSnackbar}=useSnackbar();
	const {source_code_element_set_id,element,label}=props;
	const client=useApolloClient();
	if (!source_code_element_set_id) return "No element set id provided";
	const [open, setOpen] = React.useState(false);
	const [newItem, setNewItem] = React.useState({element});

	const handleClickOpen = () => {setOpen(true);};

	const handleClose = () => {setOpen(false);};

	function getError(v,def){
		if (!v) return def ||"";
		if (v.indexOf("_")>=0) return 'No underscores';
		if (v!==escape(v)) return "Invalid characters";
		return def;
	}

	const saveNewItem=()=>{
		if (!newItem.label) return alert("A label is required");
		let e=getError(newItem.value);
		if (e){return alert(e);}
		client.mutate({mutation:SOURCE_CODE_VALUE_ADD,
			refetchQueries:["elements"],
			variables:Object.assign({_id:source_code_element_set_id,element},newItem)
		}).then(result=>{
			enqueueSnackbar("Saved "+newItem.label,{ variant: 'success'});
			setOpen(false);
		},
		error=>{console.error(error);enqueueSnackbar("Error adding element",{ variant: 'error'});}
		);
	};

	return (
		<React.Fragment>
			<Button color="primary" onClick={handleClickOpen}><AddIcon/></Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Add {label}</DialogTitle>
				<form onSubmit={saveNewItem}>
					<DialogContent>
						<TextField
							autoFocus
							margin="dense"
							id="label"
							label="Label (e.g. Ocean Campaign, etc)"
							onChange={e=>setNewItem(Object.assign({},newItem,{label:e.target.value}))}
							fullWidth
						/>
						<TextField
							margin="dense"
							id="value"
							label="Unique Code (e.g. OC,EL,etc)"
							onChange={e=>{setNewItem(Object.assign({},newItem,{value:e.target.value}));}}
							fullWidth
							error={!!getError(newItem.value)}
							helperText={getError(newItem.value,"No underscores or special characters")}
						/>

					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
						Cancel
						</Button>
						<Button onClick={saveNewItem} color="primary">Save</Button>
					</DialogActions>
				</form>
			</Dialog>
		</React.Fragment>
	);
};

export default function ElementForm(props) {
	const {values,source_code_elements,onChange}=props;

	let o={};

	source_code_elements.forEach(d=>o[d._id]='');

	o.date=o.date ||new Date().toISOString();

	//let labelMap={};

	const handleChange = name => event => {
		const v=typeof event=='string'?event:
			typeof event.toISOString=='function'?event.toISOString():event.target.value;
		if (typeof onChange=='function'){

			const returnVal=Object.assign({},values); returnVal[name]=v;
			console.log("Calling change date with ",returnVal);
			onChange(returnVal);
		}
	};

	return <React.Fragment>
		<Card>
			<CardContent className="form row" autoComplete="off">
				{source_code_elements.filter(d=>d.name!=='date').map((el,i)=>{
					delete el.__typename;
					let vals=(el.values||[]).filter(d=>{
						if (!d.filter) return true;
						// eslint-disable-next-line
						let response=eval("("+d.filter+")(values)");
						return response;
					});

					if (!el.type && el.values){
						if (el.values.length>0){
							el.type="select";
						}else{
							return "No values";
						}
					}

					return <div key={i} className="col-lg-3 col-sm-6 col-12">
						{(() => {
							switch(el.type){
							case 'text': return <div style={{marginTop: "16px"}}>
								<FormControl fullWidth>
									<InputLabel>{el.label}</InputLabel>
									<Input value={values[el.name]} onChange={handleChange(el.name)}
										onBlur={handleChange(el.name)}
										helperText={el.description} fullWidth/>
								</FormControl>
							</div>;
							default:return <Grid container>
								<Grid item md={10}>
									<FormControl fullWidth className="w-100 mb-2">
										<InputLabel htmlFor={el.name}>{el.label}</InputLabel>
										<Select
											value={values[el.name]||""}
											onChange={handleChange(el.name)}
											input={<Input id="campaign-helper"/>}
										>
											<MenuItem value="">
												<em>None</em>
											</MenuItem>
											{vals.map((v,i2)=>(
												<MenuItem key={i2} value={v.value}>{v.label}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={2}>
									<AddModal source_code_element_set_id={el.source_code_element_set_id} element={el.name} label={el.label}/>
								</Grid>
							</Grid>;
							}
						})()}
						<FormHelperText>{el.description}</FormHelperText>
					</div>;
				})}
			</CardContent>
		</Card>
	</React.Fragment>;
}
