import React from 'react';

import gql from 'graphql-tag';

import {fraktureQuery} from '../../FraktureQuery';
import ScheduleSelect from '../../inputs/ScheduleSelect';

import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';

const DATAFLOW_SCHEDULE_QUERY=gql`
query DATAFLOW_SCHEDULE_QUERY($dataflow_id:ID!) {
	dataflow(_id:$dataflow_id) {
		_id
		schedule
	}
}`;

const UPDATE_DATAFLOW_MUTATION = gql`mutation UPDATE_DATAFLOW($dataflow_id:ID!, $schedule: String!) {
	dataflow_update(_id: $dataflow_id, update:{schedule: $schedule}) {
		_id
		label
		schedule
	}
}
`;

export default function(props) {
	const{dataflow_id}=props;
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();

	return fraktureQuery({
		query: DATAFLOW_SCHEDULE_QUERY,
		variables: {dataflow_id}
	}, ({dataflow}) => {
		return <ScheduleSelect schedule={dataflow.schedule}
			onChange={(schedule) =>{
				client.mutate({
					mutation: UPDATE_DATAFLOW_MUTATION,
					variables: {
						dataflow_id,
						schedule
					}
				}).then(notify,notifyErr);}
			}/>;
	});
};
