import React from 'react';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";
import useNotifiers from '../Notifiers';
import {useNavigate} from 'react-router-dom';

import Async from 'react-select/async';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

const AVAILABLE_BOTS=gql(`query AVAILABLE_BOTS{
  bot_definitions(
    limit:1000,
    types:["workerbot","channelbot","controlbot"]
	) {
	   page {
	    bot_path
	    metadata {
	      logo
	    }
	  }
  }
}`);

const createBotMutation=gql(`mutation createBot($account_id:ID!,$bot_path:String!,$warehouse_bot_id:ID){
	bot_create(account_id:$account_id, bot_path: $bot_path, warehouse_bot_id: $warehouse_bot_id){
		_id
		label
		path
		warehouse_bot_id
		warehouse_table_prefix
		api_endpoint
	}
}`);

function AddBotSelector(props){
	const{account_id}=props;
	const {notify,notifyErr} = useNotifiers();
	const [opt,setOpt]=React.useState({label:null,value:null});

	const navigate=useNavigate();
	const {data,error,loading,client}=useQuery(AVAILABLE_BOTS);
	if(error || loading) return error||'Loading...';
	const bots = data.bot_definitions.page;

	const elements=bots.map(b=>{
		return {label:b.bot_path.split(".").pop(),value:b.bot_path};
	});
	const{value,label}=opt;
	const select = <Async defaultOptions={elements} cacheOptions value={value && {value,label}} onChange={option => {
		const{label,value}=option;
		setOpt({label,value});
	}} loadOptions={(input) => {
		const inputs = input.trim().toLowerCase().split(/\s+/g);
		const isMatch = (vars) => {
			vars = vars.map(x=>x.toLowerCase());
			// if(vars[0].indexOf(inputs[0]) != -1) console.log(vars[0],inputs[0]);
			return inputs.every(inp => vars.find(v => v.indexOf(inp) !== -1));
		};

		return new Promise(resolve => resolve(elements.map(x => {
			if(!x.options) {
				if(isMatch([x.value, x.label])) {
					return x;
				}
				else return null;
			}
			return {
				label: x.label,
				options: x.options.filter(y => {
					return isMatch([x.label, y.value, y.label]);
					// return inSubmodule || y.value.toLowerCase().indexOf(input) != -1 || y.label.toLowerCase().indexOf(input) != -1;
				})
			};
		}).filter(x=>x)));
	}} placeholder='Add Bot ...' />;

	const addButton = <Button disabled={!value} variant="contained" size="small" color="primary" onClick={() => {
		const{value}=opt;

		client.mutate({mutation:createBotMutation,variables:{bot_path:value,account_id},refetchQueries:["ACCOUNT_BOTS_QUERY"]})
			.then(({data})=>{
				let _id=data?.bot_create?._id;
				notify("Added Bot");
				if (_id) navigate(`/app/${account_id}/bot/${_id}`);
			},notifyErr);
	}}><AddIcon />Add Bot</Button>;

	return <div className="d-flex">
		<div className="w-100 p-1">{select}</div>
		<div className="ml-auto text-nowrap p-1">{addButton}</div>
	</div>;
}

export default AddBotSelector;
