import { InMemoryCache
	,defaultDataIdFromObject
} from "@apollo/client";
//import {toIdValue} from '@apollo/client/utilities';
function toIdValue(x){
	return x;
}
// eslint-disable-next-line no-undef
export default function() {
	const cache = new InMemoryCache({

		//update maybe an ID is needed so warehouse queries don't keep retrying?
		//Not sure of the benefits of adding a bot_id as the _id here when putting into the cache

		dataIdFromObject: obj => {
			let cacheableObj=obj;
			switch(obj.__typename) {
			case 'Warehouse': {
				if(!obj._id){
					//So, this apparently has to be the bot_id, otherwise the cache toggles on the SQL filter
					let _id=obj.bot_id;//obj?.table?.data_grid_batch?.sql || obj.bot_id;
					cacheableObj=JSON.parse(JSON.stringify(obj));
					cacheableObj._id = _id;
				}
				break;
			}
			case 'WarehouseUser':{
				if(!obj.id || !obj.bot_id) {
					console.error('WarehouseUser _must_ include bot_id and id:',obj);
					return null;
				}
				try {
					obj._id = obj.bot_id+'-'+obj._id;
				} catch(e) {
					console.error(e);
					throw e;
				}
				break;
			}
			default:
			}
			return defaultDataIdFromObject(cacheableObj);
		},

		cacheRedirects: {
			Query: {
				bot: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Bot', id: _id })),
				dataflow: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Dataflow', id: _id })),
				jobList: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'JobList', id: _id })),
				account: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Account', id: _id })),
				report: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Report', id: _id })),
				job: (_, {_id}) => toIdValue(cache.config.dataIdFromObject({ __typename: 'Job', id: _id })),

				warehouse: (_, {bot_id}) => {
					const id = toIdValue(cache.config.dataIdFromObject({ __typename: 'Warehouse', id: bot_id }));
					return id;
				}
			},

			Dataflow: {
				job: ({_id}, {context_id}) => toIdValue(cache.config.dataIdFromObject({
					__typename:'DataflowJob',
					_id: _id+'.'+context_id
				}))
			},

			User: {
				warehouse_user: ({_id}, {bot_id}) => {
					return toIdValue(cache.config.dataIdFromObject({ __typename: 'WarehouseUser', bot_id, id:_id }));
				}
			},

			Warehouse: {
				table: ({bot_id}, {name}) => {
					return toIdValue(cache.config.dataIdFromObject({
						__typename: 'WarehouseTable',
						id: JSON.stringify({bot_id, name})
					}));
				}
			}
		},
	});
	return cache;
};
