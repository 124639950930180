import {GraphQLScalarType} from 'graphql';

// These are inverted, since we're going FROM the network layer TO
// the client layer, so parsing is _actually_ serializing. Because
// GraphQL doesn't have a way of natively handling scalars
// This is being tracked here:
// https://github.com/apollographql/apollo-feature-requests/issues/2
let output={
	JSON:new GraphQLScalarType({
		name: 'JSON',
		description: 'An untyped object with freeform data. Used for options and outputs',
		parseValue(value) {
			//THis is going TO the server ... or maybe from the warehouse?
			//NO idea why we have to stringify this twice, we don't have to parse twice
			let s=JSON.stringify(value);
			if (typeof value=='string' || typeof value=='object'){
				//console.log("Double stringifying:",typeof value,value);
				return JSON.stringify(s);
			}
			return s;
		},
		serialize(value) {
			//console.log("Parsing JSON:",typeof value,value);
			//Coming FROM the server
			//console.log("Parsing:",typeof value,Array.isArray(value),value);
			//if(typeof value == 'object') return value;
			//console.log("Parsing:",typeof value);
			let parsed=value;
			try{
				if (typeof parsed!='object')parsed=JSON.parse(value);
			}catch(e){
				console.log("Error parsing",value,e);
			}
			//console.log("Completed parsing:",value);
			//console.log("Parsed",typeof parsed,Array.isArray(parsed),parsed);

			//console.log("Trying to parse again");
			//Not sure why we have to do it twice .... but do we for options perhaps?
			try{
				if (typeof parsed!='object') parsed=JSON.parse(parsed);
			}catch(e){
				//console.log("2nd parse -- error parsing",value,e);
			}
			return parsed;
		},
		parseLiteral(ast) {
			console.log("Parsing literal");
			return ast.toString();
		},
	}),

	Date:new GraphQLScalarType({
		name: 'Date',
		description: 'An ISO Date',
		serialize(value) {
			if(!value) return null;
			return new Date(value);
		},
		parseValue(value) {
			if(!value) return null;
			if(typeof value == 'string') return value;
			return value.toISOString();
		},
		parseLiteral(ast) {
			if(!ast) return null;
			return new Date(ast.toString());
		}
	})
};

export default output;
