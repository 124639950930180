import React from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


const BOT_AUTH_OKAY = gql(`
query botData($bot_id: ID!) {
	bot(_id: $bot_id) {
		_id
		token
		api_endpoint
		auth_ok
	}
}`);

function Response(props){
	const {data}=props;
	if (!data) return "";
	if (!data.bot) return "No bot in the response";
	let r=data.bot.auth_ok;
	if (r.error){
		return <div className="alert alert-danger">{r.error.message|| (typeof r.error==='string')?r.error:JSON.stringify(r.error)}</div>;
	}
	let message=r.message || "Authorization is valid";
	return <div className="alert alert-success">{message}</div>;

}


export default function CheckAuth(props) {
	const {bot_id}=props;
	const [
		getAuthOkay,
		{ loading, data,error }
	] = useLazyQuery(BOT_AUTH_OKAY,{ variables: { bot_id},fetchPolicy: 'network-only' });
	let errorMessage=null;
	if (error) errorMessage=["There was an error with authentication:",error.message,error.error].filter(Boolean);
	return (
		<div>
			<Tooltip title={"Confirm that the current authorization settings are valid"}>
				<span>
					<Button variant='contained' color='primary' onClick={() => getAuthOkay()}>Check Authorization</Button>
				</span>
			</Tooltip>
			{error && <div className="alert alert-danger">{errorMessage.map((d,i)=><div key={i}>{d}</div>)}</div>}
			{loading && <div className="alert alert-info">Checking .... this may take a moment.</div>}
			{data && <Response data={data}/>}
		</div>);
}
