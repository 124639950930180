import React from 'react';

import gql from 'graphql-tag';

import FormControl from '@mui/material/FormControl';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import {useAccountId} from '../AccountInfo';

import {fraktureQuery} from '../../FraktureQuery';
import {useUserContext} from '../../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import useNotifiers from '../../Notifiers';

import CardContent from '@mui/material/CardContent';

const ACCOUNT_SET_LOCATION_MUTATION=gql`
mutation ACCOUNT_SET_LOCATION_MUTATION($account_id:ID!,$bot_location_id:ID!){
	account_set_bot_location(_id:$account_id,bot_location_id:$bot_location_id) {
		_id
		location {
			_id
		}
	}
}`;


const BOT_LOCATION_QUERY=gql`
query BOT_LOCATION_QUERY($account_id:ID!){
  bot_locations {
    _id
    label
  }
	account(_id:$account_id) {
		_id
		location {_id}
	}
}`;

export default function LocationConfiguration(props){
	const {is_frakture_admin}=useUserContext();
	const account_id = useAccountId(props);
	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();

	if(!is_frakture_admin) return null;

	return fraktureQuery({query: BOT_LOCATION_QUERY,variables:{account_id}}, data => {
		const {bot_locations, account}=data;
		const location_id = account.location._id;

		function mutateLocation(bot_location_id){
			client.mutate({mutation:ACCOUNT_SET_LOCATION_MUTATION,variables:{account_id,bot_location_id}}).then(notify,notifyErr);;
		};
		return <CardContent>
			<h5>{account.location.label}</h5>
			<FormControl fullWidth>
				<Select
					value={location_id}
					onChange={e => mutateLocation(e.target.value)}
					displayEmpty={true}
				>
					<MenuItem key={"inherit"} value={"__none__"}>(Inherited from account)</MenuItem>
					{bot_locations.map(location => {
						const {_id:i,label}=location;
						return <MenuItem key={i} value={i}>{label}</MenuItem>;
					})}
				</Select>
			</FormControl>
		</CardContent>;
	});
};
