// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReportCard from '../ReportCard';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import sift from 'sift';
import FraktureScorecard from './FraktureScorecard.js';
import FraktureVerticalBarChart from './FraktureVerticalBarChart.js';
import FraktureBarChart from './FraktureBarChart.js';
import FraktureReportTable from './FraktureReportTable.js';
//import FraktureStackedBarChart from './FraktureStackedBarChart.js';
import sourceCodeFieldOptions from '../SourceCodeFields';
//import {TabContent,TabContainer} from '../../../FraktureTabs';
import './MessageBySourceCode.css';

function GroupBy(props){
	const {optionsArray,field:defaultField,onChange=()=>{}}=props;
	//let parsed=JSON.parse(value || "{}");
	const [field,setField]=React.useState(defaultField);


	return <div className="">
		<Select value={field||""}>
			<MenuItem value="" onClick={e=>{setField("");onChange({field:""});}}>{field?"(Clear Field)":"(Select Filter)"}</MenuItem>
			{optionsArray.map((s,i)=><MenuItem key={i} value={s.name} onClick={e=>{setField(s.name);onChange(s.name);}}>{s.label}</MenuItem>)}</Select>
	</div>;
}

function Filter(props){
	const {optionsArray,field:defaultField,value:defaultValue,onChange}=props;
	//let parsed=JSON.parse(value || "{}");
	const [field,setField]=React.useState(defaultField);
	const [value,setValue]=React.useState(defaultValue);
	let opts=[];
	if (field){
		let vals=((optionsArray.find(n=>n.name===field)||{}).values||[]);
		opts=Object.keys(vals).sort().map(k=>({value:k,label:<span>{k}<small> ({vals[k]})</small></span>}));
	}

	return <div className="">
		<Select value={field||""}>
			<MenuItem value="" onClick={e=>{setField("");setValue("");if (onChange){onChange({field:"",value:""});}}}>{field?"(Clear Field)":"(Select Filter)"}</MenuItem>
			{optionsArray.map((s,i)=><MenuItem key={i} value={s.name} onClick={e=>{setField(s.name);setValue("");}}>{s.label}</MenuItem>)}</Select>
		<Select value={value}>{opts.map((opt,i)=><MenuItem key={i} value={opt.value} onClick={
			e=>{
				setValue(opt.value);
				if (onChange){onChange({field,value:opt.value});}
			}}
		>{opt.label || "(Blank)"}</MenuItem>)}</Select>
	</div>;
}

function getSums(d){
	return d.reduce((a,b)=>{
		a.messages++;
		Object.keys(b).forEach(k=>{
			if (typeof b[k]==='number'){
				a[k]=(a[k]||0)+b[k];
			}
		});
		return a;
	},{messages:0});
}
function groupBy(d,field){
	let vals={};
	d.forEach(o=>{
		let lookup=o[field];
		if (lookup===undefined || lookup==null) lookup="";
		vals[lookup]=(vals[lookup]||[]).concat(o);
	});
	let output=Object.keys(vals).map(val=>{
		let o=getSums(vals[val]);
		o[field]=val;
		o.label=val;
		o.col0=val;
		return o;
	});
	return output;
}

function getFieldAndValue(o){
	let k=Object.keys(o||{});
	if (k.length===0) return {field:"",value:""};
	return {field:k[0],value:o[k[0]]};
}

export default function MessageBySourceCode(props){
	let {data:rawData,label=""}=props;
	if (rawData.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;
	const location=useLocation();
	let params = queryString.parse(location.hash);

	params.filter=JSON.parse(params.filter||"[]");
	params.group1=params.group1||"channel";
	//params.group2=params.group2||"campaign_label";

	/*let now=parseInt(new Date().toISOString().slice(0,4));
	*/
	function setFilter(i,y){
		params.filter[i]=y;
		params.filter=JSON.stringify(params.filter.filter(Boolean));
		document.location.hash=queryString.stringify(params);
	}
	const valuesByField=rawData.reduce((a,b)=>{
		Object.keys(b).forEach(key=>{
			if (['date','message_id','label','subject','publish_date','campaign_id'].indexOf(key)>=0) return;
			if (!b[key] || typeof b[key]!='string') return;

			a[key]=a[key]||{};
			a[key][b[key]]=(a[key][b[key]] ||0)+1;
		});
		return a;},{});

	let availableSourceCodeFields=[];
	sourceCodeFieldOptions.forEach(f=>{
		let values=valuesByField[f.name+"_label"];
		if (!values)values=valuesByField[f.name];
		if (values){
			availableSourceCodeFields.push(Object.assign({},f,{values}));
		}
	});

	const data=rawData.filter(sift({$and:params.filter}));
	const sums=getSums(data);

	const group1=groupBy(data,params.group1);
	function setGroupBy(y){
		params.group1=y;
		params.filter=JSON.stringify(params.filter.filter(Boolean));
		document.location.hash=queryString.stringify(params);
		//group1=JSON.parse(JSON.stringify(groupBy(data,params.group1)));
	}

	return <Grid container spacing={3}>
		<Grid item xs={12} md={6}>
			<ReportCard title="Filter">
				<Filter optionsArray={availableSourceCodeFields} {...getFieldAndValue(params.filter[0])} onChange={({field,value})=>setFilter(0,field?({[field]:value}):null)}/>
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={6}>
			<ReportCard title="Grouping">
				<GroupBy optionsArray={availableSourceCodeFields} field={params.group1} onChange={(field)=>setGroupBy(field)}/>
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={4}>
			<ReportCard title="Totals">
				<Grid container spacing={3}>
					<Grid item xs={4}><FraktureScorecard label="Messages" metric={{name:"messages"}} data={[sums]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="Impressions" metric={{name:"impressions"}} data={[sums]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="Clicks" metric={{name:"clicks"}} data={[sums]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="CTR" metric={{name:"ctr",format:"percent"}} data={[{ctr:sums.clicks/sums.impressions}]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="Revenue" metric={{name:"attributed_revenue",format:"currency"}} data={[sums]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="Transactions" metric={{name:"attributed_transactions"}} data={[sums]}/></Grid>
					<Grid item xs={2}></Grid>
					<Grid item xs={4}><FraktureScorecard label="Spend" metric={{name:"spend",format:"currency"}} data={[sums]}/></Grid>
					<Grid item xs={4}><FraktureScorecard label="ROI" metric={{name:"roi",format:"percent"}} data={[{roi:sums.attributed_revenue/sums.spend}]}/></Grid>
					<Grid item xs={2}></Grid>
				</Grid>
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={4}>
			<ReportCard title="Revenue">
				<FraktureVerticalBarChart data={group1} metric={{alias:"attributed_revenue",format:"currency"}} />
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={4}>
			<ReportCard title="Impressions">
				<FraktureVerticalBarChart data={group1} metric={{alias:"impressions"}} />
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={6}>
			<ReportCard title="Impressions Over Time">
				<FraktureBarChart data={data} is_date={true} dimension_alias="date" metrics={[{alias:"impressions"}]} breakdown={{alias:params.group1}} />
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={6}>
			<ReportCard title="Revenue Over Time">
				<FraktureBarChart data={data} is_date={true} dimension_alias="date" metrics={[{alias:"attributed_revenue",format:"currency"}]} breakdown={{alias:params.group1}} />
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={12}>
			<ReportCard title="Summary" contentClassName="p-0">
				<FraktureReportTable className="p-0" height={400} data={group1} metrics={[{fql:params.group1},{fql:"impressions"},{fql:"clicks"},{fql:"spend",format:"currency"},{fql:"attributed_revenue",label:"Revenue",format:"currency"}]}/>
			</ReportCard>
		</Grid>
		<Grid item xs={12} md={12}>
			<ReportCard title="Messages" contentClassName="p-0">
				<FraktureReportTable className="p-0" title="Message" height={600} data={data} metrics={[{fql:"label"},{fql:"subject"},{fql:"publish_date",format:"date"},{fql:"impressions"},{fql:"clicks"},{fql:"spend",format:"currency"},{fql:"attributed_transactions",label:"Transactions"},{fql:"attributed_revenue",label:"Revenue",format:"currency"}]}/>
			</ReportCard>
		</Grid>
	</Grid>;
};
