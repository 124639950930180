// eslint-disable-next-line no-unused-vars
import React,{useState} from 'react';
import {
	Bar,
	ComposedChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,CartesianGrid,
	YAxis,Legend
} from "recharts";

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {formatValue} from '../../../formatters';
import {useColorFunc} from './colors';


export default function FraktureStackedBarChart(props){
	let {dimension,metrics,label="",data}=props;
	const get_color=useColorFunc();

	let xAlias=dimension.alias || "col0";

	let fields=[].concat(dimension).concat(metrics);
	fields.forEach((d,i)=>d.alias=d.alias || d.label || "col"+i);


	function formatter(value){
		return formatValue(value,metrics[0].format);
	}

	//find the first left format
	let left1=metrics.find(d=>d.yaxis!=="right") ||{};
	function yFormatLeft(val){return formatValue(val,left1.format);}
	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;

	let transpose={};
	fields.slice(1).forEach(f=>{
		transpose[f.alias]={name:f.alias};
	});
	let bars={};
	data.forEach(d=>{bars[d[xAlias]]=true;});

	fields.slice(1).forEach(f=>{
		data.forEach(d=>{
			Object.keys(bars).forEach(bar=>{
				if (bar!==d[xAlias]) return;
				transpose[f.alias][bar]=d[f.alias];
			});
		});
	});

	data=Object.values(transpose);


	return <React.Fragment>
		{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}
		<Grid container className="w-100 report-stacked-bar-chart">
			<Grid item md={12} sm={12}>
				<ResponsiveContainer>
					<ComposedChart
						data={data}
						margin={{
							top: 20, right: 20, bottom: 20, left: 20,
						}}
					>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis dataKey="name"/>
						<YAxis tickFormatter={yFormatLeft}/>
						<Tooltip formatter={formatter}/>
						<Legend />
						{Object.keys(bars).map((f,i)=>{
							let color=f.color || get_color(i,f);
							return <Bar key={i} name={f.label} dataKey={f} stackId="a" fill={color}/>;
						})}
					</ComposedChart>
				</ResponsiveContainer>
			</Grid>
		</Grid>
	</React.Fragment>;
};
