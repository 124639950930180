// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ReportCard from '../../ReportCard';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FraktureScorecard from '../FraktureScorecard.js';
import FraktureVerticalBarChart from '../FraktureVerticalBarChart.js';
import FraktureBarChart from '../FraktureBarChart.js';
//import FraktureStackedBarChart from './FraktureStackedBarChart.js';
import {TabContent,TabContainer} from '../../../../FraktureTabs';
import dayjs from 'dayjs';

export default function StrategicCustomerAnalysis(props){
	let {data:_data,label=""}=props;
	const location=useLocation();
	let params = queryString.parse(location.hash);
	let now=parseInt(new Date().toISOString().slice(0,4));
	const ytdDay=dayjs().subtract(1,'day').format("MMM D");

	params.year=params.year || now;
	params.segment=params.segment || "customers";

	function setYear(y){
		params.year=y;
		document.location.hash=queryString.stringify(params);
	}

	function setSegment(y){
		params.segment=y;
		document.location.hash=queryString.stringify(params);
	}

	if (_data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;

	let currencyFormat="long_currency";
	let longFormat="long";


	const years=[...Array(22).keys()].map(x=>x+2003).filter(x=>x<=now+1);
	const segments=[
		{value:"customers",label:"All Donors"},
		{value:"recurring",label:"Sustainers Only"},
		{value:"nonrecurring",label:"1x Donors (aka non-Sustainers)"},
		{value:"customer_1200",label:"Major Donors ($1,200+ cume)"}
	];
	let revenueFields=[
		{alias:'transaction_revenue_new',label:"New Donor"},
		{alias:'transaction_revenue_newish',label:"Recaptured Donor"},
		{alias:'transaction_revenue_win_back',label:"Won Back"},
		{alias:'transaction_revenue_second_year',label:"Second Year"},
		{alias:'transaction_revenue_multi',label:"Multiyear"},
		/*
		{alias:'transaction_revenue_lapsed',label:"Lapsed"},
		{alias:'transaction_revenue_lapsed_new',label:"Lapsed"},
		{alias:'transaction_revenue_lapsed_extended',label:"Lapsed"}
		*/
	];
	revenueFields.forEach(f=>Object.assign(f,{stackId:"a",format:"currency",type:"bar"}));

	let uniqueCustomerFields=[
		{alias:'transaction_unique_person_new',label:"New Donor"},
		{alias:'transaction_unique_person_newish',label:"Recaptured Donor"},
		{alias:'transaction_unique_person_win_back',label:"Won Back"},
		{alias:'transaction_unique_person_second_year',label:"Second Year"},
		{alias:'transaction_unique_person_multi',label:"Multiyear"},
	];
	uniqueCustomerFields.forEach(f=>Object.assign(f,{stackId:"a",format:"long",type:"bar"}));

	let ytd=false;
	const data=_data.filter(d=>ytd?d.unit==='fytd':d.unit==='fiscal');
	_data.forEach(d=>d.col0=d.date.slice(0,4));

	let thisYear=data.find(d=>d.unit==='fiscal' && d.date===(params.year+'-01-01'));
	let lastYear=data.find(d=>d.unit==='fiscal' && d.date===((parseInt(params.year)-1)+'-01-01'));

	let thisYTD=_data.find(d=>d.unit==='fytd' && d.date===(params.year+'-01-01'));
	let lastYTD=_data.find(d=>d.unit==='fytd' && d.date===((parseInt(params.year)-1)+'-01-01'));


	/* donor description grid
					y-2	y-1	y
					-	-	$				New Donor
					-	$	$				Second Year
					$	$	$				Multi-year
					$ $ -			  Lapsed

					- $ -			  Lapsed New
					$ - -			  Lapsed Extended
					$ - $			  Won Back
			$$	-	-	$				Won Back Extended  (But gave before) aka Newish

					-	-	-				Non donor
					*/

	function calculateByYear(y,_segment){
		let values={};

		values.customer_new=y.transaction_unique_person_new;
		values.customer_recaptured=y.transaction_unique_person_newish+y.transaction_unique_person_win_back;
		values.customer_kept=y.transaction_unique_person_second_year+y.transaction_unique_person_multi;
		values.customer_lapsed=(y.transaction_unique_person_lapsed+y.transaction_unique_person_lapsed_new);
		values.net_customer=values.customer_new+values.customer_recaptured-values.customer_lapsed;
		values.customer_total=values.customer_new+values.customer_recaptured+values.customer_kept;

		values.last_year_customers=
				y.transaction_unique_person_second_year+
				y.transaction_unique_person_multi+
				y.transaction_unique_person_lapsed+
				y.transaction_unique_person_lapsed_new;

		values.customer_retention_rate=(values.last_year_customers-values.customer_lapsed)/values.last_year_customers;
		let segment=_segment || params.segment;

		switch(segment){
		case "customers":
			values.transactions=y.transactions;
			values.revenue=y.revenue;
			values.recurring_revenue=y.recurring_revenue;
			values.bracket=y.transactions_bracket;
			values.recurring_bracket=y.transactions_recurring_bracket;
			values.customers=y.transaction_unique_person;
			break;
		case "recurring":
			values.transactions=y.transaction_count_from_recurring_customers;
			values.revenue=y.transaction_revenue_from_recurring_customers;
			values.recurring_revenue=y.transaction_revenue_from_recurring_customers-y.transaction_revenue_nonrecurring_from_recurring_customers;
			values.bracket=y.recurring_customer_transactions_bracket;
			values.recurring_bracket=y.recurring_customer_transactions_recurring_bracket;
			values.customers=y.transaction_unique_person_recurring;
			break;
		case "nonrecurring":
			values.transactions=y.transaction_count_from_nonrecurring_customers;
			values.revenue=y.transaction_revenue_from_nonrecurring_customers;
			values.recurring_revenue=0;
			values.bracket=y.nonrecurring_customer_transactions_bracket;
			values.recurring_bracket=y.nonrecurring_customer_transactions_recurring_bracket;
			values.customers=y.transaction_unique_person-y.transaction_unique_person_recurring;
			break;
		case "customer_1200":
			values.transactions=y.transaction_count_from_customers_1200;
			values.revenue=y.transaction_revenue_from_customers_1200;
			values.recurring_revenue=y.transaction_revenue_recurring_from_customers_1200;
			values.bracket=y.customer_1200_transactions_bracket;
			values.recurring_bracket=y.customer_1200_transactions_recurring_bracket;
			values.customers=y.transaction_unique_person_1200;
			break;
		}


		values.average=values.revenue/values.transactions;
		values.frequency=values.transactions/values.customers;
		values.revenue_per_customer=values.revenue/values.customers;
		function getInt(s){
			if (!s) return 0;
			return s.indexOf('<')===0?-1:parseInt(s.replace(/[^0-9.-]/g,"").split("-")[0]);}
		function sort(a,b){
			return getInt(a.col0)<getInt(b.col0)?-1:1;
		}
		try{
			values.bracket=JSON.parse(values.bracket);
			values.bracket.forEach(d=>{d.col0=d.bracket;});
			values.bracket.sort(sort);
		}catch(e){
			console.error(e);
			console.error("Bracket values=",values.bracket);
			values.bracket=[];
		}
		try{
			values.recurring_bracket=JSON.parse(values.recurring_bracket);
			values.recurring_bracket.forEach(d=>{d.col0=d.bracket;});
			values.recurring_bracket.sort(sort);
		}catch(e){
			console.error(e);
			console.error("Bad Recurring bracket values=",values.recurring_bracket);
			values.recurring_bracket=[];
		}
		try{
			values.customer_by_size_bracket=JSON.parse(y.customer_by_size_bracket);
			values.customer_by_size_bracket.forEach(d=>{d.col0=d.bracket;});
			values.customer_by_size_bracket.sort(sort);
		}catch(e){
			console.error(e);
			console.error("Bad Customer by size bracket values for year=",y.date,y.customer_by_size_bracket);
			values.customer_by_size_bracket=[];
		}
		return values;
	}
	let byYear=[thisYear,lastYear].map(y=>calculateByYear(y));
	let byYTD=[thisYTD,lastYTD].filter(Boolean).map(y=>calculateByYear(y,false));

	/*
	values.last_year_customers=
			y.transaction_unique_person_second_year+
			y.transaction_unique_person_multi+
			y.transaction_unique_person_lapsed;

	values.total_lapsed=(y.transaction_unique_person_lapsed+y.transaction_unique_person_lapsed_new);
	*/

	byYear[0].customer_retention_rate2=(thisYear.transaction_unique_person-thisYear.transaction_unique_person_new)/lastYear.transaction_unique_person;

	return <div>
		<div className="report-large-select d-flex w-100">
			<Select style={{fontSize:"1em"}} value={parseInt(params.year)}>{years.map(y=><MenuItem key={y} value={parseInt(y)} onClick={e=>setYear(y)}>FY{y}</MenuItem>)}</Select>
		</div>
		<TabContainer useHash={true}>
			<TabContent label="Year To Date" >
				{(byYTD[0] && byYTD[1])?
					<ReportCard title={`Key Performance Indicators (Through ${ytdDay})`}>
						<Grid container spacing={3}>
							<Grid item xs={4}><FraktureScorecard label="Gross Revenue" metric={{name:"revenue",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={4}><FraktureScorecard label="Sustainer Revenue" metric={{name:"recurring_revenue",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={4}><FraktureScorecard label="Average Gift" metric={{name:"average",format:currencyFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Total Gifts" metric={{name:"transactions",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Donors" metric={{name:"customers",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Gifts/Donor" metric={{name:"frequency",format:longFormat}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={3}><FraktureScorecard label="Revenue/Donor" metric={{name:"revenue_per_customer",format:"long_currency"}} data={[byYTD[0]]} include_previous={true} previousData={[byYTD[1]]}/></Grid>
							<Grid item xs={12}></Grid>
							<Grid item xs={12}><hr/></Grid>
							<Grid item xs={12}></Grid>
						</Grid>
					</ReportCard>:<div className="alert alert-info">No YTD info for this year</div>}
			</TabContent>
			<TabContent label="Yearly Summary" >
				<ReportCard title={`Key Performance Indicators (Yearly)`} action={<Select value={params.segment}>{segments.map((s,i)=><MenuItem key={i} value={s.value} onClick={e=>setSegment(s.value)}>{s.label}</MenuItem>)}</Select>}>
					<Grid container spacing={3}>
						<Grid item xs={4}><FraktureScorecard label="Gross Revenue" metric={{name:"revenue",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={4}><FraktureScorecard label="Sustainer Revenue" metric={{name:"recurring_revenue",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={4}><FraktureScorecard label="Average Gift" metric={{name:"average",format:currencyFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={3}><FraktureScorecard label="Total Gifts" metric={{name:"transactions",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={3}><FraktureScorecard label="Donors" metric={{name:"customers",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={3}><FraktureScorecard label="Gifts/Donor" metric={{name:"frequency",format:longFormat}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={3}><FraktureScorecard label="Revenue/Donor" metric={{name:"revenue_per_customer",format:"long_currency"}} data={[byYear[0]]} include_previous={true} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={12}></Grid>
						<Grid item xs={12}><hr/></Grid>
						<Grid item xs={12}></Grid>
					</Grid>
				</ReportCard>
			</TabContent>
			<TabContent label="Gift Analysis" >
				<ReportCard title="Gift Breakdown" action={<Select value={params.segment}>{segments.map((s,i)=><MenuItem key={i} value={s.value} onClick={e=>setSegment(s.value)}>{s.label}</MenuItem>)}</Select>}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<FraktureVerticalBarChart label="Gifts by Range" data={byYear[0].bracket} metric={{label:"Gifts",alias:"transactions",format:"long"}} />
						</Grid>
						<Grid item xs={6}>
							<FraktureVerticalBarChart label="Gross Revenue by Gift Size" data={byYear[0].bracket} metric={{label:"Revenue",alias:"revenue",format:currencyFormat}} />
						</Grid>
					</Grid>
				</ReportCard>
			</TabContent>
			<TabContent label="Sustainer Analysis" >
				<ReportCard title="Sustainer Revenue by Gift Type" action={<Select value={params.segment}>{segments.map((s,i)=><MenuItem key={i} value={s.value} onClick={e=>setSegment(s.value)}>{s.label}</MenuItem>)}</Select>}>
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<FraktureVerticalBarChart label="Gifts breakdown" data={byYear[0].recurring_bracket} metric={{label:"Gifts",alias:"transactions",format:"long"}} />
						</Grid>
						<Grid item xs={6}>
							<FraktureVerticalBarChart label="Revenue breakdown" data={byYear[0].recurring_bracket} metric={{label:"Revenue",alias:"revenue",format:currencyFormat}} />
						</Grid>
					</Grid>
				</ReportCard>
			</TabContent>
			<TabContent label="Donor Type" >
				<ReportCard title="All Donations -- Breakdown by Donor Type">
					<Grid container>
						<Grid item xs={2}><FraktureScorecard label="Total Donors" metric={{name:"customer_total",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={2}><FraktureScorecard label="New Donors" metric={{name:"customer_new",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={2}><FraktureScorecard label="Recaptured Donors" metric={{name:"customer_recaptured",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>
						{/*<Grid item xs={2}><FraktureScorecard label="Kept Donors" metric={{name:"customer_kept",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>*/}
						<Grid item xs={2}><FraktureScorecard label="Lapsed Donors" metric={{name:"customer_lapsed",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={2}><FraktureScorecard label="Net Change in Donors" metric={{name:"net_customer",format:longFormat}} data={[byYear[0]]} include_previous={false} previousData={[byYear[1]]}/></Grid>
						<Grid item xs={2}><FraktureScorecard label="Retention Rate" metric={{name:"customer_retention_rate",format:"percent"}} data={[byYear[0]]}/></Grid>
						{/*<Grid item xs={2}><FraktureScorecard label="Retention Rate (New)" metric={{name:"customer_retention_rate2",format:"percent"}} data={[byYear[0]]}/></Grid>*/}
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={12}><FraktureBarChart label="Revenue by Donor Type" data={data.filter(d=>(d.date>'2003-01-01' && d.date<=now+"-01-01"))} dimension={{alias:"date"}} metrics={revenueFields} /></Grid>
						<Grid item xs={12}><FraktureBarChart label="Donors by Donor Type" data={data.filter(d=>(d.date>'2003-01-01' && d.date<=now+"-01-01"))} dimension={{alias:"date"}} metrics={uniqueCustomerFields} /></Grid>
					</Grid>
				</ReportCard>
			</TabContent>

			<TabContent label="Donors By Amount">
				<ReportCard title="Donors broken down by total gifts">
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<FraktureVerticalBarChart bars={12} label="Donor Counts by Cumulative Giving" data={byYear[0].customer_by_size_bracket} metric={{label:"Donors",alias:"unique_people",format:"long"}} />
						</Grid>
						<Grid item xs={6}>
							<FraktureVerticalBarChart bars={12} label="Gross Revenue by Cumulative Giving" data={byYear[0].customer_by_size_bracket} metric={{label:"Gross Revenue",alias:"total_revenue",format:currencyFormat}} />
						</Grid>
					</Grid>
				</ReportCard>
			</TabContent>
		</TabContainer>
	</div>;
};
