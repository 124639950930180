import React from 'react';
import { useQuery } from "@apollo/client";
//import {useParams} from 'react-router-dom';
import gql from "graphql-tag";
import Grid from '@mui/material/Grid';
import FraktureTable from '../../FraktureTable';
import FraktureBarChart from '../../report/display/components/FraktureBarChart';
import FraktureScorecard from '../../report/display/components/FraktureScorecard';
import {formatValue} from '../../formatters';

import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import Box from '@mui/material/Box';
import {useAccountDetails} from '../AccountInfo';
import {useUserContext,AdminOnly} from '../../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import useNotifiers from '../../Notifiers';


const ACCOUNT_SETTINGS_MUTATION=gql`
mutation ACCOUNT_SETTINGS_MUTATION($_id:ID!,$name:String!,$value:String!){
	account_settings_set(_id:$_id,name:$name,value:$value) {
		_id
		name
		settings
		uninherited_settings
	}
}`;


const ACCOUNTING_QUERY = gql(`
query ACCOUNTING_QUERY($account_ids: [ID],$start:String,$end:String) {
	accounting_summary(account_ids: $account_ids,start:$start,end:$end)	
}`);

function useSummariesByAccount({data,loading,error}){
	if (loading) return {loading};
	if (error) return {error};
	return {accounts:data?.accounting_summary?.map(account=>{
		if (typeof account=='string') account=JSON.parse(account);
		let {_id:account_id,name,usage,invoices=[],payments=[],child_count=0,rate_string="$0"}=account;
		let months={};
		invoices.sort((a,b)=>a.date<b.date?-1:1);
		let lastInvoice=invoices.filter(d=>d.amount>0).slice(-1)[0];
		payments.sort((a,b)=>a.date<b.date?-1:1);
		let lastPayment=payments.filter(d=>d.amount>0).slice(-1)[0];
		let totals={
			account_id,
			name,
			invoices:0,
			invoice_amount:0,
			outstanding_invoices:0,
			outstanding_amount:0,
			payments:0,
			payment_amount:0,
			child_count,
			rate_string,
			last_invoice:lastInvoice,
			last_invoice_date:lastInvoice?.date,
			last_invoice_amount:lastInvoice?.amount,
			last_payment:lastPayment,
			last_payment_date:lastPayment?.date,
			needs_updating:''
		};
		(usage||[]).forEach(x=>{
			let m=x.date.slice(0,7)+"-01";
			months[m]=months[m]||{date:m};

			if (x.jobs>30){//allow for a small variation of on-demand jobs that don't really count
				totals.jobs=(totals.jobs||0)+(x.jobs||0);
				if (!totals.last_job_date || totals.last_job_date<x.date) totals.last_job_date=x.date;
			}
			if(x.realtime_hits>10){
				totals.realtime_hits=(totals.realtime_hits||0)+(x.realtime_hits||0);
				if (!totals.last_realtime_date || totals.last_realtime_date<x.date) totals.last_realtime_date=x.date;
			}
			months[m].realtime_hits=(months[m].realtime_hits||0)+(x.realtime_hits||0);
			months[m].jobs=(months[m].jobs||0)+(x.jobs||0);
		});
		invoices.forEach(x=>{
			totals.invoices++;
			totals.invoice_amount+=x.amount;
			totals.outstanding_amount+=(x.balance||0);
			if (x.balance>0) totals.outstanding_invoices++;
			let m=x.date.slice(0,7)+"-01";
			months[m]=months[m]||{date:m};
			months[m].invoice_amount=(months[m].invoice_amount||0)+(x.amount||0);
			months[m].outstanding_amount=(months[m].outstanding_amount||0)+(x.balance||0);
		});
		payments.forEach(x=>{
			totals.payments++;
			totals.payment_amount+=x.amount;
			let m=x.date.slice(0,7)+"-01";
			months[m]=months[m]||{date:m};
			months[m].payment_amount=(months[m].payment_amount||0)-(x.amount||0);
		});
		let outMonths=Object.values(months).sort((a,b)=>a.date<b.date?-1:1);
		if (rate_string){
			let rate_parts=rate_string.split("/");
			let amount=parseFloat(rate_parts[0].replace(/[^0-9.]/g,''));
			if (amount!==totals.last_invoice_amount && amount>0){
				totals.needs_updating=`Amount:${amount}!==${totals.last_invoice_amount}`;
			}else if (lastInvoice?.date && amount>0){
				let secondsAgo=(new Date().getTime()-new Date(lastInvoice.date).getTime())/1000;
				let schedule=(rate_parts[1]||"month").toLowerCase();
				switch(schedule){
				case 'yr':
				case 'year':if (secondsAgo>370*24*60*60){totals.needs_updating=`> 1 Year (${Math.floor(secondsAgo/(24*60*60))} days)`;} break;
				case 'month':if (secondsAgo>33*24*60*60){totals.needs_updating=`> 1 Month (${Math.floor(secondsAgo/(24*60*60))} days)`;} break;
				case 'qt':
				case 'qtr':
				case 'qr':
				case 'quarter':if (secondsAgo>95*24*60*60){totals.needs_updating=`> 1 Quarter (${Math.floor(secondsAgo/(24*60*60))} days)`;} break;
				case '2qr':
				case '2quarter':if (secondsAgo>185*24*60*60){totals.needs_updating=`> 2 Quarter (${Math.floor(secondsAgo/(24*60*60))} days)`;} break;
				}
			}
		}

		return {account_id,totals,months:outMonths};
	})};
}

function AccountDisplay({totals,months}){
	if (months.length===0){
		return "No accounting or usage data for this timeframe and account";
	}
	return <Grid style={{height:"600px"}} container>
		<Grid item xs={3}><FraktureScorecard label="Invoice Total" metric={{name:"invoice_amount",format:"currency"}} data={[totals]}/></Grid>
		<Grid item xs={3}><FraktureScorecard label="Payment Total" metric={{name:"payment_amount",format:"currency"}} data={[totals]}/></Grid>
		<Grid item xs={3}><FraktureScorecard label="Child Accounts" metric={{name:"child_count"}} data={[totals]}/></Grid>
		<Grid item xs={3}><FraktureScorecard label="Current Rate" metrics={[{name:"rate_string",format:"string"}]} data={[totals]}/></Grid>
		<Grid item xs={4}><FraktureScorecard label="Outstanding Invoices" metric={{name:"outstanding_invoices"}} data={[totals]}/></Grid>
		<Grid item xs={4}><FraktureScorecard label="Outstanding Amount" metric={{name:"outstanding_amount",format:"currency"}} data={[totals]}/></Grid>
		<Grid item xs={4}><FraktureScorecard label="Last Invoice" metrics={[{name:"last_invoice_amount",format:"currency"},{name:"last_invoice_date",format:"date"}]} data={[totals]}/></Grid>
		<Grid item xs={12}><FraktureBarChart label="Accounting Activity" data={months} is_date={true}
			dimension_alias="date" metrics={[
				{alias:"realtime_hits",yaxis:"left"},
				{alias:"jobs",yaxis:"left"},
				{alias:"invoice_amount",type:"bar",color:"#633",yaxis:"right",format:"currency"},
				{alias:"outstanding_amount",type:"bar",color:"#E33",yaxis:"right",format:"currency"},
				{alias:"payment_amount",type:"bar",color:"#393",yaxis:"right",format:"currency"}]}/></Grid>
	</Grid>;
}

export function AccountingSettings(props){
	const {is_frakture_admin}=useUserContext();
	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const {account,loading,error}=useAccountDetails(props);
	if (loading) return "Loading...";
	if (error) return "Error loading account";
	if(!is_frakture_admin) return null;
	let {_id:account_id,settings}=account;
	function mutateSetting({name,value}){
		client.mutate({mutation:ACCOUNT_SETTINGS_MUTATION,
			refetchQueries:["ACCOUNTING_QUERY"],
			variables:{_id:account_id,name,value}}).then(notify,notifyErr);
	};
	if (typeof settings=='string'){
		try{
			settings=JSON.parse(settings);
		}catch(e){
		}
	}
	let {accounting_customer_id,rates}=settings;
	return <AdminOnly>
		<Box
			component="form"
			sx={{
				'& .MuiFormControl-root': { m: 1,p:1 }
			}}
			noValidate
			autoComplete="off"
		>
			<TextField label='Accounting Customer ID' defaultValue={accounting_customer_id} fullWidth onBlur={e => {
				let value=e.target.value;
				if (value===accounting_customer_id) return;
				mutateSetting({name:"accounting_customer_id",value});
			}} />
			<TextField label='Accounting Rates'
				multiline
				rows={5} defaultValue={rates} fullWidth onBlur={e => {
					let value=e.target.value;
					if (value===rates) return;
					mutateSetting({name:"rates",value});
				}} />
		</Box>
	</AdminOnly>;
}

export function Accounting({account_id,include_table=false}){
	const {data,loading,error}=useQuery(ACCOUNTING_QUERY,{variables:{account_ids:[account_id],start:"-4y"}});
	const {accounts,loading:loading2,error:error2}=useSummariesByAccount({data,loading,error});
	if(loading2) return "";
	if(error2) throw error2;
	return <div>
		{accounts.length===0?"No usage or accounting information for this account":accounts.map((d,i)=>{
			if (typeof d=='string') d=JSON.parse(d);
			return <AccountDisplay key={i} {...d}/>;
		})}
		<Divider>Accounting Settings</Divider>
		<AccountingSettings/>
	</div>;
};


export function AllAccounting(){
	const {data,loading,error}=useQuery(ACCOUNTING_QUERY,{variables:{account_ids:['*'],start:"-2y"}});
	const {accounts,loading:loading2,error:error2}=useSummariesByAccount({data,loading,error});
	if(loading2) return "";
	if(error2) throw error2;
	function currency(v){return formatValue(v,"currency");}
	function date(v){return formatValue(v,"date");}
	let rows=accounts.map(x=>x.totals);
	let columns=[
		{field:"account_id",
			title:"Account",
			render:({row})=><a target="_blank" href={`/app/${row.account_id}/settings?account_settings=accounting`}>{row.name}</a>
		},
		{field:"last_invoice_date",title:"Last Invoice",format:date},
		{field:"last_invoice_amount",title:"Last Invoice $",format:currency},
		{field:"rate_string",title:"Rate",format:x=>x},
		{field:"needs_updating",title:"Update Needed?",format:x=>x},
		{field:"outstanding_amount",title:"Outstanding Total",format:currency},
		{field:"outstanding_invoices",title:"Outstanding Invoices"},
		{field:"child_count",title:"Children"},
		{field:"realtime_hits",title:"Realtime Hits"},
		{field:"jobs",title:"Jobs"},
		{field:"last_realtime_date",title:"Last Realtime",format:date},
		{field:"last_job_date",title:"Last Job",format:date},
		{field:"payments",title:"Payments",hidden:true},
		{field:"payment_amount",title:"Payment Total",format:currency},
		{field:"invoices",title:"Invoices"},
		{field:"invoice_amount",title:"Invoice Total",format:currency,hidden:true}
	];
	//let selectedFields=columns.map((f)=>f.hidden?null:f.field).filter(x=>x!==null);
	return <div>
		<FraktureTable rows={rows} columns={columns} rowsPerPage={1000}/>
	</div>;
};

