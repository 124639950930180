import React, {useState} from 'react';
import gql from "graphql-tag";
import FraktureQuery from "../FraktureQuery";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import StubMutation from "../StubMutation";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from 'dayjs';

const deployedPackageFragment=`
	_id
	label
	deployments {
		type
	}
	need_redeploy
	ready_to_deploy
	last_deployed
	configure_job_list_id
	configuration {
		options {
			context_id
			label
			select_options
			value
			waiting_for_previous
		}
	}
	status
	deploy_job_list_id
	package {
		_id
		hash
		label
	}
`;

const deployedPackageQuery=gql`query deployedPackage($deployed_package_id: ID!) {
	deployed_package(_id: $deployed_package_id) {
		${deployedPackageFragment}
	}
}`;

const startConfigureMutation=gql`mutation startConfigure($deployed_package_id:ID!) {
	package_start_redeploy(deployed_package_id: $deployed_package_id) {
		_id
		configure_job_list_id
		status
		configuration {
			options {
				context_id
				label
				select_options
				value
			}
		}
	}
}`;

const setValueMutation=gql`mutation setValue($deployed_package_id: ID!, $input: PackageConfigurationOptionInput!) {
	package_configuration_update(deployed_package_id: $deployed_package_id, input: $input) {
		${deployedPackageFragment}
	}
}`;

function PackageConfigurationOption({deployed_package_id,option}) {
	let{label,context_id,select_options,value,waiting_for_previous,description}=option;

	const[storedValue,setStoredValue]=useState(value);

	return <StubMutation mutation={setValueMutation}>
		{ mutate => {
			if(!description) {
				description = label;
				label = context_id.split('_').map(x => x[0].toUpperCase()+x.substring(1)).join(' ');
			}

			const onChange = e => {
				mutate({
					variables: {
						deployed_package_id,
						input: {
							context_id,
							value: e.target.value
						}
					}
				});
			};

			let keepDefault;
			if(value){
				keepDefault = <InputAdornment>
					<Button disabled={waiting_for_previous} onClick={() => {
						mutate({
							variables: { deployed_package_id, input: {context_id, value } }
						});
					}}><i className="zmdi zmdi-hc-2x zmdi-check-square"/></Button>
				</InputAdornment>;
			}

			if(!waiting_for_previous && select_options && select_options.length) {
				let selectValue=null;
				if(value) selectValue = value.value || value.id || value._id || value;
				return <FormControl fullWidth>
					<InputLabel>{label}</InputLabel>
					<Select value={selectValue} onChange={onChange} endAdornment={keepDefault}>
						{select_options.map(opt => {
							const label = opt.label;
							const value = opt.value || opt.id || opt._id;
							return <MenuItem key={value} value={value}>{label}</MenuItem>;
						})}
					</Select>
				</FormControl>;
			}

			return <FormControl fullWidth>
				<InputLabel>{label}</InputLabel>
				<Input value={storedValue} onChange={e => setStoredValue(e.target.value)}
					onBlur={onChange} endAdornment={keepDefault}
					helperText={description} fullWidth disabled={waiting_for_previous}/>
			</FormControl>;
		}}
	</StubMutation>;
};

const deployPackageMutation=gql`mutation deployPackage($deployed_package_id: ID!) {
	package_start_deploy(deployed_package_id: $deployed_package_id) {
		${deployedPackageFragment}
	}
}`;

function StartDeploymentButton(props) {
	const{deployed_package}=props;
	const{ready_to_deploy,_id:deployed_package_id}=deployed_package;
	return <StubMutation mutation={deployPackageMutation}>
		{deploy => <Button variant='contained' color='primary' onClick={() => {
			console.log('running for:',deployed_package_id);
			deploy({variables:{deployed_package_id}});
		}} disabled={!ready_to_deploy}>
			Deploy Package
		</Button>}
	</StubMutation>;
};

function PackageConfiguration(props) {
	const {deployed_package}=props;
	const deployed_package_id = deployed_package._id;
	return <div>
		{deployed_package.configuration.options.map(option => <div key={option.context_id} style={{marginTop:'15px'}}>
			<PackageConfigurationOption option={option} deployed_package_id={deployed_package_id} />
		</div>)}
		<div style={{marginTop:'20px'}}>
			<StartDeploymentButton deployed_package={deployed_package} />
		</div>
	</div>;
};

function MonitorDeployPackageConfiguration() {
	return <div>
		This package is currently being deployed:
	</div>;
};

export default class DeployedPackage extends React.Component {
	renderRedeploy(deployed_package) {
		const {need_redeploy, /*last_deployed,*/ _id:deployed_package_id}=deployed_package;
		if(need_redeploy) {
			const redeploy = <StubMutation mutation={startConfigureMutation}>
				{mutate => {
					return <button onClick={() => mutate({variables:{deployed_package_id}})}>redeploy</button>;
				}}
			</StubMutation>;
			return <div>
				<i className="zmdi zmdi-alert-triangle" style={{color:'red'}} />
				This deployment is out of date. Would you like to {redeploy}?
			</div>;
		}

		return <div>This deployment is up to date.</div>;
	}

	renderDeployed(deployed_package) {
		const {/*last_deployed,*/_id: deployed_package_id}=deployed_package;
		let lastDeployed = 'N/A';
		if(deployed_package.last_deployed) lastDeployed=dayjs(deployed_package.last_deployed).calendar();
		const redeploy = <StubMutation mutation={startConfigureMutation}>
			{mutate => {
				return <button className="anchor" onClick={() => mutate({variables:{deployed_package_id}})}>redeploy</button>;
			}}
		</StubMutation>;
		return <div>
			<div>This Deployment is up to date. Last deployed: {lastDeployed}.</div>
			<div>Would you like to {redeploy}?</div>
		</div>;
	}

	render() {
		const{deployed_package_id}=this.props.match.params;
		return <Card style={{maxWidth: '1000px', margin:'auto'}}>
			<FraktureQuery query={deployedPackageQuery} variables={{deployed_package_id}} pollInterval={10000}>
				{(data) => {
					const{deployed_package}=data;
					const fp = deployed_package.package;
					return <React.Fragment>
						<CardHeader title={deployed_package.label || deployed_package._id} subheader={fp.label}/>
						<CardContent>
							<div>
								Status: {deployed_package.status}
							</div>
							{(() => {
								if(deployed_package.status === 'configuration') return <PackageConfiguration deployed_package={deployed_package} />;
								if(deployed_package.status === 'deploying') return <MonitorDeployPackageConfiguration deployed_package={deployed_package}/>;
								if(deployed_package.need_redeploy) return this.renderRedeploy(deployed_package);
								if(deployed_package.status === 'deployed') return this.renderDeployed(deployed_package);
								return 'unknown';
							})()}
						</CardContent>
					</React.Fragment>;
				}}
			</FraktureQuery>
		</Card>;
	}
};
