import React from 'react';

import gql from 'graphql-tag';

import {fraktureQuery} from '../../FraktureQuery';
import useBotId from '../BotIdProvider';
import {useUserContext} from '../../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import useNotifiers from '../../Notifiers';

const HIDDEN_BOT_FIELD_QUERY=gql`
query HIDDEN_BOT_FIELD_QUERY($bot_id:ID!) {
	bot(_id:$bot_id) {
		_id
		hidden_auth_fields
	}
}`;

const UNSET_FIELD_MUTATION=gql`
mutation UNSET_FIELD_MUTATION($bot_id:ID!,$key:String!) {
	bot_unset_auth(_id:$bot_id, key:$key) {
		_id
		hidden_auth_fields
	}
}`;

export default function HiddenBotAuthFields(props) {
	const {is_frakture_admin}=useUserContext();

	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const bot_id = useBotId(props);
	if(!is_frakture_admin) return null;

	return fraktureQuery({query:HIDDEN_BOT_FIELD_QUERY,variables:{bot_id}}, data => {
		const {hidden_auth_fields} = data.bot || {};
		if(!(hidden_auth_fields||[]).length) return null;

		return <React.Fragment>
			<h3>Hidden Auth Fields:</h3>
			{hidden_auth_fields.map(key => {
				return <div key={key}>
					<button className="anchor" onClick={(e) => {
						e.preventDefault();
						client.mutate({
							mutation: UNSET_FIELD_MUTATION,
							variables: {bot_id,key}
						}).then(notify,notifyErr);
					}}>Remove</button> {key}?
				</div>;
			})}
		</React.Fragment>;
	});
}
