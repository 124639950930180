import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import {Link} from 'react-router-dom';

import {useAllAccounts} from '../account/AccountInfo';
import {Header} from '../components/Header';
import NewAccountCard from './NewAccountCard';


/*
function DataflowStatus(props){
	if (true) return "";
	const {
		dataflow_count,
		scheduled_dataflows,
		unscheduled_dataflows,
		errored_dataflows
	}=props.account.metrics||{};

	if(!dataflow_count) return "Dataflows: 0";

	return <div style={{textAlign:"right"}}>
		<br/> {scheduled_dataflows?<span className="badge badge-pill text-white badge-green bg-green">
			{scheduled_dataflows}&nbsp;
			<span className="zmdi zmdi-check" />
		</span>:""}
		{errored_dataflows?<span className="badge badge-pill text-white badge-red bg-red">
			{errored_dataflows}&nbsp;
			<span className="zmdi zmdi-close" />
		</span>:""}{unscheduled_dataflows?<span className="badge badge-pill text-white badge-grey bg-grey">
			{unscheduled_dataflows}&nbsp;?
		</span>:""}
	</div>;
}
*/

function AccountList({parent_id}){
	const {accounts,loading,error}=useAllAccounts();
	if (loading) return loading;
	if (error) return error;

	let stats={};
	let parentMap=accounts
		.filter(d=>{
			if (!parent_id) return true;
			return (d.parent_ids||[]).find(p=>p===parent_id);
		})
		.reduce((a,b)=>{a[(b.parent_ids||[])[0]||""]=(a[(b.parent_ids||[])[0]||""]||[]).concat(b);
			(b.parent_ids||[]).forEach(p=>{
				stats[p]=stats[p]||{active:0,disabled:0};
				if (b.disabled) stats[p].disabled++;
				else stats[p].active++;
			});
			return a;},{});
	Object.keys(parentMap).forEach(k=>parentMap[k]=parentMap[k].sort((a,b)=>{
		let x=(a.disabled?1:0)+a.name;
		let y=(b.disabled?1:0)+b.name;
		return x<y?-1:1;
	}));

	return <div className="app-main-content-wrapper">
		<Header
			primary={[
				<h2 key="title">Account List</h2>
			]}
		/>
		<div className="app-main-content">
			<NewAccountCard parent_id={parent_id}/>
			{Object.keys(parentMap).sort().map((id,key)=>{
				let statLine="";
				let name=accounts.find(d=>d._id===id)?.name;
				if (stats[id]) statLine=" - Active: "+(stats[id].active ||0);
				if (stats[id]?.disabled)statLine+=", Disabled: "+(stats[id].disabled ||0);
				return <React.Fragment key={key}>
					<Paper className="p-3 m-2">
						<h1>{name || id || "(No parent)"} {statLine}</h1>
						<Grid container spacing={1}>
							{parentMap[id].map((account,key2)=><Grid key={key2} item md={3}><Link to={`/app/${account._id}/jobs`}>{account.name}</Link></Grid>)}
						</Grid>
					</Paper>
				</React.Fragment>;
			})}
		</div>
	</div>;
}

export default AccountList;
