/* global firebase */
import {useState,useEffect} from 'react';

if(!window.fraktureUiInfo) throw new Error('missing fraktureUiInfo');

const urlParams = new URLSearchParams(window.location.search);
let qsAuthToken=urlParams.get('auth_token');

let getCurrentUser = () => new firebase.auth().currentUser;
if(qsAuthToken != null) {
	getCurrentUser = () => ({
		getIdToken: () => new Promise((resolve) => resolve(qsAuthToken))
	});
} else {
	// Initialize Firebase
	let config = {
		apiKey: "AIzaSyD3WmGipWoRWBxZQ5BKMZzKBALGDMktw5c",
		authDomain: "fraktureconsole.firebaseapp.com",
		databaseURL: "https://fraktureconsole.firebaseio.com",
		projectId: "fraktureconsole",
		storageBucket: "fraktureconsole.appspot.com",
		messagingSenderId: "828436552232"
	};
	firebase.initializeApp(config);
}
window.getCurrentUser = getCurrentUser;

export async function getToken() {
	const token = await getCurrentUser().getIdToken();
	return token;
}

export async function refreshUser() {
	await getCurrentUser().refresh();
}

export function useAuthToken() {
	const [authToken,setAuthToken]=useState();
	const [error,setError]=useState();

	const [loading,setLoading]=useState(true);

	useEffect(() => firebase.auth().onIdTokenChanged(async (user) => {
		let t;
		try {
			t = await getCurrentUser().getIdToken();
		} catch(e) {
			console.log("Error getting user",e);
			setError(e);
			setAuthToken(null);
			setLoading(false);
			return;
		}
		setAuthToken(t);
		setLoading(false);
	}), []);

	useEffect(() => {
		(async () => {
			let user = getCurrentUser();
			if(user) {
				let t=await user.getIdToken();
				setAuthToken(t);
				//console.log("currentUser: Setting loading=false",{t,error,authToken});
				setLoading(false);
			}
		})();
	},[]);

	if(qsAuthToken) {
		return qsAuthToken;
	}
	if (!authToken && !error && !loading) debugger;
	//console.log("useAuthToken returning:",{authToken,error,loading});
	return [authToken,error,loading];
}

export function useCheckUserExists() {
	const {userCheckEndpoint}=window.fraktureUiInfo;
	const [token] = useAuthToken();
	// start at true, and then we'll see if we can't break it
	const [exists,setExists]=useState('maybe');

	useEffect(() => {
		if(!userCheckEndpoint) {
			console.error('missing userCheckEndpoint');
			setExists(true);
			return;
		}
		if(!token) return;
		(async () => {
			const r = await fetch(userCheckEndpoint, {
				headers: {
					Authorization: 'BEARER '+token
				}
			});
			setExists(r.status === 200);
		})();
	}, [token,userCheckEndpoint]);
	return exists;
}

export async function acceptInvite(invite_token) {
	const {inviteEndpoint} = window.fraktureUiInfo;
	if(!inviteEndpoint) {
		console.error('missing inviteEndpoint');
		return false;
	}
	const token = await getToken();
	if(!token) return;
	const r = await fetch(inviteEndpoint+invite_token, {
		headers: {
			Authorization: 'BEARER '+token
		},
		method: 'POST'
	});
	const result = await r.json();
	return result;
}

export function isVerified() {
	const user = getCurrentUser();
	if(!user) return false;
	return user.emailVerified;
}
export function sendVerificationEmail() {
	const user = getCurrentUser();
	if(!user) return;
	user.sendEmailVerification();
}

export function loginWithGoogle() {
	const provider = new firebase.auth.GoogleAuthProvider();
	firebase.auth().signInWithPopup(provider).catch(e => {throw(e);});
};
export function login({username,password}, callback) {
	// let failed=false;
	firebase.auth().signInWithEmailAndPassword(username, password).then(u => {
		if(callback) callback(null, u);
	}).catch(function(error) {
		if(callback) callback(error);
		else throw error;
	});
};
export function createAccount({username,password},callback) {
	firebase.auth().createUserWithEmailAndPassword(username,password)
		.then(u => callback(null,u))
		.catch(e => {
			switch(e.code) {
			case 'auth/email-already-in-use': return callback({
				email_error: 'email already in use'
			});
			case 'auth/invalid-email': return callback({
				email_error: 'invalid email - please provide a valid email address'
			});
			case 'auth/operation-not-allowed': return callback('unexpected error occurred');
			case 'auth/weak-password': return callback({
				password_error: 'invalid password - please follow password creation rules'
			});
			default: throw new Error("Authentication -- invalid code:"+e.code);
			}
		});
};

let onLogout=() => {};
export function logout() {
	firebase.auth().signOut().then(() => window.location.reload());
};
export function setOnLogout(fn) {
	onLogout = fn || onLogout;
};
export function currentUser(){
	return new firebase.auth().currentUser;
}
