import React from 'react';

import useNotifiers from '../../Notifiers';
// import {useUserContext} from '../../../AdminCheck';
import {fraktureQuery} from '../../FraktureQuery';
import {useApolloClient} from '@apollo/client';
import {Link} from 'react-router-dom';

import FraktureTable from '../../FraktureTable';

import gql from 'graphql-tag';

import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import FormControlLabel from '@mui/material/FormControlLabel';
import BotSharableCheckbox from './BotSharableCheckbox';

import SharedAccountSelect from '../../account/SharedAccountSelect';

import fragments from '../gql-fragments';

const BOT_CHILDREN=gql`
query BOT_CHILDREN($bot_id:ID!) {
	bot(_id:$bot_id) {
		... botWithChildren
	}
}
${fragments.botWithChildren}
`;

const ADD_CHILD_FOR_ACCOUNT=gql`
mutation($path: String!, $parent_id:ID!,$account_id:ID!) {
	bot_create(account_id:$account_id, bot_path:$path, parent_id:$parent_id) {
		_id
		parent {
			... botWithChildren
		}
	}
}
${fragments.botWithChildren}
`;

function AddChildForAccountButton(props) {
	const client = useApolloClient();
	const{notify,notifyErr}=useNotifiers();
	const{parent_id,account_id,path}=props;
	return (
		<IconButton
			onClick={() => {
				client.mutate({
					mutation: ADD_CHILD_FOR_ACCOUNT,
					variables: {
						parent_id,
						account_id,
						path
					}
				}).then(notify,notifyErr);
			}}
			size="large">
			<AddIcon />
		</IconButton>);
}

const BOT_UPDATE_AUTH=gql`mutation($bot_id:ID!,$key:String!,$value:String!) {
	bot_set_auth(_id:$bot_id,key:$key,value:$value) {
		_id
		auth {
			bot{
				api_endpoint
				token
			}
			key
			value
			parent_value
			is_value_set
			is_parent_value_set
			oauth_uri
			field {
				type
				label
				description
				is_visible
				show_in_ui
		  }
		}
	}
}`;

function ChildBotAuthField(props) {
	const {bot_id,auth,field,auth_key}=props;

	const [newValue,setNewValue]=React.useState(auth&&auth.value);
	const {notify,notifyErr}=useNotifiers();
	const client=useApolloClient();

	let placeholder;
	if(!auth.is_value_set && auth.is_parent_value_set) {
		placeholder=auth.parent_value || '**********';
	}

	let type;
	if(!field.is_visible) type='password';

	let displayValue = newValue;
	if(!newValue && field.is_value_set && !field.is_visible) displayValue = '**********';

	return <TextField
		fullWidth
		placeholder={placeholder}
		type={type}
		onChange={e => setNewValue(e.target.value)}
		onBlur={e => {
			const value = e.target.value;
			if(value === auth.value || (!auth.is_value_set && !value)) return;

			client.mutate({
				mutation: BOT_UPDATE_AUTH,
				variables: {bot_id,key:auth_key, value}
			}).then(notify,notifyErr);
		}}
		value={displayValue}/>;
};

function SelectAccountAddBot(props) {
	const {account_id,bot_id,path}=props;

	const [child,setChild]=React.useState(null);
	console.log(child);

	return <React.Fragment>
		<SharedAccountSelect account_id={account_id}
			onChange={x=>setChild(x)}
			value={child}
		/>
		<AddChildForAccountButton
			account_id={(child||{}).value}
			parent_id={bot_id}
			path={path}
		/>
	</React.Fragment>;
};

const UPDATE_BOT_LABEL_MUTATION = gql`mutation UPDATE_BOT_MUTATION($bot_id:ID!,$label:String,$nickname:String) {
	bot_update(_id:$bot_id, data:{label:$label,nickname:$nickname} ) {
		_id
		label
		nickname
	}
}`;
function ChildBotLabelField(props) {
	const {bot_id,label}=props;
	const {notify,notifyErr}=useNotifiers();
	const [newValue,setNewValue]=React.useState(label);
	const client = useApolloClient();
	React.useEffect(() => setNewValue(label), [label]);

	return <TextField
		fullWidth
		style={{minWidth:"250px"}}
		onChange={e => setNewValue(e.target.value)}
		onBlur={e => {
			const value = e.target.value;
			if(value === label) return;
			client.mutate({
				mutation: UPDATE_BOT_LABEL_MUTATION,
				variables: {label:value,bot_id}
			}).then(notify,notifyErr);
		}}
		value={newValue} />;
};
function ChildBotNicknameField(props) {
	const {bot_id,nickname}=props;
	const {notify,notifyErr}=useNotifiers();
	const [newValue,setNewValue]=React.useState(nickname);
	const client = useApolloClient();
	React.useEffect(() => setNewValue(nickname), [nickname]);

	return <TextField
		fullWidth
		style={{minWidth:"150px"}}
		onChange={e => setNewValue(e.target.value)}
		onBlur={e => {
			const value = e.target.value;
			if(value === nickname) return;
			client.mutate({
				mutation: UPDATE_BOT_LABEL_MUTATION,
				variables: {nickname:value,bot_id}
			}).then(notify,notifyErr);
		}}
		value={newValue} />;
};

const UPDATE_BOT_TABLE_PREFIX_MUTATION = gql`mutation UPDATE_BOT_MUTATION($bot_id:ID!, $warehouse_table_prefix:String!) {
	bot_set_warehouse_prefix(_id:$bot_id, warehouse_table_prefix:$warehouse_table_prefix) {
		_id
		warehouse_table_prefix
	}
}`;
function ChildBotTablePrefixField(props) {
	const {bot_id,warehouse_table_prefix}=props;
	const {notify,notifyErr}=useNotifiers();
	const [newValue,setNewValue]=React.useState(warehouse_table_prefix);
	const client = useApolloClient();
	React.useEffect(() => setNewValue(warehouse_table_prefix), [warehouse_table_prefix]);

	return <TextField
		fullWidth
		onChange={e => setNewValue(e.target.value)}
		onBlur={e => {
			const value = e.target.value;
			if(value === warehouse_table_prefix) return;
			client.mutate({
				mutation: UPDATE_BOT_TABLE_PREFIX_MUTATION,
				variables: {warehouse_table_prefix:value,bot_id}
			}).then(notify,notifyErr);
		}}
		value={newValue} />;
};

export default function BotChildren(props) {
	const{bot_id}=props;
	return fraktureQuery({
		query: BOT_CHILDREN,
		variables:{bot_id}
	}, ({bot:{path,children,auth,account}}) => {

		const childAccounts = account.children.map(x => {
			return {account_id:x._id, account:x};
		}).filter(x => !children.find(c => c.account_id === x.account_id));

		const columns=[{
			title:'Account',
			field:'account',
			render: ({row}) =>
				<Link to={`/app/${row.account_id}`}>{row.account.name}{row.account.disabled?" (Disabled)":""}</Link>
		},
		{
			title:'Account ID',
			field:'account_id',
			hidden:true
		},
		{
			title: 'Bot',
			field: 'bot',
			render: ({row}) => {
				if(row.bot_id) return <Tooltip title={row.bot.label}>
					<Link to={`/app/${row.account_id}/bot/${row.bot_id}`}>{row.bot_id}</Link>
				</Tooltip>;

				return <AddChildForAccountButton
					account_id={row.account_id}
					parent_id={bot_id}
					path={path}
				/>;
			}
		}, {
			title: 'Label',
			field: 'label',
			render: ({row}) => {
				if(!row.bot_id) return '';
				return <ChildBotLabelField bot_id={row.bot_id} label={row.bot.label} />;
			}
		},
		{
			title: 'Nickname',
			field: 'nickname',
			render: ({row}) => {
				if(!row.bot_id) return '';
				return <ChildBotNicknameField bot_id={row.bot_id} nickname={row.bot.nickname} />;
			}
		},

		{
			title: 'Warehouse Prefix',
			field: '',
			render: ({row}) => {
				if(!row.bot_id) return '';
				return <ChildBotTablePrefixField bot_id={row.bot_id} warehouse_table_prefix={row.bot.warehouse_table_prefix} />;
			}
		}].concat(auth.map(x => {
			return {
				title: x.field.label,
				field: x.key,
				render: ({row}) => {
					const cell = (row.auth_fields||{})[x.key];
					if(!cell) return;
					return <ChildBotAuthField key={x.key} {...{
						auth_key:x.key,
						field:x.field,
						auth:cell,
						bot_id:row.bot_id}}
					/>;
				}
			};
		}));
		const rows=children.map(x => {
			const data= {auth_fields: {}};
			x.auth.forEach(a => data.auth_fields[a.key]=a);
			data.account_id = x.account_id;
			data.bot_id = x._id;
			data.account = x.account;
			data.bot=x;
			return data;
		}).concat(childAccounts);
		rows.sort((a)=>a.account.disabled?1:-1);

		return <Card>
			<CardHeader title="Child Bots"/>
			<CardContent>
				<FormControlLabel
					control={<BotSharableCheckbox bot_id={bot_id} />}
					label='Shareable?'
				/>
			</CardContent>
			<CardContent>
				<FraktureTable {...{columns,rows}} />
			</CardContent>
			<CardContent>
				<SelectAccountAddBot account_id={account._id} path={path} bot_id={bot_id} />
			</CardContent>
		</Card>;
	});
}
