import React from 'react';
import {useQuery} from '@apollo/client';
import Grid from '@mui/material/Grid';
import {DICTIONARY_BOT_QUERY} from '../warehouse/account-warehouse-info.js';
import DataGridWarehouseTable from '../warehouse/DataGridWarehouseTable';
import useAccountWarehouseInfo from '../warehouse/account-warehouse-info';
import {useAccountId} from '../account/AccountInfo.js';



function SourceCodeDateRange(){
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading || error) return loading || error;
	let {global_table_prefix,warehouse_bot_id}=data;
	let table=global_table_prefix+"source_code_metadata_by_range";
	let fields=[
		{field:"source_code",title:"Source Code",width:350},
		{field:"start",title:"Start Date",width:120},
		{field:"end",title:"End Date",width:120},
		{field:"budget",title:"Budget",width:150,format:"currency"},
		{field:"budget_paid_by_client",title:"Budget Paid By Client",width:150,format:"currency"},
		{field:"planned_spend",title:"Planned Spend",width:150,format:"currency"},
		{field:"projected_audience_size",title:"Prj. Audience Size",width:180,format:"long"},
		{field:"projected_messages",title:"Prj. Messages",width:180,format:"long"},
		{field:"projected_transaction_rate",title:"Prj. Transaction Rate",width:180,format:"percent"},
		{field:"projected_average_transaction_amount",title:"Prj. Transaction Amount",width:180,format:"currency"},
		{field:"projected_revenue",title:"Prj. Revenue",width:180,format:"currency"},
		{field:"commission_rate",title:"Commission Rate",width:180,format:"percent"},
		{field:"projected_vcr",title:"Prj. VCR",width:180,hidden:true},
		{field:"network_type",title:"Ad Network Type",width:180,hidden:true},
		{field:"cost_structure",title:"Cost Structure",width:180,hidden:true},
		{field:"ipt",title:"IPT",width:180,hidden:true},
		{field:"n_cpm",title:"N_CPM",width:180,hidden:true},
		{field:"id",title:"ID",width:30,hidden:true}
	];
	let date=new Date().toISOString().slice(0,4)+"-01-01";

	return <Grid container className="h-100 w-100">
		<Grid item className="w-100"><DataGridWarehouseTable
			table={table}
			warehouse_bot_id={warehouse_bot_id}
			limit={10000}
			fields={fields}
			includeInsert={true}
			insertDefaultFunction={()=>({source_code:"(Blank Code "+new Date().toISOString()+")",start:date,end:date})}
		/></Grid>
	</Grid>;
}

export default function Cleanup() {
	let {account_id}=useAccountId();
	const {data}=useQuery(DICTIONARY_BOT_QUERY,{variables:{account_id}});
	if (!data) return "";
	const {account:{default_warehouse_bot:{_id:warehouse_bot_id,global_table_prefix}}}=data;

	let _props={global_table_prefix,warehouse_bot_id};

	return <div className="d-flex flex-column h-100 cleanup-wrapper"><SourceCodeDateRange {..._props}/></div>;
};
