import React from 'react';

import FraktureDataTable from '../warehouse/FraktureDataTable';
import useAccountWarehouseInfo from '../warehouse/account-warehouse-info';
import {useAccountId} from '../account/AccountInfo.js';
import {QuickDateRange2,QueryStringForm,TextFieldInput} from '../inputs/QueryStringInputs';
import {Header} from '../components/Header';
import {useSearchParams} from 'react-router-dom';

import {relativeDate} from '../formatters';

export const modelField={fql:`case when model_id=1 then 'Model: First Touch'
when model_id=2 then 'Model: CRM Origin'
when model_id=4 then 'Model: Authentic First Touch'
when model_id=8 then 'Model: Last Channel Acquisition'
when model_id=9 then 'Model: Authentic V2'
else model_id end`,alias:"Model"};

function TransactionModels(){
	const [params]=useSearchParams();
	const person_id=params.get("person_id");
	const person_id_int=params.get("person_id_int");
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let table=global_table_prefix+"transaction_model_source_code";
	let meta=global_table_prefix+"transaction_metadata";
	let sc=global_table_prefix+"source_code_summary";
	let person=global_table_prefix+"person_metadata";
	let joins=[
		{target:meta,alias:"meta",match_fql:table+".transaction_id=meta.transaction_id"},
		{target:sc,alias:"dict",match_fql:table+".source_code_id=dict.source_code_id"},
		{target:person,alias:"person",match_fql:"meta.person_id_int=person.person_id_int"}
	];

	let fields=[
		modelField,
		{fql:"meta.ts",title:"Transaction Date"},
		{fql:"meta.amount",title:"Amount"},
		{fql:"person.person_id",title:"Person"},
		{fql:"dict.source_code",title:"Source Code"},
		{fql:"dict.source_code_channel",title:"Source Code Channel"},
		{fql:"dict.source_code_date",title:"Source Code Date"},
		{fql:"dict.acquisition_date",title:"Source Code Acquisition Date"},
		{fql:"person.person_id_int",title:"Person ID"},
	];
	fields.forEach(f=>{
		let t=f.field || f.alias ||"";
		if (!f.title) f.title=t.replace(/_/g," ");
		if (f.fql && !f.alias)f.alias=f.fql.split(".").pop();
	});

	let conditions=[];
	if (person_id_int){
		conditions.push({fql:`person.person_id_int=${parseInt(person_id_int)}`});
	}else if (person_id){
		conditions.push({fql:`person.person_id='${person_id}'`});
	}else{
		return "No person specified";
	}

	return <FraktureDataTable
		title={"Transaction Models"}
		table={table}
		joins={joins}
		conditions={conditions}
		use_query_conditions={false}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		limit={500}
	/>;
}


function PersonModels(){
	const [params]=useSearchParams();
	const person_id=params.get("person_id");
	const person_id_int=params.get("person_id_int");
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let table=global_table_prefix+"person_model_source_code_summary";

	let fields=[
		modelField,
		{field:"person_id",title:"Person ID"},
		{field:"source_code",title:"Source Code"},
		{field:"date_of_source",title:"Date Sourced"},
		{field:"reasons",title:"Reasons"},
		{field:"person_id_int",title:"Person ID"},
		/*
		{field:"reason_first_touch"},
		{field:"reason_crm_origin"},
		{field:"reason_no_crm_origin"},
		{field:"reason_blank_crm_origin"},
		{field:"reason_no_valid_crm_origin"},
		{field:"reason_valid_crm_origin"},
		{field:"reason_no_valid_date_in_crm_origin"},
		{field:"reason_no_valid_entries"},
		{field:"reason_first_transaction"},
		{field:"reason_duplicate_crm_entries"},
		{field:"reason_multiple_origin_codes"},
		{field:"reason_source_code_override"}
		*/
	];
	fields.forEach(f=>{
		let t=f.field || f.alias || "";
		if (!f.title) f.title=t.replace(/_/g," ");
	});

	let conditions=[].filter(Boolean);
	if (person_id_int){
		conditions.push({fql:`${table}.person_id_int=${parseInt(person_id_int)}`});
	}else if (person_id){
		conditions.push({fql:`${table}.person_id='${person_id}'`});
	}else{
		return "No person specified";
	}

	return <FraktureDataTable
		title={"Person Models"}
		table={table}
		conditions={conditions}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		limit={500}
	/>;
}

function ClassicOrigin(){
	const [params]=useSearchParams();
	const person_id=params.get("person_id");
	const person_id_int=params.get("person_id_int");
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let table=global_table_prefix+"person_metadata";
	let dict=global_table_prefix+"source_code_summary";
	let override=global_table_prefix+"person_metadata_override";
	let joins=[
		{target:dict,alias:"dict",match_fql:table+".final_origin_source_code_id=dict.source_code_id"},
		{target:override,alias:"override",match_fql:table+".person_id=override.person_id"}
	];

	let fields=[
		{fql:"person_id_int",title:"Person ID"},
		{fql:"person_id",title:"Person"},
		{fql:"origin_source_code",title:"Origin Source Code"},
		{fql:"override.origin_source_code_override",title:"Origin Override"},
		{fql:"dict.source_code",title:"Final Origin Code"},
		{fql:"dict.source_code_channel",title:"Origin Channel"},
		{fql:"dict.source_code_date",title:"Origin Date"},
		{fql:"dict.acquisition_date",title:"Acquisition Date"},
	];
	fields.forEach(f=>{
		let t=f.field || f.alias || "";
		if (!f.title) f.title=t.replace(/_/g," ");
		if (f.fql && !f.alias)f.alias=f.fql.split(".").pop();
	});

	let conditions=[];
	if (person_id_int){
		conditions.push({fql:`${table}.person_id_int=${parseInt(person_id_int)}`});
	}else if (person_id){
		conditions.push({fql:`${table}.person_id='${person_id}'`});
	}else{
		return "No person specified";
	}

	return <FraktureDataTable
		title={"Classic Origin People"}
		table={table}
		joins={joins}
		conditions={conditions}
		use_query_conditions={false}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		limit={500}
	/>;
}
function ClassicTransaction(){
	const [params]=useSearchParams();
	const person_id=params.get("person_id");
	const person_id_int=params.get("person_id_int");
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}

	let table=global_table_prefix+"transaction_metadata";
	let sc=global_table_prefix+"source_code_summary";
	let person=global_table_prefix+"person_metadata";
	let joins=[
		{target:table,alias:"meta",match_fql:table+".transaction_id=meta.transaction_id"},
		{target:sc,alias:"dict",match_fql:table+".origin_source_code_id=dict.source_code_id"},
		{target:person,alias:"person",match_fql:`${table}.person_id_int=person.person_id_int`}
	];

	let fields=[
		{fql:"meta.ts",title:"Transaction Date"},
		{fql:"meta.amount",title:"Amount"},
		{fql:"dict.source_code",title:"Origin Source Code"},
		{fql:"dict.source_code_channel",title:"Origin Source Code Channel"},
		{fql:"dict.source_code_date",title:"Origin Source Code Date"},
		{fql:"dict.acquisition_date",title:"Origin Source Code Acquisition Date"},
		{fql:"person.person_id_int",title:"Person ID"},
		{fql:"person.person_id",title:"Person"},
	];
	fields.forEach(f=>{
		let t=f.field || f.alias || "";
		if (!f.title) f.title=t.replace(/_/g," ");
		if (f.fql && !f.alias)f.alias=f.fql.split(".").pop();
	});

	let conditions=[];
	if (person_id_int){
		conditions.push({fql:`person.person_id_int=${parseInt(person_id_int)}`});
	}else if (person_id){
		conditions.push({fql:`person.person_id='${person_id}'`});
	}else{
		return "No person specified";
	}

	return <FraktureDataTable
		title={"Classic Origin Transaction"}
		table={table}
		joins={joins}
		conditions={conditions}
		use_query_conditions={false}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		limit={500}
	/>;
}



function Timeline(){
	const [params]=useSearchParams();
	const person_id=params.get("person_id");
	const person_id_int=params.get("person_id_int");
	let start=params.get("start");
	const end=params.get("end");
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let table=global_table_prefix+"timeline_v3_summary";

	let fields=[
		{field:"ts",title:"Effective Date",width:""},
		{field:"person_id",title:"Person ID",width:""},
		{field:"entry_type_label",title:"Entry Type",width:""},
		{field:"source_code",title:"Source Code",width:120},
		{field:"source_code_date",title:"Source Code Date",width:""},
		{field:"source_code_date_parsed",title:"Parsed Source Date",width:""},
		{field:"acquisition_date",title:"Acquisition Date",width:""},
		{field:"person_id_int",title:"Person ID",width:""},
		{field:"reference_id",title:"Reference ID",width:""},
		{field:"date_created",title:"Created",width:""},
		{field:"bot_id",hidden:true},
		{field:"bot_label",title:"Bot",width:""}
	];

	if (!start && !end && !person_id && !person_id_int){
		start="-3d";
	}
	let conditions=[
		(start?{fql:`${table}.ts>='${relativeDate(start||"-3M").toISOString()}'`}:null),
		(end?{fql:`${table}.ts<'${relativeDate(end).toISOString()}'`}:null)
	].filter(Boolean);
	if (person_id_int){
		conditions.push({fql:`${table}.person_id_int=${parseInt(person_id_int)}`});
	}
	if (person_id){
		conditions.push({fql:`${table}.person_id='${person_id}'`});
	}

	return <FraktureDataTable
		table={table}
		conditions={conditions}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		order_by={[{table,
			field:"ts",order_by_direction:"DESC"}]}
		limit={500}
		menu={<QuickDateRange2/>}
	/>;
}

export function SourceCodeSummary(props){
	const {model_id}=props;
	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id}}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let table=global_table_prefix+"timeline_v3_summary";

	let fields=[
		{field:"date_of_source",title:"Effective Date",width:""},
		{fql:"count(*)",alias:"count"}
	];

	let conditions=[].filter(Boolean);
	if (model_id){
		conditions.push({fql:`model_id=${parseInt(model_id)}`});
	}else{
		fields.unshift(modelField);
	}

	return <FraktureDataTable
		title={"SourceCodeSummary"}
		table={table}
		conditions={conditions}
		warehouse_bot_id={warehouse_bot_id}
		fields={fields}
		order_by={[{table,
			field:"ts",order_by_direction:"DESC"}]}
		limit={500}
		menu={<QuickDateRange2/>}
	/>;
}


export default function(){
	return <div className="app-main-content-wrapper account-wrapper">
		<Header
			primary={[
				"Timeline and Models",
				<QueryStringForm key="form">
					{ ({onChange,values}) => {
						return (<div className="mb-0">
							<TextFieldInput label="Id Search" name="person_id_int"
								onChange={onChange}
								value={values.person_id_int}/>
							<TextFieldInput label="Email Search" name="person_id"
								onChange={onChange}
								value={values.person_id}/>
						</div>);}
					}</QueryStringForm>
			]}
		/>
		<div className="app-main-content-body">
			<Timeline/>
			<PersonModels/>
			<TransactionModels/>
			<ClassicOrigin/>
			<ClassicTransaction/>
		</div>
	</div>;
};
