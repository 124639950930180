import React from 'react';
import StubQuery from "../StubQuery";
import gql from "graphql-tag";

import {NavLink} from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import SearchBox from '../SearchBox';
dayjs.extend(calendar);

const tablesFragment = `{
	name
	count
	type
	object_type
	frakture_table_type
	bot_id
	last_updated_bot_id
	last_updated
	owner_bot_id
}`;

const warehouseTableQuery=gql(`
query warehouseTable($bot_id:ID!) {
	warehouse(bot_id:$bot_id) {
		bot_id
    	bot_token
    	graphql_uri
		warehouse_bot_id
		warehouse_bot {
			label
		}
		tables(limit:100) {
			tables ${tablesFragment}
		}
	}
}`);

const warehouseTableSearchQuery=gql(`
query warehouseTableSearch($bot_id:ID!, $search_text: String!) {
	warehouse(bot_id:$bot_id) {
		bot_id
		warehouse_bot_id
		bot_token
    	graphql_uri
		warehouse_bot {
			label
		}
		tables: table_search(text:$search_text) ${tablesFragment}
	}
}
`);

export default class TableList extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			search_text:""
		};
	};

	renderWarehouse(warehouse) {
		const {warehouse_bot_id,account_id}=this.props;

		let tables;
		if(this.state.search_text) tables = warehouse.tables;
		else tables = warehouse.tables.tables;

		return <Table>
			<TableHead>
				<TableRow>
					<TableCell>Table</TableCell>
					{/*
					<TableCell>Records</TableCell>
					<TableCell>Frakture Types</TableCell>
					<TableCell>Bot</TableCell>
					<TableCell>Last Updated Bot</TableCell>
					<TableCell>Last Updated</TableCell>
	<TableCell>Owner Bot</TableCell>*/}
				</TableRow>
			</TableHead>
			<TableBody>
				{tables.map((table,i)=>{
					//let lastUpdated = '';
					//if(table.last_updated) lastUpdated = dayjs(table.last_updated).calendar();
					return <TableRow key={i}>
						<TableCell>
							<NavLink style={{textTransform:'none'}} to={`/app/${account_id}/warehouse/${warehouse_bot_id}/${table.name}`}>
								<span className="nav-text">{table.name}</span>
							</NavLink>
						</TableCell>
						{/*
						<TableCell>{table.count}</TableCell>
						<TableCell>{[].concat(table.object_type).concat(table.frakture_table_type).filter(Boolean).join().split().join()}</TableCell>
						<TableCell>{table.bot_id}</TableCell>
						<TableCell>{table.last_updated_bot_id}</TableCell>
						<TableCell>{lastUpdated}</TableCell>
				<TableCell>{table.owner_bot_id}</TableCell>*/}
					</TableRow>;
				})}
			</TableBody>
		</Table>;
	}

	renderSearchBox() {
		const{search_text}=this.state;
		return <SearchBox value={search_text} onChange={e => this.setState({search_text:e.target.value})} />;
	}

	getQuery() {
		const{search_text}=this.state;
		if(search_text) return warehouseTableSearchQuery;
		return warehouseTableQuery;
	}

	render(){
		const {warehouse_bot_id,account_id}=this.props;
		if (!account_id) return "No account_id for a warehouse table";
		return <div className="app-main-content"><Card>
			<CardHeader title="Tables"/>
			{this.renderSearchBox()}
			<StubQuery query={this.getQuery()} variables={{bot_id:warehouse_bot_id, search_text: this.state.search_text}}>
				{({loading,data,error}) => {
					console.log("Querying for tables, results=",loading,data,error);
					if(loading) return 'Loading table list ...';
					if (error) {return 'There was an error loading tables';}
					const {warehouse}=data;
					return this.renderWarehouse(warehouse);
				}}
			</StubQuery>
		</Card>
		</div>;
	}
};
