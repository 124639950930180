
import gql from "graphql-tag";
import React from 'react';

import StubQuery from "../StubQuery";
import StubMutation from "../StubMutation";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import ReactMarkdown from 'react-markdown';
import {AdminOnly} from '../../AdminCheck';

class DocumentationEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			value: ''
		};
	}
	render() {
		const{description, saveDescription}=this.props;
		if(!this.state.editing || !saveDescription) return <div style={{marginTop:'10px'}} onDoubleClick={() => {
			this.setState({editing:true, value: description});
		}} >
			<ReactMarkdown source={description || 'No Description Provided'} />
		</div>;
		return <textarea style={{width:'100%'}} value={this.state.value} autoFocus
			onChange={e => this.setState({value:e.target.value})}
			onBlur={() => {
				this.setState({editing:false});
				saveDescription(this.state.value);
			}}/>;
	}
};

const warehouseTableDocQuery=gql(`
query warehouseTableDoc($bot_id:ID!, $table: String!) {
	warehouse(bot_id: $bot_id) {
		_id
		table(name: $table) {
			id
			name
			description
			fields {
				name
				sql_type
				description
			}
		}
	}
}
`);
const updateFieldDescriptionMutation=gql(`
mutation updateFieldDescriptionMutation($bot_id:ID!, $table: String!, $field: String!, $description: String) {
	update_warehouse_field_description(
		bot_id: $bot_id,
		table_name: $table,
		field: $field,
		description: $description
	) {
		id
		name
		fields {
			description
		}
	}
}`);
const updateTableDescriptionMutation=gql(`
mutation updateTableDescriptionMutation($bot_id:ID!, $table: String!, $description: String) {
	update_warehouse_table_description(
		bot_id: $bot_id,
		table_name: $table,
		description: $description
	) {
		id
		name
		description
	}
}
`);
export default class TableDocumentationDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			open: false
		};
	}

	render() {
		const{table,warehouse_bot_id}=this.props;
		return <AdminOnly>{() => <React.Fragment>
			<button className="anchor" onClick={() => this.setState({open: true})}>
				<i className="zmdi zmdi-book-image" style={{color:'gray'}}/>
			</button>
			<Dialog key='dialog' open={this.state.open} onClose={() => {this.setState({open:false});}} fullWidth={true} maxWidth="md">
				<DialogTitle>Documentation for {table}</DialogTitle>
				<StubQuery query={warehouseTableDocQuery} variables={{bot_id:warehouse_bot_id,table}}>
					{({loading,data,error}) => {
						if(loading) return JSON.stringify(loading);
						if(error) return JSON.stringify(error);

						// return JSON.stringify(data);
						return <DialogContent>
							<StubMutation mutation={updateTableDescriptionMutation}>
								{(saveDescription, {loading,error}) => {
									if(loading) return <p>Saving ...</p>;
									if(error) return <p> There was an error </p>;
									return <DocumentationEditor
										description={data.warehouse.table.description}
										saveDescription={description => {
											const variables={
												bot_id: warehouse_bot_id,
												description,
												table: data.warehouse.table.name
											};
											saveDescription({variables});
										}} />;
								}}
							</StubMutation>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Field</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Description</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{(data.warehouse.table.fields || []).map(x => {
										return <TableRow key={x.name}>
											<TableCell>{x.name}</TableCell>
											<TableCell>{x.sql_type}</TableCell>
											<TableCell>
												<StubMutation mutation={updateFieldDescriptionMutation}>
													{(saveDescription, {loading,error}) => {
														if(loading) return <p>Saving ...</p>;
														if(error) {
															console.log(error);
															return <p> There was an error </p>;
														}
														return <DocumentationEditor
															description={x.description}
															saveDescription={description => {
																const variables={
																	bot_id: warehouse_bot_id,
																	description,
																	table: data.warehouse.table.name,
																	field: x.name
																};
																saveDescription({variables});
															}} />;
													}}
												</StubMutation>
											</TableCell>
										</TableRow>;
									})}
								</TableBody>
							</Table>
						</DialogContent>;
					}}
				</StubQuery>
			</Dialog>
		</React.Fragment>}</AdminOnly>;
	}
};
