import React from 'react';
import {Link,useLocation} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import gql from 'graphql-tag';
/*
import List from '@mui/material/List';
import {JobSmall} from './JobListDetail';
*/
import {useNavigateToJobList} from './JobModal';
import JobStatusIcon from './JobStatusIcon';
import dayjs from 'dayjs';

import {fraktureQuery} from '../FraktureQuery';

const JOB_LIST_OVERVIEW_QUERY=gql`query JOB_LIST_OVERVIEW($job_list_id:ID!) {
	job_list(_id:$job_list_id) {
		_id
		label
		status
		account_id
		last_update {
      		ts
    	}
		jobs {
			_id
			status
		}
	}
}`;

const JobListSmallCard = function(props) {
	const navigateToJobList = useNavigateToJobList();
	let location=useLocation();
	let show_extended=props.show_extended;

	let {joblist,joblist:l,query_string='', job_list_id=joblist._id}=props;
	if(!joblist) return fraktureQuery({
		query: JOB_LIST_OVERVIEW_QUERY,
		variables: {job_list_id}
	}, data => {
		joblist = data.job_list;
		if(!joblist) throw new Error('Unable to load joblist');
		return <JobListSmallCard {...{joblist, nav:props.nav,show_extended}} />;
	});

	function navigate() {
		// find the first noncomplete job
		// or the last job
		const job = l.jobs.find(x => x.status !== 'complete') || l.jobs.slice(-1)[0];
		const job_id = job ? job._id : '';
		navigateToJobList({job_list_id,job_id});
	};

	let display_date = (l.last_update||{}).ts || l.display_date;
	if (display_date.getTime) display_date=dayjs(display_date).format("MMM DD, HH:mm:ss a z");

	// let items=[];
	let joblistStatus=l.status;
	// let len=l.jobs.length;
	let subheader=<div className="d-flex justify-content-between">
		<JobStatusIcon status={joblistStatus}/>
		<div className="">
			<p className="sub-heading">
				{show_extended?`ID:${job_list_id} Last Job:${display_date}`:display_date}
			</p>
		</div>
	</div>;

	let content=null;
	if (location.pathname.indexOf('multiaccount')>=0){
		content=<CardContent className="p-1"><Link to={`/app/${l.account_id}/job${query_string}`}>{l.account_id}</Link></CardContent>;
	}

	return <Card className={`h-100 flex-card job-card status-${joblistStatus}`}>
		<CardHeader className="flex-card-body" onClick={()=>navigate()}
			title={<h5 className="card-heading" >{l.label}</h5>}
		/>{content}
		<CardActions className="flex-card-actions">{subheader}</CardActions>
	</Card>;
};
export default JobListSmallCard;
