import React from 'react';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";
import {useNavigate,useLocation} from 'react-router-dom';
import {useAccountId} from '../account/AccountInfo';
import List from '@mui/material/List';
//import Grid from '@mui/material/Grid';
//import { JsonViewer } from '@textea/json-viewer';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardActionArea from '@mui/material/CardActions';
import {SidebarListItem} from '../components/SidebarList';
import {SMSInbound} from './SMSInbound';


const ACCOUNT_BOTS = gql(`
query ACCOUNT_BOTS_QUERY($account_id: ID!) {
	account(_id: $account_id) {
		_id
		bots {
			_id
			label
			path
			account_id
			parent {
				_id
				account_id
			}
			children {
				_id
				account_id
			}
			status{
				status
				last_updated
			}
			definition {
				_id
				metadata {
					picture
					logo
					thumbnail
					categories
				}
			}
		}
	}
}`);

const ACCOUNT_BOTS_WITH_METHODS = gql(`
query ACCOUNT_BOTS_WITH_METHODS_QUERY($account_id: ID!) {
	account(_id: $account_id) {
		_id
		bots {
			_id
			label
			path
			account_id
			definition {
				_id
				methods {
					name
				}
				submodules {
					name
					methods {
						name
					}
				}
			}
		}
	}
}
`);



export function useBots(props){
	const account_id=props?.account_id || useAccountId();
	const {data,loading,error}=useQuery(ACCOUNT_BOTS,{variables:{account_id}});
	if (error) return {error:true};
	if (loading){
		return {loading:true};
	}
	let bots=data?.account?.bots ||[];
	let categories={};
	bots
		.filter(bot => !bot.parent || bot.parent.account_id !== account_id)
		.sort((a,b)=>a.label<b.label?-1:1)
		.forEach(bot => {

			let c=[].concat(bot?.definition?.metadata?.categories).filter(Boolean);
			if (c.length===0) c=["utility"];
			c.forEach(category=>{
				categories[category]=(categories[category]||[]).concat(bot);
			});
		});
	return {bots,categories};
}

export function useBotsWithMethods({account_id}){
	const {data,loading,error}=useQuery(ACCOUNT_BOTS_WITH_METHODS,{variables:{account_id}});
	if (error){
		console.error(error);
		return {error:"Error loading bots with method"};
	}
	if (loading){
		return {loading:true};
	}
	let bots=data?.account?.bots ||[];
	return {bots};
}



export function BotList({account_id:accountProp}){
	const account_id=accountProp|| useAccountId();
	const navigate=useNavigate();
	const location=useLocation();
	const {bots,loading,error}=useBots({account_id});
	if (loading) return "Loading...";
	if (error) return "Error loading bots";
	const links = bots
		.filter(bot => !bot.parent || bot.parent.account_id !== account_id)
		.sort((a,b)=>a.label<b.label?-1:1)
		.map(bot => {
			const children = (bot.children||[]).filter(x => x.account_id === account_id);
			let primary;
			const to = "/app/"+account_id+"/bot/"+bot._id;
			if(!children.length) {
				primary = bot.label;
			} else {
				primary = <span>{bot.label} ({children.length} children)</span>;
			}
			let thumbnail=bot?.definition?.metadata?.thumbnail || "/images/bots/databot-icon.png";

			return <SidebarListItem key={bot._id}
				selected={location.pathname===to}
				primary={[<img key="image" className="p-1" width="50" height="50" src={thumbnail}/>,primary]}
				onClick={()=>navigate(to)}
			/>;
		});
	return <div id="sidebar2">
		<List>{links}</List>
	</div>;
}

function BotCard({bot}){
	const navigate=useNavigate();
	let account_id=bot.account_id;

	const to = "/app/"+account_id+"/bot/"+bot._id;
	let logo=bot?.definition?.metadata?.logo;

	return <Card>
		<CardActionArea onClick={()=>navigate(to)}>
			<CardContent style={{overflow: "auto"}}>
				{logo&&<p className="text-center"><img src={logo} height={100} width={200}/></p>}
				<h2 style={{overflowWrap: "break-word"}}>{bot.label}</h2>
				{bot.children.length?`${bot.children.length} child${bot.children.length===1?"":"ren"}`:""}
			</CardContent>
		</CardActionArea>
	</Card>;
}

export function BotGallery({account_id:accountProp}){

	const account_id=accountProp|| useAccountId();
	const {categories,loading,error}=useBots({account_id});
	if (loading) return "Loading...";
	if (error) return "Error loading bots";

	return <div className="gallery p-3">
		{(account_id==='system') && <SMSInbound/>}
		{Object.keys(categories).map((category,i)=><React.Fragment key={i}>
			<Divider textAlign="left">{category}</Divider>
			<div className="gallery-row-wrapper">
				{categories[category].map((bot,j)=><div key={j} className="gallery-item"><BotCard bot={bot}/></div>)}
			</div>
		</React.Fragment>)}
	</div>;
}

export function BotAvatar({bot}){
	return <Avatar sx={{ bgcolor: "red"}}>{bot.path.slice(0,1).toUpperCase()}</Avatar>;
}

export default BotList;
