import React,{useState,useEffect,useRef} from 'react';
import gql from 'graphql-tag';
import {useQuery,useApolloClient} from '@apollo/client';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import DefaultValues from './DefaultValues';
import TestSourceCodes from './TestSourceCodes';
import useNotifiers from '../../Notifiers';
import {useAccountId} from '../../account/AccountInfo';



const ACCOUNT_SOURCE_CODE_FORMATS=gql`
fragment ACCOUNT_SOURCE_CODE_FORMATS on Account {
	_id
	source_code_config {
		formats {
			account_id

			_id
			format
			regex
			full_regexp_str
			match_order

			default_values
			fields {
				name
				regexp
			}
		}
	}
}
`;

const SOURCE_CODE_QUERY = gql`
query SOURCE_CODE_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		... ACCOUNT_SOURCE_CODE_FORMATS
	}
}
${ACCOUNT_SOURCE_CODE_FORMATS}
`;

const SOURCE_CODE_TEST_FORMAT=gql`
query SOURCE_CODE_TEST_FORMAT($account_id:ID!, $format: String!) {
	account(_id:$account_id) {
		source_code_test_format(format:$format) {
			_id
			format
			regex
			regex_elements
			fields {
				name
				regexp
			}
		}
	}
}`;

const UPSERT_FORMAT_MUTATION=gql`
mutation UPSERT_FORMAT_MUTATION($account_id:ID!,$data:JSON!) {
	source_code_format_upsert(account_id:$account_id, data:$data) {
		_id
		match_order
		format
		default_values
		account {
			... ACCOUNT_SOURCE_CODE_FORMATS
		}
	}
}
${ACCOUNT_SOURCE_CODE_FORMATS}
`;

const DELETE_FORMAT_MUTATION=gql`
mutation DELETE_FORMAT_MUTATION($account_id:ID!,$_id:ID!) {
	source_code_format_delete(account_id:$account_id, _id:$_id) {
		_id
	}
}
${ACCOUNT_SOURCE_CODE_FORMATS}
`;

function MatchOrderInput(props){
	const {match_order,account_id,_id}=props;
	if (!_id) return "Auto";
	const {notify,notifyErr}=useNotifiers();
	const client = useApolloClient();
	return <input className="frakture-save-on-blur"
		type='text'
		defaultValue={match_order}
		onBlur={(e) => {
			const v = e.target.value;
			client.mutate({
				mutation: UPSERT_FORMAT_MUTATION,
				variables: {
					account_id,
					data: JSON.stringify({
						_id,
						match_order: v
					})
				}
			}).then(notify,notifyErr);
		}}
	/>;
}


function SourceCodeFormatEditorRow(props) {
	const {_id,match_order,regex,full_regexp_str}=props;
	const account_id=useAccountId();

	const {notify,notifyErr}=useNotifiers();

	const [format,setFormat]=useState(props.format || '');
	let d=props.default_values||{};
	if (typeof d=='string')d=JSON.parse(d);
	const [defaultValues,setDefaultValues]=useState(d);

	const [raw,setRaw]=useState(format);

	const client = useApolloClient();

	const lastTimeout = useRef(null);
	const input = <input
		className='frakture-save-on-blur'
		type='text'
		value={raw}
		onChange={(e) => {
			const v = e.target.value;
			setRaw(v);
			if(lastTimeout.current) clearTimeout(lastTimeout.current);
			lastTimeout.current = setTimeout(() => setFormat(v), 400);
		}}
	/>;

	const parsed = useQuery(SOURCE_CODE_TEST_FORMAT, {
		variables: {
			format: format||'',
			account_id
		},
		skip: (format||'').length < 1
	});
	const [formatInfo,setFormatInfo]=useState({fields:[]});
	useEffect(() => {
		if(!parsed.data) return;
		const {source_code_test_format:{fields}}=parsed.data.account;
		setFormatInfo({fields});
	}, [parsed.data, parsed.error, parsed.loading]);

	let onClose = props.onClose || (() => {});

	let saveButton=<Button style={{width:'77px'}} variant="contained" color='primary' className="float-right" disabled={!format} onClick={() => {
		if(!format) return;

		let data={
			_id,
			format,
			default_values: defaultValues
		};
		if (!_id){
			data.match_order=match_order;
		}
		client.mutate({
			mutation: UPSERT_FORMAT_MUTATION,
			refetchQueries:["SOURCE_CODE_QUERY"],
			variables: {
				account_id,
				data:JSON.stringify(data)
			}
		}).then(notify,notifyErr);
	}}>Save</Button>;

	return <React.Fragment>
		<TableRow>
			<TableCell>
				<MatchOrderInput {...{account_id,match_order,_id}}/>
			</TableCell>
			<TableCell>
				{input}
			</TableCell>
			<TableCell style={{width:'110px'}} className="">
				{!_id && saveButton}
				{_id && <Button style={{width:'77px'}} variant="outlined" onClick={() => onClose()}>Close</Button>}
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell padding='checkbox' />
			<TableCell>
				<Typography variant='h6'>
					Component Regexp
				</Typography>
				<Table size="small">
					<TableBody>
						{(() => {
							if(!formatInfo.fields||!formatInfo.fields.length) {
								return <TableRow>
									<TableCell colSpan={2}>Enter a format to show components</TableCell>
								</TableRow>;
							}
							return formatInfo.fields.map((x) => {
								return <TableRow key={x.name}>
									<TableCell style={{width:'50%'}}>{x.name}</TableCell>
									<TableCell style={{width:'50%'}}>{x.regexp}</TableCell>
								</TableRow>;
							});
						})()}
					</TableBody>
				</Table>
			</TableCell>
		</TableRow>
		{_id && <>
			<TableRow>
				<TableCell padding='checkbox' />
				<TableCell>
					<Typography variant='h6'>
						Default Values
					</Typography>
					<Table size="small">
						<TableBody>
							<DefaultValues {...{defaultValues,setDefaultValues}} />
						</TableBody>
					</Table>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell padding='checkbox' />
				<TableCell>
					<Typography variant='h6'>
						Advanced
					</Typography>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell padding='checkbox' />
				<TableCell>Parsed Regex: {full_regexp_str}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell padding='checkbox' />
				<TableCell>Custom Regex: {regex || ""}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell padding='checkbox' />
				<TableCell>
					<Button variant="contained" color="secondary" disabled={!format} onClick={() => {
						if(!format) return;
						if (confirm("Are you sure you want to remove this format?")){
							client.mutate({
								mutation: DELETE_FORMAT_MUTATION,
								refetchQueries:["SOURCE_CODE_QUERY"],
								variables: {
									account_id,
									_id
								}
							}).then(notify,notifyErr);
						}
					}}>Delete</Button></TableCell>
				<TableCell>{saveButton}</TableCell>
			</TableRow>
		</>
		}
	</React.Fragment>;
};

function NewSourceCodeFormatRow(props) {
	const {maxMatchOrder=0}=props;
	const account_id=useAccountId();

	return <SourceCodeFormatEditorRow
		match_order={maxMatchOrder+1}
		default_values={{}}
		format={''}
		account_id={account_id}
	/>;
}
function EditableSourceCodeRow(props) {
	const [editing,setEditing]=useState(false);

	if(!editing) {
		const {format,match_order,account_id,_id}=props;
		return <TableRow key={format}>
			<TableCell>
				<MatchOrderInput {...{account_id,match_order,_id}}/>
			</TableCell>
			<TableCell>
				{format}
			</TableCell>
			<TableCell style={{width:'110px', textAlign:'right'}}>
				<Button style={{width:'77px'}} variant="outlined" onClick={()=>setEditing(true)}>Edit</Button>
			</TableCell>
		</TableRow>;
	}

	return <SourceCodeFormatEditorRow
		{...props}
		onClose={() => setEditing(false)}
	/>;
}

function SourceCodeFormatList(props) {
	const {formats}=props;
	const account_id=useAccountId();
	if(!account_id) throw new Error('account_id is required');

	let format_account_id=account_id;
	if(formats.length) format_account_id = formats[0].account_id;

	const rows = formats.map(d=>d)
		.sort((a,b)=>a.match_order < b.match_order ? -1:1)
		.map((f) => {
			return <EditableSourceCodeRow key={f.match_order} {...f} account_id={format_account_id}/>;
		});

	const maxMatchOrder = Math.max.apply(null,formats.map(x=>parseInt(x.match_order||0)).concat(0));

	return <Table className="w-100">
		{rows}
		<NewSourceCodeFormatRow account_id={format_account_id} maxMatchOrder={maxMatchOrder} />
	</Table>;
}

export default function SourceCodeFormatEditor() {
	const account_id=useAccountId();
	const query=useQuery(SOURCE_CODE_QUERY, {
		variables: {account_id}
	});
	if(query.loading) return '';
	const formats = query.data.account.source_code_config.formats;
	return <Paper>
		<SourceCodeFormatList {...{formats,account_id}} />
		<TestSourceCodes/>
	</Paper>;
}
