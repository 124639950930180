import {useMutation} from "@apollo/client";
import useNotifiers from './Notifiers';

//A quick hack to move to hooks from <StubQuery>

export default function Query(props){
	const {notifyErr}=useNotifiers();
	const {mutation,refetchQueries,onCompleted,onError}=props;
	const [modify,{data,error,loading,client}]=useMutation(mutation,
		{refetchQueries,onCompleted, onError:(onError || ((e)=>notifyErr(e)))});
	//if (error) return "";
	//if (loading) return "Loading...";
	return props.children(modify,{data,error,loading,client});
}
