import React from 'react';
import Container from '@mui/material/Container';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import {useParams,Link} from 'react-router-dom';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import Grid from '@mui/material/Grid';

import Level0, {useLevel0StepComplete} from './Level0';
import Level1, {useLevel1StepComplete} from './Level1';
import Level2 from './Level2';

function StepLabelIcon(props) {
	console.log('Label Icons',props);
	const {active,completed,error}=props;
	let icon;

	let color;
	if(active) color = 'primary';
	else if(error) color = 'secondary';
	else color = 'disabled';

	if(completed) {
		icon = <CheckCircleIcon color={color}/>;
	} else {
		icon = <CancelIcon color={color}/>;
	}

	return icon;
}

export default function FraktureWizard(props) {
	// const {account_id}=props;
	// const [activeStep, setActiveStep] = React.useState(0);
	const params = useParams();
	const activeStep = params.setup_step || 0;
	const {account_id}=params;

	console.log(params);

	const level0 = useLevel0StepComplete(props);
	const level1 = useLevel1StepComplete(props);

	const steps = [{
		label: 'Level 0 - Bots & Dataflows',
		completed: level0.completed,
		missing: level0.missing,
		render: () => <Level0 {...props} />
	}, {
		label: 'Level 1 - Data Load',
		completed: level1.completed,
		missing: level1.missing,
		render: () => <Level1 {...props} />
	}, {
		label: 'Level 2 - Source Coding & Attribution',
		completed: false,
		render: () => <Level2 {...props} />
	}, {
		label: 'Level 3 - Strategic Reporting',
		completed: false,
		render: () => 'level3'
	}];

	/*
	<StepButton error={error} completed={x.completed} onClick={()=>setActiveStep(i)}>
		{x.label}
	</StepButton>
	*/

	return <Container maxWidth='lg'>
		<Grid container spacing={2}>
			<Grid item xs={12}/>
			<Grid item xs={12}>
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((x,i) => {
						let error = activeStep > i && !x.completed;
						return <Step key={i}>
							<Link to={`/app/${account_id}/setup/${i}`}>
								<StepLabel
									error={error}
									completed={x.completed}
									StepIconComponent={StepLabelIcon}
								>{x.label}</StepLabel>
							</Link>
						</Step>;
					})}
				</Stepper>
			</Grid>
			<Grid item xs={12}>
				{steps[activeStep].render()}
			</Grid>
		</Grid>
	</Container>;
};
