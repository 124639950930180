import React from 'react';
import gql from "graphql-tag";

import {Link} from 'react-router-dom';

import CardContent from '@mui/material/CardContent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';
import {useAccountId} from '../AccountInfo';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useApolloClient } from '@apollo/client';

import { useUserContext } from '../../../AdminCheck';
import useNotifiers from '../../Notifiers';
import { fraktureQuery } from '../../FraktureQuery';

import AsyncSelect from 'react-select/async';

const ACCOUNT_SEARCH = gql`query ACCOUNT_SEARCH($text: String!) {
	account_search(text: $text) {
		value: _id
		label: name
	}
}`;

function AccountChildSearch(props) {
	const client = useApolloClient();
	const{onChange}=props;
	return <AsyncSelect defaultOptions={[]} cacheOptions
		placeholder='Select existing account to add as child...'
		loadOptions={async text => {
			text=text || '';
			const {data} = await client.query({
				query: ACCOUNT_SEARCH,
				variables: {text}
			});
			return data.account_search;
		}} onChange={(o => {
			if(!o) return onChange(null);
			onChange(o.value);
		})}
	/>;
};

const ACCOUNT_FRAGMENT=`{
	_id
	name
	children {
		_id
		name
		disabled
	}
	parents {
		_id
		name
	}
}`;

const ACCOUNT_SETTINGS = gql`query ACCOUNT_SETTINGS($account_id: ID!) {
	account(_id: $account_id) ${ACCOUNT_FRAGMENT}
}`;

const ACCOUNT_CHILDREN_RESULT=`{
	account {
		_id
		parent_ids
		parents {
			_id
			name
		}
	}
	parent {
		_id
		children_ids
		children {
			_id
			name
		}
	}
}`;

const ACCOUNT_NEW_CHILD = gql`mutation ACCOUNT_NEW_CHILD($parent_id:ID!,$name:String,$_id:ID!) {
	account_create(parent_id:$parent_id, _id:$_id, name:$name) {
		_id
		name
		parents ${ACCOUNT_FRAGMENT}
	}
}`;

function AccountNewChild() {
	const account_id=useAccountId();
	const client=useApolloClient();
	const {notify,notifyErr}=useNotifiers();

	const baseId=`${account_id}_`;
	const basePostfix=``;

	const [hasModifiedId,setHasModifiedId]=React.useState(false);
	const [childId,setChildId]=React.useState(baseId);
	const [childName,setChildName]=React.useState(basePostfix);

	function createNewChild() {
		client.mutate({
			mutation: ACCOUNT_NEW_CHILD,
			variables: {
				parent_id: account_id,
				name: childName,
				_id: childId
			}
		}).then(notify,notifyErr);
	};

	return (
		<form onSubmit={e => {
			e.preventDefault();
			createNewChild();
			setChildId(baseId);
			setChildName(basePostfix);
		}}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell>
							<TextField label='Child Account Name' value={childName} fullWidth onChange={e => {
								const newName = e.target.value;
								setChildName(newName);
								if(!hasModifiedId && newName.slice(-basePostfix.length) === basePostfix) {
									const idPart = newName.slice(0,-basePostfix.length);
									const newId = baseId + idPart.replace(/[^\w\d\s]/g,' ').toLowerCase().trim().replace(/\s+/g,'_');
									setChildId(newId);
								}
							}} />
						</TableCell>
						<TableCell>
							<TextField label='Child Account Id' value={childId} fullWidth onChange={e => {
								setChildId(e.target.value);
								if(!hasModifiedId) setHasModifiedId(true);
							}} />
						</TableCell>
						<TableCell padding='checkbox'>
							<IconButton type='submit' size="large">
								<AddIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</form>
	);
};

const ACCOUNT_ADD_PARENT = gql`mutation ACCOUNT_ADD_PARENT($account_id: ID!, $parent_id: ID!) {
	account_add_parent(_id:$account_id,parent_id: $parent_id) ${ACCOUNT_CHILDREN_RESULT}
}`;
const ACCOUNT_REMOVE_PARENT = gql`mutation ACCOUNT_REMOVE_PARENT($account_id: ID!, $parent_id: ID!) {
	account_remove_parent(_id:$account_id,parent_id: $parent_id) ${ACCOUNT_CHILDREN_RESULT}
}`;

function AccountList(props) {
	const [addAccount,setAddAccount]=React.useState();
	const {accounts,add,remove}=props;

	const rows = accounts.map(({_id,name,disabled}) => {
		let status = '';
		if(disabled) status = ' (disabled)';
		return (
			<TableRow key={_id}>
				<TableCell>
					{name} (<Link to={`/app/${_id}`}>{_id}{status}</Link>)
				</TableCell>
				<TableCell padding='checkbox'>
					<IconButton onClick={() => remove(_id)} size="large">
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
		);
	});

	return (
		<Table>
			<TableBody>
				{rows}
				<TableRow>
					<TableCell>
						<AccountChildSearch onChange={_id => setAddAccount(_id)} />
					</TableCell>
					<TableCell padding='checkbox'>
						<IconButton onClick={() => add(addAccount)} size="large">
							<AddIcon />
						</IconButton>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

function ParentSettings() {
	const client = useApolloClient();
	const account_id=useAccountId();
	return fraktureQuery({
		query: ACCOUNT_SETTINGS,
		variables:{account_id}
	}, ({account:{parents}}) => {
		return <AccountList
			accounts={parents}
			add={parent_id => client.mutate({
				mutation: ACCOUNT_ADD_PARENT,
				variables:{account_id,parent_id}
			})}
			remove={parent_id => client.mutate({
				mutation: ACCOUNT_REMOVE_PARENT,
				variables: {account_id,parent_id}
			})}
		/>;
	});
};

function ChildrenSettings() {
	const client = useApolloClient();
	const account_id=useAccountId();
	return fraktureQuery({
		query: ACCOUNT_SETTINGS,
		variables:{account_id}
	}, ({account:{children}}) => {
		return <AccountList accounts={children}
			add={child_id => client.mutate({
				mutation: ACCOUNT_ADD_PARENT,
				variables:{account_id:child_id,parent_id:account_id}
			})}
			remove={child_id => client.mutate({
				mutation: ACCOUNT_REMOVE_PARENT,
				variables: {account_id:child_id,parent_id:account_id}
			})}
		/>;
	});
};

export default function ShareSettings() {
	const{is_frakture_admin} = useUserContext();
	if(!is_frakture_admin) return "You do not have permission to view this screen";

	const account_id=useAccountId();
	if(!account_id) return 'Invalid - account_id required';
	return fraktureQuery({
		query: ACCOUNT_SETTINGS,
		variables:{account_id}
	}, ({account}) => {
		const{name}=account;

		return <React.Fragment>
			<CardContent>
				<h3>Parent Accounts</h3>
				<ParentSettings {...{account_id}}/>
			</CardContent>
			<CardContent>
				<h3>Create Child Account</h3>
				<AccountNewChild {...{account_id,name}} />
			</CardContent>
			<CardContent>
				<h3>Child Accounts</h3>
				<ChildrenSettings {...{account_id}}/>
			</CardContent>
		</React.Fragment>;
	});
};
