import React,{useState} from 'react';
// import {useAccountId} from '../AccountInfo';
import gql from 'graphql-tag';
import {useApolloClient} from '@apollo/client';
import useNotifiers from '../Notifiers';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import ChildAccountSelect from './ChildAccountSelect';

import {useUserContext} from '../../AdminCheck';

import {
	CHILD_ACCOUNT_DETAILS
} from './gql-fragments';


const ACCOUNT_NEW_CHILD = gql`mutation ACCOUNT_NEW_CHILD($parent_id:ID!,$name:String,$_id:ID!,$source_account_id:ID) {
	account_create(parent_id:$parent_id, _id:$_id, name:$name,source_account_id:$source_account_id) {
		_id
		name
		parents {
			_id
			children {
				... CHILD_ACCOUNT_DETAILS
			}
		}
	}
}
${CHILD_ACCOUNT_DETAILS}
`;

function NewAccountForm(props) {
	const{account_id, afterCreate}=props;
	const client=useApolloClient();
	const {notify,notifyErr}=useNotifiers();

	const baseId=`${account_id}_`;

	const [hasModifiedId,setHasModifiedId]=React.useState(false);
	const [childId,setChildId]=React.useState(baseId);
	const [childName,setChildName]=React.useState('');
	const [source_account_id,setSourceAccountId]=React.useState('');

	function createNewChild() {
		client.mutate({
			mutation: ACCOUNT_NEW_CHILD,
			variables: {
				parent_id: account_id,
				name: childName,
				_id: childId,
				source_account_id
			}
		}).then(notify,notifyErr);
	};

	let idError;
	if(childId.indexOf(account_id+'_') !== 0) idError = 'ID of child account needs to start with "'+account_id+'_"';

	return (
		<form onSubmit={e => {
			e.preventDefault();
			createNewChild();
			setChildId(baseId);
			setChildName('');
			afterCreate();
		}}>
			<Table>
				<TableBody>
					<TableRow>
						<TableCell style={{borderBottom:'0px'}}>
							<TextField label='Child Account Name' value={childName} fullWidth onChange={e => {
								const newName = e.target.value;
								setChildName(newName);
								if(!hasModifiedId) {
									const idPart = newName;
									const newId = baseId + idPart.replace(/[^\w\d\s]/g,' ').toLowerCase().trim().replace(/\s+/g,'_');
									setChildId(newId);
								}
							}} />
						</TableCell>
						<TableCell style={{borderBottom:'0px'}}>
							<TextField label='Child Account Id' value={childId} fullWidth onChange={e => {
								setChildId(e.target.value);
								if(!hasModifiedId) setHasModifiedId(true);
							}} error={idError ? true : false } helperText={idError} />
						</TableCell>
						<TableCell style={{borderBottom:'0px'}}>
							<ChildAccountSelect label={"Clone Account"} account_id={account_id}
								onChange={e => setSourceAccountId(e.target.value)}
							/>
						</TableCell>
						<TableCell style={{borderBottom:'0px'}} padding='checkbox'>
							<IconButton type='submit' size="large">
								<AddIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</form>
	);
};

export default function NewChildAccount(props) {
	const [creating,setCreating]=useState(false);
	const userContext = useUserContext();
	const {parent_id}=props;

	if(!userContext.is_frakture_admin) return '';

	let content;
	if(!creating) {
		content = <Button
			variant="contained"
			disableElevation
			color='primary'
			onClick={() => {
				setCreating(true);
			}}
		> Add New Child Account </Button>;
	} else {
		content = <NewAccountForm account_id={parent_id} afterCreate={() => setCreating(false)} />;
	}

	return <Card>
		<CardContent>
			{content}
		</CardContent>
	</Card>;
}
