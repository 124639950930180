import React from 'react';
import gql from "graphql-tag";
import {useQuery} from '@apollo/client';
import {useAccountDetails} from '../AccountInfo';
import {Error} from '../../Error';


const SOURCE_CODE_CONFIG=gql`query SOURCE_CODE_CONFIG($warehouse_bot_id:ID!) {
    warehouse(bot_id:$warehouse_bot_id) {
  	  bot_id
      warehouse_bot_id
      bot_token
      graphql_uri
	  source_code_config
    }
  }`;


const loadingIcon="Loading ...";

export function useSourceCodeConfig() {
	const {account,loading,error}=useAccountDetails();
	let warehouse_bot_id=account?.default_warehouse_bot?._id;
	let variables=JSON.parse(JSON.stringify({warehouse_bot_id}));
	let skip=loading || error || false;
	if (skip)console.log("Skipping query");
	else console.log("Running SOURCE_CODE_CONFIG with:",{skip,variables});
	const {data,loading:loading2,error:error2}=useQuery(SOURCE_CODE_CONFIG, {variables,
		errorPolicy: "none",
		skip});
	if (skip) return {loading,error:error?<Error error={error}/>:null};
	if (loading2) return {loading:loadingIcon};

	if (error2){
		let e=error2?.error?.props?.error || error2;
		return {error:<Error error={e}/>};
	}
	let d=data?.warehouse?.source_code_config;
	if (typeof d=='string') d=JSON.parse(d);
	return {data:d};
};

export default function SourceCodeConfig(){
	let {data:config,loading,error}=useSourceCodeConfig();
	if (loading || error) return loading || error;
	return <div>{config.elements.map((el,i)=><li key={i}>{JSON.stringify(el)}</li>)}
	</div>;
}
