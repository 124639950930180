// eslint-disable-next-line no-unused-vars
import React,{useState} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import {useColorFunc} from './colors';
import {relativeDate} from "../../../formatters.js";
import {
	Area,
	Bar,
	ComposedChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,CartesianGrid,
	Line,YAxis,Legend,ReferenceArea
} from "recharts";
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import {formatValue} from '../../../formatters';

export function Delta(props){
	const {label,value,percent,format}=props;
	return <div className={`d-flex p-3 justify-content-around align-middle text-center`}>
		<div>
			<strong>{label}</strong>
			<div className={`${value>0?"text-success":value<0?"text-danger":""}`}>
				<Typography variant="h4">{formatValue(value,format)}</Typography>
				<Typography variant="h5">{formatValue(percent,'percent')}
					{value>0 && <ArrowUpward/>}
					{value<0 && <ArrowDownward/>}
				</Typography>
			</div>
		</div>
	</div>;
}


export default function FraktureBarChart(props){
	let {metrics,breakdown:_breakdown,display_max=5,data=[],is_date,label="",qs,delta}=props;
	const get_color=useColorFunc();
	let [refAreaLeft,setLeft]=useState();
	let [refAreaRight,setRight]=useState();
	const navigate=useNavigate();
	const location=useLocation();
	if (data.length===0) return <>{label && <Typography className="report-item-label" variant="h6">{label}</Typography>}No data available</>;
	let breakdown=[].concat(_breakdown).filter(Boolean);
	if (breakdown.length>1) throw new Error("Only one breakdown field allowed");
	breakdown=breakdown[0];
	//return <pre>{JSON.stringify(data,null,4)}</pre>;
	//if (!qs) return "No qs";

	function xFormat(val){
		//console.log("Formatting ",val);
		let v=is_date?formatValue(val,"utcdate"):val;
		//console.log("Formatted ",v);
		return v;
	}
	let xAlias=props.dimension_alias || "dimension_alias";
	if (!data[0][xAlias] && data[0].col0) xAlias="col0";

	let formattersByName={};
	metrics.forEach(f=>{
		formattersByName[f.alias]=(val)=>formatValue(val,f.format);
		formattersByName[f.label]=(val)=>formatValue(val,f.format);
	});

	let displayMetrics=metrics;
	const total={};
	if (breakdown){
		let distinctBreakdown=data.reduce((a,d)=>{a[d[breakdown.alias]]=(a[d[breakdown.alias]]||0)+1; return a;},{});
		let keyCount=Object.keys(distinctBreakdown).map(key=>({key,count:distinctBreakdown[key]})).sort((a,b)=>a.count<b.count?1:-1);
		let validValueLookup=null;
		if (keyCount.length>display_max){
			keyCount=keyCount.slice(0,display_max);
			validValueLookup=keyCount.reduce((a,b)=>{a[b.key]=true;return a;},{});
			keyCount.push({key:"(Other)"});
		}
		let newData={};
		data.forEach(d=>{

			newData[d[xAlias]]=newData[d[xAlias]]||{};//Create an object
			newData[d[xAlias]][xAlias]=d[xAlias]; //set the date
			let value=d[metrics[0].alias];
			if (validValueLookup && !validValueLookup[d[breakdown.alias]]){
				total["(Other)"]=(total["(Other)"]||0)+value;
				newData[d[xAlias]]["(Other)"]=(newData[d[xAlias]]["(Other)"]||0)+value;
			}else{
				total[d[breakdown.alias]]=(total[d[breakdown.alias]]||0)+value;
				newData[d[xAlias]][d[breakdown.alias]]=(newData[d[xAlias]][d[breakdown.alias]]||0)+value; //assign an other value
			}
		});
		data=Object.values(newData);

		displayMetrics=[].concat(keyCount.map(d=>{
			formattersByName[d.key]=(val)=>formatValue(val,metrics[0].format);
			return {alias:d.key,yAxis:breakdown.yAxis,type:"bar",stackId:"stackA"};}));
	}


	let leftPad=30;
	let rightPad=0;
	if (metrics && metrics[0] && metrics[0].type==="bar"){
		if (data.length<3){leftPad=300;rightPad=0;}
		else if (data.length<5){leftPad=250;rightPad=0;}
		else if (data.length<10){leftPad=90;rightPad=0;}
		else if (data.length<20){leftPad=50;rightPad=0;}
		//return JSON.stringify({length:data.length,leftPad,rightPad});
	}

	let xaxis=<XAxis dataKey={xAlias} tickFormatter={xFormat} />;
	let domain=null;


	if (is_date){
		//Set the domain based on the resulting querystring coming from the server
		//domain=[relativeDate("-3M").getTime(),relativeDate("now").getTime()];
		domain=["auto","auto"];
		if (qs){
			if (qs.start) domain[0]=relativeDate(qs.start).getTime();
			if (qs.end){
				domain[1]=relativeDate(qs.end).getTime();
			}
		}

		xaxis=<XAxis
			dataKey={xAlias}
			domain={domain}
			scale='time'
			type='number'
			padding={{ left: leftPad,right:rightPad }}
			tickFormatter={xFormat}
		/>;
	}

	if (breakdown) breakdown.alias=breakdown.alias || breakdown.label || "_breakdown";

	let rightAxis=null;
	if (metrics.find(d=>d.yaxis==="right")){
		let r1=metrics.find(d=>d.yaxis==="right");
		function yFormatRight(val){
			return formatValue(val,r1.format);
		}
		rightAxis=<YAxis yAxisId="right" orientation='right' tickFormatter={yFormatRight}/>;
	}
	//find the first left format
	let left1=metrics.find(d=>d.yaxis!=="right") ||{};
	function yFormatLeft(val){return formatValue(val,left1.format);}
	data=data.map(d=>{
		if (!is_date) return d;
		if (String(d[xAlias]).length===4){
			d[xAlias]=new Date(String(d[xAlias])+"-01-01").getTime(); // fix for year functions that don't return full date format
		}else{
			d[xAlias]=new Date(d[xAlias]).getTime();
		}
		if (domain && d[xAlias]<domain[0]){
			//console.log("Ignoring ",d[xAlias], new Date(d[xAlias])," as before ",domain[0],new Date(domain[0]));
			return false;
		}
		if (domain && d[xAlias]>domain[1]) return false;
		return d;
	}).filter(Boolean);


	function zoom(){
		if (refAreaLeft === refAreaRight || refAreaRight === '') {
			setLeft('');
			setRight('');
			return;
		}

		// xAxis domain
		if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];


		let query=queryString.parse(location.search) || {};
		query.start=refAreaLeft;
		query.end=refAreaRight;
		navigate({location:location.pathname,search:queryString.stringify(query)});

		setLeft(null);
		setRight(null);
		//alert(refAreaLeft+","+refAreaRight);
	}

	if (is_date){
		data.sort((a,b)=>{return a[xAlias]<b[xAlias]?-1:1;});
	}
	let deltas=[];
	if (delta){
		if (Array.isArray(delta)){
			delta={range:delta};
		}
		let includeSince=false;
		if (delta.includeSince) includeSince=true;

		if (Array.isArray(delta.range) && data.length>1){
			let [a,b]=delta.range;
			let x1=a<0?data.length+a:a;
			let x2=b<0?data.length+b:b;

			let value=data[x2][metrics[0].alias]-data[x1][metrics[0].alias];
			let percent=value/data[x1][metrics[0].alias];
			let label="Since "+formatValue(relativeDate(data[x1][xAlias]),'date');
			deltas.push(Delta({value,percent,format:metrics[0].format,label:includeSince?label:""}));
			if (data.length>2){
				let allValue=data[data.length-1][metrics[0].alias]-data[0][metrics[0].alias];
				let allPercent=allValue/data[0][metrics[0].alias];
				let allLabel="";
				if (qs.start){
					allLabel="Since "+formatValue(relativeDate(qs.start),'date');
					deltas.push(Delta({value:allValue,percent:allPercent,format:metrics[0].format,label:includeSince?allLabel:""}));
				}
			}

		}else{
			console.error("Invalid delta:",delta);
		}
	}

	return <React.Fragment>
		<Grid container className="h-100 report-bar-chart">
			{label && <Grid item sm={12}><Typography className="report-item-label" variant="h6">{label}</Typography></Grid>}
			<Grid item md={deltas.length?10:12} sm={12}>
				<ResponsiveContainer>
					<ComposedChart
						data={data}
						margin={{
							top: 20, right: 10, bottom: 0, left: 25,
						}}
						onMouseDown={is_date?e => {if (e) setLeft(e.activeLabel);}:null}
						onMouseMove={is_date?e => e && refAreaLeft && setRight(e.activeLabel):null}
						onMouseUp={is_date?e=>e && zoom():null}
					>
						<defs>
							<filter x="0" y="-10%" id="lineBlur">
								<feGaussianBlur in="SourceAlpha" stdDeviation="8" result="offsetBlur"></feGaussianBlur><feOffset dx="0" dy="10"></feOffset><feBlend in="SourceGraphic" mode="multiply"></feBlend>
							</filter>
							<filter id="lineShadow" height="200%">
								<feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
								<feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
								<feFlood floodColor="#006991" floodOpacity="0.5" result="offsetColor" />
								<feComposite
									in="offsetColor"
									in2="offsetBlur"
									operator="in"
									result="offsetBlur"
								/>
								<feMerge>
									<feMergeNode />
									<feMergeNode in="SourceGraphic" />
								</feMerge>
							</filter>
						</defs>
						<CartesianGrid />
						{xaxis}
						<YAxis yAxisId="left" tickFormatter={yFormatLeft}/>
						{rightAxis}
						<Tooltip labelFormatter={(value) => is_date?formatValue(value,'utcdate'):value} formatter={
							(value, n)=>{
								if (typeof formattersByName[n]==='function'){
									return formattersByName[n](value);
								}else{
									//console.error("Could not find formatter "+n);
									return value;
								}
							}
						}/>
						<Legend />
						{displayMetrics.map((f,i)=>{
							let color=f.color || get_color(i,f.label || f.alias);
							let dot=null;
							if (data.length<40) dot={ fill:color,
								stroke:"#FFF",
								strokeWidth: 5,
								r: 6,
								filter: "none"
							};
							switch(f.type){
							case "bar": return <Bar key={i} yAxisId={f.yaxis||"left"} name={f.label} dataKey={f.alias} stackId={f.stackId||(props.stack?"stackA":"")|| null} fill={color}/>;
							case "area":return <Area key={i} yAxisId={f.yaxis||"left"} type="linear" name={f.label} dataKey={f.alias} fill={color} stroke={color} />;
							default: return <Line type="monotone" dot={dot} connectNulls={false} key={i} name={f.label} yAxisId={f.yaxis||"left"} dataKey={f.alias} stroke={color} filter="url(#lineShadow)" />;
							}
						})}
						{
							(refAreaLeft && refAreaRight) ? (
								<ReferenceArea yAxisId="left" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />) : null
						}
					</ComposedChart>
				</ResponsiveContainer>
			</Grid>
			{deltas.length>0 && <Grid item style={{alignSelf:"center"}} md={2} sm={12}>
				{deltas}
			</Grid>}
		</Grid>
	</React.Fragment>;
};
