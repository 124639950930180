import React from 'react';

import FraktureDataTable from '../warehouse/FraktureDataTable';
import {QuickDateRange2} from '../inputs/QueryStringInputs';
//import Card from '@mui/material/Card';
import useAccountWarehouseInfo from '../warehouse/account-warehouse-info';
import {useAccountId} from '../account/AccountInfo.js';
import {Header} from '../components/Header';

import './sourcecode.css';

export function TransactionRow({row,fields}){
	return <tr><td colSpan={fields.length}>{JSON.stringify(row)}</td></tr>;
}

export default function Transactions(){

	const account_id=useAccountId();
	const {loading,error,data} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return JSON.stringify(error);
	const {default_warehouse_bot:{global_table_prefix,_id:warehouse_bot_id},source_code_elements}=data;
	if (!warehouse_bot_id){
		console.log(data);
		return "No warehouse_bot_id from useAccountWarehouseInfo";
	}
	let join=global_table_prefix+"transaction_metadata_override";
	let attribution=global_table_prefix+"attribution";

	let transaction_fields=[
		{field:"transaction_id",editable:false,title:"ID",cellBackgroundColor:"#EEE",width:80,hidden:true},
		{field:"ts",editable:false,title:"Date",cellBackgroundColor:"#EEE",width:110,type:"date"},
		{field:"amount",title:"Amount",type:"currency",editable:false,width:120,cellBackgroundColor:"#EEE"},
		{alias:"recurs",title:"Recurs",fql:`
		case when (recurs_int = 5) then 'Annually'
			when (recurs_int = 4) then 'Quarterly'
			when (recurs_int = 3) then 'Monthly'
			when (recurs_int = 2) then 'Weekly'
			when (recurs_int = 1) then 'Daily'
		else 'Non-recurring' end`,editable:false,cellBackgroundColor:"#EEE",width:120},
		{alias:"person_id",fql:"person_metadata.person_id",title:"Person ID",editable:false,cellBackgroundColor:"#EEE"}
	];


	let table=global_table_prefix+"transaction_summary";
	transaction_fields=transaction_fields.concat([
		{alias:global_table_prefix+"transaction_source_code",
			fql:global_table_prefix+"transaction_summary.transaction_source_code",
			alwaysHidden:true},
		{ title:"Source Code",
			alias:"source_code_final",
			editable:false,
			cellBackgroundColor:"#EEE",
			width:250,
			value:function(row){
				let v=row[global_table_prefix+'transaction_metadata_override-transaction_source_code_override'];
				if (v) return v;
				else return row['transaction_source_code'];
			}
		},
		{ title:"Source Override",
			alias:global_table_prefix+"transaction_metadata_override-transaction_source_code_override",
			fql:join+".transaction_source_code_override",
			width:140
		},]);
	transaction_fields=transaction_fields.concat([
		{ alias:"recommended_message_id",
			fql:attribution+".recommended_message_id",alwaysHidden:true
		},
		{ title:"Message Id",
			alias:"message_id_final",
			editable:false,
			width:120,cellBackgroundColor:"#EEE",
			value:function(row){
				let v=row[global_table_prefix+'transaction_metadata_override-override_message_id'];
				if (v) v=parseInt(v);
				if (v && v!==0) return v;
				else return row['recommended_message_id'];
			}
		},
		{	title:"Message ID Override (-1 for none)",
			alias:global_table_prefix+"transaction_metadata_override-override_message_id",
			fql:`${join}.override_message_id`,
			editable:true,width:120,cellBackgroundColor:"#FFF"},
	]);

	transaction_fields=transaction_fields.concat([{field:"remote_transaction_id",editable:false,title:"Remote Id",width:120,cellBackgroundColor:"#EEE"},
		{field:"remote_page_name",editable:false,title:"Page",width:160,cellBackgroundColor:"#EEE"}]);
	source_code_elements.forEach(def => {
		transaction_fields.push({
			title:def.label,
			alias:global_table_prefix+"transaction_summary-"+def.name,
			fql:global_table_prefix+"transaction_summary."+def.name,
			group:"Source Code Parsing",
			cellBackgroundColor:"#EEE"
		});
	});


	let models=[
		{name:"trans_model_first_touch",label:"Model: First Touch"},
		{name:"trans_model_crm_origin",label:"Model: CRM"}
	];
	transaction_fields=transaction_fields.concat([
		{field:"person_id_int",title:"Person Id (int)",editable:false,cellBackgroundColor:"#EEE",width:90},
		{alias:"origin_source_code",fql:"origin_dictionary.source_code",title:"Origin Source",editable:false,cellBackgroundColor:"#EEE"},
		{field:"transaction_bot_id",editable:false,title:"Bot",width:100,cellBackgroundColor:"#EEE"}
	]);
	models.forEach(m=>{
		transaction_fields.push({
			title:m.label+" Source Code",
			table:global_table_prefix+"transaction_summary",
			alias:global_table_prefix+"transaction_summary-"+m.name+"_source_code",
			field:m.name+"_source_code",
			group:"Models",
			cellBackgroundColor:"#EEE"
		});
	});

	let joins=[
		{alias:join,target:join,
			match_fql:`${join}.remote_transaction_id=${table}.remote_transaction_id AND ${join}.transaction_bot_id=${table}.transaction_bot_id`},
		{alias:attribution,target:attribution,
			match_fql:`${attribution}.remote_transaction_id=${table}.remote_transaction_id AND ${attribution}.transaction_bot_id=${table}.transaction_bot_id`},
		/*{alias:"source_code_dictionary",target:global_table_prefix+"source_code_dictionary",
			match_fql:`${table}.transaction_source_code_id=source_code_dictionary.source_code_id`},
		{alias:"source_code_dictionary_override",target:global_table_prefix+"source_code_dictionary_override",
			match_fql:`${table}.transaction_source_code=source_code_dictionary_override.source_code`},
		*/
		{alias:"origin_dictionary",target:global_table_prefix+"source_code_dictionary",
			match_fql:`${table}.origin_source_code_id=origin_dictionary.source_code_id`},
		{alias:"person_metadata",target:global_table_prefix+"person_metadata",
			match_fql:`${table}.person_id_int=person_metadata.person_id_int`},
	];

	let defaultStart="-7d";

	return <React.Fragment>
		<Header
			primary={[
				"Transactions",
				<QuickDateRange2 key="date" start={defaultStart}/>
			]}
		/>
		<FraktureDataTable
			table={table}
			date_field="ts"
			joins={joins}
			primary_field={"transaction_id"}
			view="grid"
			warehouse_bot_id={warehouse_bot_id}
			fields={transaction_fields}
			default_query={{start:defaultStart}}
			limit={50000}
			order_by={[{table:global_table_prefix+"transaction_summary",
				field:"ts",order_by_direction:"DESC"}]}
		/>
	</React.Fragment>;
}
