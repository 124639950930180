
// Login material
import React,{useEffect,useState} from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import {
	login,
	loginWithGoogle,
	createAccount,
	sendVerificationEmail,
	isVerified,
	useAuthToken,
	logout,
	useCheckUserExists
} from './auth';

class GoogleLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			mouse:null
		};
	}
	render() {
		const{mouse}=this.state;

		const onClick=() => {
			loginWithGoogle();
		};

		let button;

		if(mouse === 'over') button = <img alt="google_signin" onClick={onClick}
			src='/vendors/google/btn_google_signin_dark_focus_web.png'
			onMouseOut={() => this.setState({mouse:null})} />;
		else button = <img alt="google_signin" onClick={onClick}
			src='/vendors/google/btn_google_signin_dark_normal_web.png'
			onMouseOver={() => this.setState({mouse:'over'})}
			onMouseOut={() => this.setState({mouse:null})}/>;

		return <span style={{align:'center', marginTop:'10px', cursor:'pointer'}}>{button}</span>;
	}
};

class CreateNewAccount extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			username:'',
			password:'',

			email_error:'',
			password_error:''
		};
	}

	render() {
		const {username,password,password_error,email_error}=this.state;
		return (<main style={{
			width: 400,
			display: 'block', // Fix IE11 issue.
			marginLeft: 'auto',
			marginRight: 'auto'
		}}>
			<Paper style={{
				marginTop: 80,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: `20px 30px 30px`,
			}}>
				<Typography variant="h5">Create New Account</Typography>
				<form onSubmit={(e) => {
					e.preventDefault();
					createAccount({username,password}, (e)=>{
						if(e) {
							// eslint-disable-next-line no-shadow
							let email_error = e.email_error;
							// eslint-disable-next-line no-shadow
							let password_error=e.password_error;
							if(!email_error && !password_error) email_error = e;
							return this.setState({email_error,password_error});
						}
						console.log('Successfully created account');
					});
				}}>
					<FormControl margin="normal" required fullWidth>
						<TextField label='Email'
							error={!!email_error} helperText={email_error}
							value={username} required onChange={e=>this.setState({username:e.target.value})} />
					</FormControl>
					<FormControl margin="normal" required fullWidth>
						<TextField label='Password' type='password'
							error={!!password_error} helperText={password_error}
							required value={password} onChange={e=>this.setState({password:e.target.value})} />
					</FormControl>
					<div>
						<Button fullWidth type="submit" color="primary">
							Create Account
						</Button>
					</div>
				</form>
			</Paper>
		</main>);
	}
};

export function VerifyEmail() {
	useEffect(() => {
		sendVerificationEmail();
	},[]);

	return (<main style={{
		width: 400,
		display: 'block', // Fix IE11 issue.
		marginLeft: 'auto',
		marginRight: 'auto'
	}}>
		<Paper style={{
			marginTop: 80,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `20px 30px 30px`,
		}}>
			<Typography variant="h5">Verify your email</Typography>
			<div style={{marginTop: '20px'}}>
				A verification email has been sent to the address you provided.
			</div>
			<div style={{marginTop: '20px'}}>
				If you have verified your email and you still see this message,
				please <button className="anchor" onClick={()=>logout()}>sign out and log back in</button>
			</div>
		</Paper>
	</main>);
}

export function DoesNotExist() {
	return (<main style={{
		width: 400,
		display: 'block', // Fix IE11 issue.
		marginLeft: 'auto',
		marginRight: 'auto'
	}}>
		<Paper style={{
			marginTop: 80,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `20px 30px 30px`,
		}}>
			<Typography variant="h5">No Accounts</Typography>
			<div style={{marginTop: '20px'}}>
				It would appear that you are not a part of any Frakture accounts -
				you'll need to be invited to join one before you can access the Console.
			</div>
			<div style={{marginTop: '20px'}}>
				Click <button className="anchor" onClick={()=>logout()}>here</button> to sign out.
			</div>
		</Paper>
	</main>);
}

function Login(props) {
	const [username,setUsername]=useState('');
	const [password,setPassword]=useState('');

	const [createNewAccount,setCreateNewAccount]=useState(false);

	if(createNewAccount) return <CreateNewAccount />;

	const{onSignIn}=props;
	const onSubmit = (event) => {
		event.preventDefault();
		login({username,password}, onSignIn);
	};
	const{error}=props;

	let email_error,auth_error;
	if(error && error.code === 'auth/invalid-email') {
		email_error = error.message;
	} else if(error && error.code==='auth/user-not-found') {
		email_error = 'This user does not exist';
	} else if(error && error.code==='auth/wrong-password') {
		auth_error = 'This password is invalid';
	}

	let createNewAccountButton = null;
	//creating a new account not currently allowed -- email/password must be provided
	/*if(props.allowCreateNewAccount) {
		createNewAccountButton = <div style={{marginTop: '20px'}}>Don't have an account? <button className="anchor"onClick={(e) => {
			e.preventDefault();
			// this.setState({createNewAccount: true});
			setCreateNewAccount(true);
		}}>Create One</button></div>;
	}*/

	return (<main style={{
		width: 400,
		display: 'block', // Fix IE11 issue.
		marginLeft: 'auto',
		marginRight: 'auto'
	}}>
		<Paper style={{
			marginTop: 80,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			padding: `20px 30px 30px`,
		}}>
			<Typography variant="h5">Sign in</Typography>
			<GoogleLogin onSignIn={(e,r)=>onSignIn(r)} />
			<br/><hr className="w-100"/><br/>
			<Typography variant="h5">Email Login</Typography>
			Not recommended -- if necessary please obtain a password from your Frakture partner.
			<form onSubmit={e=>onSubmit(e)}>
				<FormControl margin="normal" required fullWidth>
					<TextField label='Email'
						error={!!email_error} helperText={email_error}
						value={username} required onChange={e=>setUsername(e.target.value)} />
				</FormControl>
				<FormControl margin="normal" required fullWidth>
					<TextField label='Password' type='password'
						error={!!auth_error} helperText={auth_error}
						required value={password} onChange={e=>setPassword(e.target.value)} />
				</FormControl>
				<Button type="submit" fullWidth variant="contained">
					Sign in
				</Button>
			</form>
			{createNewAccountButton}
		</Paper>
	</main>);
};

export function useLogin(props) {
	props = props || {};

	const [authenticated,setAuthenticated]=useState('unknown');
	const {allowCreateNewAccount}=props;

	const [token,error,loading]=useAuthToken();
	const exists = useCheckUserExists();

	const [loginError,setLoginError]=useState();
	const verified = isVerified();

	useEffect(() => {
		if(token) {
			if(authenticated !== 'yes' && !verified) {
				setAuthenticated('unverified');
				return;
			}
			if(authenticated !== 'yes' && exists === false) {
				setAuthenticated('no-user');
				return;
			}
			if(exists === true) {
				setAuthenticated('yes');
				return;
			}
			return;
		}
		if(!loading && error) {
			setAuthenticated('no');
			return;
		}
	},[token,error,loading,verified,exists,authenticated]);

	let loginElement = null;

	if(authenticated === 'no') {
		loginElement = <Login onSignIn={(e) => {
			if(e) {
				console.error('error on sign in',e);
				setLoginError(e
				);
			} else {
				setLoginError(undefined);
			}
		}} {...{allowCreateNewAccount,error:loginError}}/>;
	}
	if(authenticated === 'unverified') {
		console.log('account is unverified');
		loginElement = <VerifyEmail/>;
	}

	if(authenticated === 'no-user') {
		loginElement = <DoesNotExist />;
	}

	return [
		{
			authenticated,
			token: authenticated === 'no-user' ? null : token,
			error,
			loading
		},
		loginElement
	];
};

export default Login;
export {CreateNewAccount};
