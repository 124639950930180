import React,{useState} from 'react';
import TextField from '@mui/material/TextField';
import {fraktureQuery} from '../FraktureQuery';
import gql from "graphql-tag";

const VALIDATE_PERMISSION = gql`query validate($permission:String) {
	validate_permission(permission: $permission)
}`;
export default function ValidatePermission(props) {
	const{onValid}=props;
	const[text,setText]=useState('');
	const [unvalidated,setUnvalidated]=useState('');
	const textField = <TextField fullWidth value={text}
		placeholder="Enter New Permission"
		onChange={e => {
			setText(e.target.value);
			setUnvalidated('');
		}} onBlur={() => {
			setUnvalidated(text);
			if(!text) onValid('');
		}} />;

	if(!unvalidated) return textField;

	return <React.Fragment>
		{textField}
		<div>
			{fraktureQuery({
				fetchPolicy:'network-only',
				onCompleted: data => {
					console.log('on completed:',data);
					const{validate_permission}=data;
					if(validate_permission && validate_permission[0] === 'valid') onValid(text);
					else onValid('');
				},
				onError: error => {
					setUnvalidated(JSON.stringify(error));
				},
				query: VALIDATE_PERMISSION,
				variables: {permission:text}
			}, data => JSON.stringify(data))}
		</div>
	</React.Fragment>;
};
