import React from 'react';
import Grid from '@mui/material/Grid';


class ErrorBoundary extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false,error:null };
	}

	componentDidCatch(error) {
		// Display fallback UI
		console.error("Caught an error at the top level",error);
		this.setState({ hasError: true,error:error });
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, info);
	}

	render() {

		if (this.state.hasError) {
		// You can render any custom fallback UI
			return <Error error={this.state.error}/>;
		}

		return this.props.children;
	}
}
export const defaultLoadIcon = <i className='zmdi zmdi-hc-3x zmdi-spinner zmdi-hc-spin' style={{position:"relative",margin:"auto"}}/>;
export function Loading(){
	return defaultLoadIcon;
}

class Error extends React.Component {

	render() {
		const error=this.props.error;

		function getErrorMessage(e){
			if (!e){return "";}
			if (typeof e=='string') return e;
			if (Array.isArray(e) && e.length>0) return getErrorMessage(e[0]);
			if (e.graphQLErrors && e.graphQLErrors.length>0) return getErrorMessage(e.graphQLErrors);
			if (e.originalError) return getErrorMessage(e.originalError);
			if (e.errors) return getErrorMessage(e.errors);
			if (e.message) return getErrorMessage(e.message);
			let m=e.toString();
			try{
				m=JSON.stringify(e);
			}catch(e){
				//could be circular reference
			}
			return m;
		}

		let message=getErrorMessage(error);

		console.error("Error component called with:",message);
		console.error(error);

		return (
			<Grid container justifyContent="center">
				<Grid item xs={8}>
					<div className="card mb-3">
						<div className="card-header  text-white bg-danger">Houston, we have a problem.</div>
						<div className="card-body">
							<p className="card-text">{message}</p>
						</div>
					</div>
				</Grid>
			</Grid>
		);
	}
}
export {Error,ErrorBoundary};
export default Error;
