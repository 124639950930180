import React,{useRef} from 'react';
import {QuickDateRange2,SegmentPicker} from '../../inputs/QueryStringInputs';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useParams,useNavigate} from 'react-router-dom';
import useNotifiers from '../../Notifiers';
import {MenuToggle} from '../../sidebar/';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faCopy,faShareNodes,faSliders} from '@fortawesome/pro-regular-svg-icons';

import IconButton from '@mui/material/IconButton';
function Copy({text}) {
	const {notify}=useNotifiers();
	const textAreaRef = useRef(null);

	function copyToClipboard(e) {
		textAreaRef.current.select();
		document.execCommand('copy');
		// This is just personal preference.
		// I prefer to not show the whole text area selected.
		e.target.focus();
		notify({message:'Copied!'});
	};

	return (
		<div className="copy-text">
			<form>
				<input
					ref={textAreaRef}
					value={text}
					readOnly
				/>
			</form>
			{document.queryCommandSupported('copy') &&
				<div className="copy-button">
					<IconButton onClick={copyToClipboard} variant="outlined"><FontAwesomeIcon icon={faCopy}/></IconButton>
				</div>
			}
		</div>
	);
}



function ShareDialog() {
	const [open, setOpen] = React.useState(false);
	const {report_folder_short:short,report_id}=useParams();

	const handleClickOpen = () => {setOpen(true);};

	const handleClose = () => {setOpen(false);};
	let domain=window.location.hostname.indexOf('local')>0?window.location.hostname:"report.frakture.app";
	//let folder_url="https://"+domain+'/report/'+short;
	let report_url=null;
	if (report_id) report_url="https://"+domain+'/report/'+short+"/"+report_id+window.location.search;

	return (
		<React.Fragment>
			<IconButton variant="outlined" onClick={handleClickOpen}>
				<FontAwesomeIcon icon={faShareNodes} />
			</IconButton>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Share Links</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{report_id?<React.Fragment>
						Anyone with these links can access these reports.
							<h3>Report Link</h3>
							<Copy text={report_url}/>
						</React.Fragment>:<div>Anyone with this link can access these shared reports.</div>
						}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<IconButton onClick={handleClose} color="primary" autoFocus size="large">Close</IconButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export function FolderMenu(props){
	const {folder}=props;
	const navigate=useNavigate();
	const params=useParams();
	const {report_folder_short}=params;

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);

	return	<div className="folder-menu-wrapper">
		<Button className="text-nowrap h-100" onClick={handleOpen}><FontAwesomeIcon icon={faBars} fixedWidth/></Button>
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
		>
			<List id="report-list"
				subheader={
					<ListSubheader component="div" id="nested-list-subheader">
						{folder.label}
					</ListSubheader>
				}
			>
				<List>
					{(folder.reports||[]).map(r=><ListItem key={r._id} onClick={e=>navigate("/report/"+report_folder_short+"/"+r._id)}>
						<ListItemText>{r.label}</ListItemText>
					</ListItem>)}
				</List>
			</List>
		</Popover></div>;
}


export default function ReportHeader(props){
	let {short,report_id,account,label,include_date,
		include_segments,folder,
		filters,logo="https://frakturecdn.s3.amazonaws.com/accounts/frakture/frakture-icon-lg.png",editing_tools,
		query_string,
		toggleFilter,
		header_variant
	}=props;
	const {report_folder_short}=useParams();

	if (include_date===false) include_date=false;
	else include_date=true; //if not specified, include the date

	let logoItem=logo;
	if (logo){
		if (typeof logo=='string'){
			logoItem=<img src={logo} height="48" alt={label}/>;
		}else{
			logoItem=logo;
		}
	}

	return (
		<div className="frakture-report-header">
			<div className="report-row d-flex">
				{(label || folder) && <div className="d-flex">
					{folder?<FolderMenu folder={folder}/>:<MenuToggle/>}
					{label && <div className="report-label">
						<span className="report-label-text">{label}</span>
					</div>}
				</div>}
				{report_folder_short==='slug'?<span className="report-label-demo"><i>Demo Data</i></span>:""}
				<div className="report-filters">
					{include_date?<QuickDateRange2 query_string={query_string} fiscal_year={account?.fiscal_year}/>:""}
					{include_segments?<SegmentPicker/>:""}
				</div>
				<div className="report-logo d-none d-md-block text-nowrap text-right">
					{header_variant==='full' && account && account.name && <span className="report-account-name">{account.name}</span>}
					<ShareDialog short={short} report_id={report_id}/>
					{editing_tools}
					{logoItem}
					{filters?.length>0 &&
					<IconButton
						aria-label="open filters"
						edge="end"
						onClick={toggleFilter}>
						<FontAwesomeIcon icon={faSliders}/>
					</IconButton>
					}
				</div>
			</div>
		</div>
	);
}
