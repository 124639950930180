import React,{useState} from 'react';
import DataGridWarehouseTable from "./DataGridWarehouseTable";
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';


import {useConditionsFromQueryString,useWarehouseQuery} from './WarehouseQuery';
import {useAccountId} from '../account/AccountInfo';
import FraktureTable from '../FraktureTable';
import {useSetHash} from '../inputs/QueryStringInputs';
import sift from 'sift';

//import {formatValue} from '../formatters';

//import { DataGrid } from '@mui/x-data-grid';

/*
function FraktureDataGrid({data,fields,sort,sortDirection,onSort,onFilter,includeGroupBy}) {
	let columns = null;
	if ((!fields || fields.length===0)&&data.length>0){
		columns=Object.keys(data[0]).map(x=>({field: x, headerName: x}));
	}else{
		columns=fields.map(x=>({field: x.alias || x.field, headerName: x.alias|| x.field}));
	}
	return <DataGrid
		rows={data}
		columns={columns}
	/>;
}
*/



export function HTMLTable(props){
	let {warehouse_bot_id,fields,filters,table,join,joins,offset,
		limit,includeGroupBy,includeFilter,rowsPerPage,sort,sortDirection,selectedFields,setSelectedFields}=props;
	const account_id=useAccountId();
	const [localFilters,setLocalFilters]=useState(filters);

	let onFilter=null;
	if (includeFilter){
		onFilter=function(f){
			setLocalFilters(f);
		};
	};

	let conditions=(props.conditions||[]).concat(filters||[]).concat(localFilters||[]);

	let order_by=JSON.parse(JSON.stringify(props.order_by||[]));
	if(sort) order_by.push({fql:sort,order_by_direction:sortDirection.toUpperCase()});
	let graphqlFields=(fields||[]).map(d=>({table:d.table,field:d.field,fql:d.fql,alias:d.alias}));
	const {data,fields:responseFields,loading,error}=useWarehouseQuery({bot_id:warehouse_bot_id,table,join,joins,conditions,order_by,offset,limit,fields:graphqlFields});
	if (loading) return loading;
	if (error) return error;
	let columns = null;
	if (fields){
		columns=fields.map(x=>({field: x.alias || (x.table+'-'+x.field), title: x.title}));
	}else{
		if ((!responseFields || responseFields.length===0) && data.length>0){
			console.log("No responseFields found, intuiting from data");
			columns=Object.keys(data[0]).map(x=>({field: x, title: x.split("-").pop()}));
		}else if (responseFields && responseFields.length>0){
			console.log("responseFields found");
			columns=responseFields.map(x=>({field:x.table?x.table+'-'+x.field:x.field, title: x.field,data_type:x.data_type,raw:x}));
		}else{
			return "No data and no available fields";
		}
	}
	columns.forEach(d=>{
		if (d.field.split("-").pop()==='person_id'){
			d.render=({row})=><a href={`/app/${account_id}/timeline?person_id=${row[d.field]}`}>{row[d.field]}</a>;
		}else if (d.field.split("-").pop()==='person_id_int'){
			d.render=({row})=><a href={`/app/${account_id}/timeline?person_id_int=${row[d.field]}`}>{row[d.field]}</a>;
		}
	});
	let liveQuery="";

	return <div className="frakture-data-table">
		{(data.length===0)?<div className="alert alert-info">No records match these filters</div>
			:
			<FraktureTable
				header={null}
				rows={liveQuery?data.filter(sift(liveQuery)):data}
				columns={columns}
				selectedFields={selectedFields}
				setSelectedFields={setSelectedFields}
				includeGroupBy={includeGroupBy}
				includeFilter={true}
				rowsPerPage={rowsPerPage}
				bot_id={warehouse_bot_id}
				sort={sort}
				sortDirection={sortDirection}
				onFilter={onFilter}
			/>}
	</div>;
}

export default function FraktureDataTable(props){
	let {title,warehouse_bot_id,table,join,joins,date_field,default_query,
		conditions:conditionsProp=[],use_query_conditions=true,order_by,offset=0,
		limit=5000,
		fields,primary_field,height,menu,editable=true,view,
		includeInsert,insertDefaultFunction
	}=props;

	const location=useLocation();
	const setHash=useSetHash();

	let hashParams = queryString.parse(location.hash);

	if (!view) view='html';
	if (hashParams.view)view=hashParams.view;

	//which columns to show, default the first 10
	let selectedFields=(hashParams.fields ||"").split(",").filter(Boolean);
	//const [selectedFields,_setSelectedFields] = useState(f);
	function setSelectedFields(c){
		if (c.length===0) setHash('fields');
		setHash('fields',c.join(","));
	}

	/*
	selectedFields=fields.map((f,i)=>f.hidden?null:i);
		selectedFields=selectedFields.filter(x=>x!==null);
		selectedFields=columns.map((r,i)=>(fields||[]).find(f=>f.alias===r.field)?.hidden?null:i).filter(Boolean);
	*/

	const {conditions:queryConditions,loading:queryLoading,limit:queryLimit}=useConditionsFromQueryString({parameter:"filter",date_field,default_query});
	if (queryLoading) return "Loading conditions";

	limit=queryLimit||limit;
	let conditions=JSON.parse(JSON.stringify((conditionsProp||[])));
	if(use_query_conditions)conditions=conditions.concat(queryConditions);
	if(props.fql) conditions.push({fql:props.fql});
	let allFieldsWithDetails=null;
	if (fields){
		let named=fields.filter(t=>t.name);
		if (named.length>0){
			// console.log("Fields props:",fieldsProp);
			console.error("Some fields have 'name' properties, this is deprecated in favor of table and field",named);
			return "Error -- don't include name in field, should be table+field.alias";
		}
		allFieldsWithDetails=fields //don't filter here, we may need these fields later
			//.filter(d=>d.field || d.fql) //If there isn't a field or fql, don't request from source but filter this out later, because there may be hidden fields
			.map(_d=>{
				let out=Object.assign({},_d);
				out.table=out.table || table;
				let {fql,alias}=out;
				if (fql && !alias) throw new Error("fql fields require an alias, none found for "+fql);
				//only assign an alias IF there's a field or alias, because this may be a calculated non-database field
				if (out.alias || out.field) out.alias=out.alias||out.table+"-"+out.field;
				return out;
			});
	}

	let hashConditions=Object.keys(hashParams).filter(k=>k.indexOf('f.')===0).map(k=>{
		let fieldName=k.split(".")[1];
		let arr=hashParams[k].split(",").filter(Boolean).map(v=>v==="_blank"?"":v);
		if (arr.length===0) return null;
		else if (arr.length===1){
			return {fql:fieldName+"="+`"${arr[0]}"`};
		}else{
			return {fql:fieldName+" IN ("+arr.map(v=>'"'+v+'"').join(",")+")"};
		}
	}).filter(Boolean);
	conditions=conditions.concat(hashConditions);
	let opts={warehouse_bot_id,table,join,joins,conditions,order_by,offset,limit,
		fields:allFieldsWithDetails,
		selectedFields,
		setSelectedFields,
		primary_field,height:height||1000,menu,editable,
		includeInsert,insertDefaultFunction};
	let header=[];
	if (title)header.push(<h2 key="title">{title}</h2>);

	return <>
		{header.length?<div className="app-main-content-header">{header}</div>:""}
		{(view==='grid')?<DataGridWarehouseTable {...opts}/>:<HTMLTable {...opts}/>}
	</>;
}
