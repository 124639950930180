import React,{useState} from 'react';

import Cookie from 'js-cookie';
import gql from 'graphql-tag';

import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import PublishIcon from '@mui/icons-material/Publish';

import useNotifiers from '../../Notifiers';
import {fraktureQuery} from '../../FraktureQuery';
import {useApolloClient} from '@apollo/client';
import {useAuthToken} from '../../../auth';

const updateAuthValueMutation=gql`mutation updateBotAuth($_id:ID!,$key:String!,$value:String!) {
	bot_set_auth(_id:$_id,key:$key,value:$value) {
		_id
		auth {
			bot{
				api_endpoint
				token
			}
		  	key
		  	value
		  	is_value_set
			oauth_uri
		  	field {
				type
				label
				description
				is_visible
				show_in_ui
		  	}	
		}
	}
}`;

function OTPUploadField({bot_id,field}) {
	// const {notify,notifyErr} = useNotifiers();

	const [file,setFile]=useState();
	const [,setUploadStatus]=React.useState(null);
	const [token]=useAuthToken();

	// hardcoded uris are bad, but so is sadness
	const upload_uri = `https://data.frakture.com:4000/bot/${bot_id}/auth/${field.key}/qr`;

	return (
		<form onSubmit={e=>{
			e.preventDefault();
			const formData = new FormData();
			formData.append('qr_auth',file);
			fetch(upload_uri, {
				method: 'post',
				headers: {
					'Authorization': 'BEARER '+token
				},
				body: formData
			}).then(x => {
				console.log(x);
				return x.json();
			}).then(x => {
				setUploadStatus(x);
			}).catch(e => {
				console.error("error:", e);
				setUploadStatus(e);
			});
		}}>
			<Input type='file' name='qr_auth' onChange={e=>setFile(e.target.files[0])} />
			<IconButton type='submit' size="large">
				<PublishIcon/>
			</IconButton>
		</form>
	);
};

export function BotAuthField({auth,bot_id}){
	const {notify,notifyErr} = useNotifiers();
	const client = useApolloClient();
	const [newValue,setNewValue]=React.useState(auth.value);

	let {
		field,
		field:{label,description,is_visible},
		oauth_uri,
		key,
		is_value_set,
		is_parent_value_set,
		parent_value,
		can_auth
	}=auth;

	let value=auth.value || "";
	if(!is_visible && is_value_set) value='**********';

	if(!description && (label||'').length > 30) {
		description = label;
		const keyParts = key.split('_')
			.map(x => x === 'api' || x === 'id' ? x.toUpperCase() : x)
			.map(x => x[0].toUpperCase() + x.slice(1));
		label = keyParts.join(' ');
	}

	let link=oauth_uri||"";
	if(oauth_uri){
		if (can_auth){
			if (document.location.href.indexOf("console.")<0){
				link="(Go to console.frakture.com to authorize)";
			}else{
				link=<a onClick={() => {
				// document.cookie=`last_bot_id=${bot_id}`;
					Cookie.set('last_bot_id', bot_id, {path:'/'});
				}} href={oauth_uri}>Click to get access token</a>;
			};
		}else{
			link="Restricted from OAuth";
		}
	}

	function mutate(value){
		if (value===null){return;} //Null isn't valid, it means we haven't changed anything.  Blank is valid.
		client.mutate({mutation:updateAuthValueMutation,variables:{_id:bot_id,key,value}}).then(notify,notifyErr);
	}

	let type;
	if(!is_visible) type='password';

	let placeholder;
	if(is_parent_value_set) {
		if(!is_visible) placeholder = '********** - From Parent';
		else placeholder = parent_value + ' - From Parent';
	}

	let inputLabelProps;
	if(placeholder) inputLabelProps={
		shrink: !!placeholder
	};

	let displayValue = newValue || "";
	if(!newValue && is_value_set && !is_visible) displayValue = '**********';

	let upload;
	if(field.type === 'otp_secret') {
		upload = <OTPUploadField {...{bot_id,field:{key}}} />;
	}

	return (
		<React.Fragment>
			<form autoComplete='off' onSubmit={null}>
				<TextField
					margin="dense"
					key={key}
					label={label}
					value={displayValue}
					fullWidth
					type={type}
					placeholder={placeholder}
					InputLabelProps={inputLabelProps}
					InputProps={{
						endAdornment:<InputAdornment position="end">
							<Tooltip title="Clear field">
								<IconButton onClick={() => mutate('')} size="large">
									<ClearIcon />
								</IconButton>
							</Tooltip>
						</InputAdornment>
					}}
					autoComplete='off'
					onKeyPress={(e) => {
						if (e.key === 'Enter') {
							mutate(newValue);
							e.preventDefault();
						}
					}}
					onChange={e => {
						setNewValue(e.target.value);
					}}
					onBlur={e => {
						if(newValue !== value) mutate(newValue);
					}}
					helperText={description}
					disabled={!can_auth}
				/>
				{link}
				{upload}
			</form>
		</React.Fragment>
	);
};

const BOT_AUTH=gql`query BOT_AUTH($bot_id:ID!) {
	bot(_id:$bot_id) {
		_id
		auth {
		  key
		  value
		  	bot{
				api_endpoint
				token
		   }
		   oauth_uri
		   is_value_set
		   parent_value
		   is_parent_value_set
		   can_auth
		  field {
				label
				type
				description
				is_visible
				show_in_ui
				required
		  }
		}
	}
}`;

export default function BotAuthFields(props){
	const {bot_id, required_only=false}=props;
	return fraktureQuery({
		query: BOT_AUTH,
		variables: {bot_id}
	}, ({bot:{auth}}) => auth
		.filter(x => !required_only || x.field.required).map((a,i) => <BotAuthField
			key={i}
			bot_id={bot_id}
			auth={a}
			autoFocus={!i}/>));
};
