import React from 'react';
import gql from 'graphql-tag';

import {useQuery} from '@apollo/client';

import Tooltip from '@mui/material/Tooltip';
import {Link} from 'react-router-dom';

const TABLE_INFO_QUERY=gql`
query TABLE_INFO_QUERY($bot_id:ID!) {
	bot(_id:$bot_id) {
		account_id
		warehouse_bot_id
	}
}`;

export function TableLink({bot_id,table}) {
	const {data,error,loading}=useQuery(TABLE_INFO_QUERY,{variables:{bot_id}});

	if(loading) return <span>{table}</span>;
	if(error) {
		console.error(error);
		return <span>{table}</span>;
	}

	const {account_id,warehouse_bot_id}=data.bot||{};

	const uri = `/app/${account_id}/warehouse/${warehouse_bot_id}/${table}`;
	return <Tooltip title='Click to open table'>
		<Link to={uri}>{table}</Link>
	</Tooltip>;
}
