import React from 'react';

export default ([
	{name:"account_name",label:"Account"},
	{name:"account_type",label:"Account Type"},
	{name:"channel",label:"Message Channel (aka Platform)"},
	{"name":"source_code_channel",
		"label":"Source Code Channel","description":"Channel as defined in the source code, possibly different from the sending message platform"},
	{name:"bot_label",label:"Bot"},
	{name:"bot_nickname",label:"Bot Nickname"},
	{name:"bot_path",label:"Bot Path"},
	{"name":"campaign_name","label":"Campaign (By message)","description":"Campaign name pulled from message"},
	//{"name":"campaign","label":"Campaign (By Source Code)","description":"Campaign pulled from source code"},
	{"name":"campaign_label","label":"Campaign (By Source Code)","description":"Campaign label pulled from source code"},

	{"name":"message_set","label":"Message Set","description":"Multiple grouped messages, like AdSet"},
	{"name":"variant","label":"Variant","description":"Specific message variant"},

	{"name":"account_prefix","label":"Account (from Source code)","description":""},
	{"name":"source_code_date","label":"Date (from Source code)","description":"YYYYMMDD format of the source code date.  Perhaps different from message publish date"},

	{"name":"subchannel","label":"Channel subtype","description":"More details on the communication channel"},
	{"name":"media","label":"Media type","description":"Video, text, image, etc. aka MIME type"},

	//content type groupings
	{"name":"appeal","label":"Appeal","description":"A specific grouping of content, e.g."},
	{"name":"affiliation","label":"Affiliation","description":"High level affiliation for message"},
	{"name":"fund","label":"Fund","description":"The fund this is targeted to"},
	{"name":"issue","label":"Issue","description":"Broad issue area"},
	{"name":"theme","label":"Theme","description":"Broad theme of the message"},
	{"name":"policy","label":"Policy","description":"Policy area, e.g. Federal/State/International policy"},
	{"name":"goal","label":"Goal","description":"e.g. Advocacy, Fundraising, Retention, Acquisition, Ticket sales"},
	{"name":"goal_2","label":"Secondary Goal","description":"Secondary Goal"},

	//targeting data -- use 'audience' as segment could be used later down the road
	{"name":"audience","label":"Audience","description":"Target audience"},
	{"name":"targeting","label":"Targeting Details","description":"More targeting details"},
	{"name":"targeting_2","label":"Additional Targeting Details","description":"More targeting details"},

	{"name":"author","label":"Author","description":"The human credited sender of the message - John Smith, Mary Jane"},
	{"name":"signer","label":"Signer","description":"The human at the bottom of the letter -- often a celebrity -- ‚'Prince','Gandhi','The Rolling Stones', etc."},
	{"name":"agency","label":"Agency","description":"The responsible marketing agency"},
	{"name":"partner","label":"Partner","description":"Sending organization, used for list rentals, partnerships, etc"},

	{"name":"department","label":"Organization/Department","description":"Responsible department within the organization"},
	{"name":"geo","label":"Geography","description":"Target geography"},

	//types of messaging
	{"name":"organic","label":"Organic/Paid","description":"Organic or paid message"},
	{"name":"recurtype","label":"Recurring Campaign Type","description":"If this is a recurring campaign effort, please select from the following options"},

	{"name":"additional_info","label":"Additional","description":"Additional info"}
].sort((a,b)=>a.name<b.name?-1:1));
