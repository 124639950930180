import React from 'react';
import {Route, Routes} from 'react-router-dom';

import Settings from './Settings';
import Home from './Home';

export default function() {
	return <Routes key="content">
		<Route path='*' element={<Home/>} />
		<Route path='settings' element={<Settings/>}/>
	</Routes>;
};


