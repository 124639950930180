import React,{useState,useEffect} from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import SingleComponent,{useComponentData} from '../report/display/SingleComponent';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Grid from '@mui/material/Grid';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';

import RunDataflowButton from '../dataflow/RunDataflowButton';

import {useLevel0Data} from './Level0';

export function useLevel1Data(props) {
	const{account_id}=props;

	const level0=useLevel0Data(props);
	const [level1,setLevel1]=useState();

	const useData = (opts) => useComponentData({
		account_id,
		table: opts.table,
		fields: {
			bot_id: opts.bot_id,
			total: 'count(*)',
			min_date: `min(${opts.date_field})`,
			max_date: `max(${opts.date_field})`,
			max_date_within_1_month: `max(${opts.date_field}) > date_sub(now(), interval 1 month)`,
			months_of_data: `datediff(max(${opts.date_field}), min(${opts.date_field})) / 30`
		},
		conditions:[
			`${opts.bot_id} is not null and ${opts.bot_id} <> ''`,
			`${opts.date_field} <> 0`
		],
		group_by: [opts.bot_id]
	});

	const tx = useData({
		table: "${global_table_prefix}transaction_metadata",
		bot_id: 'transaction_bot_id',
		date_field: 'ts'
	});
	const msg = useData({
		table: "${global_table_prefix}global_message",
		bot_id: 'bot_id',
		date_field: 'publish_date'
	});
	const ppl = useData({
		table: "${global_table_prefix}person_metadata",
		bot_id: 'origin_bot_id',
		date_field: 'origin_date_created'
	});

	useEffect(() => {
		if(ppl.loading && tx.loading && msg.loading) return;
		if(!tx.data && !ppl.data && !msg.data) return;

		const l1 = Object.assign({}, level1||{});

		l1.byBot = Object.assign(l1.byBot || {});

		l1.byType = Object.assign({}, l1.byType || {});
		l1.byType.person = Object.assign({},l1.byType.person||{});
		l1.byType.transaction = Object.assign({},l1.byType.transaction||{});
		l1.byType.message = Object.assign({},l1.byType.message||{});

		let loadTypesByBot = {};
		if(level0) {
			loadTypesByBot = level0.loadTypesByBot||{};
			l1.level0 = level0;
		}

		console.log('level0:',level0);

		const process = (type, {data}) => {
			if(!data) return;
			data.forEach(x => {
				const b = l1.byBot[x.bot_id] = Object.assign({}, l1.byBot[x.bot_id] || {});
				b[type] = x;
				l1.byType[type][x.bot_id]=x;
				x.missing = x.missing || {};
				if(!x.max_date_within_1_month) x.missing['Most recent data is from over 1 Month ago']=1;
			});

			Object.keys(loadTypesByBot).filter(x => !!loadTypesByBot[x][type]).forEach(bot_id => {
				if(!l1.byType[type][bot_id]) {
					l1.byType[type][bot_id] = {
						total: 0,
						missing: ['No data of this type has been loaded using this Bot']
					};
				}
			});
		};

		process('transaction', tx);
		process('message', msg);
		process('person', ppl);

		setLevel1(l1);
	}, [tx.data, tx.loading, ppl.data, ppl.loading, level0]);

	// if(tx.error) throw tx.error;

	return level1;
}

export function useLevel1StepComplete(props) {
	const level1 = useLevel1Data(props);
	if(!level1) return {completed: false, missing:[]};
	const {byType={}} = level1||{};
	const missing = [];
	Object.keys(byType).forEach(x => {
		const bots = Object.values(byType[x]);
		bots.forEach(b => {
			if(b.missing&&b.missing.length) missing.push(b.bot_id + ' - ' +b.missing.join(','));
		});
	});

	return {
		completed: !missing.length,
		missing
	};
}

function Level1OverviewForType(props) {
	const {level1, account_id, date_field, type} = props;

	const overview=<SingleComponent {...{
		account_id,
		table: props.table,
		name: props.name,
		component: 'FraktureBarChart',
		fields: {
			date: `date(${date_field})`,
			bot_id: props.bot_id,
			count: 'count(*)'
		},
		group_by: [`date(${date_field})`, props.bot_id],
		is_date: true,
		breakdown: 'bot_id',
		date_field: 'date'
	}} />;

	let rows = '';
	if(level1 && level1.level0) rows = Object.values(level1.byType[type]||{}).map(bot => {
		const {bot_id,max_date,min_date,total,months_of_data, missing} = bot;

		if(!(level1.level0.loadTypesByBot||{})[bot_id]) return '';
		const df = level1.level0.loadTypesByBot[bot_id][type];
		if(!df) return '';

		const def = (level1.level0||{}).channelBotsById[bot_id];

		let show = bot_id;
		if(def) show = <img width={150} height={75} src={def.definition.metadata.logo}/>;

		let status;
		if(missing&&Object.keys(missing).length) {
			status = <Tooltip title={Object.keys(missing).join(',')}>
				<ErrorIcon color='error' fontSize='large'/>
			</Tooltip>;
		}
		else {
			status = <Tooltip title='Good to go!'>
				<CheckCircleIcon color='primary' fontSize='large'/>
			</Tooltip>;
		}

		const dataflow_id = df._id;

		return <TableRow key={bot_id}>
			<TableCell style={{width:'35px'}}>{status}</TableCell>
			<TableCell style={{width:'150px'}}>{show}</TableCell>
			<TableCell><b>{total}</b> records across <b>{parseInt(months_of_data)} months</b> from {min_date} to {max_date}</TableCell>
			<TableCell><RunDataflowButton dataflow_id={dataflow_id} /></TableCell>
		</TableRow>;
	});

	return <React.Fragment>
		{overview}
		<Table>
			<TableBody>
				{rows}
			</TableBody>
		</Table>
	</React.Fragment>;
}

function Level1TransactionOverview(props) {
	return <Level1OverviewForType {...props}
		table="${global_table_prefix}transaction_metadata"
		type='transaction'
		name='Transactions by Source Bot'
		bot_id='transaction_bot_id'
		date_field='ts'
	/>;
}

function Level1MessageOverview(props) {
	return <Level1OverviewForType {...props}
		table="${global_table_prefix}global_message"
		type='message'
		name='Messages by Source Bot'
		bot_id='bot_id'
		date_field='publish_date'
	/>;
}

function Level1PersonOverview(props) {
	return <Level1OverviewForType {...props}
		table="${global_table_prefix}person_metadata"
		type='person'
		name='People by Source Bot'
		bot_id='origin_bot_id'
		date_field='origin_date_created'
	/>;
}

export default function Level1(props) {
	const {account_id}=props;
	const level1 = useLevel1Data(props);

	if(!level1) return 'loading';

	return <Grid container spacing={2}>
		<Grid item xs={12}>
			<Card>
				<CardHeader title='Level 1 - Transactions' />
				<CardContent>
					<Level1TransactionOverview {...{account_id,level1}} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12}>
			<Card>
				<CardHeader title='Level 1 - Messages' />
				<CardContent>
					<Level1MessageOverview {...{account_id,level1}} />
				</CardContent>
			</Card>
		</Grid>
		<Grid item xs={12}>
			<Card>
				<CardHeader title='Level 1 - People' />
				<CardContent>
					<Level1PersonOverview {...{account_id,level1}} />
				</CardContent>
			</Card>
		</Grid>
	</Grid>;
}
