import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

export default function ReportCard(props){
	const {title, subheader,icon, action,contents,className,children}=props;
	let avatar=(icon && typeof icon=='string')?<i className={`zmdi zmdi-hc-fw ${icon}`}/>:icon;
	return <Card elevation={0} className={`report-card${className?" "+className:""}`}>
		{title && <CardHeader title={title} subheader={subheader} className="report-card-header" avatar={avatar} action={action}/>}
		<CardContent className={props.contentClassName}>{contents || children}</CardContent>
	</Card>;
}
