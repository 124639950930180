import React,{useState} from 'react';
import Card from '@mui/material/Card';
import gql from "graphql-tag";

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CardHeader from '@mui/material/CardHeader';

import { useQuery } from '@apollo/client';
import {useLocation,useSearchParams,useNavigate} from 'react-router-dom';
import {useAccountId} from '../../account/AccountInfo';
import FraktureTable from '../../FraktureTable';
import '../sourcecode.css';

const PARSE_QUERY=gql`query parseQuery($account_id: ID!,$source_codes:[String]) {
	account(_id: $account_id) {
		_id
		parse_source_codes(source_codes:$source_codes){
      source_code
      format{
      	_id
				format
    	}
      source_code_element_values{
    		name
    		value
  		}
			sorted_parse
			all_formats{
				_id
				format
				match_order
				matched
			}
    }
	}
}`;


function SourceCodeResults(){
	const [qs]=useSearchParams();
	let sourceCodes=qs.get("source_codes");
	const account_id=useAccountId();
	let source_codes=(sourceCodes||"").split(/[\r\n]/).map(d=>d.trim()).filter(Boolean);
	const {data,loading,error}=useQuery(PARSE_QUERY,{variables:{account_id,source_codes},skip:source_codes.length===0});
	if (error){
		console.error(error);
		return "Error running query";
	}
	return <div>
		{loading && "Parsing source codes ...."}
		{data &&
		<div className="w-100">{data.account.parse_source_codes.map((sc,i)=>{
			let elements=[];
			if (sc.sorted_parse){
				let s=sc.sorted_parse;
				if (typeof s=='string'){
					try{
						s=JSON.parse(s);
					}catch(e){
						s={error_parsing:e.toString()};
					}
				}
				elements=Object.keys(s).map(name=>{return {name,value:s[name]};});
			}else{
				elements=(sc.source_code_element_values||[]);
			}

			return <Card key={i}><CardHeader title={sc.source_code}/>
				{(sc.source_code.indexOf("http:/")>=0 || sc.source_code.indexOf("https:/")>=0)?<div className="alert alert-danger">It looks like this source code is a link (https://...) -- please use just the source code.</div>:""}
				{(sc.all_formats.filter(d=>d.matched).length===0) && <div className="alert alert-danger">No matches for this source code</div>}
				<div className="p-3">
				Elements{elements.filter(el=>el.value).map((el,j)=><div key={j}>
						<b>{el.name}:</b>{el.value} {(el.name==='acquisition_cost' && el.value===-1) && <span className="text-danger">* Unparseable</span>}
					</div>)}
				</div>
				<FraktureTable {...{
					sort:"match_order",
					columns:[
						//{field:"_id",title:"ID"},
						{title:"Matched",render:({row})=>row.matched?"Yes":""},
						{field:"match_order",title:"Match Order"},
						{field:"format",title:"Format"},
					],
					rows:sc.all_formats}} />
			</Card>;
		})}</div>
		}</div>;
}

export default function TestSourceCodes(){
	const navigate = useNavigate();
	const location=useLocation();
	let [qs]=useSearchParams();
	let searchCodes=qs.get("source_codes");
	let [value,setValue]=useState(searchCodes ||"");


	return <div className="p-3">
		<form onSubmit={e=>{
			e.preventDefault();
			navigate(location.pathname+'?source_codes='+value);
		}}>
			<TextField
				id="source_code"
				className="w-100"
				value={value}
				name="source_codes" rows={10}
				aria-label="Source codes to test" placeholder="Testing Source Codes"
				onChange={e=>{
					console.log("Setting value to "+e.target.value);
					setValue(e.target.value);}}/>
			<Button variant="contained" color="primary" type="submit">Test Source codes</Button>
		</form>
		<SourceCodeResults/>
	</div>;
}
