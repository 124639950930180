import "setimmediate";
import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthWrapper from './AuthWrapper.js';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

//eslint-disable-next-line
if (true || process.env.NODE_ENV === 'production') {
	disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('app-site'));
root.render(
	<React.StrictMode>
		<AuthWrapper />
	</React.StrictMode>
);
