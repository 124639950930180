import React from 'react';

import {useBotsWithMethods} from '../../bots/BotList';
import {useAccountId} from '../../account/AccountInfo';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AsyncSelect from 'react-select/async';

function getSelectElements(bots) {
	const elements=[];
	bots.forEach(bot => {
		const {_id,label,definition}=bot;

		const methodOptions = (definition.methods||[]).map(method => {
			return {
				value: `${_id}.${method.name}`,
				label: method.name
			};
		});
		if(methodOptions.length) elements.push({
			label: `${label} (${_id})`,
			options: methodOptions
		});

		(definition.submodules||[]).forEach(submodule => {
			const {name}=submodule;
			const groupLabel = `${label} (${_id}).${name}`;
			const optgroup = {
				label: groupLabel,
				options: submodule.methods.map(method => {
					const value = `${_id}.${name}.${method.name}`;
					return {
						value,
						label: method.name
					};
				})
			};
			elements.push(optgroup);
		});
	});
	return elements;
}

export default function NewJobSelector(props) {
	const account_id=useAccountId();
	const [state,setState]=React.useState({
		bot_id: null, method: null, submodule: null,
		value:null, label: null
	});
	const{addJob}=props;
	const {bots,loading,error}=useBotsWithMethods({account_id});
	if (loading){
		console.log("Loading useBotsWithMethods");
		return "Loading ...";
	}
	if (error){
		return error;
	}

	const{value,label}=state;

	const elements = getSelectElements(bots);
	const loadOptions=(input,cb) => {
		//let start=new Date().getTime();
		let regex=new RegExp(input.split(" ").join(".*"),"i");
		let r=elements.map(parent=>({
			label:parent.label,
			options:parent.options.filter(d=>{
				return [parent.label,d.value].filter(Boolean).join(" ").match(regex);
			}).sort((aa,bb) => {
				const a = aa.value, b=bb.value;
				if(a === input) return -1;
				if(b === input) return 1;
				if(a.indexOf(input) === 0 && b.indexOf(input) !== 0) return -1;
				if(b.indexOf(input) === 0 && a.indexOf(input) !== 0) return 1;
				if(a.length <= b.length) return -1;
				else return 1;
			}).slice(0,5)
		})).filter(p=>p.options.length>0);
		//console.log(input+"->",r.length,new Date().getTime()-start,'ms');
		return cb(r);
	};
	const selectComponent = <AsyncSelect
		options={elements}
		menuPortalTarget={document.body}
		styles={{ menuPortal: base => ({ ...base,
			zIndex: 9999,
		}) }}
		value={value && {value,label}}
		loadOptions={loadOptions}
		placeholder='Select Bot and Job'
		//components={{ MenuList }}
		onChange={select => {
			const{label,value} = select;
			const parts = value.split('.');
			let bot_id = parts[0],submodule,method = parts[2] || parts[1];
			if(parts.length === 3) submodule = parts[1];
			setState({bot_id,submodule,method, value,label});
		}}/>;

	const addButton = <Button disabled={!value} variant="contained" size="small" color="primary" onClick={() => {
		const{bot_id,submodule,method}=state;
		addJob({bot_id,submodule,method});
	}}><AddIcon />Add</Button>;

	return <div className="d-flex">
		<div className="p-1" style={{flex:"1 0 150px"}}>{selectComponent}</div>
		<div className="ml-auto text-nowrap p-1" style={{
			flex: "0 0 100px",
			flexGrow: 0,
			flexShrink: 0
		}}>{addButton}</div>
	</div>;
};
