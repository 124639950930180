import { from, createHttpLink } from "@apollo/client";
import {
	wrapSchema,
	introspectSchema
} from '@graphql-tools/wrap';

//THis is annoying -- for reasons I don't understand import seems to pick the wrong library and is uncompilable
// eslint-disable-next-line
const { linkToExecutor } = require("@graphql-tools/links");
//import {linkToExecutor} from '@graphql-tools/links';



import {setContext} from "@apollo/client/link/context";

//let loaded = false;

export function dataLayer(options,cb) {
	//if(loaded) throw new Error('LOADING TWICE');
	//loaded = true;

	const {dataLayerUri, getToken} = options;
	if(!dataLayerUri) throw new Error('dataLayerUri required');

	const dataLayerLink = createHttpLink({uri: dataLayerUri,
		//fetchOptions: {mode: 'no-cors'}//optimization? //required with current server config
	});
	const authMiddleware = setContext(async () => {
		const token = await getToken();
		return {
			headers: {Authorization: 'BEARER '+token}
		};
	});

	const finalHttpLink=from([authMiddleware,dataLayerLink]);

	introspectSchema(linkToExecutor(finalHttpLink))
		.then(dataLayerSchema => {
			let sch=wrapSchema({
				schema: dataLayerSchema,
				executor: linkToExecutor(finalHttpLink)
			});
			cb(null,sch);
		})
		.catch(e => {
			console.error(e);
			console.error("Calling cb because of an error");
			cb(e);
		});
}

export function warehouse(opts,cb) {
	const {stubWarehouseSchemaUri}=opts;
	if(!stubWarehouseSchemaUri) throw new Error('stubWarehouseSchemaUri required');
	console.log("Getting warehouse schema from ",stubWarehouseSchemaUri);
	const stubLink = createHttpLink({uri: stubWarehouseSchemaUri,
		//fetchOptions: {mode: 'no-cors'}//optimization? //required with current server config
	});
	introspectSchema(linkToExecutor(stubLink))
		.then(schema => {
			const customFetch = (uri, options) => {
				// convert to the correct format for making a job call
				const {bot_id,bot_token,graphql_uri}=options.headers;
				if(!bot_id) throw new Error('missing bot_id');
				if(!bot_token) throw new Error('missing bot_token');
				if(!graphql_uri) throw new Error('missing graphql_uri');
				uri=graphql_uri;

				delete options.headers.bot_id;
				delete options.headers.bot_token;
				options.headers.Authorization="BEARER "+bot_token;

				//we wrap it to catch any errors
				//return fetch(uri, options);
				//console.log("Calling customFetch:",uri);
				return new Promise((resolve, reject) => {
					console.log("Calling fetch:",uri,options);
					fetch(uri,options)
						.then(res => {
							res
								.json()
								.then((data) => {
									let status=200;
									let statusText="Success";
									if (data.errors?.length){
										console.error(data.errors);
										//throw new Error("Error getting data from server:"+JSON.stringify(data.errors));
										//Errors need both a message, AND a path, otherwise it gets ignored by Apollo
										let errors=data.errors.map(d=>typeof d=='object'?d:{message:d,path:["warehouse"]});
										data.graphQLErrors=errors;
										data.errors=errors;
										//we have to delete the data, otherwise the error stack isn't called.  So weird.
										delete data.data;
										console.log("Returning stringified version of:",data);
										//return JSON.stringify(data);
										status=501;
										statusText="Warehouse Error";
									}
									if (typeof data?.data?.table?.data_grid_batch?.data==='string'){
										//console.log("data_grid_batch string returned, parsing");
										data.data.table.data_grid_batch.data=JSON.parse(data.data.table.data_grid_batch.data);
									}

									let blob = new Blob([JSON.stringify(data, null, 2)], {type : 'application/json'});

									let init = { status, statusText};
									let warehouseResponse = new Response(blob, init);
									resolve(warehouseResponse);
								})
								.catch(err =>{
									console.error("remote-schema Catching local error and rejecting");
									reject(err);
								});
						});
				} );
			};
			const link = setContext((request,previousContext) => {
				return {headers: {
					bot_id: previousContext.graphqlContext.bot_id,
					bot_token: previousContext.graphqlContext.bot_token,
					graphql_uri: previousContext.graphqlContext.graphql_uri
				}};
			}).concat(createHttpLink({ fetch: customFetch }));
			const remoteSchema=wrapSchema({
				schema, executor: linkToExecutor(link)
			});
			cb(null, remoteSchema);
		})
		.catch(e => cb(e));
}
export default {dataLayer,warehouse};
