import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import {relativeDate} from "../formatters.js";

import {useAccountId} from "../account/AccountInfo";
import {useQuery} from '@apollo/client';

import {useLocation} from 'react-router-dom';

import queryString from 'query-string';
import {QuickDateRange,QueryStringForm,TextFieldInput} from '../inputs/QueryStringInputs';

import FraktureDataTable from '../warehouse/FraktureDataTable';
import {useWarehouseDescribe} from '../warehouse/WarehouseQuery';
import {BOT_WITH_METHOD_SELECT_QUERY} from '../bots/BotSelect';
import {TransactionRow} from '../sourcecode/Transactions';


function MessageSourceCodeTable({table,warehouse_bot_id,conditions}){
	return <FraktureDataTable includeGroupBy={true} bot_id={warehouse_bot_id} table={table} query={{
		conditions,
		limit: 20
	}}/>;
}



export default function AttributionWorkbook(){
	const location=useLocation();
	const account_id=useAccountId();
	const {data:bots,error,loading}=useQuery(BOT_WITH_METHOD_SELECT_QUERY,{variables: {account_id,method:"loadTransactions"}});
	let skip=!!(error||loading);
	let tableNames={wh:"${global_table_prefix}transaction_summary"};
	let b=bots?.account?.bots_with_method;
	if (b){
		b.forEach(bot=>tableNames[bot.bot._id]=bot.bot.warehouse_table_prefix+"bot_transaction_summary");
	}

	const descReturn=useWarehouseDescribe({account_id,tables:Object.values(tableNames),skip});
	let {data:desc,loading:loading2,error:error2}=descReturn;

	if (loading || error) return loading || error;
	if (loading2 || error2) return loading2 || error2;

	const {global_table_prefix,warehouse_bot_id,tables}=desc;

	const query=queryString.parse(location.search || "");

	if (!account_id) return "account_id required";

	let conditions=[];
	let attributionConditions=[];
	let messageConditions=[];

	if (query.start){
		conditions.push({fql:"ts>='"+relativeDate(query.start).toISOString()+"'"});
		attributionConditions.push({fql:"ts>='"+relativeDate(query.start).toISOString()+"'"});
		messageConditions.push({fql:"publish_date>='"+relativeDate(query.start).toISOString()+"'"});
	}
	if (query.end){
		conditions.push({fql:"ts<'"+relativeDate(query.end).toISOString()+"'"});
		attributionConditions.push({fql:"ts<'"+relativeDate(query.end).toISOString()+"'"});
		messageConditions.push({fql:"publish_date<'"+relativeDate(query.end).toISOString()+"'"});
	}
	if (query.source_code){
		conditions.push({fql:"source_code like '%"+query.source_code+"%'"});
		attributionConditions.push({fql:"recommended_source_code like '%"+query.source_code+"%'"});
		messageConditions.push({fql:"source_code like '%"+query.source_code+"%'"});
	}

	let table=global_table_prefix+"transaction_summary";
	let fields=tables.find(d=>d.name===table);
	if (!fields) return "Could not find table "+table;
	let transactionFields=[
		{alias:"ID",fql:"transaction_id"},
		{alias:"Remote Transaction ID",fql:"remote_transaction_id"},
		{alias:"Amount",fql:"amount",type:"currency"},
		{alias:"Date",fql:"ts",type:"date"},
		{alias:"Source Code",fql:"source_code"}
	];

	return <>
		<h2>Attribution</h2>
		<div className="app-main-content-ltr">
			<QueryStringForm>
				{ ({onChange,values}) => {
					return (<div className="mb-0" style={{"justifyContent":"flex-end"}}>
						<QuickDateRange
							start={values.start}
							end={values.end}
							onChange={onChange}/>
						<TextFieldInput label="Source Code" name="source_code"
							onChange={onChange}
							value={values.source_code}/>
					</div>);}
				}</QueryStringForm>
			<Grid container>
				<Grid item xs={6}></Grid>
				<Grid item xs={6}>
					<Card><CardHeader/>
						<FraktureDataTable includeGroupBy={false}
							query={{
								fields:transactionFields,
								order_by:{fql:"ts",order_by_direction:"DESC"},
								conditions
							}}
							renderRow={(row,i)=><TransactionRow i={i} row={row} fields={transactionFields}/>}
							bot_id={warehouse_bot_id}
							table={global_table_prefix+"transaction_summary"}
						/>
					</Card>
				</Grid>
				<Grid item xs={4}>
					<Card><CardHeader title="Message Source Codes"/>
						<MessageSourceCodeTable conditions={messageConditions} warehouse_bot_id={warehouse_bot_id} table={global_table_prefix+"message_source_code"}/>
					</Card>
				</Grid>
			</Grid>
		</div>
	</>
	;
};
