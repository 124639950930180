import React,{useState,useEffect} from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PlusIcon from '@mui/icons-material/Add';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useApolloClient } from '@apollo/client';
import useNotifiers from '../Notifiers';
import BotAuthFields from '../bots/config/BotAuthFields';

import {useBotOverrideMutation} from '../dataflow/editor/ChildDataflowEditor';

import {DataflowLink} from '../dataflow/Links';

const MESSAGES_DATAFLOW = '5da875c9651c5b3ca4f71670';
const PERSON_DATAFLOW = '5dcdc30fe110ee2323ca1c92';
const TRANSACTION_DATAFLOW = '5daa12cf7be7f9769fda34ef';

const LEVEL_0_ACCOUNT_FRAGMENT=gql`
fragment LEVEL_0_ACCOUNT_FRAGMENT on Account {
	parents {
		_id
		bots {
			_id
			sharable
			label
		}
		dataflows {
			_id
			sharable
			label
		}
	}

	transaction_bots: bots_with_method(method:"loadTransactions") {
		_id
		bot {
			_id
		}
		path
	}
	person_bots: bots_with_method(method:"loadPeople") {
		_id
		bot {
			_id
		}
		path
	}
	message_bots: bots_with_method(method:"loadMessages") {
		_id
		bot {
			_id
		}
		path
	}

	bots {
		_id
		path
		warehouse_table_prefix

		definition {
			_id
			bot_path
			metadata {
				logo
			}
		}
	}

	dataflows {
		_id
		schedule
		label
		last_completed
		most_recent_job_list {
			_id
			label
			status
			jobs {
				_id
				label
				status
			}
		}

		parent: original_parent {
			_id
			label
		}

		config {
			job_option_overrides {
				overrides {
					option
					value
					default_value
					override_value
				}
			}

			bot_overrides {
				_id
				bot_id
				default_id
				override_id
				default_bot {
					_id
					label
				}
				override_bot {
					_id
					label
				}
			}
		}
	}
}
`;

const LEVEL_0_QUERY=gql`
query LEVEL_0_QUERY($account_id:ID!) {

	message: dataflow(_id:"${MESSAGES_DATAFLOW}") {
		_id
		label
	}
	person: dataflow(_id:"${PERSON_DATAFLOW}") {
		_id
		label
	}
	transaction: dataflow(_id:"${TRANSACTION_DATAFLOW}") {
		_id
		label
	}

	account(_id:$account_id) {
		... LEVEL_0_ACCOUNT_FRAGMENT
	}
}
${LEVEL_0_ACCOUNT_FRAGMENT}
`;

export function useLevel0Data(props) {
	const {account_id}=props;
	// const [data,setData]=useState();
	const [status,setStatus]=useState();
	// const client=useApolloClient();

	const {data,loading,error} = useQuery(LEVEL_0_QUERY, {
		variables: {account_id}
	});

	useEffect(() => {
		if(!data || loading) return;

		const {bots,dataflows, transaction_bots,person_bots,message_bots}=data.account;
		const channelBotsById = {};
		const dataflowsByParent = {};

		const forLoadType = (arr) => {
			return arr.map(x => ({
				_id: x._id,
				bot_id: x.bot._id,
				bot_path: x.path
			}));
		};
		const botsByLoadType={
			person: forLoadType(person_bots),
			transaction: forLoadType(transaction_bots),
			message: forLoadType(message_bots),
		};

		bots.filter(x=>x.path.indexOf('channel')===0 && x.path.indexOf('GoogleDrive') === -1).forEach(x => channelBotsById[x._id]=x);
		dataflows.filter(x=>x.parent).forEach(df => {
			const a = dataflowsByParent[df.parent._id] = dataflowsByParent[df.parent._id] || [];
			a.push(df);
		});

		const missing = [];
		const botCount = Object.keys(channelBotsById).length;
		if(botCount <= 1) missing.push('Need 2 or more bots - have '+botCount);

		const {person,message,transaction} = data;
		const ppl = dataflowsByParent[person._id]||[];
		const msg = dataflowsByParent[message._id]||[];
		const tx = dataflowsByParent[transaction._id]||[];

		if(!ppl.length) missing.push('Need at least 1 load People');
		if(!msg.length) missing.push('Need at least 1 load Message (Email, Ads, etc)');
		if(!tx.length) missing.push('Need at least 1 load Transaction');

		const loadTypesByBot = {};

		Object.entries({
			person: person._id,
			transaction: transaction._id,
			message: message._id
		}).map(([type,dataflow_id]) => {
			(dataflowsByParent[dataflow_id]||[]).forEach(d => {
				const overrides = d.config.bot_overrides
					.map(b=>b.override_id||b.default_id)
					.filter(b=>!!b)
					.map(b=>channelBotsById[b]);
				console.log(overrides, d.config.bot_overrides);
				const ov = overrides[0];
				if(!ov) return;

				const lt = loadTypesByBot[ov._id]=loadTypesByBot[ov._id]||{};
				lt[type]=d;
			});
		});

		setStatus({
			channelBotsById,
			dataflowsByParent,
			loadTypesByBot,
			botsByLoadType,
			completed: missing.length === 0,
			missing
		});
	}, [data, loading]);

	if(loading) return null;
	if(error) {
		console.error(error);
		return null;
	}

	return status || data;
}

export function useLevel0StepComplete(props) {
	const status = useLevel0Data(props);
	if(!status) return {completed:false,missing:[]};
	return status;
};

const NEW_CHILD_DATAFLOW_FRAGMENT=gql`
fragment NEW_CHILD_DATAFLOW_FRAGMENT on Dataflow {
	_id
	label
	config {
		bot_overrides {
			_id
			override_options {
				bot_id
				bot_path
				override_id
				override_path
			}
		}
	}
}
`;
const ADD_DATAFLOW_MUTATION = gql`
mutation ADD_DATAFLOW_MUTATION($parent_id:ID!, $account_id:ID!) {
	dataflow_create(parent_id:$parent_id,account_id:$account_id) {
		... NEW_CHILD_DATAFLOW_FRAGMENT
		account {
			... LEVEL_0_ACCOUNT_FRAGMENT
		}
	}
}
${NEW_CHILD_DATAFLOW_FRAGMENT}
${LEVEL_0_ACCOUNT_FRAGMENT}
`;
function Level0DataflowCreator(props) {
	const botOverride = useBotOverrideMutation();
	const client = useApolloClient();
	const [selected, setSelected]=useState('');
	const {parent_dataflow_id, bots, account_id}=props;
	const {notify,notifyErr} = useNotifiers();

	return (
		<React.Fragment>
			<form onSubmit={(e) => {
				e.preventDefault();
				if(!selected) return;
				const bot = bots.find(x => x._id === selected);
				if(!bot) {
					notifyErr('failed to find bot: '+selected);
					return;
				}
				const{bot_id,bot_path}=bot;
				client.mutate({
					mutation: ADD_DATAFLOW_MUTATION,
					variables: {
						account_id,
						parent_id: parent_dataflow_id
					}
				}).then(({data}) => {
					const {
						dataflow_create:{
							_id:dataflow_id,
							config:{
								bot_overrides:[{override_options}]
							}
						}
					}=data;
					const option = override_options.find(x => {
						return x.override_id === bot_id && x.override_path === bot_path;
					});
					if(!option) {
						notifyErr('failed to find bot: '+selected+' in override options');
						console.error(data);
						return;
					}
					const update = Object.assign({},option,{dataflow_id});
					console.log('updating:',update);
					botOverride(update).then(notify,notifyErr);
				}, notifyErr);
			}}>
				<Select
					value={selected}
					onChange={e => setSelected(e.target.value)}
				>
					{bots.map(b => {
						return <MenuItem key={b._id} value={b._id}>
							{b.bot_path} ({b.bot_id})
						</MenuItem>;
					})}
				</Select>
				<IconButton type='submit' size="large">
					<PlusIcon />
				</IconButton>
			</form>
		</React.Fragment>);
};

function Level0Bots(props) {
	if(!props.status) return '';

	const {channelBotsById = []} = props.status;

	const rows = Object.values(channelBotsById).map(x => {
		return <TableRow key={x._id}>
			<TableCell style={{width:'150px', verticalAlign:'top'}}>
				<img src={x.definition.metadata.logo} width={150} height={75} />
			</TableCell>
			<TableCell>
				<BotAuthFields bot_id={x._id} required_only={true} />
			</TableCell>
		</TableRow>;
	});

	return <Table>
		<TableBody>{rows}</TableBody>
	</Table>;
}

function Level0Dataflow(props) {
	if(!props.status) return '';

	const {
		dataflowsByParent = [],
		channelBotsById,
		botsByLoadType={}
		// message, transaction, person
	} = props.status;

	let sections = [{
		label: 'Transactions',
		dataflow_id: TRANSACTION_DATAFLOW,
		bots: botsByLoadType.transaction || []
	}, {
		label: 'People',
		dataflow_id: PERSON_DATAFLOW,
		bots: botsByLoadType.person || []
	}, {
		label: 'Messages',
		dataflow_id: MESSAGES_DATAFLOW,
		bots: botsByLoadType.message || []
	}];

	sections = sections.map(x => {
		const rows = (dataflowsByParent[x.dataflow_id]||[]).map(d => {
			const overrides = d.config.bot_overrides
				.map(b=>b.override_id||b.default_id)
				.filter(b=>!!b)
				.map(b=>channelBotsById[b]);

			const ov = overrides[0];

			console.log(d.config.bot_overrides,'override:',ov);

			let icon = '';
			if(ov) icon = <img width={75} height={37} src={ov.definition.metadata.logo}/>;

			return <TableRow key={d._id}>
				<TableCell style={{width:'75px'}}>
					{icon}
				</TableCell>
				<TableCell style={{width:'30%'}}><DataflowLink dataflow_id={d._id}/></TableCell>
				<TableCell>{d.schedule}</TableCell>
				<TableCell>{(d.last_completed||'never completed').toString()}</TableCell>
			</TableRow>;
		});

		return <React.Fragment key={x.dataflow_id}>
			<Grid item xs={12}>
				<Typography component='h5'>
					{x.label} Loading
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Table>
					<TableBody>
						{rows}
						<TableRow>
							<TableCell style={{width:'75px'}}/>
							<TableCell colSpan='3'>
								<Level0DataflowCreator
									account_id={props.account_id}
									level0={props.status}
									parent_dataflow_id={x.dataflow_id}
									bots={x.bots}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Grid>
		</React.Fragment>;
	});

	return <Grid container spacing={1}>
		{sections}
	</Grid>;
}

export default function Level0(props) {
	const status = useLevel0Data(props);

	return <Grid container spacing={2}>
		<Grid item xs={12}><Card>
			<CardHeader title='Level 0 - Bot Setup' />
			<CardContent>
				{status && <Level0Bots {...{status}} />}
			</CardContent>
		</Card></Grid>
		<Grid item xs={12}><Card>
			<CardHeader title='Level 0 - Dataflow Setup' />
			<CardContent>
				{status && <Level0Dataflow {...props} {...{status}} />}
			</CardContent>
		</Card></Grid>
	</Grid>;
};
