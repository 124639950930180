import React, {useState,useEffect} from 'react';

import gql from "graphql-tag";
import {useQuery,useApolloClient} from '@apollo/client';

import useAccountWarehouseInfo from '../../warehouse/account-warehouse-info';
import useNotifiers from '../../Notifiers';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Autocomplete from '@mui/material/Autocomplete';

const LABEL_QUERY=gql`
query LABEL_QUERY($warehouse_bot_id:ID!) {
	warehouse(bot_id:$warehouse_bot_id) {
		elements: table(name:"\${global_table_prefix}source_code_element_metadata") {
			name
			data_grid_batch(
				fields: [{
					field: "source_code_element_id"
				},{
					field: "element"
				}]
			) {
				data
				length
				primary_field
			}
		}

		values: table(name:"\${global_table_prefix}source_code_element_value_label") {
		  name
			data_grid_batch(
				fields: [{
					field: "label_value_id"
				},{
					field: "source_code_element_id"
				},{
					field: "element"
				},{
					field: "key"
				},{
					field: "label"
				}]
			) {
				data
				length
				primary_field
			}
		}
  }
}`;

const SET_LABEL_MUTATION = gql`
mutation SET_LABEL_MUTATION($warehouse_bot_id:ID!,$data:JSON!,$table:String!) {
	data_grid_batch_update(bot_id:$warehouse_bot_id, update: {
		table: $table,
		data: $data
	}) {
		uuid
	}
}
`;


function useElementLabelData(props) {
	const account_id=props.account_id;
	const [elementLabelData,setElementLabelData]=useState([]);
	const [elements,setElements]=useState([]);

	const [byElement,setByElement]=useState({});

	const client=useApolloClient();
	const {notify,notifyErr}=useNotifiers();
	const {loading,error,data:accountInfo} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return error;
	const {default_warehouse_bot,source_code_elements} = accountInfo;
	const {global_table_prefix, _id:warehouse_bot_id}=default_warehouse_bot;

	const labelQueryData = useQuery(LABEL_QUERY, {
		variables: {
			warehouse_bot_id
		},
		skip: !warehouse_bot_id
	});

	useEffect(() => {
		if(!labelQueryData.data||labelQueryData.loading) return;
		let {warehouse:{
			values:{data_grid_batch:{data}},
			elements:{data_grid_batch:{data:elementsData}}
		}}=labelQueryData.data;

		setElementLabelData(data);
		if (typeof elementsData=='string') elementsData=JSON.parse(elementsData);
		setElements(elementsData.map(x => {
			const el = source_code_elements.find(s => s.name === x.element);
			if(!el) return x;
			return Object.assign({},x,{title:el.label});
		}));

		const by={};
		data.forEach(x => {
			(by[x.element]=by[x.element]||[]).push(x);
		});
		setByElement(by);
	}, [labelQueryData]);

	return {
		loading: labelQueryData.loading||accountInfo.loading,
		label_data: elementLabelData,
		elements:elements||[],
		by_element: byElement,
		save_labels: ({element,updates}) => {
			const u = updates[element];
			if(!u||!Object.keys(u).length) return;
			const el = elements.find(x => x.element === element);
			if(!el) {
				console.error('Failed to find:',element,'in',elements);
				return;
			}
			const {source_code_element_id}=el;
			const data = Object.entries(u).map(([key,label]) => {
				return {
					key,label,source_code_element_id
				};
			});
			console.log('updates:',data);
			client.mutate({
				mutation: SET_LABEL_MUTATION,
				variables: {
					warehouse_bot_id,
					table: global_table_prefix+'source_code_element_value_label_norm',
					data
				}
			}).then(notify,notifyErr);;

			// Promise.all(ua.map(data => {
			// 	return client.mutate({
			// 		mutation: SET_LABEL_MUTATION,
			// 		variables: {
			// 			warehouse_bot_id,
			// 			table: global_table_prefix+'source_code_element_value_label_norm',
			// 			data
			// 		}
			// 	});
			// })).then(notify,notifyErr);
		}
	};
};

function ElementPicker(props) {
	const{element,setElement, elements}=props;
	return <Select value={element} onChange={e => setElement(e.target.value)} fullWidth>
		{elements.map(x => {
			return <MenuItem key={x.element} value={x.element}>
				{x.title} ({x.element})
			</MenuItem>;
		})}
	</Select>;
};

export default function ElementLabelEditor(props) {
	const data = useElementLabelData(props);

	const [element,setElement]=useState('');
	const [tableValues,setTableValues]=useState([]);
	const [gridValues,setGridValues]=useState([]);
	const [updates,setUpdates]=useState({});

	const [autoInput,setAutoInput]=useState('');

	const saveLabels = () => {
		data.save_labels({element,updates});
	};

	useEffect(() => {
		const tv = (data.by_element[element]||[]).map(x => Object.assign({},x));
		setTableValues(tv);

		const v = {};
		let part = props.table+'-'+element;
		props.grid.data.forEach(x => {
			if(x[part]) v[x[part]]=1;
		});
		setGridValues(Object.keys(v));

		if(!updates[element]) setUpdates(Object.assign({},updates,{[element]:{}}));
	}, [element,data.by_element]);

	const shown = {};

	const updateValue=(k,v) => {
		const u = Object.assign({},updates);
		u[element]=Object.assign({},u[element],{[k]:v});
		setUpdates(u);
	};

	const elementValueLabels = <Table size='small' style={{marginTop:'10px'}}>
		<TableHead>
			<TableRow>
				<TableCell>Value</TableCell>
				<TableCell>Label</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{tableValues.map(x => {
				let v = (updates[element]||{})[x.key]||x.label;
				shown[x.key]=1;
				return {
					value: v,
					key: x.key
				};
			}).concat(Object.entries(updates[element]||{})
				.filter(([k]) => !shown[k])
				.map(([key,value]) => {
					return {key,value};
				})
			).map(x => {
				return <TableRow key={x.key} >
					<TableCell>{x.key}</TableCell>
					<TableCell>
						<TextField value={x.value} onChange={e => {
							updateValue(x.key, e.target.value);
						}} />
					</TableCell>
				</TableRow>;
			})}
			<TableRow>
				<TableCell colSpan={2}>
					<Autocomplete
						freeSolo
						blurOnSelect
						noOptionsText='No matching values present - can still add'

						inputValue={autoInput}
						onInputChange={(e,v) => setAutoInput(v)}

						onChange={(e,v) => {
							console.log('on change:',e,v);

							if(shown[v]) return;

							// const u = Object.assign({},updates);
							// u[element]=Object.assign({},u[element],{[v]:''});

							updateValue(v,'');
							setAutoInput('');
						}}

						options={gridValues}
						renderInput={params => {
							return <TextField {...params} label="Add Label For..." size="small" />;
						}}
					/>
				</TableCell>
			</TableRow>
		</TableBody>
	</Table>;

	const elementPicker = <ElementPicker {...{element,setElement,elements:data.elements.filter(x=>!shown[x]&&!updates[x])}} />;

	return <Card style={{minWidth:'600px',maxWidth:'800px', border:"none", boxShadow:"none"}}>
		<CardHeader title='Element Labels'/>
		<CardContent>
			{elementPicker}
			{elementValueLabels}
		</CardContent>
		<CardActions>
			<Button size="small" color="primary" disabled={!Object.keys(updates[element]||{}).length} onClick={() => {
				saveLabels();
			}}>
        Save Labels
			</Button>
		</CardActions>
	</Card>;
};
