import React from 'react';

import gql from 'graphql-tag';
import {fraktureQuery} from '../FraktureQuery';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ACCOUNT_USER_QUERY=gql`query ACCOUNT_USER_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		users(limit:1000) {
			page {
				_id
				username
			}
		}
	}
}`;

export default function AccountUserSearch(props) {
	const{account_id,ignore}=props;
	if(!account_id) return "account_id is required";

	return fraktureQuery({
		query: ACCOUNT_USER_QUERY,
		variables: {account_id}
	}, data => {
		let users = data.account.users.page;
		users.sort((a,b)=>a.username<b.username?-1:1);
		return <Select {...props}>
			{users.filter(x => {
				if(ignore && ignore.length) return !ignore.find(y=>y===x._id);
				return true;
			}).map(c => {
				return <MenuItem key={c._id} value={c._id}>
					{c.username}
				</MenuItem>;
			})}
		</Select>;
	});
}
