import React from 'react';
import gql from 'graphql-tag';

import {fraktureQuery} from '../../FraktureQuery';
import FraktureTable from '../../FraktureTable';
import {BotOverrideSelect} from './ChildDataflowEditor';

import {Link} from 'react-router-dom';

import useNotifiers from '../../Notifiers';
import { useApolloClient } from '@apollo/client';
import fragments from '../gql-fragments';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import DataflowScheduleSelect from './DataflowScheduleSelect';
import SharedAccountSelect from '../../account/SharedAccountSelect';
import RunButton from './RunButton';

const DATAFLOW_CHILDREN_QUERY = gql`
query DATAFLOW_CHILDREN($dataflow_id:ID!){
	dataflow(_id:$dataflow_id) {
		_id
		sharable

		bots {
			_id
			path
		}

		bots_to_override {
			_id
			bot_path
			bot_id
		}

		... dataflowChildren

		children {
			... dataflowBotOverrides
		}

		account {
			_id
			children {
				_id
				name
				disabled
			}
		}
	}
}
${fragments.dataflowChildren}
${fragments.dataflowBotOverrides}
`;

const DATAFLOW_SET_SHARABLE=gql`
mutation DATAFLOW_SET_SHARABLE($dataflow_id:ID!,$sharable:Boolean!) {
	dataflow_set_sharable(_id: $dataflow_id, sharable: $sharable) {
		_id
		sharable
		... dataflowChildren
		children {
			... dataflowBotOverrides
		}
	}
}
${fragments.dataflowChildren}
${fragments.dataflowBotOverrides}
`;

function DataflowSharableCheckbox(props) {
	const client = useApolloClient();
	const{notify,notifyErr}=useNotifiers();
	const{dataflow_id}=props;
	return fraktureQuery({
		query: DATAFLOW_CHILDREN_QUERY,
		variables: {dataflow_id}
	}, ({dataflow: {sharable}}) => <Checkbox
		checked={sharable}
		onChange={() => client.mutate({
			mutation: DATAFLOW_SET_SHARABLE,
			variables: {dataflow_id, sharable: !sharable},
			//refetchQueries:["DATAFLOW_CHILDREN_QUERY"]
		}).then(notify,notifyErr)}
	/>);
};

const ADD_CHILD_FOR_ACCOUNT=gql`
mutation ADD_CHILD_FOR_ACCOUNT($parent_id:ID!,$account_id:ID!) {
	dataflow_create(account_id:$account_id, parent_id:$parent_id) {
		_id
		parent {
			... dataflowChildren
		}
		... dataflowBotOverrides
	}
}
${fragments.dataflowChildren}
${fragments.dataflowBotOverrides}
`;

function AddDataflowForAccountButton(props) {
	const client = useApolloClient();
	const{notify,notifyErr}=useNotifiers();
	const{parent_id,account_id}=props;
	return (
		<IconButton
			onClick={() => client.mutate({
				mutation: ADD_CHILD_FOR_ACCOUNT,
				variables: {
					parent_id,
					account_id
				},
				refetchQueries:["DATAFLOW_CHILDREN"]
			}).then(notify,notifyErr)
			}
			size="large">
			<AddIcon />
		</IconButton>
	);
}

function SelectAccountAddDataflow(props) {
	const {account_id,dataflow_id,ignore}=props;

	const [child,setChild]=React.useState(null);

	return <React.Fragment>
		<SharedAccountSelect account_id={account_id}
			onChange={x=>setChild(x.value || x)}
			value={child}
			ignore={ignore}
		/>
		<AddDataflowForAccountButton
			account_id={child}
			parent_id={dataflow_id}
		/>
	</React.Fragment>;
};

export default function DataflowChildren(props) {
	const{dataflow_id}=props;
	return fraktureQuery({
		query: DATAFLOW_CHILDREN_QUERY,
		variables: {dataflow_id}
	}, ({dataflow}) => {
		const{children,account,sharable, bots_to_override:bots}=dataflow;

		let table=null;

		if(sharable) {
			const childAccounts = account.children;
			const rows = children.map(child => {
				return {
					account: child.account,
					dataflow: child,
					dataflow_id: child._id,
					overrides: child.config.bot_overrides
				};
			}).concat(childAccounts.filter(x => {
				return !children.find(c => c.account._id ===x._id);
			})
				.filter(child=>!child.disabled)
				.map(child => {
					return {
						account: child
					};
				}));
			const columns = [{
				title: 'Account',
				field: 'account',
				render: ({row}) => <Link to={`/app/${row.account._id}`}>{row.account.name}{row.account.disabled?" (Disabled)":""}</Link>
			}, {
				title: 'Dataflow',
				field: 'dataflow_id',
				render: ({row}) => {
					if(!row.dataflow_id) return <AddDataflowForAccountButton
						account_id={row.account._id}
						parent_id={dataflow_id}
					/>;
					return <Link to={`/app/${row.account._id}/dataflow/${row.dataflow._id}`}>{row.dataflow.label}</Link>;
				}
			}, {
				title: 'Schedule',
				field: 'schedule',
				render: ({row}) => row.dataflow && <DataflowScheduleSelect dataflow_id={row.dataflow._id} />
			},
			{
				title: 'Run',
				field: 'run',
				render: ({row}) => row.dataflow && <RunButton dataflow_id={row.dataflow._id} />
			}
			].concat(bots.map(bot => {
				const{_id,bot_path,bot_id}=bot;
				const [title]=bot_path.split('.').slice(1);
				return {
					title,
					field: _id,
					render: ({row}) => {
						if(!row.dataflow) return '';
						const override = row.overrides.find(x => x.bot_path === bot_path && x.bot_id === bot_id);
						if(!override) {
							console.log('looked for',bot,'in',row.overrides);
							return 'No overrides for';
						}
						return <BotOverrideSelect {...{override,dataflow:row.dataflow}} />;
					}
				};
			}));
			table = <FraktureTable {...{rows,columns}} />;
		}

		return <div>
			<FormControlLabel
				control={<DataflowSharableCheckbox {...props}/>}
				label='Shareable?'
			/>
			{table}
			<SelectAccountAddDataflow account_id={account._id} dataflow_id={dataflow_id} />
		</div>;
	});
};
