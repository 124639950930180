import React from 'react';
import gql from "graphql-tag";
import useNotifiers from '../Notifiers';
import { useMutation } from "@apollo/client";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {useUserContext} from '../../AdminCheck';

const ACCOUNT_SET_DISABLED=gql(`mutation account_set_disabled($account_id: ID!,$disabled:Boolean!) {
		account_set_disabled(account_id: $account_id,disabled:$disabled) {
		  _id
			disabled
		}
}`);
export default function DisabledSwitch({account_id,disabled,client}){
	const {notify,notifyErr} = useNotifiers();
	const userContext = useUserContext();
	const [mutateDisabled] = useMutation(ACCOUNT_SET_DISABLED);
	const {is_frakture_admin} = userContext;
	if(!is_frakture_admin){
		return <div>{disabled?<b className="text-danger">Disabled</b>:<b className="text-success">Enabled</b>}</div>;
	}
	return (
		<FormControlLabel
			labelPlacement="bottom"
			control={
				<Switch
					checked={!disabled}
					onChange={e=>mutateDisabled({variables:{account_id,disabled:!e.target.checked}}).then(notify,notifyErr)}
					color="primary"
				/>
			}
			label={disabled?<b className="text-danger">Disabled</b>:"Enabled"}
		/>
	);
};
