import React from 'react';
import {humanize} from '../formatters.js';
import {MenuToggle} from '../sidebar/';


export function Header(props){
	const {className,selected,primary,secondary,footer,onClick}=props;
	let classes=(className||"").split(" ").concat("app-main-content-header");
	if (selected) classes.push("selected");
	let f=null;
	if (footer) f=<div className="footer">{[].concat(footer).map((d,i)=>typeof d=='string'?<span key={i}>{humanize(d,30)}</span>:d)}</div>;
	return <div className={classes.join(" ")} onClick={onClick}>
		<div className="primary">
			<MenuToggle/>
			{[].concat(primary).map((d,i)=>(typeof d==='string')?<h2 key={i}>{humanize(d,60)}</h2>:d)}</div>
		{secondary?<div className="secondary">{[].concat(secondary).map((d,i)=>(typeof d==='string')?<span key={i}>{humanize(d,30)}</span>:d)}</div>:null}
		{f}
	</div>;
}
