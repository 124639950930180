import React from 'react';

import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import {QuickDateRange,ToggleSwitch,TextFieldInput,QueryStringForm} from '../inputs/QueryStringInputs';

function JobFilterMenu(props){

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const {account_id}=props;


	const query={};
	if(account_id){
		query.account_ids = [account_id];
	}

	return (<React.Fragment>
		<Button onClick={handleClick}>Filters <ExpandMoreIcon /></Button>
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<Paper className="p-4">
				<QueryStringForm
					onSubmit={handleClose}
				>
					{ ({values,onChange:_onChange}) => {
						let onChange=(e)=>{
							_onChange(e);
						};
						return (
							<React.Fragment>
								{account_id?"":<TextFieldInput name="account_ids" label="Accounts" onChange={onChange} value={values.account_ids}/>}
								<div><TextFieldInput name="job_error_match" label="Error Match" onChange={onChange} value={values.job_error_match}/></div>
								<div><TextFieldInput name="job_method_match" label="Method Match" onChange={onChange} value={values.job_method_match}/></div>
								<div><TextFieldInput name="job_bot_match" label="Bot Match" onChange={onChange} value={values.job_bot_match}/></div>
								<div><TextFieldInput name="bot_location_match" label="Location Match" onChange={onChange} value={values.bot_location_match}/></div>
								<div><TextFieldInput name="parent_account_id" label="Parent (or 'none')" onChange={onChange} value={values.parent_account_id}/></div>
								<div><TextFieldInput name="dataflow_id" label="Dataflow ID" onChange={onChange} value={values.dataflow_id}/></div>
								<div><TextFieldInput name="tracking_code" label="Tracking Code" onChange={onChange} value={values.tracking_code}/></div>
								<div><QuickDateRange name="start" onChange={onChange} value={values.start}/></div>
								<div><ToggleSwitch name="archived" label="Archived?" onChange={onChange} value={values.archived}/></div>
								<div><ToggleSwitch name="completed_since" label="Completed Since?" onChange={onChange} value={values.completed_since}/></div>
								<div><FormControl>
									<Select
										native
										name="error_level"
										value={values.error_level}
										onChange={onChange}
									>
										<option value="">All</option>
										<option value="authentication">Auth Errors</option>
										<option value="!authentication">Non-Auth Errors</option>
									</Select>
								</FormControl>
								</div>
								<div><Button variant="contained" type="submit">Submit</Button></div>
							</React.Fragment>
						);}}
				</QueryStringForm>
			</Paper>
		</Popover>
	</React.Fragment>
	);
}

export default JobFilterMenu;
