import React,{useState,useEffect,useCallback} from 'react';

import gql from 'graphql-tag';

import { useQuery } from '@apollo/client';

import ComponentWrapper from './ComponentWrapper';


const DATA_QUERY=gql`
query DATA_QUERY($account_id:ID!,$table:String!,$query:WarehouseQueryInput!) {
	account(_id:$account_id) {
		default_warehouse_bot {
			warehouse {
				table(name:$table) {
					query_result(query:$query) {
						success
						error_message
						rows {
							values
						}
					}
				}
			}
		}
	}
}`;

export function useComponentData(props) {
	const {
		account_id,
		table,
		fields={},
		group_by=[],
		conditions=[],
		joins=[],
		date_field,
		qs={},
		order_by=[]
	}=props;
	let {dimension_alias}=props;

	const query = {};
	query.fields = Object.entries(fields).map(([alias,fql]) => ({alias,fql}));
	query.conditions = conditions.map(fql=>({fql: fql.fql || fql}));

	if(joins&&joins.length) query.joins = joins;

	let dateFql;
	if(date_field) dateFql = fields[date_field];

	if(date_field && qs.start) query.conditions.push({fql: `${dateFql} >= date("${qs.start}")`});
	if(date_field && qs.end) query.conditions.push({fql: `${dateFql} < date("${qs.end}")`});

	query.group_by = group_by.map(fql => ({fql}));

	query.order_by = order_by.map(x => {
		if(x.fql) return x;
		return {fql:x};
	});

	let providedData = (props.data !== undefined);

	let {loading,error,data} = useQuery(DATA_QUERY, {
		variables: {
			query,
			table,
			account_id
		},
		skip: !!providedData
	});

	if(providedData) {
		loading = false;
	}

	const [extract,setExtract]=useState();
	const [errorMessage,setErrorMessage]=useState();
	useEffect(() => {
		if(providedData) {
			setExtract(props.data);
			return;
		}

		if(!data||loading) return;
		const r = data.account.default_warehouse_bot.warehouse.table.query_result;
		if(!r.success) {
			setErrorMessage(r.error_message);
			return;
		}
		let rows = r.rows.map(x=>Object.assign({},x.values));

		if(date_field && !dimension_alias) dimension_alias = date_field;

		if(dimension_alias) rows.forEach(x => {
			x.dimension_alias = x[dimension_alias];
			delete x[dimension_alias];
		});

		setExtract(rows);
	},[data,loading,providedData,props.data]);

	return {
		loading: loading || !extract,
		error: errorMessage || error,
		data: extract,
	};
}

export default function SingleComponent(props) {
	props = Object.assign({},props);

	const [divDim,setDivDim] = useState();
	const divRef = useCallback(node => {
		if(!node) return;
		const rect = node.getBoundingClientRect();
		setDivDim({
			width:rect.width,
			height:rect.height
		});
	},[]);

	const qs = Object.assign({}, props.qs || {});
	if(!qs.start && props.is_date) {
		qs.start = '2020-10-01';
	}
	props.qs = qs;

	const {loading,data,error} = useComponentData(props);
	// const {fields}=props;

	if(error) return error.toString();
	if(loading) return 'loading';

	let {metrics, breakdown} = props;
	if(typeof breakdown == 'string') breakdown = {
		alias: breakdown
	};
	// if(!dimension && props.group_by) dimension = {
	// 	alias: (Object.entries(props.fields).filter(([,value]) => value === props.group_by))
	// };
	if(!metrics) metrics = Object.keys(data[0]||{}).filter(x => {
		return x !== 'dimension_alias' &&
			(!breakdown || breakdown.alias !== x);
	}).map(x => ({
		label:x, alias:x
	}));
	const component = Object.assign({}, props, {
		data,
		metrics,
		breakdown,
		label: props.label || props.name
	});

	const divStyle = Object.assign(props.style||{});
	if(!divStyle.width) divStyle.width='100%';
	if(!divStyle.height) divStyle.height='200px';

	let element;
	if(!divDim) element = null;
	else element = <ComponentWrapper
		name={props.name}
		component={component}
		style={divDim}
		editing={false}

		qs={qs} />;

	return <div ref={divRef} style={divStyle}>
		{element}
	</div>;
};
