import React from 'react';

import InputBase from '@mui/material/InputBase';

import './inputs/search-box.css';

export default class SearchBox extends React.Component {
	render() {
		const placeholder = this.props.placeholder || "Search...";
		const value = this.props.value || '';
		const onChange = this.props.onChange || (()=>{});
		return <div className='frakture-search-box'>
			<i className="zmdi zmdi-search zmdi-hc-2x" style={{marginLeft:'10px',marginRight:'10px'}}/>
			<InputBase placeholder={placeholder}
				value={value} onChange={onChange} />
		</div>;
	}
};
