import React,{useState} from 'react';
import FraktureTable from '../../../FraktureTable';
import Typography from '@mui/material/Typography';


export default function FraktureWarehouseTable(props){
	let {label,height,data}=props;

	const [sort, setSort] = useState(null);
	const [sortDirection, setSortDirection] = useState('asc');

	let rowsPerPage=Math.floor((height-80)/25);
	if (rowsPerPage<3) rowsPerPage=3;

	return <React.Fragment>
		{label && <Typography variant="h6">{label}</Typography>}
		<div style={{height:"100%",overflow:"auto",zoom:"1.0"}}>
			<FraktureTable
				key="table"
				rows={data}
				includePagination={true}
				includeColumnPicker={true}
				rowsPerPage={rowsPerPage}
				sort={sort}
				sortDirection={sortDirection}
				onSort={(field,dir)=>{console.log("Called setSort",field);setSort(field);setSortDirection(dir);}}
				padding="none"
			/></div>;
	</React.Fragment>;
};
