import React from 'react';

import gql from 'graphql-tag';
import {useAccountId} from '../account/AccountInfo';
import {useQuery} from '@apollo/client';

import {TabContent,TabContainer} from '../FraktureTabs';

import Monitor from './Monitor';
import {Header} from '../components/Header';

import AccountList from './AccountList';
import Card from '@mui/material/Card';
import SourceCodeFormatEditor from '../sourcecode/format-editor';



const CHILD_ACCOUNT_QUERY=gql`
query CHILD_ACCOUNT_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		_id
		children {
			_id
			name
			type

			disabled
		}
	}
}
`;
function s(i){return i===1?"":"s";}
export default function(props) {
	const account_id=useAccountId(props);
	const{data,error,loading}=useQuery(CHILD_ACCOUNT_QUERY,{variables: {account_id}});
	if (error) return "Error loading accounts";
	if (loading || !data) return "Loading Accounts";
	let description = 'No children';
	let {account:{children:children}}=data;
	if(children.length) {
		const active = children.filter(x => !x.disabled).length;
		const notactive = children.length - active;
		description = `${active} account${s(active)} active, ${notactive} account${s(notactive)} not active`;
	}

	return <>
		<Header
			primary="Parent Settings"
			secondary={<span>{description}</span>}
		/>
		<div className="app-main-content">
			<Card>
				<TabContainer urlName='agency_tab'>
					<TabContent label="Monitor"><Monitor {...props}/></TabContent>
					<TabContent label="Source Code Formats" ><SourceCodeFormatEditor {...props} /></TabContent>
					<TabContent label="Child Accounts"><AccountList parent_id={account_id} {...props}/></TabContent>
				</TabContainer>
			</Card>
		</div>
	</>;
}
