import React from 'react';
import gql from "graphql-tag";
import useNotifiers from '../../Notifiers';
import { useMutation } from "@apollo/client";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {useUserContext} from '../../../AdminCheck';

const DISABLE_DATAFLOW_MUTATION = gql`mutation UPDATE_DATAFLOW($dataflow_id:ID!, $update: DataflowUpdate!) {
	dataflow_update(_id: $dataflow_id, update:$update) {
		_id
		disabled
	}
}`;

export default function DisabledSwitch({dataflow_id,disabled,client}){
	const {notify,notifyErr} = useNotifiers();
	const userContext = useUserContext();
	const [mutateDisabled] = useMutation(DISABLE_DATAFLOW_MUTATION);
	const {is_frakture_admin} = userContext;
	if(!is_frakture_admin){
		return <div>{disabled?<b className="text-danger">Disabled</b>:<b className="text-success">Enabled</b>}</div>;
	}
	return (
		<FormControlLabel
			labelPlacement="start"
			control={
				<Switch
					checked={!disabled}
					onChange={e=>mutateDisabled({variables:{dataflow_id,update:{disabled:!e.target.checked}}}).then(notify,notifyErr)}
					color="primary"
				/>
			}
			label={disabled?<b className="text-danger">Disabled</b>:"Enabled"}
		/>
	);
};
