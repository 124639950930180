import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {UserAdministration,UserAdministrationList} from './UserAdmin';
export default function(){
	return <div className="app-main-content">
		<Routes>
			<Route path='*' element={<UserAdministrationList/>} />
			<Route path=':user_id' element={<UserAdministration/>} />
			<Route path=':user_id/*' element={<UserAdministration/>} />
		</Routes></div>;
}
