import gql from 'graphql-tag';

const botWithChildren=gql`
fragment botWithChildren on Bot {
	_id
	path
	nickname
	auth {
		key
		value
		field {
			type
			label
			description
			is_visible
			show_in_ui
		}
		bot{
			api_endpoint
			token
		}
		oauth_uri
	}
	warehouse_table_prefix

	sharable
	children {
		_id
		account_id
		account {
			_id
			name
			disabled
		}
		label
		nickname
		warehouse_table_prefix

		auth {
			key
			value
			parent_value
			is_value_set
			is_parent_value_set
			field {
				type
			}
			bot{
				api_endpoint
				token
			}
			oauth_uri
		}
	}

	account {
		_id
		children {
			_id
			name
			disabled
		}
	}
}
`;

export default {
	botWithChildren
};
