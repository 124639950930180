import React from 'react';
import {useParams,useLocation} from 'react-router-dom';
import TableList from './TableList';
import queryString from 'query-string';

import TypeAwareTableView from './TypeAwareTableView';
import DataGridWarehouseTable from './DataGridWarehouseTable';
import useAccountWarehouseInfo from './account-warehouse-info';

export default function() {
	const params = useParams(), location=useLocation();
	let {warehouse_bot_id,account_id,table}=params;

	const {loading,error,data:accountInfo} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return error;
	const {default_warehouse_bot} = accountInfo;
	const {_id:default_warehouse_bot_id}=default_warehouse_bot;
	warehouse_bot_id=warehouse_bot_id|| default_warehouse_bot_id;

	if(!table) return <TableList warehouse_bot_id={warehouse_bot_id} account_id={account_id}/>;

	const qs = queryString.parse(location.search);
	if(qs.grid && qs.grid !== 'false'){
		return <DataGridWarehouseTable warehouse_bot_id={warehouse_bot_id} table={table} editable={true}/>;
	}


	return <TypeAwareTableView warehouse_bot_id={warehouse_bot_id} table={table}/>;
}
