import React from 'react';

import gql from 'graphql-tag';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Box from '@mui/material/Box';

import {useAccountDetails,useAccountTypes} from '../AccountInfo';
import {useUserContext} from '../../../AdminCheck';
import { useApolloClient } from '@apollo/client';
import useNotifiers from '../../Notifiers';
import DisabledSwitch from '../DisabledSwitch';
import CardContent from '@mui/material/CardContent';

const ACCOUNT_MUTATION=gql`
mutation ACCOUNT_UPDATE_MUTATION($account_id:ID!,$update:JSON){
	account_update(_id:$account_id,update:$update) {
		_id
		name
		disabled
		type
		fiscal_year
		settings
		uninherited_settings
	}
}`;


export default function AccountSettings(props){
	const {is_frakture_admin}=useUserContext();
	const client = useApolloClient();
	const accountTypes=useAccountTypes();
	const {notify,notifyErr} = useNotifiers();
	const {account,loading,error}=useAccountDetails(props);
	if (loading) return "Loading...";
	if (error) return "Error loading account";
	if(!is_frakture_admin) return null;
	let {_id:account_id,disabled,
		//,uninherited_settings
	}=account;

	function mutateInfo(update){
		client.mutate({mutation:ACCOUNT_MUTATION,variables:{account_id,update:JSON.stringify(update)}}).then(notify,notifyErr);
	};


	return <CardContent>
		<Box
			component="form"
			sx={{
				'& .MuiFormControl-root': { m: 1,p:1 }
			}}
			noValidate
			autoComplete="off"
		>
			<TextField label='Account Name' defaultValue={account.name} fullWidth onBlur={e => {
				mutateInfo({name:e.target.value});
			}} />
			<FormControl fullWidth>
				<InputLabel>Account Type</InputLabel>
				<Select
					value={account.type||""}
					onChange={e => mutateInfo({type:e.target.value})}
					displayEmpty={true}
				>
					{accountTypes.map((type,i) => {
						return <MenuItem key={i} value={type.value}>{type.name}</MenuItem>;
					})}
				</Select>
			</FormControl>
			<TextField label='Fiscal Year' defaultValue={account.fiscal_year} fullWidth onBlur={e => {
				mutateInfo({fiscal_year:e.target.value});
			}} />
			<DisabledSwitch account_id={account_id} disabled={disabled}/>
		</Box>
	</CardContent>;
};
