import React from 'react';
import {TabContent,TabContainer} from '../../FraktureTabs';

import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import CardContent from '@mui/material/CardContent';

import LocationConfig from './LocationConfig';
import WarehouseConfig from './WarehouseConfig';
import ShareSettings from './ShareSettings';
import UserGroupList from '../../usergroup';
import {AccountUserList} from './Users';
import Usage from './Usage';
import {Accounting} from './Accounting';
import {useAdmin} from '../../../AdminCheck';
import AccountSettings from './AccountSettings';
import SourceCodeFormatEditor from '../../sourcecode/format-editor';
import SourceCodeElements from './SourceCodeConfig';
import {Header} from '../../components/Header';

import {useAccountId} from '../AccountInfo';

export default function Settings(props) {
	const account_id = useAccountId(props);
	const isAdmin=useAdmin();
	let tabs=[
		<TabContent key="account" label="Account" >
			<CardContent>
				<AccountSettings {...props} />
				{isAdmin?<><Divider textAlign="left">Location</Divider>
					<LocationConfig {...props} />
					<Divider textAlign="left">Default Warehouse</Divider>
					<WarehouseConfig {...props} /></>:""}
			</CardContent>
		</TabContent>,
		<TabContent key="sharing" label="Sharing" >
			<CardContent><ShareSettings {...props} /></CardContent>
		</TabContent>,
		<TabContent key="formats" label="Source Code Formats" ><SourceCodeFormatEditor {...props} /></TabContent>,
		<TabContent key="elements" label="Source Code Elements" ><SourceCodeElements {...props} /></TabContent>,
		<TabContent key="groups" label="Groups">
			<CardContent><UserGroupList {...{account_id}} /></CardContent>
		</TabContent>,
		<TabContent key="users" label="Users">
			<CardContent><AccountUserList {...{account_id}} /></CardContent>
		</TabContent>
	];
	if (isAdmin){
		tabs=tabs.concat([
			<TabContent key="usage" label="Usage">
				<CardContent><Usage {...{account_id}} include_table={true} /></CardContent>
			</TabContent>,
			<TabContent key="accounting" label="Accounting">
				<CardContent><Accounting {...{account_id}}/></CardContent>
			</TabContent>
		]);
	}
	return <div className="app-main-content-wrapper">
		<Header
			primary={[
				<h2 key="title">Account Settings</h2>
			]}
		/>
		{isAdmin?
			<div className="app-main-content">
				<Paper style={{overflow:'visible'}} className="w-100">
					<TabContainer urlName='account_settings'>
						{tabs}
					</TabContainer>
				</Paper>
			</div>:"Only admins currently have access to account settings."}
	</div>;
};
