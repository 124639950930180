import React from 'react';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';

export default function SimpleCollapse(props){
	const [open, setOpen] = React.useState(false);
	return <React.Fragment><Button
		onClick={() => setOpen(!open)}
		aria-expanded={open}
		className={props.className}
	>
		{props.title || "Click to open"}
	</Button>
	<Collapse in={open}>
		{props.children}
	</Collapse>
	</React.Fragment>;
}
