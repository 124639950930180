import {ApolloClient, from, split, gql } from "@apollo/client";

import {SchemaLink} from "@apollo/client/link/schema";
// for traversal
import {GraphQLID} from 'graphql';

import remoteSchemas from './remote-schemas';
import {wrapSchema,RenameRootFields} from '@graphql-tools/wrap';

import {mergeSchemas,makeExecutableSchema} from '@graphql-tools/schema';

import async from 'async';
import {print} from 'graphql/language/printer';

import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from "@apollo/client/link/ws";

import cacheFunc from './frakture-cache';
import delegateFunc from './types/warehouse_delegate';
import transformFunc from './frakture-transform';
import linkFunc from './frakture-link';
import resolvers from './types/core-resolvers';

export default function(options, callback) {
	let {uri,subscription_uri, getToken}=options;
	if(!uri) throw new Error('Required: uri');

	async.parallel({
		dataLayer: cb => remoteSchemas.dataLayer({
			dataLayerUri: options.uri,
			getToken
		}, cb),
		warehouse: cb => remoteSchemas.warehouse({
			stubWarehouseSchemaUri: options.uri+'/wh',
			getToken
		}, cb)
	}, async (e, schemas) => {
		if(e) return callback(e);
		const{dataLayer,warehouse}=schemas;

		const cache = cacheFunc();
		let delegates;
		try {
			delegates = delegateFunc(warehouse,dataLayer);
		} catch(e) {
			console.error('failed to build delegates:',e);
			throw e;
		}

		const fraktureTransforms = transformFunc();
		const fraktureLinks = linkFunc();

		try {
			const transformedWarehouse=wrapSchema({
				schema:warehouse,
				transforms:[
					new RenameRootFields((operation,name) => {
						if(operation === 'Mutation') return 'warehouse_'+name;
						if(operation === 'Query') return 'warehouse_'+name;
						return name;
					}),
					fraktureTransforms.addFieldToTypesTransform({
						name: "bot_id",
						type: GraphQLID
					})
				]});
			let customScalarSchema=makeExecutableSchema({
				typeDefs:gql`
						scalar JSON
						scalar Date`,
				resolvers
			});
			const clientSchema=mergeSchemas({
				schemas: [dataLayer,transformedWarehouse,customScalarSchema],
				typeDefs:[delegates.typeDefs,gql`
						scalar JSON
						scalar Date`],
				resolvers: [delegates.resolvers,resolvers]
			});

			const schemaLink = new SchemaLink({schema:clientSchema});
			let ensureFields = fraktureLinks.ensureFieldsInRequest({schema: clientSchema, fields: ['_id','id','bot_id']});
			const links=[
				// fraktureLinks.logError(),
				//fraktureLinks.validateRequest({schema: clientSchema}),
				//ensureFields,
				schemaLink
			];

			window.testEnsureFields = (q) => {
				const query=gql(q);
				ensureFields.request({
					query,
					log: console.log
				}, complete => {
					console.log(print(complete));
				});
			};

			const queryPath = from(links);
			let final = queryPath;
			if(false && subscription_uri) { //disable subscriptions until we get other things working
				const wsLink = new WebSocketLink({
					uri: subscription_uri,
					options: {
						reconnect: false,
						connectionParams: {
							authToken: await getToken()
						}
					}
				});
				final = split( // split based on operation type
					({query}) => {
						const { kind, operation } = getMainDefinition(query);
						const pick = kind === 'OperationDefinition' && operation === 'subscription';
						return pick;
					},
					wsLink,
					queryPath
				);
			} else {
				console.error('subscription_uri not provided, not setting up subscriptions');
			}
			console.log("Creating apollo client");
			const client = new ApolloClient({
				connectToDevTools: true,
				link: final,
				cache
			});
			callback(null, client, clientSchema);
		} catch(e) {
			console.error(e);
			return callback(e);
		}
	});
}
