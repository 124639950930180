import React,{useEffect} from 'react';

import queryString from 'query-string';
import {useLocation,useNavigate} from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export function TabContainer(props) {
	const {useHash=false}=props;
	const children = React.Children.toArray(props.children);
	const location = useLocation();
	const navigate = useNavigate();

	const indexByName = {};
	children.forEach((x,i) => indexByName[x.props.label.toLowerCase()]=i);

	const urlName = props.urlName || 'tab';

	const params = queryString.parse(useHash?document.location.hash:location.search);
	const selectedTab = indexByName[params[urlName]];
	const urlValue=params[urlName];

	const [tab,setTab]=React.useState(selectedTab || 0);

	useEffect(() => {
		if (urlName==='none') return;
		const fromUrl = params[urlName];
		if(indexByName[fromUrl] != null) {
			setTab(indexByName[fromUrl]);
		}
	}, [urlValue,indexByName,params,urlName]);

	if(!children.length) return "No Content";

	const tabs = <Tabs value={tab} onChange={(e,v) => {
		const label = children[v].props.label.toLowerCase();
		const newParams = Object.assign({},params,{[urlName]:label});
		if (urlName==='none'){
			setTab(v);
		}else if (useHash){
			//Set the hash directly so it doesn't trigger a refresh
			document.location.hash=queryString.stringify(newParams);
		}else{
			const newLocation = Object.assign({},location, {search:queryString.stringify(newParams)});
			navigate(newLocation);
		}
	}} > {children.map(x => <Tab key={x.props.label} label={x.props.label}/>)}
	</Tabs>;
	const visible = (children[tab] || children[0]).props.children;
	return <React.Fragment>
		{tabs}
		{visible}
	</React.Fragment>;
};

export function TabContent(props) {
	return props.label;
};
