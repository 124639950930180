import gql from 'graphql-tag';

export const DATAFLOW_OVERRIDES=gql`
fragment DATAFLOW_OVERRIDES on Dataflow {
	config {
		job_option_overrides {
			overrides {
				option
				value
				default_value
				override_value
			}
		}

		bot_overrides {
			_id
			bot_id
			default_id
			override_id
			default_bot {
				_id
				label
			}
			override_bot {
				_id
				label
			}
			override_options {
				bot_id
				bot_path
				override_id
				override_path
				override {label}
			}
		}
	}
}
`;

export const ACCOUNT_OVERVIEW_DATAFLOWS=gql`
fragment ACCOUNT_OVERVIEW_DATAFLOWS on Account {
	_id
	dataflows {
		_id
		label
		original_parent {
			_id
			label
		}

		schedule
		last_completed

		full_status {
			errored_job_lists
			expected_last_completed
			status
		}

		most_recent_job_list {
			_id
			label
			status
		}

		... DATAFLOW_OVERRIDES
	}
}
${DATAFLOW_OVERRIDES}
`;

export const ACCOUNT_OVERVIEW_BOTS=gql`
fragment ACCOUNT_OVERVIEW_BOTS on Account {
	_id
	bots {
		_id
		path
		sharable
		label
		definition {
			_id
			bot_path
			metadata {
				logo
			}
		}
	}
}
`;

export const BOT_WITH_METHOD_FRAGMENT=gql`
fragment BOT_WITH_METHOD_FRAGMENT on BotInstanceMethod {
	_id
	path
	bot {
		_id
	}
}`;

export const CHILD_ACCOUNT_DETAILS=gql`
fragment CHILD_ACCOUNT_DETAILS on Account {
	_id
	name
	type

	disabled

	bots {
		_id
		label
		definition {
			_id
			bot_path
			metadata {
				logo
			}
		}

		auth {
		  is_value_set
			is_parent_value_set
			can_auth
		  field {
				required
		  }
		}
	}

	person_bots: bots_with_method(method:"loadPeople") {
		... BOT_WITH_METHOD_FRAGMENT
	}
	transaction_bots: bots_with_method(method:"loadTransactions") {
		... BOT_WITH_METHOD_FRAGMENT
	}
	message_bots: bots_with_method(method:"loadMessages") {
		... BOT_WITH_METHOD_FRAGMENT
	}

	... ACCOUNT_OVERVIEW_DATAFLOWS
}
${BOT_WITH_METHOD_FRAGMENT}
${ACCOUNT_OVERVIEW_DATAFLOWS}
`;

export const ACCOUNT_DEFAULT_WAREHOUSE=gql`
fragment ACCOUNT_DEFAULT_WAREHOUSE on Account {
	_id
	disabled
	default_warehouse_bot {
		_id
		global_table_prefix
	}
}`;
