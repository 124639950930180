// eslint-disable-next-line no-unused-vars
import React,{useState,useEffect} from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';
import {toFiscalYear} from '../../../../util/fiscal';


export default function FiscalYearSelect({fiscal_year="01-01",years=[2021,2022,2023,2024,2025]}){
	const location=useLocation();
	let params = queryString.parse(location.hash);
	function setParam(name,val){
		params[name]=val;
		document.location.hash=queryString.stringify(params);
		/*
		let newParams=Object.assign({},params,{[name]:val});
		setParams(newParams);
		*/
	}
	const year=params["year"] || years.slice(-1)[0] || toFiscalYear(fiscal_year,new Date()) ;
	return <Select variant="standard" style={{fontSize:"1em"}} value={parseInt(year)}>{years.map(y=><MenuItem key={y} value={parseInt(y)} onClick={e=>setParam('year',y)}>FY{y}</MenuItem>)}</Select>;
}