import React from 'react';
import StubQuery from "./StubQuery";
import gql from "graphql-tag";

let linkQuery=gql(`query fileurl($bot_id:ID!,$filename:String!){
  bot_file_url(bot_id:$bot_id,filename:$filename)
}`);

export default class FileLink extends React.Component {
	render(){
		if (!this.props.bot_id) return "bot_id missing for links";
		let filenames=this.props.filenames;
		if (filenames===undefined) return "(no filenames specified)";
		if (typeof filenames=='string') filenames=filenames.split(",").map(d=>d.trim());
		return <React.Fragment>{filenames.map((filename,i)=>{
			return <StubQuery key={i} query={linkQuery} variables={{bot_id:this.props.bot_id, filename}}>
				{({data,error,loading}) => {
					if(!data && loading) return 'Searching...';
					if(!data && error){
						console.error(error);
						return 'Error loading...';
					}
					if (this.props.download!==undefined)data.bot_file_url+="&download";

					return <a target="_blank" rel="noopener noreferrer" href={data.bot_file_url}>{filename}</a>;
				}}</StubQuery>;
		})}
		</React.Fragment>;
	}
}
