import FrakturePieChart from './components/FrakturePieChart';
import FraktureScorecard from './components/FraktureScorecard';
import FraktureLabel from './components/FraktureLabel';
import FraktureVerticalBarChart from './components/FraktureVerticalBarChart';
import FraktureBarChart from './components/FraktureBarChart';
import FraktureBubbleChart from './components/FraktureBubbleChart';
import FraktureStackedBarChart from './components/FraktureStackedBarChart';
import FraktureReportTable from './components/FraktureReportTable';
import FraktureWarehouseTable from './components/FraktureWarehouseTable';
import FraktureTextFilter from './components/FraktureTextFilter';
import FraktureQuerySelectFilter from './components/FraktureQuerySelectFilter';
import MessageBySourceCode from './components/MessageBySourceCode';
import StrategicCustomerAnalysis from './components/v1/StrategicCustomerAnalysis';
import FilteredTransactions from './components/FilteredTransactions';
import CustomerEvolution from './components/CustomerEvolution';
import CompetitiveSpend from './components/CompetitiveSpend';

import {SegmentPicker} from '../../inputs/QueryStringInputs';

export default ({
	FrakturePieChart,
	FraktureLabel,
	FraktureScorecard,
	FraktureReportTable,
	FraktureVerticalBarChart,
	FraktureBarChart,
	FraktureBubbleChart,
	FraktureStackedBarChart,
	FraktureWarehouseTable,
	FraktureTextFilter,
	FraktureQuerySelectFilter,
	CompetitiveSpend,
	MessageBySourceCode,
	StrategicCustomerAnalysis,
	FilteredTransactions,
	CustomerEvolution,
	SegmentPicker
});
