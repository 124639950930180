import React from 'react';
import gql from "graphql-tag";
import FraktureQuery from "../FraktureQuery";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormHelperText from '@mui/material/FormHelperText';
dayjs.extend(calendar);
const fileQuery = gql(`
query files($account_id: ID!) {
	account(_id:$account_id) {
		files {
			type
			url
			label
			last_modified
			description
			postfix
		}
	}
}`);

class FileListRow extends React.Component {
	render() {
		const{file}=this.props;
		const{label,description,last_modified,url}=file;
		console.log(dayjs(last_modified).calendar);
		return <TableRow>
			<TableCell>
				<div>
					{label}
				</div>
				<FormHelperText>
					{description}
				</FormHelperText>
			</TableCell>
			<TableCell>{dayjs(last_modified).calendar()}</TableCell>
			<TableCell><a href={url+'&download'} download>Download</a></TableCell>
		</TableRow>;
	}
};

export default class FileList extends React.Component {
	render() {
		const{account_id}=this.props;
		return <Card>
			<CardHeader title='Account Files' />
			<CardContent>
				<FraktureQuery query={fileQuery} variables={{account_id}}>
					{data => {
						const{files}=data.account;
						return <Table>
							<TableHead>
								<TableRow>
									<TableCell>File</TableCell>
									<TableCell>Last Modified</TableCell>
									<TableCell>Download Link</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{files.map((file,key) => <FileListRow key={key} file={file}	/>)}
							</TableBody>
						</Table>;
					}}
				</FraktureQuery>
			</CardContent>
		</Card>;
	}
};
