import React,{useState} from 'react';
import {useApolloClient} from '@apollo/client';
import gql from "graphql-tag";
import {useNavigate,useLocation} from 'react-router-dom';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import IconButton from '@mui/material/IconButton';

import OptionEditor from '../inputs/OptionEditor';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Button from '@mui/material/Button';

import Tooltip from '@mui/material/Tooltip';
import {useQuery} from '@apollo/client';
import {useDataflow} from './DataflowListItem';



dayjs.extend(calendar);

const DATAFLOW_RUN_QUERY=gql`
query DATAFLOW_RUN_QUERY($dataflow_id:ID!) {
	dataflow(_id: $dataflow_id) {
		_id
		label
		jobs {
			options
			method
		}
		merged_jobs {
			merged_job {
				options
				method
			}
		}
	}
}`;
const DATAFLOW_RUN_MUTATION=gql`
mutation DATAFLOW_RUN_MUTATION($dataflow_id:ID!, $options:[DataflowOptionUpdate]) {
	job_list_id: dataflow_run(dataflow_id:$dataflow_id, options:$options, pause_first_job:false)
}`;
const JOB_LIST_NAV_QUERY=gql`
query JOB_LIST_NAV_QUERY($job_list_id: ID!) {
	job_list(_id:$job_list_id) {
		account_id
		jobs {
			_id
		}
	}
}`;

function RunDataflowDialog(props) {
	const {onClose,dataflow_id,label}=props;
	const client = useApolloClient();
	const navigate = useNavigate();
	const location = useLocation();

	const [options,setOptions]=useState({});
	const [first,setFirst]=useState(null);

	const {data} = useQuery(DATAFLOW_RUN_QUERY,{
		variables: {dataflow_id},
		onCompleted: d => {
			console.log('completed:',d);
			const {dataflow}=d;
			const {jobs,merged_jobs}=dataflow||{};
			let f;
			if(jobs&&jobs.length) f = jobs[0];
			else if(merged_jobs) f = merged_jobs[0].merged_job;
			else {
				console.log('missing first:',data.dataflow);
			}
			setFirst(f);
			let opts=f.options;
			if (typeof opts=='string') opts=JSON.parse(opts);
			if(f) setOptions(Object.assign({}, opts, options));
		}
	});

	const content = (() => {
		if(!data || !first) return '';
		if(first.method !== 'configStep') return 'No configuration';
		let o=options;

		const inputs = Object.entries(o).map(([name,value]) => {
			return <OptionEditor key={name} onOptionSave={({value,key}) => {
				if (typeof value=='object'){
					throw new Error("value should not be an object");
				}
				setOptions(Object.assign({},o,{[key]:value}));
			}} value={value} definition={{name}}/>;
		});

		return <form onSubmit={(e) => e.preventDefault()}>
			{inputs}
		</form>;
	})();

	return <Dialog {...{onClose}}
		fullWidth={true}
		maxWidth={"md"}
		open>
		<DialogTitle>{label}</DialogTitle>
		<DialogContent>{content}</DialogContent>
		<DialogActions>
			<Button onClick={onClose} color="secondary">
        Cancel
			</Button>
			<Button onClick={() => {
				client.mutate({
					mutation:DATAFLOW_RUN_MUTATION,
					variables: {
						dataflow_id,
						options: Object.entries(options).map(([key,value])=>({key,value:JSON.stringify(value)}))
					}
				}).then(x => {
					const {job_list_id}=x.data;
					return client.query({
						query: JOB_LIST_NAV_QUERY,
						variables: {job_list_id}
					}).then(j => {
						const {jobs}=j.data.job_list||{};
						const job_id=jobs[0]._id;
						const uri = location.pathname+
							(location.search||'')+`#job_list_id=${job_list_id}&job_id=${job_id}`;
						// close this dialog and open the job list
						onClose();
						navigate(uri);
					});
				});
			}} color="primary">
        Run
			</Button>
		</DialogActions>
	</Dialog>;
}

export default function RunDataflowButton(props) {
	const {dataflow,loading,error}=useDataflow(props);
	const [dialog,setDialog]=useState(false);
	if (error){
		console.log("RunDataflow error",error);
		return "";
	}
	if (loading) return loading;
	let can_run = dataflow.can_run || false;

	if(!can_run) return null;

	const {label} = dataflow;

	let dialogComponent = null;
	if(dialog) dialogComponent = <RunDataflowDialog {...props} label={label} onClose={() => setDialog(false)} />;

	return (
		<React.Fragment>
			<Tooltip title='Run Dataflow'>
				<IconButton onClick={e => setDialog(true)} size="large">
					<PlayArrowIcon />
				</IconButton>
			</Tooltip>
			{dialogComponent}
		</React.Fragment>);
}
