import React from 'react';
import gql from "graphql-tag";

import {fraktureQuery} from '../../FraktureQuery';
import {useUserContext} from '../../../AdminCheck';

import {useReportId} from './ReportProvider';
import {ReportLink,ReportSettingsLink} from '../Links';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {DeleteReportIconButton} from './ReportDelete';
import ReportSharing from './ReportSharing';

const REPORT_DETAIL_QUERY=gql`
query REPORT_DETAIL_QUERY($report_id:ID!) {
	report(_id:$report_id) {
		_id
		slug
		label
		account_id

		account {
			children {
				_id
				name
				reports {
					_id
					label
					parent_id
				}
			}
		}

		data_sources_array {
      alias
      table
      bot_path
      method
      warehouse_bot_id
    }

		parent {
			slug
			_id
			account_id
			label
		}
	}
}
`;

export default function ReportSettings(props) {
	const{is_frakture_admin}=useUserContext();
	const report_id=useReportId(props);
	if (!report_id) return "No report_id available";
	if(!is_frakture_admin) return '';

	return <Card>
		{fraktureQuery({
			query:REPORT_DETAIL_QUERY,
			variables: {
				report_id
			}
		}, data => {
			const{report}=data;
			const{label,parent}=report || {};

			let parentLink='';
			if(parent) parentLink=<span>
				Parent: <ReportSettingsLink report_id={parent._id}>{parent.label}</ReportSettingsLink> ({parent.account_id})
			</span>;
			let link=<ReportLink report_id={report_id}>{label}</ReportLink>;

			return <React.Fragment>
				<CardHeader title={link} subheader={parentLink} action={<DeleteReportIconButton/>}/>
				<CardContent>
					<h1>Sharing</h1>
					<ReportSharing {...props} />
				</CardContent>
			</React.Fragment>;
		})}
	</Card>;
};
