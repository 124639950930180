import React,{useState} from 'react';
import gql from "graphql-tag";

import {useQuery,useMutation} from '@apollo/client';
import {useNavigate} from 'react-router-dom';
import useNotifiers from '../../Notifiers';
import {useAccountId} from '../../account/AccountInfo';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import {fraktureQuery} from '../../FraktureQuery';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import {useUserContext} from '../../../AdminCheck';

const REPORT_PARENTS=gql`
query REPORT_PARENTS($account_id:ID!) {
	account(_id:$account_id) {
		valid_parent_reports {
			_id
			label
			slug
			account {
				_id
				name
			}
		}
	}
}`;

const REPORT_CANDIDATES=gql`
query REPORT_CANDIDATES($account_id:ID!,$slug:ID!) {
	account(_id:$account_id) {
		_id
		bots {
			_id
			label
		}
		report_data_source_candidates(slug:$slug){
			alias
			bot_id
			bot_path
		}
	}
}`;

const CREATE_CHILD_REPORT_MUTATION=gql`
mutation CREATE_CHILD_REPORT_MUTATION($parent_id:ID,$slug:ID,$account_id:ID!,$data_source_candidates:[ReportDataSourceCandidate]) {
	report_create(account_id:$account_id, parent_id:$parent_id,slug:$slug,data_source_candidates:$data_source_candidates) {
		_id
		label
	}
}`;
export function useReportCreate(props) {
	const {parent_id,slug,account_id,data_source_candidates,redirect} = props;
	const {notify,notifyErr} = useNotifiers();
	const navigate=useNavigate();
	function onCompleted(data){
		if (redirect){
			notify({message:"Created report"});
			let reportUri="/app/"+account_id+"/report/"+data.report_create.folders[0].short+"/"+data.report_create._id;
			navigate(reportUri);
		}else{
			notify({message:"Created report"});
		}
	}
	const [createReport]=useMutation(CREATE_CHILD_REPORT_MUTATION,{refetchQueries:["ACCOUNT_REPORTS"],onCompleted,onError:notifyErr});
	return () => {
		createReport({variables: {parent_id,slug,account_id,data_source_candidates}});
	};
};

export function AddReportButton(props){
	const reportCreate = useReportCreate(props);
	return <Button onClick={reportCreate} color="primary" variant="contained" size="small"><AddIcon />{props.label}</Button>;
}


export function CandidatePicker(props){
	const account_id=useAccountId(props);
	const {slug,parent_id}=props;
	const {data,error,loading}=useQuery(REPORT_CANDIDATES,{variables: {account_id,slug}});
	if (error) return "Error loading candidates";
	if (loading) return "Loading...";
	if (!slug && !parent_id) return "No slug or parent_id provided";
	const bots=data.account.bots.reduce((a,b)=>{a[b._id]=b.label;return a;},{});
	return data.account.report_data_source_candidates.map((candidates,i)=>{
		let label="Add "+candidates.map(candidate=>{
			delete candidate.__typename;
			let {bot_id,bot_path}=candidate;
			return bots[bot_id]||bot_path;
		}).join(",");
		if (data.account.report_data_source_candidates.length===1) label="Deploy Report";
		return <AddReportButton key={i} account_id={account_id} parent_id={parent_id} slug={slug} label={label} redirect={props.redirect} data_source_candidates={candidates}/>;
	});
}

export function useCreateReportObjects(props) {
	const account_id=useAccountId(props);
	const [parent_id,setParent]=useState('');
	const select = fraktureQuery({
		query: REPORT_PARENTS,
		variables: {account_id}
	}, data => {

		const valid_parent_reports=((data.account||{}).valid_parent_reports||[]).sort((a,b)=>a.label<b.label?-1:1);
		let accounts={};
		valid_parent_reports.forEach(r=>{
			accounts[r.account.name]=(accounts[r.account.name] ||[]).concat(r);
		});
		let keys=Object.keys(accounts).sort((a,b)=>{
			if (a==='Frakture Master'){return 1;}
			if (b==='Frakture Master'){return -1;}
			return a<b?-1:1;
		});
		let pickedSlug=null;
		if (parent_id) pickedSlug=(valid_parent_reports.find(d=>d._id.toString()===parent_id.toString())||{}).slug;

		return <React.Fragment><Select key={account_id+'select-parent'} fullWidth value={parent_id||'none'} onChange={e=>{
			if(e.target.value === 'none') setParent('');
			else setParent(e.target.value);
		}} >
			<MenuItem value='none'><em>Select report</em></MenuItem>
			{keys.map(a=>{
				return [<ListSubheader key="head">{a}</ListSubheader>
				].concat(accounts[a].map(({_id,label,slug}) => {
					return <MenuItem key={_id} value={_id}>{label} ({slug})</MenuItem>;
				}));
			})}
			});}
		</Select>
		{pickedSlug && <CandidatePicker slug={pickedSlug} parent_id={parent_id}/>}
		</React.Fragment>;
	});
	return [select];
}

export function CreateChildReportButton(props) {
	const reportCreate=useReportCreate(props);
	return <IconButton onClick={reportCreate} size="large"><AddIcon/></IconButton>;
}

export function PickAndAddReport(props){
	const{is_frakture_admin} = useUserContext();
	const [select,addButton]=useCreateReportObjects(props);
	if(!is_frakture_admin) return null;
	return <div id="add-report" className="d-flex">
		<div className="w-100 p-1">{select}</div>
		<div className="ml-auto text-nowrap p-1">{addButton}</div>
	</div>;
}
