import React from 'react';
import Grid from '@mui/material/Grid';

export function getEvenGrid(arr){
	const a=arr.filter(Boolean);
	// eslint-disable-next-line no-use-before-define
	if (a.length<=2) return getLeftGrid(arr);

	let sizes={md:2,xs:12};
	switch(a.length){
	case 1:sizes={md:12,xs:12}; break;
	case 2:sizes={md:6,xs:12}; break;
	case 3:sizes={md:4,xs:12}; break;
	case 4:sizes={md:3,xs:12}; break;
	case 5:sizes={md:2,xs:12}; break;
	case 6:sizes={md:2,xs:12}; break;
	case 7:
	case 8:sizes={md:1,xs:12}; break;
	default :sizes={md:1,xs:12}; break;
	}
	return a.map((k,i)=>{
		return <Grid item key={i} {...sizes}>{k}</Grid>;
	});
}

export function getLeftGrid(arr){
	console.log(arr);
	const a=arr.filter(Boolean);
	if (a.length>2) return getEvenGrid(arr);
	if (a.length===1) return <Grid item md={12} xs={12}>{a[0]}</Grid>;
	if (a.length===2) return [<Grid item key={0} md={a[0]?.props?.md|| 9} xs={12}>{a[0]}</Grid>,
		<Grid item key={1} md={a[1]?.props?.md|| 3} xs={12}>{a[1]}</Grid>];
	return "";
}
