import React from 'react';
import {BotList,BotGallery} from './BotList';
import Bot from './Bot';
import {useParams} from 'react-router-dom';
import {Header} from '../components/Header';
import {useAccountId} from '../account/AccountInfo';
import {AdminOnly} from '../../AdminCheck';
import AddBotSelector from '../bots/AddBotSelector';
import {Link} from 'react-router-dom';


export default function(){
	let params=useParams();
	const account_id=useAccountId();
	return <React.Fragment>
		<Header
			primary={[
				<Link key="head" to={"/app/"+account_id+"/bot"}><h2>Bots</h2></Link>,
				<AdminOnly key="addbot">
					<AddBotSelector account_id={account_id}/>
				</AdminOnly>
			]}
		/>
		<div className="app-main-content-ltr">
			<BotList/>
			<div className="app-main-content">{params.bot_id?<Bot/>:<BotGallery/>}</div>
		</div>
	</React.Fragment>;
};
