import React from 'react';
import {useQuery} from '@apollo/client';
import gql from "graphql-tag";
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import queryString from 'query-string';
import {useNavigate,useLocation,useParams,useSearchParams} from 'react-router-dom';
import Settings from '@mui/icons-material/EditSharp';
import IconButton from '@mui/material/IconButton';
import {SidebarListItem} from '../components/SidebarList';

import Tooltip from '@mui/material/Tooltip';

import {getCronLabel,humanizeDate} from '../formatters.js';
import {AdminOnly} from '../../AdminCheck';

import RunDataflowButton from './RunDataflowButton';

dayjs.extend(calendar);

const dataflowViewQuery=gql(`
query DATAFLOW_LIST_ITEM($id: ID!) {
	dataflow(_id: $id) {
		_id
		account_id
		label
		schedule
		disabled
		next_run
		can_run
		last_completed
		status
		full_status {
			errored_job_lists
			expected_last_completed
			status
			message
		}
	}
}
`);

export function useDataflow(props){
	let {dataflow,dataflow_id}=props;
	if (dataflow && dataflow.label) return {dataflow};
	dataflow_id=dataflow_id || dataflow._id;
	if (!dataflow_id) return "No dataflow id specified in props";
	const {data,loading,error}=useQuery(dataflowViewQuery,{variables:{id:dataflow_id}});
	if (loading || error) return {loading,error};
	return {dataflow:data?.dataflow};
}

export function DataflowStatus(props) {
	let color, icon;
	const{status,message=status}=props;
	switch(status) {
	case 'ok': color="green"; icon=<i className="zmdi zmdi-check-circle"/>; break;
	case 'error': color="red"; icon=<i className="zmdi zmdi-alert-circle"/>; break;
	case 'unscheduled':
	default: color="grey"; icon=<i className="zmdi zmdi-minus-circle"/>; break;
	}
	return <Tooltip title={message}>
		<span alt={message} style={{color, marginRight: '5px'}}>{icon} {status}</span>
	</Tooltip>;
};

// const dateFormat='ddd, MMM Do, h:mm a';
export default function DataflowListItem(props){
	const {dataflow,loading,error}=useDataflow(props);
	const [searchParams,setSearchParams]=useSearchParams();
	const navigate=useNavigate();
	const location=useLocation();
	const params=useParams();
	function setDataflow(new_id){
		let p={};
		for (const [k,v] of searchParams.entries()){p[k]=v;};
		p.dataflow_id=new_id;
		setSearchParams(p);
	}

	let query=queryString.parse(location.search);
	if (loading || error) return loading || error;

	const {_id,account_id,label,/*disabled,status_message,*/ schedule, last_completed,/*next_run,job_lists*/}=dataflow;


	//const dfStatus = <DataflowStatus key="dfStatus" {... full_status}/>;

	return <SidebarListItem
		onClick={()=>{
			//navigate("/app/"+account_id+"/job?dataflow_id="+_id);
			setDataflow(_id);
		}}
		selected={(params.dataflow_id===_id || query.dataflow_id===_id)}
		primary={[label]}
		secondary={[dataflow.disabled?<span className="text-danger">Disabled</span>:getCronLabel(schedule),<br key="br"/>,last_completed && "Last completed: "+humanizeDate(last_completed)]}
		footer={[
			<RunDataflowButton key={0} {...{dataflow,dataflow_id:_id}} />,
			<AdminOnly key={1}>
				<Tooltip title='Edit Dataflow'>
					<IconButton edge="end" aria-label="Settings" size="large" onClick={(e)=>{
						navigate("/app/"+account_id+"/dataflow/"+_id);
						e.stopPropagation();
					}}>
						<Settings />
					</IconButton>
				</Tooltip>
			</AdminOnly>
		]}
	/>;
};
