
import React from 'react';
import gql from "graphql-tag";

import FraktureQuery from '../FraktureQuery';

import CardHeader from '@mui/material/CardHeader';

import TableDocumentationDialog from './TableDocumentation';
import TableSettingsDialog from './TableSettings';

const tableMetadataQuery=gql(`query tableMetadata($warehouse_bot_id:ID!, $table:String!) {
	warehouse(bot_id:$warehouse_bot_id) {
		bot_id
		table_def:table(name:$table) {
			name
			object_type
			frakture_table_type
			owner_bot {
				definition {
					_id
					metadata {
						logo
					}
				}
			}
		}
	}
}`);

export default class TableHeader extends React.Component {
	render() {
		const{table,warehouse_bot_id}=this.props;
		return <FraktureQuery query={tableMetadataQuery} variables={{table,warehouse_bot_id}}>
			{data => {
				const title=<React.Fragment>
					<TableDocumentationDialog warehouse_bot_id={warehouse_bot_id} table={table} />
					<TableSettingsDialog bot_id={warehouse_bot_id} table={table} />
					{table}
				</React.Fragment>;
				let image;
				const {table_def}=data.warehouse;
				console.log(table_def.owner_bot);
				if(table_def.owner_bot && table_def.owner_bot.definition.metadata.logo) {
					image = <img alt="bot-logo" src={table_def.owner_bot.definition.metadata.logo} height={75} width={150}/>;
				}
				return <CardHeader title={title} subheader={warehouse_bot_id} action={image} />;
			}}
		</FraktureQuery>;
	}
};
