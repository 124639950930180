import React from 'react';
import {useParams} from 'react-router-dom';
import FraktureDataTable from '../warehouse/FraktureDataTable';

import {useLocation,useSearchParams} from 'react-router-dom';
import {TabContent,TabContainer} from '../FraktureTabs';
import {Header} from '../components/Header';

import CardContent from '@mui/material/CardContent';

import useAccountWarehouseInfo from '../warehouse/account-warehouse-info';
import ElementLabelEditor from './element-labels';
import {useUserContext} from '../../AdminCheck';

import './sourcecode.css';
import queryString from 'query-string';
import {QuickDateRange2} from '../inputs/QueryStringInputs';

export function getOverrideFields(o){
	let {table,name:field,override_field,label:title,width=120}=o;
	override_field=override_field || field;
	title=title||field;
	if (!field){
		console.error("Bad override field configuration:",o);
		throw new Error("No field specified");
	}
	return [
		{title:(title|| field)+" (raw)",field,table,alwaysHidden:true},
		{ title:(title||field)+" Override",
			table:table+"_override",
			field:override_field,
			cellBackgroundColor:"#FFF",
			width,
			isOverride:true
		},
		{ title,
			editable:false,
			cellBackgroundColor:"#EEE",
			width,
			value:function(row){
				let v=null;
				if (row[table+'_override-'+override_field]){
					v=row[table+'_override-'+override_field]+"*";
				}else{
					v=row[table+"-"+field];
				}
				//console.log(title+": Returning "+v+" for ",{table,field});
				return v;
			}
		}
	];
}


export default function SourceCodeList(props){
	const {account_id}=useParams(props);
	const location=useLocation();
	const [searchParams]=useSearchParams();

	let {fieldset=''}=queryString.parse(location.hash.slice(1));
	let override=searchParams.get("override");

	const {is_frakture_admin}=useUserContext();

	const {loading,error,data:accountInfo} = useAccountWarehouseInfo({account_id});
	if (loading) return "Loading ...";
	if (error) return error;
	const {default_warehouse_bot,source_code_elements} = accountInfo;
	const {global_table_prefix, _id:warehouse_bot_id}=default_warehouse_bot;

	let statDefaults={
		table:global_table_prefix+"source_code_stats",
		editable:false,
		cellBackgroundColor:"#EFE",
		width:100,
		group:"Statistics"};


	let statsFields=[
		{field:"revenue",title:"Revenue",type:"currency"},
		{field:"spend",title:"Spend",type:"currency"},
		{field:"initial_transaction_revenue",title:"Initial Rev.",type:"currency",},
		{field:"refund_amount",title:"Refunds",type:"currency"},
		{field:"origin_transaction_revenue",title:"Origin Revenue",type:"currency"},
		{field:"origin_person_count",title:"Origin People",type:"number"},
		{field:"initial_transaction_unique_person",title:"initial_transaction_unique_person",type:"number",hidden:true},
		/*{field:"origin_equals_last_click_revenue",title:"origin_equals_last_click_revenue",type:"currency",hidden:true},
		{field:"origin_equals_last_click_transactions",title:"origin_equals_last_click_transactions",type:"number",hidden:true},
		{field:"origin_equals_last_click_unique_person",title:"origin_equals_last_click_unique_person",type:"number",hidden:true},
		{field:"origin_initial_transaction_revenue",title:"origin_initial_transaction_revenue",type:"currency",hidden:true},
		{field:"origin_initial_transaction_count",title:"origin_initial_transaction_count",type:"number",hidden:true},
		{field:"origin_initial_transaction_unique_person",title:"origin_initial_transaction_unique_person",type:"number",hidden:true},
		{field:"origin_transaction_revenue_before_unsub",title:"transaction_revenue_before_unsub",type:"currency",hidden:true},
		{field:"origin_transaction_count_before_unsub",title:"transaction_count_before_unsub",type:"number",hidden:true},
		{field:"origin_transaction_unique_person_before_unsub",title:"transaction_unique_person_before_unsub",type:"number",hidden:true}
	*/
	].map(o=>Object.assign({},statDefaults,o));
	let modelFields=[];
	let models=['First Touch','CRM Origin','Last Acquisition'];
	if (account_id.indexOf('authentic')===0) models=['Authentic Origin'];
	models.forEach(m=>{
		['person_count','revenue','transactions'].forEach(f=>{
			let field='trans_model_'+m.toLowerCase().replace(/ /g,'_')+'_'+f;
			let title=m+" - "+f.replace(/_/g,' ');
			modelFields.push({field,title,type:f==='revenue'?'currency':'number',hidden:true});
		});
	});

	/*[
		{field:"timeline_signups",title:"Timeline - Unique signups",type:"number",hidden:true},
		{field:"timeline_resubscribes",title:"Timeline - Unique Resubscribes",type:"number",hidden:true},
		{field:"timeline_unsubscribes",title:"Timeline - Unique Unsubscribes",type:"number",hidden:true},
		{field:"resub_source_code_transaction_revenue",title:"Resub Attributed Revenue",type:"currency",hidden:true},
		{field:"resub_source_code_transaction_count",title:"resub_source_code_transaction_count",type:"number",hidden:true},
		{field:"resub_source_code_transaction_unique_person",title:"resub_source_code_transaction_unique_person",type:"number",hidden:true},
		{field:"resub_equals_last_click_revenue",title:"resub_equals_last_click_revenue",type:"currency",hidden:true},
		{field:"resub_equals_last_click_transactions",title:"resub_equals_last_click_transactions",type:"number",hidden:true},
		{field:"resub_equals_last_click_unique_person",title:"resub_equals_last_click_unique_person",type:"number",hidden:true},
		{field:"resub_initial_transaction_revenue",title:"resub_initial_transaction_revenue",type:"currency",hidden:true},
		{field:"resub_initial_transaction_count",title:"resub_initial_transaction_count",type:"number",hidden:true},
		{field:"resub_initial_transaction_unique_person",title:"resub_initial_transaction_unique_person",type:"number",hidden:true}
	]*/
	statsFields=statsFields.concat(modelFields.map(o=>{
		return Object.assign({
			table:global_table_prefix+"source_code_summary",
			editable:false,
			cellBackgroundColor:"#FDA",
			width:200,
			group:"Advanced Model Stats"},o);
	}));


	let fields=[
		{title:"Source Code",field:"source_code",width:400,editable:false},
		{title:"Last Seen",field:"source_code_last_used",width:110,editable:false,type:"date"},
		{title:"Source Code ID",field:"source_code_id",width:100,editable:false,hidden:true},
		{title:"Format",field:"format",width:400,editable:false,hidden:true},
		{title:"Format Regex",field:"format_regex",width:400,editable:false,hidden:true},
		{title:"Parsed Date",field:"source_code_date_parsed",width:150,type:"date",editable:false,hidden:true}
	];
	fields.forEach(d=>{d.group="Coding";});

	source_code_elements.forEach(def => {
		//if (def.name==='date') return; //some legacy handling for elements named 'date'
		getOverrideFields(Object.assign({},def,{
			table: global_table_prefix+"source_code_dictionary",
		})).forEach(y => {
			//Check for override in the hash
			if (y.isOverride){
				if (override){
					y.hidden=false;
				}else{
					y.hidden=true;
				}
			}
			y.group="Parsing";
			if(!y.alwaysHidden) y=Object.assign({},y,{auto_show_if_present: (!accountInfo.parent)});
			fields.push(y);
		});
	});
	let shown=0;
	fields.forEach((f)=>{
		if (f.field && f.field.indexOf("acquisition")===0){
			f.hidden=true;
			f.group="Acquisition";
		}else if (f.field && f.field.indexOf("_override")>=0){
			f.hidden=true;
		}else if (f.title && f.title.indexOf("Acquisition")===0){
			f.hidden=true;
			f.group="Acquisition";
		}else{
			shown++;
		}
		if (shown>24){
			f.hidden=true;
			return;
		}
	});//don't show all fields initially

	fields = fields.concat(statsFields);
	if (fieldset){
		fields=fields.filter(d=>d.fieldsets && d.fieldsets.indexOf(fieldset)>=0);
	}

	let join="source_code_dictionary_override,source_code_stats,source_code_summary".split(",").map(d=>global_table_prefix+d).join(",");

	const popover_items = [];
	if(accountInfo.childSelectPopover) popover_items.push(accountInfo.childSelectPopover);

	if(is_frakture_admin) popover_items.push({
		label: "Element Labels",
		fn: (p) => <ElementLabelEditor {...p} table={global_table_prefix+"source_code_dictionary"} account_id={account_id} />
	});
	let defaultStart="-30d";

	return <React.Fragment>
		<Header
			primary={[
				"Source Code Dictionary",
				/*<QuerySwitch key="switch" label="Override" name="override"/>,*/
				<QuickDateRange2 key="date" start={defaultStart}/>
			]}
		/>
		<FraktureDataTable
			{...props}
			view={"grid"}
			table={global_table_prefix+"source_code_dictionary"}
			join={join}
			date_field={"source_code_last_used"}
			default_query={{start:defaultStart}}
			order_by={[{
				fql: "source_code_last_used",
				order_by_direction: "DESC"
			}]}
			warehouse_bot_id={warehouse_bot_id}
			fields={fields}
			popover_items={popover_items}
		/></React.Fragment>;
}

export function AllDictionaryFeatures(){
	return <React.Fragment>
		<TabContainer urlName='dictionary_tab' useHash={true}>
			<TabContent label="All Codes">
				<CardContent><SourceCodeList/></CardContent>
			</TabContent>
			<TabContent label="Search">
				<CardContent>FOO</CardContent>
			</TabContent>
			<TabContent label="Formats">
				<CardContent>FOO</CardContent>
			</TabContent>
		</TabContainer>
	</React.Fragment>;
}

