import React from 'react';

export default class ReportError extends React.Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false,error:null };
	}

	componentDidCatch(error) {
		console.error("Caught an error at the top level",error);
		this.setState({ hasError: true,error:error });
		// You can also log the error to an error reporting service
		//logErrorToMyService(error, info);
	}

	render(props) {
		if (this.state.hasError) {
			let content=(props||{}).content;
			if (!content) content=(<div>{JSON.stringify(this.state.error)}}</div>);
			return content;
		}

		return this.props.children;
	}
}
