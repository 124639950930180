import React,{useState} from 'react';
import FormControl from '@mui/material/FormControl';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

export const ScheduleSelectOptions=[
	{value: "", label: "Manual"},
	{value: "0 0 3 * * *", label: "Every morning at 10 PM EST"},
	{value: "0 0 4 * * *", label: "Every morning at 11 PM EST"},
	{value: "0 0 5 * * *", label: "Every morning at 12 AM EST"},
	{value: "0 0 6 * * *", label: "Every morning at 1 AM EST"},
	{value: "0 0 7 * * *", label: "Every morning at 2 AM EST"},
	{value: "0 0 8 * * *", label: "Every morning at 3 AM EST"},
	{value: "0 0 9 * * *", label: "Every morning at 4 AM EST"},
	{value: "0 0 10 * * *", label: "Every morning at 5 AM EST"},
	{value: "0 0 11 * * *", label: "Every morning at 6 AM EST"},
	{value: "0 0 12 * * *", label: "Every morning at 7 AM EST"},
	{value: "0 0 13 * * *", label: "Every morning at 8 AM EST"},
	{value: "0 0 14 * * *", label: "Every morning at 9 AM EST"},
	{value: "0 0 15 * * *", label: "Every morning at 10 AM EST"},
	{value: "0 0 16 * * *", label: "Every morning at 11 AM EST"},
	{value: "0 0 17 * * *", label: "Every morning at 12 AM EST"},
	{value: "0 0 11 * * 1", label: "Every Monday morning at 6AM"},
	{value: "0 20 */12 * * *", label: "Every twelve hours"},
	{value: "0 10 */8 * * *", label: "Every eight hours"},
	{value: "0 0 */4 * * *", label: "Every four hours"},
	{value: "0 0 */2 * * *", label: "Every two hours"},
	{value: "0 0 * * * *", label: "Every hour"},
	{value: "0 50 * * * *", label: "Every hour at x:50"},
	{value: "0 */30 * * * *", label: "Every 30 minutes"},
	{value: "0 */15 * * * *", label: "Every 15 minutes"},
	{value: "0 */10 * * * *", label: "Every 10 minutes"},
	{value: "0 */5 * * * *", label: "Every 5 minutes"}
];

export function ScheduleSelectPopOver(props) {
	// const value = props.value||props.schedule||'';
	const onChange = props.onChange;
	if(!onChange) console.error('no onChange specified');

	const [internalAnchor,setInternalAnchor]=useState(null);

	const anchorEl = props.anchorEl || internalAnchor;
	const open = anchorEl!=null;
	const onClose = props.onClose || (() => setInternalAnchor(null));

	// const ref = props.ref;
	const children = props.children;

	let next = null;
	if(children) next = <span onClick={(e) => setInternalAnchor(e.currentTarget)}>
		{children}
	</span>;

	const menu = <Menu
		anchorEl={anchorEl}
		keepMounted
		open={open}
		onClose={onClose}
	>
		{ScheduleSelectOptions.map(x => {
			return <MenuItem key={x.value} onClick={() => {
				onChange(x.value);
				onClose();
			}}>
				<ListItemText primary={x.label}/>
			</MenuItem>;
		})}
	</Menu>;

	return <React.Fragment>
		{next}
		{menu}
	</React.Fragment>;
}

export default function ScheduleSelect({schedule,onChange}) {
	let value=schedule;

	return (
		<FormControl>
			<input list="datalist" defaultValue={value} onBlur={
				e=>{
					console.log("Blurring value:"+e.target.value);
					if (typeof onChange=='function') onChange(e.target.value);
				}
			}/>
			<datalist id="datalist">
				{ScheduleSelectOptions.map((item, key) =>
					<option key={key} value={item.value}>{item.label}</option>
				)}
			</datalist>
		</FormControl>
	);
}
