import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LabelEdit from '../inputs/LabelEdit';
import useNotifiers from '../Notifiers';
import StubQuery from '../StubQuery';
import {useApolloClient} from '@apollo/client';
import gql from "graphql-tag";
import {useParams} from 'react-router-dom';
import {AdminOnly} from '../../AdminCheck';

import BotParentSelect from './config/BotParentSelect';
import BotAuthFields from './config/BotAuthFields';
import HiddenBotAuthFields from './config/HiddenBotAuthFields';
import BotChildren from './config/BotChildren';
import CheckAuth from './CheckAuth';

import WarehouseConfiguration from './config/WarehouseConfiguration';

import DataflowListItem from '../dataflow/DataflowListItem.js';

import {IsAdmin,useUserContext} from '../../AdminCheck';
import {TabContent,TabContainer} from '../FraktureTabs';

import {fraktureQuery} from '../FraktureQuery';
import DeleteBotButton from './DeleteBotButton';

const botLocationQuery=gql(`
	query bot_locations{
		bot_locations{
			_id
			label
		}
	}`
);


const BOT_DATA = gql(`
query BOT_DATA($bot_id: ID!) {
	bot(_id: $bot_id) {
		_id
		label
		path
		nickname
		global_table_prefix
		warehouse_table_prefix
		warehouse_bot_id
		warehouse_bot_id_raw
		account {
			_id
			bots {
				_id
				account_id
				label
				nickname
				global_table_prefix
				definition {
					_id
					metadata {
						isWarehouse
					}
				}
			}
		}
		location{
			_id
			label
		}
		location_override{
			_id
			label
		}
		dataflows{
			_id
			account_id
			label
			schedule
		}
		definition{
			_id
			metadata {
			  alias
			  bot_path
			  thumbnail
			  isWarehouse
			  iso_picture
			  logo
			  channel
				auth_sections {
					id
					label
				}
			}
		}
		can_auth_ok
		auth {
			key
		  value
		  is_value_set
			parent_value
			is_parent_value_set
			can_auth
		  field {
				type
				label
				description
				is_visible
				show_in_ui
				section {
					id
				}
		  }
			${''/*
			may not be needed
			bot{
				api_endpoint
				token
			}
			oauth_uri
			*/}
		}
	}
}`);

const UPDATE_BOT_MUTATION = gql`mutation UPDATE_BOT_MUTATION($_id:ID!,$warehouse_bot_id:ID!,$data:BotUpdate!) {
	bot_update(_id:$_id, data:$data ) {
		_id
		label
		nickname
	}
	refresh_bot_metadata(bot_id:$warehouse_bot_id,warehouse_bot_id:$warehouse_bot_id)
}`;

function generateImageFromText(text){
	let canvas = document.createElement("canvas");
	let ctx = canvas.getContext("2d");
	ctx.font = "italic 40px Arial";
	ctx.fillText(text,30,60);
	let url=canvas.toDataURL("image/png", 1.0);
	return url;
}

function renderDataflowList(dataflows) {
	if(!dataflows.length) return "No dataflows";
	return <List className="p-0">
		{dataflows.map((d,i)=> <DataflowListItem key={i} dataflow={d}/> )}
	</List>;
}

const BotContent=function(props) {
	// let bot=assignDefaults(JSON.parse(JSON.stringify(this.props.bot)));
	const {bot,client} = props;
	const {notify,notifyErr} = useNotifiers();
	const dataflows=bot.dataflows ||[{label:"No dataflows"}];
	const{is_frakture_admin}=useUserContext();
	if (!bot.warehouse_bot_id) return JSON.stringify(bot);

	let sharing=null,parent=null;
	if(is_frakture_admin) sharing = <TabContent label="Sharing" path='sharing'>
		<BotChildren bot_id={bot._id}/>
	</TabContent>;

	if(is_frakture_admin) parent = <React.Fragment>
		<Typography gutterBottom variant="h5" component="h3">Parent</Typography>
		<Grid container>
			<Grid item md={6}>
				<FormControl fullWidth>
					<BotParentSelect fullWidth bot_id={bot._id} />
				</FormControl>
			</Grid>
		</Grid>
	</React.Fragment>;

	return <Card key={bot.id}><CardContent>
		<Grid container spacing={0}>
			<Grid item xs={9}>
				<LabelEdit value={bot.label} style={{width:'calc(100% - 120px)',fontSize:"1.8em"}}
					onChange={(label) => client.mutate({
						mutation: UPDATE_BOT_MUTATION,
						variables: {
							_id:bot._id,
							data:{label},
							warehouse_bot_id:bot.warehouse_bot_id
						}
					}).then(notify,notifyErr)}/>
				<br/>
				<LabelEdit placeholder="Nickname" value={bot.nickname} style={{fontSize:"1.4em"}}
					onChange={(nickname) => client.mutate({
						mutation: UPDATE_BOT_MUTATION,
						variables: {
							_id:bot._id,
							data:{nickname},
							warehouse_bot_id:bot.warehouse_bot_id
						}
					}).then(notify,notifyErr)}/>
			</Grid>

			<Grid item xs={3}>
				<img alt="bot_logo" className="img-rounded" src={bot.definition.metadata.logo} height="80"/>
			</Grid>
		</Grid>

		<Grid container spacing={4}>
			<Grid item md={12} sm={12}>
				<TabContainer>
					<TabContent label="Authorization" path='authorization'>
						<Card>
							<CardContent>
								<Grid container spacing={4}>
									<Grid item md={6}>
										<Typography gutterBottom variant="h5" component="h3">Authorization</Typography>
										<BotAuthFields bot_id={bot._id}/>
										<HiddenBotAuthFields bot_id={bot._id}/>
									</Grid>
									<Grid item md={6}>
										{bot.can_auth_ok?<CheckAuth bot_id={bot._id}/>:<AdminOnly>Auth button not implemented</AdminOnly>}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</TabContent>
					<TabContent label="Settings" path='settings'>
						<Card>
							<CardContent>
								<Grid container spacing={4}>
									<Grid item md={6}>
										{parent}
									</Grid>
									<Grid item md={6}>
										{bot.definition.metadata.isWarehouse?
											<div>
												<Typography gutterBottom variant="h5" component="h5">Global Table Prefix</Typography>
												{bot.global_table_prefix || "(none)"}
											</div>:
											<div>
												<Typography gutterBottom variant="h5" component="h3">Warehouse Configuration</Typography>
												<WarehouseConfiguration bot={bot}/>
											</div>
										}
										<div style={{marginTop:'30px'}}>
											<Typography gutterBottom variant="h5" component="h3">Bot Location</Typography>
											<LocationConfiguration bot={bot}/>
										</div>
									</Grid>
								</Grid>
							</CardContent>
							<CardActions>
								<DeleteBotButton bot_id={bot._id} />
							</CardActions>
						</Card>
					</TabContent>
					<TabContent label="Dataflows" path='dataflows'>
						<Card>
							<CardContent style={{marginLeft:'10px', marginRight:'10px'}}>
								{renderDataflowList(dataflows)}
							</CardContent>
						</Card>
					</TabContent>
					{sharing}
				</TabContainer>
			</Grid>
		</Grid>
	</CardContent></Card>;
};


function assignDefaults(_bot){
	let bot=Object.assign({},_bot);
	//The picture of the bot
	bot.picture=bot.definition.metadata.picture;
	bot.thumbnail=bot.definition.metadata.thumbnail;
	bot.channel=bot.definition.metadata.channel;
	bot.logo=bot.definition.metadata.logo || generateImageFromText(bot.label);
	//"https://picsum.photos/400/200/?random&gravity=east&"+(thumbnail.length+bot._id.length);
	return bot;
}
/* Method to slice up the bots by warehouse, including assigning default values,
and assigning channels
return {bots,dataflows,channels}
*/
function getWarehouses(bots){
	return bots.filter(d=>d.definition.metadata.channel==="warehouse").map(b=>{
		let wh=Object.assign({},{
			dataflows:[],
			channels:[],
			bots:bots.filter(d=>{
				let include=(d.definition.metadata.channel!=="warehouse" && (d.warehouse_bot_id===b._id || !d.warehouse_bot_id));
				return include;
			}).map(b=>{return assignDefaults(JSON.parse(JSON.stringify(b)) ); }) //returned cloned versions so we can modify
		},b);

		wh.bots.forEach(b=>{
			let channel=b.definition.metadata.channel || "utility";
			if (channel==="utility"){
				wh.dataflows=wh.dataflows.concat(b.dataflows);
			}
			wh.channels[channel]=(wh.channels[channel] ||[]).concat(b);
		});
		return wh;
	});
}


const setLocation=gql(`mutation setLocation($_id:ID!,$bot_location_id:ID!){
	bot_set_location(_id:$_id,bot_location_id:$bot_location_id) {
		_id
		location_override{
			_id
		}
	}
}`);

function LocationConfiguration(props) {
	let {bot:{_id,location,location_override}}=props;

	const client = useApolloClient();
	const {is_frakture_admin}=useUserContext();

	if(!is_frakture_admin) return '';

	//console.error("Passed location info for bot ",_id+":",location_override);
	let override_id=(location_override || {})._id || "__none__";

	return <StubQuery query={botLocationQuery}>
		{({data,error,loading}) => {
			if(error) { return 'Error';}
			if(loading) return 'Loading Bot';
			const {bot_locations}=data;

			return <IsAdmin>
				{isAdmin => {
					function mutateLocation(value){
						client.mutate({mutation:setLocation,variables:{_id,bot_location_id:value}});
					};
					return <React.Fragment>
						<h5>{location.label}</h5>
						<FormControl fullWidth>
							<Select
								value={override_id}
								onChange={e => mutateLocation(e.target.value)}
								displayEmpty={true}
								disabled={!isAdmin}
							>
								<MenuItem key={"inherit"} value={"__none__"}>(Inherited from account)</MenuItem>
								{bot_locations.map(({_id:i,label}) => {
									return <MenuItem key={i} value={i}>{label}</MenuItem>;
								})}
							</Select>
						</FormControl>
					</React.Fragment>;
				}}
			</IsAdmin>;
		}}
	</StubQuery>;
}

function Bot(props) {
	const client=useApolloClient();
	if (props.bot) {
		return <BotContent {...props} client={client}/>;
	}

	const {account_id,bot_id}=useParams();
	if (!account_id || !bot_id) return "No account_id or bot_id specified in params";

	return fraktureQuery({
		query: BOT_DATA,
		variables: {bot_id}
	}, data => {
		//console.log(bot_id,'query result:',data);
		return <BotContent bot={data.bot} client={client}/>;
	});
}

export {Bot,getWarehouses,assignDefaults};
export default Bot;
