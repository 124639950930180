import React from 'react';
import gql from 'graphql-tag';

import useNotifiers from '../../Notifiers';
import {fraktureQuery} from '../../FraktureQuery';
import {useApolloClient} from '@apollo/client';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {Link} from 'react-router-dom';

const BOT_PARENT = gql`query BOT_PARENT($bot_id:ID!) {
	bot(_id: $bot_id) {
		_id
		parent {
			_id
			label
		}
		valid_parent_bots {
			_id
			label
			account_id
		}
	}
}`;

const BOT_SET_PARENT = gql`mutation BOT_SET_PARENT($bot_id: ID!, $parent_id: ID!) {
	bot_set_parent(_id: $bot_id, parent_id: $parent_id) {
		bot {
			_id
			label
			parent {
				_id
				label
			}
		}
		parent {
			_id
			label
			children {
				_id
				label
			}
		}
		old_parent {
			_id
			label
			children {
				_id
				label
			}
		}
	}
}`;

export default function BotParentSelect(props) {
	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	const{bot_id}=props;
	if(!bot_id) return "bot_id is required";

	return fraktureQuery({
		query: BOT_PARENT,
		variables: {
			bot_id
		}
	}, data => {
		const{bot}=data;
		const{valid_parent_bots=[]}=bot;
		const{_id}=bot.parent || {};
		if (valid_parent_bots.length===0) return "No valid parent bots";

		const selectOptions = valid_parent_bots.filter(p=>p._id!==bot_id).map(p => {
			return <MenuItem key={p._id} value={p._id}>{p.label} ({p.account_id})</MenuItem>;
		});

		let value = _id;
		let parentBot = valid_parent_bots.find(x => x._id === _id);
		if(!_id || !parentBot) value = '';
		let link;
		if(parentBot) link = <Link to={`/app/${parentBot.account_id}/bot/${_id}`}>
			view parent
		</Link>;

		return <React.Fragment>
			<InputLabel>Parent Bot</InputLabel>
			<Select {...props} value={value} onChange={e => client.mutate({
				mutation: BOT_SET_PARENT,
				variables: {bot_id, parent_id: e.target.value || ''}
			}).then(notify,notifyErr)}>
				<MenuItem value={null}><em>No Parent</em></MenuItem>;
				{selectOptions}
			</Select>
			<FormHelperText>{link}</FormHelperText>
		</React.Fragment>;
	});
};
