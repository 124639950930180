import React from 'react';

import {Link,useNavigate} from 'react-router-dom';
import {useReportFolders} from './ReportFolders';
import {useAccountInfo} from '../account/AccountInfo';

import {useUserContext} from '../../AdminCheck';

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

export function useReportLink(props) {
	const {_id,report_id=_id}=props;
	const {report_folders,error,loading}=useReportFolders();
	if (error){
		console.error(error);
		return "Error";
	}
	if(error || loading) {
		return props;
	}
	let report=null;
	report_folders.forEach(d=>{
		if (report!=null) return;
		report=(d.reports||[]).find(r=>r._id===report_id);
	});

	const{account_id,label,report_folder_short}=report;
	let uri=`/app/${account_id}/report/${report_folder_short}`+(report_id?"/"+report_id:"");
	return {...props, uri, label};
}

export function ReportListLink(props) {
	const {account,loading,error}=useAccountInfo;

	if(error) console.error(error);
	if (loading) return "";
	let {uri}=useReportLink(props);

	return <Link to={uri}>{props.children || props.name || account.name}</Link>;
}

export function ReportLink(props) {
	const {report_id,uri,label,children}=useReportLink(props);
	if(!report_id) return 'No ID';
	if(!uri) return label||report_id;
	return <Link className="report-link" to={uri}>
		{children || label || report_id}
	</Link>;
}

export function ReportSettingsLink(props) {
	const navigate = useNavigate();
	const{uri}=useReportLink(props);
	const {is_frakture_admin} = useUserContext();
	if(!is_frakture_admin) return null;
	const settingsUri = uri+'/settings';
	let label = props.children || (<IconButton
		onClick={e => {
			e.preventDefault();
			navigate(settingsUri);
		}}
		size="large"><SettingsIcon/></IconButton>);
	return <Link to={settingsUri}>{label}</Link>;
}
