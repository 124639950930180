import React from 'react';
import gql from 'graphql-tag';
import {fraktureQuery} from '../FraktureQuery';

import useNotifiers from '../Notifiers';
import {useApolloClient} from '@apollo/client';

import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

import FraktureTable from '../FraktureTable';

const USER_GROUP_CREATE=gql`mutation USER_GROUP_CREATE($account_id:ID!) {
	user_group_create(account_id: $account_id) {
		account {
			user_groups {
				_id
			}
		}
		_id
		label
	}
}`;
function CreateUserGroupButton({account_id}) {
	const client = useApolloClient();
	const {notify,notifyErr} = useNotifiers();
	return <Button onClick={() => {
		client.mutate({
			mutation: USER_GROUP_CREATE,
			variables: {account_id}
		}).then(notify,notifyErr);
	}}>Create</Button>;
}

export const USER_GROUPS_QUERY=gql`query USER_GROUPS_QUERY($account_id:ID!){
	account(_id: $account_id) {
		user_groups {
			_id
			label
			permissions {
				permission
			}
			accessible_accounts {
				_id
				name
			}
			users {
				_id
			}
		}
	}
}`;
export default function UserGroupList({account_id}) {
	return fraktureQuery({
		query: USER_GROUPS_QUERY,
		variables: {account_id}
	}, data => {
		const rows = data.account.user_groups.map(ug => {
			const href = `/app/${account_id}/user_group/${ug._id}`;
			return {
				href,
				label:ug.label,
				permissions: ug.permissions.length,
				users: ug.users.length,
				accounts: ug.accessible_accounts.length
			};
		});
		const columns=[
			{render:({row},i)=><Link key={i} to={row.href}>{row.label}</Link>,title:""},
			{field:"permissions",title:"Permissions"},
			{field:"users",title:"Users"},
			{field:"accounts",title:"Accounts"}
		];

		return <React.Fragment>
			<FraktureTable rows={rows} columns={columns} includeColumnPicker={false}/>
			<CreateUserGroupButton {...{account_id}} />
		</React.Fragment>;
	});
};
