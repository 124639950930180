import React from 'react';
import {humanize} from '../formatters.js';


export function SidebarListHeader(props){
	let {closeButton,primary,secondary,content,footer}=props;
	let f=null;
	if (footer) f=<div className="d-flex justify-content-between p-1" style={{alignItems: "center"}}>{[].concat(footer).map((d,i)=>typeof d=='string'?<span key={i}>{d}</span>:<React.Fragment key={i}>{d}</React.Fragment>)}</div>;
	return <div className="sidebar-header"><div className="header">
		{primary}
		{closeButton?<span className="sidebar-close">{closeButton}</span>:null}
	</div>
	{secondary?<div className="sub-heading d-flex justify-content-between">{secondary}</div>:null}
	{content}
	{f}
	</div>;
}

export function SidebarListItem(props){
	const {className,style,selected,primary,secondary,footer,onClick}=props;
	let classes=(className||"").split(" ").concat("sidebar-item");
	if (selected) classes.push("selected");
	let f=null;
	if (footer) f=<div className="footer">{[].concat(footer).map((d,i)=>typeof d=='string'?<span key={i}>{humanize(d,40)}</span>:d)}</div>;
	return <div className={classes.join(" ")} style={style} onClick={onClick}>
		<div className="primary">{[].concat(primary).map((d,i)=>(typeof d==='string')?<span key={i}>{d}</span>:d)}</div>
		{secondary?<div className="secondary">{[].concat(secondary).map((d,i)=>(typeof d==='string')?<span key={i}>{humanize(d,30)}</span>:d)}</div>:null}
		{f}
	</div>;
}
