import React from 'react';
import gql from 'graphql-tag';

import {useNavigate} from 'react-router-dom';
import useNotifiers from '../Notifiers';
import {fraktureQuery} from '../FraktureQuery';
import {useApolloClient} from '@apollo/client';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {useUserContext} from '../../AdminCheck';

const BOT_CHECK = gql`query BOT_CHECK($bot_id:ID!) {
	bot(_id: $bot_id) {
		_id
		label
		children {
			_id
		}
		dataflows {
			_id
		}
		warehouse_bot_id
		warehouse {
			channel_bots {
				_id
			}
		}
		account{
			_id
		}
	}
}`;
const BOT_DELETE_MUTATION=gql`
mutation BOT_DELETE_MUTATION($bot_id:ID!) {
	bot_delete(_id:$bot_id) {
		_id
		account {
			_id
			bots { _id }
		}
	}
}`;

export default function DeleteBotButton(props) {
	const client = useApolloClient();
	const navigate=useNavigate();
	const {notify,notifyErr} = useNotifiers();
	const{is_frakture_admin}=useUserContext();
	if(!is_frakture_admin) return null;
	const{bot_id}=props;
	if(!bot_id) return "bot_id is required";

	return fraktureQuery({
		query: BOT_CHECK,
		variables: {
			bot_id
		}
	}, data => {
		const{bot}=data;
		const{label,children=[],dataflows=[],warehouse_bot_id,warehouse:{channel_bots=[]},account:{_id:account_id}}=bot;

		let tooltip='Delete Bot', disabled=false;
		if(children.length || dataflows.length || (warehouse_bot_id === bot_id && channel_bots.length)) {
			tooltip='Bot has child, dataflow, or warehouse bot dependencies';
			disabled=true;
		}

		const runDelete = () => {
			if(disabled) return;
			if(!window.confirm('Are you sure you want to delete bot '+label+'?')) return;
			client.mutate({
				mutation: BOT_DELETE_MUTATION,
				variables: {bot_id}
			})
				.then(()=>notify("Removed Bot"),notifyErr)
				.then(()=>navigate("/app/"+account_id+"/bot"));
		};

		return <Tooltip title={tooltip}>
			<span>
				<Button variant='contained' color='secondary' disabled={disabled} onClick={() => runDelete()}>Delete Bot</Button>
			</span>
		</Tooltip>;
	});
};
