import React,{useState,useEffect} from 'react';
import gql from 'graphql-tag';
import {useQuery,useApolloClient} from '@apollo/client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import SingleComponent,{useComponentData} from '../report/display/SingleComponent';

import SourceCodeDictionary from '../sourcecode/Dictionary';

import JobListSmallCard from '../job/JobListSmallCard';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import SourceCodeFormatEditor from '../sourcecode/format-editor';

import Button from '@mui/material/Button';

import {useLevel0Data} from './Level0';

import Grid from '@mui/material/Grid';

const LEVEL_2_LOAD_META_TRACKING_CODE = `"level2-source-code-loading"`;


const LEVEL_2_INFO_QUERY = gql`
query LEVEL_2_INFO_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		... ACCOUNT_SOURCE_CODE_FORMATS
	}
}
`;

export function useLevel2Data(props) {
	const {account_id}=props;
	// const level1 = useLevel1Data(props);

	const formatStats = useComponentData({
		account_id,
		table: "${global_table_prefix}source_code_summary",
		fields: {
			format: 'format',
			source_code_count: 'count(distinct source_code)'
		},
		group_by: ['format']
	});

	const useTypeData = opts => {
		const srcCodeExists = (() => {
			if(opts.source_code_id_field) return `(${opts.source_code_id_field} is not null and ${opts.source_code_id_field} <> 0)`;
			return `(${opts.source_code_field} is not null and ${opts.source_code_field} <> "")`;
		})();

		const validFormat = `(dict.format is not null and dict.format <> '')`;

		const x = useComponentData({
			account_id,
			table: opts.table,
			fields: {
				bot_id: opts.bot_id,
				total: 'count(*)',
				total_with_source_code: `sum(if(${srcCodeExists}, 1, 0))`,

				max_date: `max(${opts.date_field})`,
				max_date_source_code: `max(if(${srcCodeExists}, ${opts.date_field}, 0))`,

				no_source_code: `sum(if(not ${srcCodeExists}, 1, 0)) / count(*)`,
				source_code_match: `sum(if(${srcCodeExists} and ${validFormat}, 1, 0)) / count(*)`,
				source_code_no_match: `sum(if(${srcCodeExists} and not ${validFormat}, 1, 0)) / count(*)`,

				complete: `(sum(if(${srcCodeExists} and ${validFormat}, 1, 0)) / count(*)) > 0.9`,
			},
			conditions:[
				`${opts.bot_id} is not null and ${opts.bot_id} <> ''`,
				`${opts.date_field} > date_sub(now(), interval 6 month)`
			],
			group_by: [opts.bot_id],
			joins: [{
				target: '${global_table_prefix}source_code_dictionary',
				alias: 'dict',
				match_fql: (() => {
					if(opts.source_code_id_field) return `dict.source_code_id = ${opts.source_code_id_field}`;
					return `dict.source_code = ${opts.source_code_field}`;
				})()
			}],
		});

		if(x.error) {
			console.error(x.error);
			return {};
		}

		return x;
	};

	const transaction = useTypeData({
		table: "${global_table_prefix}transaction_metadata",
		bot_id: 'transaction_bot_id',
		date_field: 'ts',
		source_code_id_field: 'transaction_source_code_id'
	});
	const person = useTypeData({
		table: "${global_table_prefix}person_metadata",
		bot_id: 'origin_bot_id',
		date_field: 'origin_date_created',
		source_code_id_field: 'final_origin_source_code_id'
	});
	const message = useTypeData({
		table: "${global_table_prefix}global_message",
		bot_id: 'bot_id',
		date_field: 'publish_date',
		source_code_field: 'final_primary_source_code'
	});

	console.log('message:',message);

	const lvl2 = useQuery(LEVEL_2_INFO_QUERY, {
		variables: {account_id}
	});

	const [l2Data,setL2Data]=useState();

	useEffect(() => {
		if(lvl2.loading||!lvl2.data) return;

		console.log({transaction,person});

		const formats = lvl2.data.account.source_code_config.formats;
		const l2 = Object.assign({},l2Data||{});
		l2.formats = formats;

		if(formatStats.data && formatStats.data.length) {
			const stats = {};
			formatStats.data.forEach(x => stats[x.format]=x);
			l2.stats = stats;
		}

		l2.stats = l2.stats || {};
		formats.forEach(x => l2.stats[x.format]=l2.stats[x.format]||{});

		l2.byType = {
			transaction: transaction.data || [],
			person: person.data || [],
			message: message.data || []
		};

		setL2Data(l2);
	}, [lvl2.data,lvl2.loading, formatStats.data, person.data, transaction.data, message.data]);

	if(lvl2.error) return {error:lvl2.error};
	if(lvl2.loading) return {loading:true};

	return {data: l2Data};
}

const LEVEL_2_SOURCE_CODE_LOADING_QUERY = gql`
query LEVEL_2_SOURCE_CODE_LOADING_QUERY($account_id:ID!) {
	account(_id:$account_id) {
		default_warehouse_bot {
			_id
			path
		}
	}

	job_lists(query:{
		account_ids: [$account_id]
		tracking_code: ${LEVEL_2_LOAD_META_TRACKING_CODE}
	}) {
		page {
			_id
			label
			date_created
			status
			jobs {
				_id
				label
				status
				progress
				output
			}
			account_id
			last_update {
	      ts
	    }
		}
	}
}`;
const LEVEL_2_START_LOAD_METADATA = gql`
mutation LEVEL_2_START_LOAD_METADATA ($account_id:ID!, $bot_id:ID!) {
	job_list_create(
		account_id: $account_id
		job: {
			bot_id: $bot_id,
			method: "loadSourceCodeDictionaryMetadata"
		}
		tracking_code: ${LEVEL_2_LOAD_META_TRACKING_CODE}
		pause_first_job: false
		label: "Reload Source Code Dictionary"
	) {
		_id
		jobs {
			_id
			label
			status
			progress
			output
		}
	}
}
`;

function Level2SourceCodeLoading(props) {
	const {account_id}=props;
	const client = useApolloClient();

	const scl = useQuery(LEVEL_2_SOURCE_CODE_LOADING_QUERY, {
		variables: {
			account_id
		}
	});
	const{loading,data,error,refetch}=scl;

	const [recent,setRecent]=useState();
	useEffect(() => {
		if(!data||loading) return;
		const {job_lists:{
			page:[first]=[]
		}={}}=data;
		if(!first) return;
		setRecent(first);
	},[data,loading]);

	const startJobButton = <Button
		variant='contained'
		color='primary'
		onClick={() => client.mutate({
			mutation: LEVEL_2_START_LOAD_METADATA,
			variables: {
				account_id,
				bot_id: data.account.default_warehouse_bot._id
			}
		}).then(() => {
			refetch();
		})}>Reload</Button>;

	let recentRun='';
	if(recent) recentRun = <JobListSmallCard job_list_id={recent._id} />;
	if(error) return error.toString();

	return <Card>
		<CardHeader
			title='Level 2 - Source Code Metadata Loading'
			action={startJobButton}
		/>
		<CardContent>
			{recentRun}
		</CardContent>
		<CardContent>
			<div style={{width:'1200px', height: '600px'}}>
				<div style={{position:'absolute', width:'1200px', height: '600px'}}>
					<SourceCodeDictionary
						account_id={account_id}
						conditions={[
							"format is null or format = ''",
							"source_code is not null and source_code <> ''"
						]}/>
				</div>
			</div>
		</CardContent>
	</Card>;
}

function SourceCodePresenceByBot(props) {
	const {
		account_id,
		date_field,
		source_code_id_field,
		source_code_field,
		table,
		bot_id,
		type_name,
	} = props;

	let srcCodeExists = `(${source_code_id_field} is not null and ${source_code_id_field} <> 0)`;
	if(source_code_field) {
		srcCodeExists = `(${source_code_field} is not null and ${source_code_field} <> '')`;
	}
	const srcCodeMissing = `not ${srcCodeExists}`;
	const validFormat = `(dict.format is not null and dict.format <> '')`;
	const invalidFormat = `not ${validFormat}`;

	return <React.Fragment>
		<CardContent>
			<SingleComponent {...{
				account_id,
				table,
				name: type_name + ' Missing Source Codes by Bot',
				component: 'FraktureBarChart',
				fields: {
					bot_id,
					date: `date(${date_field})`,
					count: 'count(*)'
				},
				breakdown: 'bot_id',
				is_date: true,
				date_field: 'date',
				conditions: [srcCodeMissing],
				group_by: [`${bot_id}`, `date(${date_field})`]
			}}
			/>
		</CardContent>
		<CardContent>
			<SingleComponent {...{
				account_id,
				table,
				name: type_name + ' Source Codes without Matching Format by Bot',
				component: 'FraktureBarChart',
				fields: {
					bot_id: `${props.bot_id}`,
					date: `date(${date_field})`,
					count: 'count(*)'
				},
				joins: [{
					target: '${global_table_prefix}source_code_dictionary',
					alias: 'dict',
					match_fql: (() => {
						if(source_code_field) return `dict.source_code = ${source_code_field}`;
						return `dict.source_code_id = ${source_code_id_field}`;
					})()
				}],
				breakdown: 'bot_id',
				is_date: true,
				date_field: 'date',
				conditions: [srcCodeExists, invalidFormat],
				group_by: [`${props.bot_id}`, `date(${date_field})`]
			}}
			/>
		</CardContent>
	</React.Fragment>;
}

function Level2TypeOverview(props) {
	const {
		account_id,
		date_field,
		source_code_id_field,
		source_code_field,
		level0,
		level2,
		type
	} = props;

	let srcCodeExists = `(${source_code_id_field} is not null and ${source_code_id_field} <> 0)`;
	if(source_code_field) {
		srcCodeExists = `(${source_code_field} is not null and ${source_code_field} <> '')`;
	}
	const validFormat = `(dict.format is not null and dict.format <> '')`;

	const [open,setOpen] = useState(false);

	const stats = ((level2.data||[]).byType||{})[type] || [];

	const overview=<SingleComponent {...{
		account_id,
		table: props.table,
		name: props.name,
		component: 'FraktureBarChart',
		fields: {
			date: `date(${date_field})`,
			// bot_id: props.bot_id,
			// total: `count(*)`,
			'% No Source Code': `sum(if(not ${srcCodeExists}, 1, 0)) / count(*)`,
			'% Source Code w/ Match': `sum(if(${srcCodeExists} and ${validFormat}, 1, 0)) / count(*)`,
			'% Source Code w/o Match': `sum(if(${srcCodeExists} and not ${validFormat}, 1, 0)) / count(*)`
		},
		metrics: [{
			alias: '% No Source Code',
			format: 'percent'
		}, {
			alias: '% Source Code w/ Match',
			format: 'percent'
		}, {
			alias: '% Source Code w/o Match',
			format: 'percent'
		}],
		group_by: [`date(${date_field})`],
		joins: [{
			target: '${global_table_prefix}source_code_dictionary',
			alias: 'dict',
			match_fql: (() => {
				if(source_code_field) return `dict.source_code = ${source_code_field}`;
				return `dict.source_code_id = ${source_code_id_field}`;
			})()
		}],
		is_date: true,
		date_field: 'date'
	}} />;

	const title = <span>{props.name}</span>;

	const botStatRows = stats.map(x => {
		const {bot_id,total,no_source_code,source_code_no_match,source_code_match} = x;

		const def = level0?.channelBotsById?.[bot_id];

		let show = bot_id;
		if(def) show = <img width={150} height={75} src={def.definition.metadata.logo}/>;

		return <TableRow key={bot_id}>
			<TableCell className="table-cell-no-border" style={{width:'20%'}}>
				{show}
			</TableCell>
			<TableCell className="table-cell-no-border" style={{width:'20%'}}>
				<SingleComponent
					style={{height:'100px'}}
					name='Total'
					component='FraktureScorecard'
					data={[{total}]}
					metric={{}}
				/>
			</TableCell>
			<TableCell className="table-cell-no-border" style={{width:'20%'}}>
				<SingleComponent
					style={{height:'100px'}}
					name='No Source Code'
					component='FraktureScorecard'
					data={[{no_source_code}]}
					metric={{format:'percent', danger_threshold:0.1}}
				/>
			</TableCell>
			<TableCell className="table-cell-no-border" style={{width:'20%'}}>
				<SingleComponent
					style={{height:'100px'}}
					name='No Format Match'
					component='FraktureScorecard'
					data={[{source_code_no_match}]}
					metric={{format:'percent', danger_threshold:0.1}}
				/>
			</TableCell>
			<TableCell className="table-cell-no-border" style={{width:'20%'}}>
				<SingleComponent
					style={{height:'100px'}}
					name='With Format Match'
					component='FraktureScorecard'
					data={[{source_code_match}]}
					metric={{format:'percent', success_threshold:0.9}}
				/>
			</TableCell>
		</TableRow>;
	});

	let status;

	const uncomplete = stats.find(x => !x.complete);
	if(!stats.length) {
		status = <Tooltip title='Stats are loading, or there are no bots setup'>
			<HelpIcon color='gray' fontSize='medium'/>
		</Tooltip>;
	} else if(!uncomplete) {
		status = <Tooltip title='Good to go!'>
			<CheckCircleIcon color='primary' fontSize='medium'/>
		</Tooltip>;
	} else {
		status = <Tooltip title='Insufficient source codes/matching'>
			<ErrorIcon color='secondary' fontSize='medium'/>
		</Tooltip>;
	}

	return <Card>
		<CardHeader title={title} action={
			<React.Fragment>
				{status}
				<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
					{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
				</IconButton>
			</React.Fragment>
		} />
		<CardContent>
			<Table>
				<TableBody>
					{botStatRows}
				</TableBody>
			</Table>
		</CardContent>
		<CardContent>
			{overview}
		</CardContent>
		<Collapse in={open} timeout="auto" unmountOnExit>
			<SourceCodePresenceByBot
				{...props}
			/>
		</Collapse>
	</Card>;
};

function SourceCodeStats(props) {
	return <React.Fragment>
		<Grid item xs={12}>
			<Level2TypeOverview
				{...props}
				name='Transaction Source Codes'
				type_name='Transactions'
				type='transaction'
				table="${global_table_prefix}transaction_metadata"
				bot_id='transaction_bot_id'
				date_field='ts'
				source_code_id_field='transaction_source_code_id'
			/>
		</Grid>
		<Grid item xs={12}>
			<Level2TypeOverview
				{...props}
				name='Person Source Codes'
				type_name='People'
				type='person'
				table="${global_table_prefix}person_metadata"
				bot_id='origin_bot_id'
				date_field='origin_date_created'
				source_code_id_field='final_origin_source_code_id'
			/>
		</Grid>
		<Grid item xs={12}>
			<Level2TypeOverview
				{...props}
				name='Message Source Codes'
				type_name='Messages'
				type='message'
				table="${global_table_prefix}global_message"
				bot_id='bot_id'
				date_field='publish_date'
				source_code_field='final_primary_source_code'
			/>
		</Grid>
	</React.Fragment>;
}

export default function Level2(props) {
	const level2 = useLevel2Data(props);
	const level0 = useLevel0Data(props);
	if(!level2) return 'loading';

	return <Grid container spacing={2}>

		<SourceCodeStats {...props} {...{level2,level0}} />

		<Grid item xs={12}>
			<Card>
				<CardHeader title='Level 2 - Source Code Formats' />
				<CardContent>
					<SourceCodeFormatEditor {...props} {...level2} />
				</CardContent>
			</Card>
		</Grid>

		<Grid item xs={12}>
			<Level2SourceCodeLoading {...props} {...{level2}} />
		</Grid>

	</Grid>;
}
