import React from 'react';

import {useNavigate} from 'react-router-dom';
import {SidebarListItem} from '../components/SidebarList';
import {useAccountId} from '../account/AccountInfo';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
/*
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import HeroCard from '../components/HeroCard';
*/
import Typography from '@mui/material/Typography';

import Trianglify from 'util/Trianglify';


export function ReportSummaryCard(props){
	let {report,folder}=props;
	const account_id=useAccountId();
	const navigate=useNavigate();
	let folderPath=folder.short|| folder._id;

	//return JSON.stringify(Object.keys(report));
	return (
		<Card onClick={()=>navigate('/app/'+account_id+'/report/'+folderPath+'/'+report._id)}>
			<CardMedia
				sx={{ height: "100px" }}
				title={report.name}
			><Trianglify
					xColors={['#FFF',report.color]}
					color={report.color}
					width={250}
					height={100}
				/>
			</CardMedia>
			<CardContent>
				<Typography gutterBottom variant="h5" component="div">
					{report.label}
				</Typography>
				{report.admin_only?<i>Admin Only</i>:''}
				<Typography variant="body2" color="text.secondary">{report.description}
				</Typography>
			</CardContent>
			{/*
			<CardActions>
				<Button size="small">Share</Button>
				<Button size="small">Learn More</Button>
			</CardActions>
			*/}
		</Card>
	);
}




export function ReportListItem(props){
	let {report,folder}=props;
	let account_id=useAccountId();
	if (!account_id) return "No Account Id";
	const navigate=useNavigate();
	let folderPath=folder.short|| folder._id;
	return <SidebarListItem
		primary={report.label}
		onClick={()=>navigate('/app/'+account_id+'/report/'+folderPath+'/'+report._id)}
		secondary={[report.description,<hr key="hr"/>,(report.tags||[]).map((t,i)=><span key={i} className="badge badge-primary">{t}</span>)]}
	/>;
}
