import dayjs from 'dayjs';
export function toFiscalYear(fiscal_year,date){
	fiscal_year=fiscal_year || "01-01";
	let items=fiscal_year.split('-').map(x=>parseInt(x));
	let month=null,day=null;
	if (items.length===3) [,month,day]=items;
	else if (items.length===2) [month,day]=items;

	let d=new Date();
	if (month===1 && day===1) return d.getFullYear();
	if ((d.getMonth()+1)>month || ((d.getMonth()+1)===month && d.getDate()>=day)) return d.getFullYear()+1;
	return d.getFullYear();
};

export function fiscalYearStart(fiscal_year="01-01",year){
	fiscal_year=fiscal_year || "01-01";
	let items=fiscal_year.split('-').map(x=>parseInt(x));
	let month=null,day=null;
	if (items.length===3) [,month,day]=items;
	else if (items.length===2) [month,day]=items;

	let date=dayjs(year+"-01-01");
	if (month===1 && day===1){
		return date.toDate();
	}else{
		return date.subtract(1,'year').add(month-1,'month').add(day-1,'day').toDate();
	}
};