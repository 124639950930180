import React,{useState} from 'react';
import gql from "graphql-tag";

import {fraktureQuery} from '../FraktureQuery';

import Card from '@mui/material/Card';
import Input from '@mui/material/Input';
import CardContent from '@mui/material/CardContent';

import FraktureDataTable from './FraktureDataTable';
// import TableFilterEditor from './TableFilterEditor';

import TableHeader from './TableHeader';

function FqlEditor(props) {
	const[fql,setFql]=useState(props.fql);
	return <Input value={fql || ''}
		onChange={e=>setFql(e.target.value)} onBlur={e => {
			console.log('OnBlur',e.target.value);
			if(props.setFql) {
				console.log('Setting');
				props.setFql(fql);
			}
		}}
		fullWidth/>;
};

const tableMetadataQuery=gql(`query tableMetadata($warehouse_bot_id:ID!, $table:String!) {
	warehouse(bot_id:$warehouse_bot_id) {
		bot_id
		table(name:$table) {
			name
			object_type
			frakture_table_type
			owner_bot {
				definition {
					_id
					metadata {
						logo
					}
				}
			}
		}
	}
}`);
export default function TypeAwareTableView(props) {
	const{table,warehouse_bot_id}=props;
	return <FraktureDataTable warehouse_bot_id={warehouse_bot_id} table={table}/>;

	//Legacy filter work
	const [fql,setFql]=useState('');
	let filters;
	if(fql) filters=[{fql}];
	console.log('filters:',filters);
	return fraktureQuery({
		query:tableMetadataQuery,
		variables:{warehouse_bot_id,table}
	}, data => {
		const{table:tableDef}=data.warehouse||{};
		if(!tableDef) return 'Missing tableDef';
		return <Card>
			<TableHeader table={table} warehouse_bot_id={warehouse_bot_id} />
			<CardContent style={{height:"100%"}}>
				<FqlEditor {...{setFql}}/>
				<FraktureDataTable bot_id={warehouse_bot_id} table={table} filters={filters}/>
			</CardContent>
		</Card>;
	});
};
