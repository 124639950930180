import React,{useState} from 'react';

import './label-edit.css';

export default function LabelEdit(props){
	const [value,setValue]=useState(props.value);
	const update = props.onChange || (()=>{});
	const onFocus = props.onFocus || (()=>{});
	return (<input className='frakture-label-edit'
		style={props.style}
		onChange={e=>setValue(e.target.value)}
		onBlur={e=>{if(value !== props.value) update(value);}}
		placeholder={props.placeholder}
		onFocus={(e)=>onFocus()}
		value={value||''}/>);
};
