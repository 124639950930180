/*jslint browser: true, es6: true*/
/* eslint-disable no-restricted-globals */
import canvasDatagrid from 'canvas-datagrid';

import React,{useRef,useEffect,useState} from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ColumnPicker} from './FraktureTable';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {formatValue} from './formatters';

import { useSnackbar } from 'notistack';

// import {useNavigate} from 'react-router-dom';


//Sample chart usable in sidebar with dynamic data.  Pretty cool, but for later
/*import {Pie,PieChart} from "recharts";

function Charts(props){
	const {data}=props;
	let group="source_code_channel";
	let field="spend";
	let chartData=Object.values(data.reduce((a,b)=>{
		a[b[group]]=a[b[group]] || {label:b[group],value:0};
		a[b[group]].value+=parseFloat(b[field] ||0);
		return a;
	},{}));

	return <div>
		<PieChart width={200} height={(200 / 2) + 30}>
			<Pie
				dataKey="value"
				data={chartData}
				startAngle={450} endAngle={90}
			/>
		</PieChart>
	</div>;
}
*/
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';



function nextIndexOf(data,text,start) {
	let rawRowIndex = data.slice(start).findIndex(x => JSON.stringify(x).indexOf(text) !== -1);
	if(rawRowIndex !== -1) return start + rawRowIndex;
	return data.slice(0,start).findIndex(x => JSON.stringify(x).indexOf(text) !== -1);
}

function useFindDialog(props) {
	const{grid}=props;

	const [findEnabled,setFindEnabled]=useState(props.enabled||props.findEnabled);
	const [findText,setFindText]=useState(props.findText||'');
	const [replaceText,setReplaceText]=useState(props.replaceText||'');
	const [replaceEnabled,setReplaceEnabled]=useState(props.replaceEnabled);

	const [findCell,setFindCell]=useState(null);

	const determineNextFindCell = () => {
		if(!findEnabled || !findText) return null;

		const searchForward = (findCell && findCell.findText === findText);
		//console.log(searchForward,findCell,findText);
		let minRow = searchForward ? findCell.row+1 : 0;
		const rowIndex = nextIndexOf(grid.viewData,findText,minRow);
		if(rowIndex === -1) return null;
		const row = grid.data[rowIndex];
		const key = Object.keys(row).findIndex(x => {
			return ((row[x]||"").toString()).indexOf(findText) !== -1;
		});
		return {row:rowIndex,column:key, columnName: Object.keys(row)[key], findText};
	};
	const setNextFindCell = () => {
		const next = determineNextFindCell();
		if(!next) return;
		setFindCell(next);
	};

	const onFind = () => {
		setNextFindCell();
		if(props.onFind) props.onFind(findText);
	};
	const onReplace = () => {
		if(props.onReplace) props.onReplace(findText,replaceText,findCell);
	};
	const onReplaceAll = () => {
		if(props.onReplaceAll) props.onReplaceAll(findText,replaceText);
	};

	const setFind = (v) => {
		setFindEnabled(v);
		if(props.setEnabled) props.setEnabled(v);
	};
	const setText = (find) => {
		setFindText(find);
		if(props.setFindText) props.setFindText(find);
	};

	useEffect(() => {
		if(!findEnabled) return;
		setNextFindCell();
	}, [findEnabled,findText]);

	useEffect(() => {
		if(props.enabled !== findEnabled) setFindEnabled(props.enabled);
	},[props.enabled]);

	useEffect(() => {
		if(!grid) return;
		let meta=false;
		grid.addEventListener('keydown', (e) => {
			const{NativeEvent}=e;
			const{key}=NativeEvent;
			switch(key){
			case 'Meta':meta=true; break;
			case 'f':if(!meta) return;
				setFind(true);
				e.preventDefault();
				e.NativeEvent.preventDefault();
				break;
			}
		});
		grid.addEventListener('keyup', (e) => {
			const{NativeEvent}=e;
			const{key}=NativeEvent;
			if(key === 'Meta') meta=false;
		});
	},[grid]);

	const popup = (() => {
		if(!findEnabled) return null;

		return (
			<React.Fragment>
				<TextField
					label='Find in sheet'
					value={findText}
					onKeyDown={e => {
						if(e.key === 'Escape') setFindEnabled(false);
						if(e.key === 'Enter') onFind();
					}}
					onChange={e=>setText(e.target.value)}
					inputRef={(ref) => {
						if(!ref) return;
						ref.focus();
					}}
				/>
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={() => {
						setFindEnabled(false);
						setReplaceEnabled(true);
					}}
					size="large">
					<MoreVertIcon />
				</IconButton>
			</React.Fragment>
		);
	})();

	const dialog = (() => {
		if(!replaceEnabled) return null;
		//console.log('Building dialog');
		// we use the settings below to emulate the Google find/replace
		return (
			<Dialog
				open
				onClose={() => setReplaceEnabled(false)}
				hideBackdrop
				disableEnforceFocus
				disableScrollLock
				disablePortal>
				<DialogTitle>Find and Replace</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						label="Find"
						fullWidth
						value={findText}
						onChange={e=>setText(e.target.value)}
					/>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Replace With"
						fullWidth
						value={replaceText}
						onChange={e=>setReplaceText(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onFind} color="primary">
						Find
					</Button>
					<Button onClick={onReplace} color="primary">
						Replace
					</Button>
					<Button onClick={onReplaceAll} color="primary">
						Replace All
					</Button>
					<Button onClick={() => {
						setReplaceEnabled(false);
					}} color="primary">
						Done
					</Button>
				</DialogActions>
			</Dialog>
		);
	})();

	return {
		findCell: findEnabled?findCell:null,
		popup,
		dialog
	};
}
function moveRect(grid,x,y){
	let {columnIndex,rowIndex}=grid.activeCell;
	//console.log("Move grid called with",x,y,grid.activeCell);
	if (y===1 && rowIndex>=grid.data.length-1) return;
	if (y===-1 && rowIndex<0) return;
	if (x===-1 && columnIndex<=0) return;
	if (x===1 && columnIndex>=grid.schema.length-1) return;
	let select={left:columnIndex+x,right:columnIndex+x,top:rowIndex+y,bottom:rowIndex+y};
	grid.selectArea(select);
	grid.setActiveCell(columnIndex+x,rowIndex+y);
	//console.log("Post move activeCell:",grid.activeCell);
	//console.log("Scrolling to view ",rect.left,rect.top-1);

	let {top,left}=grid.scrollIndexRect;
	let i=null,j=null;
	if (x){
		i=columnIndex+x; j=(top<=0)?0:top+1;
	}else if (y){
		i=left;j=(rowIndex+y-1)>=0?rowIndex+y-1:0;
	}
	if (i!=null || j!=null){
		try{
			grid.scrollIntoView(i,j);
		}catch(e){
			console.log("Error:moveRect called with ",x,y,e);
		}
	}

	//console.log("Move rect grid draw");
	grid.draw(); //move rect grid draw

	return;
}

function useColorEvents(props){
	const {grid,columns}=props;
	useEffect(() => {
		if(!grid) return;
		let colors=columns.filter(d=>d.cellBackgroundColor || d.cellColor);
		if (colors.length===0) return;
		grid.addEventListener('rendercell', function (e) {
			if (e.cell.rowIndex<0) return;
			let c=colors.find(x=>e.cell.header.name===x.name);
			if (c && c.cellBackgroundColor){
				if (e.value==="BAR"){
					//console.log("Coloring:",e,new Error());
				}
				e.ctx.fillStyle = c.cellBackgroundColor;
			}
		});
	},[grid]);
}

/*
This is to prevent sorting unless we actually change the sorting, so when editing cells it doesn't resort
*/
/*
function useSortEvents(props){
	const {grid}=props;
	useEffect(() => {
		if(!grid) return;
		let currentSort=null;
		grid.addEventListener('beforesortcolumn', function (e) {
			let {columnName,direction}=e;
			console.log("Calling beforesort ",new Date());
			if (currentSort==columnName+"-"+direction){
				e.preventDefault();
				return;
			}
			currentSort==columnName+"-"+direction;
		});
	},[grid]);
}
*/



const inputKeyRegex=/^[a-z0-9!@#$%^&*()+=-]{1}$/i;

function useKeyboardEvents(props) {
	const{grid,handleUpdate}=props;
	const {enqueueSnackbar} = useSnackbar();
	useEffect(() => {
		if(!grid) return;
		let meta=false;
		let shift=false;

		grid.addEventListener('keydown', (e) => {
			const{NativeEvent}=e;
			const{key}=NativeEvent;
			let {columnIndex,rowIndex}=grid.activeCell;
			switch(key){
			case 'Shift':{
				shift=true;
				break; //The shift key means we're highlighting
			}
			case 'ArrowDown':
				if(!shift){ moveRect(grid,0,1); NativeEvent.stopImmediatePropagation();}
				break;
			case 'ArrowUp':
				if(!shift){ moveRect(grid,0,-1);NativeEvent.stopImmediatePropagation();}
				break;
			case 'ArrowLeft':
				if(!shift){ moveRect(grid,-1,0);NativeEvent.stopImmediatePropagation();}
				break;
			case 'ArrowRight':
				if(!shift){moveRect(grid,1,0);NativeEvent.stopImmediatePropagation();}
				break;
			case 'Meta':meta=true; break;
			case 'Backspace':
			case 'Delete':
				let uneditable=[];
				grid.selections.forEach((cols)=>{
					cols.forEach(col=>{
						let definition=grid.schema[col];
						if (definition.hidden) return;
						if (definition && definition.editable===false) uneditable.push(definition);
					});
				});
				if (uneditable.length>0){
					//dedupe:
					let deduped=uneditable.reduce((a,b)=>{
						a[b.title]=true;
						return a;
					},{});
					enqueueSnackbar("There are some uneditable columns in your selection:"+Object.keys(deduped).join(","),{ variant: 'error'});
					shift=false;
					grid.selectNone();
					break;
				}

				let changes = [];

				grid.selections.forEach((cols,row)=>{
					let rowData=grid.viewData[row];
					cols.forEach(col=>{
						let definition=grid.schema[col];
						if (definition.hidden) return;
						let columnName=definition.name;
						rowData[columnName]="";
						let change={[columnName]:""};
						changes.push({
							data:rowData,
							change
						});
					});
				});

				//This doesn't work because the columnName is wrong -- it's trying to get from the total schema not visible schema
				grid.forEachSelectedCell((_allRows,filteredRowIndex,_columnName)=>{
					console.log(filteredRowIndex,_columnName);
				});


				/*
				//This does NOT work, because it doesn't take into account filters
				for (let x=left;x<=right;x++){
					for (let y=top;y<=bottom;y++){
						let columnName=grid.schema[x].name;
						console.log("Blanking out ",{x,y});
						grid.data[y][columnName]="";
						let change={[columnName]:""};
						//grid.data[y] = Object.assign({},grid.data[y],change);
						let d=grid.data[y];
						changes.push({
							data:d,
							change
						});
					}
				}*/
				grid.draw();
				setTimeout(()=>{
					handleUpdate({
						type: 'update',
						changes
					});
				},10);
				break;
			default:{
				if (!meta && key.match(inputKeyRegex)){
					if (grid.schema[columnIndex].editable===false) return;
					//console.log("Setting value of ",rowIndex,columnIndex,grid.schema[columnIndex].name," to blank from ",grid.data[rowIndex][grid.schema[columnIndex].name]);
					grid.viewData[rowIndex][grid.schema[columnIndex].name]="";
					grid.draw(); //Oddly if we don't do this, the value isn't reset
					grid.beginEditAt(columnIndex,rowIndex);
				}
			}
			}
		});
		grid.addEventListener('keyup', (e) => {
			const{NativeEvent}=e;
			const{key}=NativeEvent;
			if(key === 'Meta') meta=false;
			if(key === 'Shift') shift=false;
		});
		grid.addEventListener('beforebeginedit', e => {
			const{cell}=e;
			const{header:{editable}}=cell;
			if (editable===false) e.preventDefault();
			return;
		});
		grid.addEventListener('appendeditinput', e => {
			const{input}=e;
			input.addEventListener("keydown",keyEvent=>{
				keyEvent.stopImmediatePropagation(); //Stops the default enter from firing
				switch(keyEvent.key){
				case 'Enter':
				case 'ArrowDown':
					grid.endEdit();
					moveRect(grid,0,1);
					keyEvent.stopImmediatePropagation(); //Stops the default enter from firing
					break;
				case 'ArrowUp':
					grid.endEdit();
					moveRect(grid,0,-1);
					break;
				case 'ArrowLeft':
					//grid.endEdit();
					//moveRect(grid,-1,0);
					break;
				case 'ArrowRight':
					//grid.endEdit();
					//moveRect(grid,1,0);
					break;
				}
			});
			input.addEventListener('keyup', ({key}) => {
				if(key === 'Meta') meta=false;
				if(key === 'Shift') shift=false;
			});
		});

		grid.addEventListener('endedit', e => {
			if(e.abort) return;
			const{cell,value}=e;

			const{header:{name},data}=cell;
			const changes = [{
				data,
				change:{[name]:value}
			}];

			handleUpdate({
				type: 'update',
				changes
			});
			//console.log("End edit grid draw");
			grid.draw(); //end edit grid draw
		});
	},[grid]);
}

function useRepairedCopy(props){
	const{grid}=props;
	const self=grid;

	useEffect(() => {
		if(!grid) return;

		grid.addEventListener('copy', gridEvent => {
			const {NativeEvent:e}=gridEvent;
			/* Copied from original source to fix bug that was removing begin and end character for non strings */
			//if (self.dispatchEvent('copy', {NativeEvent: e})) { return; }
			if (!self.hasFocus || !e.clipboardData) { return; }
			let t,
				d,
				//data = (self.data || []),
				tableRows = [],
				textRows = [],
				outputHeaders = {},
				outputHeaderKeys,
				sData = self.selectedCells,
				s = self.schema;
			function htmlSafe(v) {
				return v.replace(/</g, '&lt;').replace(/>/g, '&gt;');
			}
			function fCopyCell(v) {
				v = v === null || v === undefined ? '' : v;
				return '<td>' + (typeof v === 'string' ? htmlSafe(v) : v) + '</td>';
			}
			function addHeaders(headers, useHtml) {
				if (!s.length || headers.length < 2) { return ''; }
				let h = [];
				if (useHtml) {
					h.push('<tr>');
				}
				s.forEach(function (column, columnIndex) {
					// intentional redefinition of column
					column = s[self.columnOrder[columnIndex]];
					if (!column.hidden && headers.indexOf(column.name) !== -1) {
						/*
						let ev = {NativeEvent: e, column: column};
						if(self.dispatchEvent('copyonschema', ev)) {
							column = ev.column;
						}*/

						let hVal = (column.name || column.title) || '';
						if (useHtml) {
							h.push('<th>' + htmlSafe(hVal) + '</th>');
						} else {
							h.push('"' + hVal.replace(/"/g, '""') + '"');
						}
					}
				});
				h.push(useHtml ? '</tr>' : '\n');
				return h.join(useHtml ? '' : ',');
			}
			function addCellValue(val, trRow, textRow) {
				// escape strings
				if (val !== null
								&& val !== false
								&& val !== undefined
				) {
					val=String(val); //Frakture rewrite -- push all things as strings, even if they're not
					trRow.push(fCopyCell(val));
					//let multiColVal='"' + val.replace(/"/g, '""') + '"';
					textRow.push(val);
					return;
				}
				if (val !== undefined) {
					textRow.push(val);
					trRow.push(fCopyCell(val));
					return;
				}
				// issue #66
				textRow.push('');
				trRow.push('<td>&nbsp;</td>');
			}
			if (sData && sData.length > 0) {
				sData.forEach(function (row) {
					let rowKeys = Object.keys(row);
					if (row) {
						let trRow = [],
							textRow = [],
							sSorted = [];
						// HACK: https://github.com/TonyGermaneri/canvas-datagrid/issues/181
						// I can't use sort here or O(1), so hacks
						s.forEach(function (column, columnIndex) {
							sSorted.push(s[self.columnOrder[columnIndex]]);
						});
						sSorted.forEach(function (column, columnIndex) {
							if (rowKeys.indexOf(column.name) !== -1) {
								outputHeaders[column.name] = true;
								// escape strings
								addCellValue(row[column.name], trRow, textRow, column);
							}
						});
						tableRows.push(trRow.join(''));
						textRows.push(textRow.join(','));
					}
				});
				outputHeaderKeys = Object.keys(outputHeaders);
				t = addHeaders(outputHeaderKeys) + textRows.join('\n');
				d = '<table>' + addHeaders(outputHeaderKeys, true) + '<tr>' + tableRows.join('</tr><tr>') + '</tr></table>';
				if (outputHeaderKeys.length === 1) {
					// if there was only one cell selected, remove the quotes from the string
					//t = t.substring(1, t.length -1);
				}
				e.clipboardData.setData('text/html', d);
				e.clipboardData.setData('text/plain', t);
				e.clipboardData.setData('text/csv', t);
				e.clipboardData.setData('application/json', JSON.stringify(sData));
				e.preventDefault();
				gridEvent.preventDefault();
				return;
			}
		});
	},[grid]);
}


function useControlledPaste(props) {
	const{grid, onPaste}=props;

	useEffect(() => {
		if(!grid) return;
		// this clobbers the content, but may be what we want to use so the paste doesn't happen on a cancel
		/*
		grid.addEventListener('beforepaste', e => {
			debugger;
		});
		*/
		//This does nothing
		/*
		grid.controlInput.addEventListener('beforepaste', e => {
			debugger;
		});
		*/

		grid.controlInput.addEventListener('paste', e => {
			if (!onPaste) return false;
			//Apparently the paste event will fire no matter what we do and use any JSON content
			let {top,bottom,left} = grid.selectionBounds;
			if (bottom===Infinity) bottom=grid.viewData.length-1;
			const headerKeys = grid.schema.map(x=>x.name);
			let json = e.clipboardData.getData('application/json');
			let text = e.clipboardData.getData('text/plain');
			let width=null,height=null,values=null;
			// grid.selectNone();
			// grid.selectArea(grid.selectionBounds);
			if(json) { //prefer JSON
				json=JSON.parse(json);
				//console.log('onPaste json:',json);
				const rawValues = json.filter(x=>x!=null);
				width = Object.keys(rawValues[0]).length;
				height = rawValues.length;
				const oldKeys = Object.keys(rawValues[0]);
				const transform = headerKeys
					.slice(left,left+oldKeys.length)
					.map((newKey,i) => {
						return {newKey,oldKey:oldKeys[i]};
					});

				values = rawValues.map(x => {
					const n={};
					transform.forEach(({newKey,oldKey}) => n[newKey]=x[oldKey]);
					return n;
				});
			}else if (text){
				if (text.slice(-1)==='\n') text=text.slice(0,-1); //trailing newlines should be ignored
				values=text.split("\n").map((s)=>{
					let cols=s.split("\t");
					let headers=headerKeys.slice(left,left+cols.length);
					let o={};
					cols.forEach((v,j)=>o[headers[j]]=v);
					return o;
				});
				width=Object.keys(values[0]).length;
				height=values.length;

				//canvas-datagrid paste will attempt to use "text/html", and can't seem to override
				//We don't want to try to parse tables, which could be very rich and annoying (e.g. Excel)
				//e.clipboardData.clearData("text/html"); //doesn't work in chrome
				//e.clipboardData.setData("text/html","<table><tr><td>Foo</td></tr></table>"); //not supported

				//let ms='<html xmlns:v="urn:schemas-microsoft-com:vml"'; //Excel content
				//So we test for the failing datagrid test, AND if it's MS, and set the values manually for that
				//situation
				let htmlContent=e.clipboardData.getData('text/html')||"";
				//This is the identical test to the canvas-datagrid code
				if (htmlContent && !/^(<meta[^>]+>)?<table>/.test(htmlContent.substring(0, 29))) {
					values.forEach((v,i)=>{Object.assign(grid.data[top+i],v);});
					grid.draw();
				}
				if (!htmlContent){
					//If there's no HTML content, it won't be drawn because of a bug in the library
					values.forEach((v,i)=>{Object.assign(grid.data[top+i],v);});
					grid.draw();
				}
			}
			if (bottom-top+1 === Infinity){
				console.error("Error with calculations:",{bottom,top});
				alert("There was an error with the paste, try pasting fewer rows");
				return;

			}
			if (values.length===1 && (bottom-top+1)>1){
				if (confirm("Are you sure you want to fill "+(bottom-top+1)+" cells?")){
					let newValues=[];
					for (let i=0;i<(bottom-top)+1;i++){
						newValues.push(values[0]);
						Object.assign(grid.viewData[top+i],values[0]);
					}
					values=newValues;
				}else{
					//None of these seem to be supported in canvas-datagrid
					e.preventDefault();
					e.stopPropagation();
					e.cancelBubble=true;
					return false;
				}
			}
			onPaste({
				values,
				selection: grid.selectionBounds,
				start: {top,left},
				dimension: {width,height}
			});
			//None of these seem to be supported in canvas-datagrid
			e.preventDefault();
			e.stopPropagation();
			e.cancelBubble=true;
			return false;
		});
	},[grid]);
}

const formatters={
	number:v=>v.value,
	percent:v=>formatValue(v.value,'percent'),
	currency:v=>{return formatValue(v.value,'currency');},
	currency_nozero:v=>{
		if (v.value===0) return "";
		return formatValue(v.value,'currency');
	},
	date:v=>formatValue(v.value,'date'),
	utcdate:v=>formatValue(v.value,'utcdate'),
	month:v=>formatValue(v.value,'month')
};

function numSort(columnName,direction){
	let asc = direction === 'asc';
	return function (a, b) {
		if (asc) {return a[columnName] - b[columnName];}
		return b[columnName] - a[columnName];
	};
}

function stringSort(columnName,direction){
	let asc = direction === 'asc';
	return function (a, b) {
		let x=String(a[columnName]||"");
		let y=String(b[columnName]||"");
		if (asc) {return x<y?-1:1;}
		return y<x?-1:1;
	};
}
const sorters={
	number:numSort,
	percent:numSort,
	currency:numSort,
	currency_nozero:numSort,
	string:stringSort
};
function numFilter(v,filterFor){
	if (!filterFor) { return true; }
	/* eslint-disable eqeqeq */
	return v==filterFor;
}
/*
function stringFilter(v,filterFor){
	if (!filterFor) { return true; }
	return v==filterFor;
}
*/
const filters={
	number:numFilter,
	percent:numFilter,
	currency:numFilter,
	currency_nozero:numFilter,
};

function useFormatText({grid}){
	const px=8;
	useEffect(() => {
		if(!grid) return;

		grid.addEventListener('formattext', e => {
			if (e.cell.formattedValue && e.cell.width<e.cell.formattedValue.length*px){
				e.cell.text={ lines: [{value: e.cell.value.slice(0,e.cell.width/px)+"..." }]};
				e.preventDefault();
				return false;
			}
			return true;
		});
	});
}


// function useGridHasFocus({grid}) {
// 	const[gridHasFocus,setGridHasFocus]=useState(false);
//
// 	useEffect(() => {
// 		if(!grid) return;
//
// 		const setFocusIn = () => setGridHasFocus(true);
// 		grid.controlInput.addEventListener('focusin', setFocusIn);
//
// 		const setFocusOut = () => setGridHasFocus(false);
// 		grid.controlInput.addEventListener('focusout', setFocusOut);
//
// 		return () => {
// 			grid.controlInput.removeEventListener('focusin', setFocusIn);
// 			grid.controlInput.removeEventListener('focusout', setFocusOut);
// 		};
// 	}, [grid]);
//
//
// 	useEffect(() => {
// 		window.addEventListener('beforeunload', () => {
// 			console.log('useBeforeunload');
// 		});
// 		history.block('Are you sure you want to navigate away?');
// 	}, []);
//
// 	return gridHasFocus;
// };

function Header(props){
	const {selectedCells=[]}=props;
	/* selected cells has empty rows for unselected items, then populated with */
	let count=0;
	let lastVal=null;
	(selectedCells||[]).forEach(a=>{
		if (!a) return;
		Object.keys(a).forEach(k=>{
			count++;
			lastVal=a[k];
		});
	});
	//console.log("Last val=",lastVal);

	return <div className="w-100" style={{border:"0 0 0px 0 solid black",padding:0,margin:0}}>
		<input className="data-grid-primary-input w-100"
			style={{border:0,padding:"4px 8px",margin:0,fontSize:"1.3em"}}
			value={count===1?lastVal||"":""} disabled={count!==1}/></div>;
}

function Footer(props){
	const {selectedCells=[]}=props;
	/* selected cells has empty rows for unselected items, then populated with */
	let count=0;
	let sum=0;
	let distinct={};
	let lastVal=null;
	(selectedCells||[]).forEach(a=>{
		if (!a) return;
		Object.keys(a).forEach(k=>{
			count++;
			lastVal=a[k];
			if (parseFloat(lastVal)===lastVal){
				sum+=parseFloat(lastVal);
			}else{
				distinct[lastVal]=(distinct[lastVal]||0)+1;
			}
		});
	});
	return <div style={{display:"flex",justifyContent:"flex-end"}}>
		<span><strong>Cells:</strong> {count}</span>
		<span/>
		<span><strong>Sum:</strong> {sum}</span>
	</div>;
}

function PopoverMenuItem(props) {
	const {label,fn,grid} = props;
	const [anchorEl, setAnchorEl] = React.useState(null);

	return <div>
		<Button style={{whiteSpace:'nowrap',padding:'4px'}} onClick={(e) => {
			setAnchorEl(e.currentTarget);
		}}>{label}</Button>
		<Popover
			open={Boolean(anchorEl)}
			onClose={()=>setAnchorEl(null)}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
			<Box className="p-2">
				{(() => {
					if(!anchorEl) return '';
					return fn({grid});
				})()}
			</Box>
		</Popover>
	</div>;
};

function PopoverMenus(props) {
	const {items,grid}=props;
	if(!items||!items.length) return <React.Fragment/>;

	return <React.Fragment>
		{items.map(x => {
			return <PopoverMenuItem key={x.label} {...x} grid={grid} />;
		})}
	</React.Fragment>;
}

export default function DataGrid(props) {
	const{data:initialData,onUpdate,onRemove,onReplaceAll,columns:propsColumns,selectedFields,setSelectedFields,menu,onClick}=props;

	let columns=propsColumns.filter(f=>!f.alwaysHidden);
	if (selectedFields && selectedFields.length>0){
		columns=selectedFields.map(name=>{
			let c=propsColumns.find(f=>(f.alias==name)||(f.name===name));
			if (!c){
				console.log("Could not find field ",name,"in",propsColumns.map(d=>d.alias || d.name));
				return false;
			}
			if (c.alwaysHidden) return false;
			let o=Object.assign({},c);
			delete o.hidden;//not hidden by definition, so make sure this is false
			return o;
		}).filter(Boolean);
	}

	const [grid,setGrid]=useState(null);
	const [selectedCells,setSelectedCells]=useState(null);
	const ref=useRef(null);
	useEffect(() => {
		document.body.classList.add('no-overscroll');
		return function(){
			document.body.classList.remove('no-overscroll');
		};
	});
	// const gridHasFocus = useGridHasFocus({grid});

	function calculateFunctions(d){
		if (!grid) return;
		d=d|| grid.data;
		columns.forEach(f=>{
			if (typeof f.value=='function'){
				//console.log("Assigning value to column "+f.name+" for "+data.length+" records",f);
				d.forEach(row=>row[f.name]=f.value(row));
			}
		});
		grid.draw();
	}

	const handleRemove = (_grid,rows) => {
		if (!_grid || !rows){
			throw new Error("handleRemove requires grid and rows fields");
		}
		if (rows.length===0) return ;

		rows.forEach(row=>{
			let id=_grid.data.indexOf(row);
			if (id!==undefined)_grid.deleteRow(id);
			else{
				console.error("Could not find row");
			}
		});
		_grid.selectNone();
		_grid.draw();
		if(onRemove){
			onRemove({type:'remove',rows});
		}

	};
	const handleUpdate = update => {
		calculateFunctions();
		if(onUpdate){
			onUpdate(update);
		}
	};

	const {popup, dialog, findCell} = useFindDialog({
		grid,
		onReplace: (findText,replaceText,cell) => {
			const{row,columnName}=cell;
			let cellData=grid.data[row];
			const raw = cellData[columnName];
			const change={
				[columnName]:raw.split(findText).join(replaceText)
			};
			const changes = [{
				data:cellData,
				change
			}];
			grid.data[row] = Object.assign({},cellData,change);
			grid.draw();
			handleUpdate({
				type: 'update',
				changes
			});
		},
		onReplaceAll: (findText,replaceText) => {
			let count=0;
			const changes=[];
			let searchableColumns=columns.filter(c=>typeof c.value!='function').map(c=>c.name);
			grid.data.forEach((x,row) => {
				let change={};
				searchableColumns.forEach((y) => {
					if(x[y] != null && (x[y].toString()).indexOf(findText) !== -1) {
						count++;
						change[y] = x[y].split(findText).join(replaceText);
					}
				});
				if(Object.keys(change).length) changes.push({
					data:x,
					row,
					change
				});
			});

			if (!confirm(("Are you sure you want to change "+count+" entrie")+((count>1)?"s?":"?"))) return false;
			//grid.draw(); //draw happens with handleUpdate
			changes.forEach(c=>{
				grid.data[c.row] = Object.assign({},c.data,c.change);
			});
			if(onReplaceAll) return onReplaceAll(findText,replaceText);

			handleUpdate({
				type: 'update',
				changes
			});

		}
	});
	//useSortEvents({grid});
	useColorEvents({grid,columns});
	useKeyboardEvents({grid, handleUpdate});
	useRepairedCopy({grid});
	useFormatText({grid});
	useControlledPaste({grid, onPaste:(arg) => {
		//console.log('onPaste:',arg);
		const{start,values}=arg;
		const{top}=start;
		let startRow = top;

		const changes=[];
		values.forEach(change => {
			changes.push({
				data: grid.viewData[startRow++],
				change
			});
		});

		/*
			We have to set a timeout here, because otherwise the calculations are done
			before the paste has completed, so the values aren't available
		*/
		setTimeout(function(){
			handleUpdate({
				type: 'update',
				changes
			});
		},10);
	}});

	useEffect(() => {
		if(!findCell) return;
		//console.log('findCell:',findCell);
		const{row,column}=findCell;
		const rect={
			top: row,
			left: column,
			bottom: row,
			right: column
		};
		//console.log("Find selecting area ",rect);
		grid.selectArea(rect);
		grid.setActiveCell(row, column);
		if(!grid.isCellVisible(column,row)) grid.gotoCell(column,row,0.5,0.5);
		grid.draw(); //on find grid draw
	},[findCell]);

	useEffect(() => {
		let schema=(columns || Object.keys(initialData[0]).map(name => ({name}))).slice(0);
		schema.forEach(column=>{
			if (!sorters[column.type]){
				column.sorter=sorters.string;
			}
			if (filters[column.type]) column.filter=filters[column.type];
		});
		const g = canvasDatagrid({
			schema,
			formatters,
			sorters,
			//filters,
			allowColumnResizing:true,
			allowColumnResize:true,//one of these should work?
			attributes:{allowColumnResizing:true}
		});
		//Object.entries(filters).forEach(([ key, val ])=>g.filters[key]=val);
		setGrid(g);
		ref.current.appendChild(g);
		g.style.contextMenuBackground="#FFF";
		g.style.contextMenuItemMargin="10px";
		g.style.contextMenuPadding="5px";
		g.style.contextMenuHoverBackground="#EEE";
		g.style.contextMenuLabelMargin="10px";
		g.style.contextMenuBorder="1px solid #EEE";

		g.style.cellFont='15px "Inter var", sans-serif';
		g.style.activeCellFont=g.style.cellFont;
		//g.style.columnHeaderCellFont='bold 14px "Inter var", sans-serif';
		g.style.cellBorderWidth=0;
		g.style.columnHeaderCellBorderWidth=0;
		g.style.rowHeaderCellBorderWidth=0;
		// Overall grid
		g.style.gridBorderWidth=0;

		g.style.width = props.width || '100%';
		g.style.height = props.height || '800px';



		if(props.autoFocus) g.focus();

		// for some reason, this is NOT the default behavior,
		// without this: if you click somewhere, then select a box elsewhere,
		// it continues with the original selection. which is weird
		g.addEventListener('selectionchanged', e => {
			if (!e || !e.selectionBounds) return ;
			g.setActiveCell(e.selectionBounds.left,e.selectionBounds.top);
			setSelectedCells(g.selectedCells);
		});
		if (onClick){
			g.addEventListener('click', onClick);
		}
		//console.log("Adding event listener for context menu");
		g.addEventListener('contextmenu', function(e){
			//So, this is a bit weird, we can't REASSIGN the items array with filter, etc
			//but we can change them inline and splice them out
			if (e.items){
				e.items.forEach((i,x)=>{
					if (typeof i.title=='object') return;
					let include=(i.title &&
							(typeof i.title.indexOf=='function')
							&& (i.title.indexOf('Add/')!==0)
							&& (i.title.indexOf('Hide')!==0)
							&& (i.title.indexOf('Show')!==0)
							&& (i.title.indexOf('Clear')!==0));
					if (include) return;
					else e.items[x]=null;
				});
				let ix=e.items.findIndex(d=>d==null);
				while (ix>=0){
					e.items.splice(ix,1);
					ix=e.items.findIndex(d=>d==null);
				}
			}
			e.items.push({
				title: 'Find',
				items: [{
					title: 'Find'
				},{
					title: 'Find/Replace'
				}]
			});
			const rowLookup = {};
			g.forEachSelectedCell((_allRows,filteredRowIndex)=>{
				rowLookup[filteredRowIndex]=_allRows[filteredRowIndex];
			},true);
			const rows=Object.values(rowLookup);
			let text=`Remove ${rows.length} row${rows.length>1?'s':''}`;

			if (rows.length>0){
				e.items.push({
					title: text,
					click:e=>{
						if (confirm(text+"?")){
							handleRemove(g,rows);
						}
					}
				});
			}
		});

		return () => g.dispose();
	},[initialData,selectedFields]);

	useEffect(() => {
		if(grid && initialData){
			calculateFunctions(initialData);
			grid.data=initialData;
		}
	},[grid]);

	if(!initialData) throw new Error('data is a required prop');
	//console.log("Initial data:",initialData.slice(0,3));
	if (!props.height){return "height must be specified";}
	if (parseFloat(props.height)===props.height){return "height must be px or %, is "+props.height;}

	const overlay = (() => {
		if(!popup) return;
		return <div style={{
			position:'absolute',
			//zIndex:1000, //to raise it above other things
			top: '5px',
			right: '5px',
			margin: '5px'
		}}>
			<Paper>
				<div style={{
					margin: '5px',
					padding: '5px'
				}}>
					{popup}
				</div>
			</Paper>
		</div>;
	})();


	return <div id="data-grid-wrapper">
		{overlay}
		{dialog}
		<div id="data-grid-header" style={{display:"flex",alignItems: "right",justifyContent:"flex-end"}}>
			<Header selectedCells={selectedCells}/>
			{menu}
			<PopoverMenus items={props.popover_items} grid={grid} />
			{props.buttons?<span className="data-grid-buttons" key="buttons">{props.buttons}</span>:null}
			<ColumnPicker columns={propsColumns} selectedFields={selectedFields} setSelectedFields={setSelectedFields}/>
		</div>
		{/*<div id="data-grid-ref" ref={ref} style={{position:"absolute",top:30,bottom:20,left:0,right:0}}/>*/}
		<div id="data-grid-ref" ref={ref} style={{}}/>
		<div id="data-grid-footer"><Footer selectedCells={selectedCells}/></div>
	</div>;
}
